import { useTheme } from "@mui/material";

const ContactPhone = ({ width, height, fill }) => {
	const theme = useTheme();
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || "55.875"}
			height={height || "55.975"}
			viewBox="0 0 55.875 55.975"
		>
			<path
				id="phone-call_11_"
				data-name="phone-call (11)"
				d="M34.112,10.893a12.366,12.366,0,0,1,9.769,9.77M34.112,1A22.26,22.26,0,0,1,53.774,20.638M51.3,40.375v7.42a4.947,4.947,0,0,1-5.392,4.947,48.946,48.946,0,0,1-21.344-7.593,48.229,48.229,0,0,1-14.84-14.84A48.947,48.947,0,0,1,2.132,8.865,4.947,4.947,0,0,1,7.054,3.473h7.42A4.947,4.947,0,0,1,19.42,7.727a31.757,31.757,0,0,0,1.731,6.95A4.947,4.947,0,0,1,20.039,19.9L16.9,23.037a39.573,39.573,0,0,0,14.84,14.84l3.141-3.141A4.947,4.947,0,0,1,40.1,33.623a31.757,31.757,0,0,0,6.95,1.731A4.947,4.947,0,0,1,51.3,40.375Z"
				transform="translate(-0.11 1.209)"
				fill="none"
				stroke={fill || theme.palette.primary.light}
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="4"
			/>
		</svg>
	);
};

export default ContactPhone;

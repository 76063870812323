import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { decrypt } from "../../../../Helpers/Secret";
import { addCoupon } from "../../../../Services/api/coupon/couponProvider";
import ManageCoupon from "./ManageCoupon";

const CreateCoupon = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const submitHandler = async (values) => {
    let response = await addCoupon(values);
    if (response.status === 201 && response.data.status) {
      history.push(`${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/coupon`);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg ? response.data.msg : t("coupon.messages.create_success")}
        </span>,
        {
          onClose: () => {},
          autoClose: 1000,
        }
      );
    } else {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.message ? response.data.message : t("coupon.messages.create_failed")}
        </span>
      );
    }
  };

  return <ManageCoupon type="create" submitHandler={submitHandler} />;
};

export default CreateCoupon;

import { useTranslation } from "react-i18next";
import classes from "./bootDetails.module.css"


const BootDetailsAudios = ({ mainAudio, backgroundAudio }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classes["boot-details__audios"]}>
      {mainAudio && (
        <div className={classes["boot-details__audios-item"]}>
          <h4 className={classes["boot-details__audios-item-title"]}>
            {t("Exhibition.main_audio")}
          </h4>
          <audio controls>
            <source src={mainAudio} />
          </audio>
        </div>
      )}
      {backgroundAudio && (
        <div className={classes["boot-details__audios-item"]}>
          <div className={classes["boot-details__audios-item-title"]}>
            {t("Exhibition.background_audio")}
          </div>
          <audio controls>
            <source src={backgroundAudio} />
          </audio>
        </div>
      )}
    </div>
  );
};

export default BootDetailsAudios;

import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";
export function getEventPerMonth(start_time, end_time) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.calender, {
          params: { start_time: start_time, end_time: end_time },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getExhibitionSponsors } from "../../../../Redux/Actions/exhibition/exhibition.action";
import SponsorsSlider from "../../../Shared/Components/SponsorsSlider/SponsorsSlider";
import classes from "./exhibitionDetails.module.css";
const ExhibitionDetailsSlider = ({ id, title }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { sponsorsLoading, sponsorsError, sponsors } = useSelector((state) => state.exhibitionReducer);

  useEffect(() => {
    dispatch(getExhibitionSponsors(id));
  }, [id]);

  return (
    <div className={classes["exhibition-details__slider"]}>
      <div className={classes["sponsors-slider__head-title"]}>{title}</div>
      {sponsors && !!sponsors.length ? (
        <SponsorsSlider sponsorsList={sponsors} />
      ) : (
        <div className={classes["exhibition-details__no-data"]}>{t("Exhibition.no_sponsers")}</div>
      )}
    </div>
  );
};

export default ExhibitionDetailsSlider;

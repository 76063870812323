import React from "react";
import i18n from "../../../i18n/i18n";
import StarsRating from "../../Shared/Components/StarsRating/StarsRating";

export default function RattingBox({ item }) {
  return (
    <div className="tw-border tw-border-gray-200 tw-rounded tw-py-4 tw-space-y-4 border">
      <div className="tw-inline-flex tw-items-center tw-justify-between tw-w-full tw-px-4">
        <div className="tw-inline-flex tw-items-center tw-justify-center tw-space-s-4">
          <div
            className="tw-bg-white-200 tw-mx-auto tw-rounded-full tw-h-12 tw-aspect-square tw-bg-cover tw-bg-no-repeat tw-bg-center tw-shadow-lg"
            style={{
              backgroundImage: `url(${item.rated_by.img})`,
            }}
          ></div>
          <div className="tw-py-2">
            <div
              className=" tw-text-center sm:tw-text-start"
              style={{ color: "#24b3b9" }}
            >
              {i18n.language === "ar"
                ? item.rated_by.name_ar
                : item.rated_by.name_en}
              <br />
              <span style={{ fontSize: "14px" }} className="tw-text-gray-400">
                {item.rated_by.email}
              </span>
            </div>
          </div>
        </div>
        <div>
          <StarsRating rating={item.stars || 0} />
        </div>
      </div>
      <div
        className=" tw-text-sm tw-px-4 ticket_view-replies-body"
        style={{ wordBreak: "break-word" }}
        dangerouslySetInnerHTML={{
          __html: item.description,
        }}
      />
    </div>
  );
}

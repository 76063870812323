import ArrowUpward from "@material-ui/icons/ArrowUpward";
import i18n from "i18next";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import toLocalTimezone from "../../../../Services/Timezone";
import { sendActivityResponse } from "../../../../Services/api/Trainee/TraineeProvider";
import { Lang } from "../../../../utils";

function LessonActivity(props) {
    require("./LessonActivity.css");
    const {t} = useTranslation();
    const [userResponse, setUserResponse] = useState("");
    const inputFile = useRef(null);
    function handleChange(event) {
        setUserResponse(event.target.value)
    }

    function sendComment() {
        let formData = new FormData();
        formData.append('content', userResponse);
        formData.append('activity_id', props.activity.id);
        formData.append('type', "text");

        sendActivityResponse(formData)
            .then((res) => {
                
                if (res.status && res.status == 200 && res.data.status) {
                    toast.success(
                        <span style={{fontSize: 13, fontWeight: "bold"}}>
                                {t('trainee.lesson.activity.successMessage')}
                              </span>
                    );
                    window.location.reload();
                }
                else{
                    toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {
                              typeof res === "string" ?
                                  <span style={{ fontSize: 12, fontWeight: "500", display:'block' }}>{res}</span>
                              :
                                  res.data?
                                      <>
                                          <span style={{ fontSize: 13, fontWeight: "bold", display:'block' }}>{res.data.msg}</span>
                                          <ul>
                                              {
                                                  Object.keys(res.data.errors).map(key => {
                                                      return (
                                                          <li><span style={{fontSize: 11}}>{res.data.errors[key]}</span></li>
                                                      )
                                                  })
                                              }
                                          </ul>
                                      </>
                                  :
                                  t("Failure_in_service")
                          }

                        </span>
                      );
                }
            })
            .catch((err) => {
                toast.error(
                    <span style={{ fontSize: 13, fontWeight: "bold", display:'block' }}>{err.data.msg}</span>
                );
            });
    }

    return (
        <div className="tajah-trainee-activity-container">
            <div className="tajah-trainee-activity-title">
                {t("trainee.lesson.activity.title")}
            </div>
            <div className="tajah-trainee-activity-name">
                {props.activity.name}
            </div>
            <div className="tajah-trainee-activity-description">
                {props.activity.description.replace(/<\/?[^>]+(>|$)/g, "")}
            </div>
            {
                props.activity.responses.map(item => {

                    let ext = item.content.slice((item.content.lastIndexOf(".") - 1 >>> 0) + 2);
                    let fileType = "file";
                    ext = ext.toLowerCase();
                    if (ext == "jpg" || ext == "jpeg" || ext == "png" || ext == "gif")
                        fileType = "image";
                    return (
                    props.activity.require_approval==0||item.is_approved==1?
                        <div className="tajah-trainee-activity-response">
                            <div style={{width: "100%", marginBottom: "20px"}} className="row">
                                <img
                                    src={item.user.img}
                                    alt="..."
                                    className="tajah-trainee-activity-response-sender-img"
                                />
                                <div>
                                    <div className="tajah-trainee-activity-response-sender-name">
                                        {i18n.language === Lang.AR ? item.user.name_ar : item.user.name_en}
                                    </div>
                                    <div className="tajah-trainee-activity-response-createdAt">
                                        {
                                            moment(new Date(toLocalTimezone(item.created_at))).format("yyyy/MM/dd")
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="tajah-trainee-activity-response-content">
                                {
                                    item.is_file == 1 && fileType == "image"?
                                        <img width="100%" height="100%" src={item.content}></img>
                                        : item.is_file == 1 && fileType == "file" ?
                                            <a href={item.content} target="_blank">
                                                <button className="tajah-trainee-activity-form-download-response">
                                                    {t("trainee.lesson.activity.downloadResponse")}
                                                </button>
                                            </a>
                                            : item.content
                                }
                            </div>
                        </div>:<></>
                    );
                })
            }
            <div className="tajah-trainee-activity-form">
                <div>
                    <img
                        src={localStorage.getItem("img")}
                        alt="..."
                        className="tajah-trainee-activity-form-sender-img"
                    />
                    <textarea
                        value={userResponse}
                        onChange={handleChange}
                        rows={5}
                        className="tajah-trainee-activity-form-textarea"
                        placeholder={t("trainee.lesson.activity.responsePlaceholder")}
                    />
                </div>
                <button className="tajah-trainee-activity-form-sendButton" onClick={sendComment}>
                    {t("trainee.lesson.activity.sendResponse")}
                </button>
                <button className="tajah-trainee-activity-form-sendFile" onClick={() => {
                    if (inputFile) {
                        inputFile.current.click();
                    }
                }}>
                    <ArrowUpward style={{
                        float: "right",
                        height: "100%",
                        width: "30%", backgroundColor: "#006D77",
                        borderRadius: "7px", color: "white",
                    }}></ArrowUpward>
                    {t("trainee.lesson.activity.sendFile")}
                </button>
                <input
                    id="file"
                    name="file"
                    type="file"
                    ref={inputFile}
                    style={{display: "none"}}
                    onChange={(event) => {
                        let file = event.target.files[0];
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            let formData = new FormData();
                            formData.append('content', event.target.files[0]);
                            formData.append('activity_id', props.activity.id);
                            formData.append('type', "image");
                            sendActivityResponse(formData)
                                .then((res) => {
                                    if (res.status && res.data.status ) {
                                        toast.success(
                                            <span style={{fontSize: 13, fontWeight: "bold"}}>
                                                    {t('trainee.lesson.activity.successMessageFile')}
                                                  </span>
                                        );
                                        window.location.reload();
                                    } else {
                                        toast.error(
                                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                              {
                                                  typeof res === "string" ?
                                                      <span style={{ fontSize: 12, fontWeight: "500", display:'block' }}>{res}</span>
                                                  :
                                                      res.data?
                                                          <>
                                                              <span style={{ fontSize: 13, fontWeight: "bold", display:'block' }}>{res.data.msg}</span>
                                                              <ul>
                                                                  {
                                                                      Object.keys(res.data.errors).map(key => {
                                                                          return (
                                                                              <li><span style={{fontSize: 11}}>{res.data.errors[key]}</span></li>
                                                                          )
                                                                      })
                                                                  }
                                                              </ul>
                                                          </>
                                                      :
                                                      t("Failure_in_service")
                                              }

                                            </span>
                                          );
                                    }
                                })
                                .catch((err) => {
                                    toast.error(
                                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                              {
                                                  typeof err === "string" ?
                                                      <span style={{ fontSize: 12, fontWeight: "500", display:'block' }}>{err}</span>
                                                      :
                                                      err.data?
                                                          <>
                                                              <span style={{ fontSize: 13, fontWeight: "bold", display:'block' }}>{err.data.msg}</span>
                                                              <ul>
                                                                  {
                                                                      Object.keys(err.data.errors).map(key => {
                                                                          return (
                                                                              <li><span style={{fontSize: 11}}>{err.data.errors[key]}</span></li>
                                                                          )
                                                                      })
                                                                  }
                                                              </ul>
                                                          </>
                                                          :
                                                          t("Failure_in_service")
                                              }

                                            </span>
                                    );
                                });
                        };
                        reader.onerror = function (error) {
                            toast.error(<span style={{fontSize: 13, fontWeight: "bold"}}>
                                        {error}
                                    </span>);
                        };
                    }}
                />
            </div>
        </div>
    )
}

export default LessonActivity;

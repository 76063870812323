import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Lang } from "../../../utils";
import { utcToLocal } from "../../../utils/utcToLocal";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import classes from "./traineeCertificates.module.css";
const TraineeCertificatesTable = () => {
  const { t, i18n } = useTranslation();
  const { traineeCertificatesList, traineeCertificatesListMeta, traineeCertificatesListLoading } = useSelector(
    (state) => state.traineeCertificatesReducer
  );

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "trainee_name",
      name: t("department_trainees.trainee_name"),
      key: "trainee_name",
      cell: ({ rowData }) => (
        <div className={classes["certificates__table-name "]}>
          {i18n.language == Lang.AR ? rowData?.user?.name_ar || "-" : rowData?.user?.name_en || "-"}
        </div>
      ),
    },
    {
      id: "trainee_email",
      name: t("department_trainees.trainee_email"),
      key: "trainee_email",
      cell: ({ rowData }) => <div className={classes["certificates__table-email "]}>{rowData?.user?.email || "-"}</div>,
    },
    {
      id: "date_issuing_certificate",
      name: t("certificate.date_issuing_certificate"),
      key: "date_issuing_certificate",
      cell: ({ rowData }) => (
        <>
          {rowData?.created_at ? (
            <>
              {moment(rowData?.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.created_at)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "serial",
      name: t("crud.list.serial"),
      key: "serial",
      cell: ({ rowData }) => <span className="cell-font">{rowData.serial}</span>,
    },
  ];

  return (
    <MainTable columns={columns} data={traineeCertificatesList || []} isLoading={traineeCertificatesListLoading} />
  );
};

export default TraineeCertificatesTable;

import { Pagination } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { updateBoothStatus } from "../../../../Redux/Actions/exhibition/exhibition.action";
import deleteIcon from "../../../../assets/icons/delete.svg";
import ViewExhDetailsIcon from "../../../../assets/icons/eye.svg";
import draftBoothIcon from "../../../../assets/icons/hourglass-end.svg";
import editExhIcon from "../../../../assets/icons/pen.svg";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./exhibitionDetails.module.css";

const BoothTable = ({ bankTransferActionHandler, openCancelModal, setCurrentPage, sortAction, activeTab }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id: exhibitionId } = useParams();
  const { boothsLoading, boothsError, booths, boothsMeta } = useSelector((state) => state.exhibitionReducer);

  const [showCancelModal, setShowCancelModal] = useState(false);

  const boothActionsList = [
    {
      id: "view-details",
      icon: <img src={ViewExhDetailsIcon} alt="" />,
      action: (id) => {
        moveToDetails(id);
      },
      tooltip: t("Exhibition.view_details"),
    },
    {
      id: "edit",
      icon: <img src={editExhIcon} alt="" />,
      action: (id) => {
        moveToEdit(id);
      },
      tooltip: t("booth.edit"),
      hide: activeTab !== 2,
    },
    {
      id: "draft-booth",
      icon: <img src={draftBoothIcon} alt="" />,
      action: (id) => {
        moveToDraftDetails(id);
      },
      tooltip: t("Exhibition.view_details"),
      hide: activeTab !== 2,
    },
    // ...(activeTab === 2
    //   ? [
    //       {
    //         id: "delete",
    //         icon: <img src={deleteIcon} alt="" />,
    //         action: (id) => {
    //           openCancelModal(id);
    //         },
    //         tooltip: t("general.delete"),
    //       },
    //     ]
    //   : []),
  ];
  const history = useHistory();

  const paymentDisplay = {
    Bank: t("Exhibition.popuppaymentbanktran"),
    Wallet: t("Exhibition.popuppaymentwallet"),
    Visa: t("Exhibition.visa"),
  };

  const boothSizeDisplay = {
    small: t("booth.small"),
    medium: t("booth.medium"),
    larg: t("booth.large"),
  };

  const paymentStatusDisplay = {
    1: t("general.waiting"),
    2: t("general.approved"),
    3: t("general.rejected"),
  };

  const updateBoothStatusHandler = async (id, status) => {
    dispatch(updateBoothStatus({ id }));
  };

  const moveToDetails = (id) => {
    history.push(`/admin/booth-details/${id}`);
  };

  const moveToDraftDetails = (id) => {
    history.push(`/admin/draft-booth-details/${id}`);
  };

  const moveToEdit = (id) => {
    history.push(`/admin/booth-edit/${exhibitionId}_${id}`);
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData.id}</>,
    },

    {
      id: "name",
      name: t("booth.name"),
      key: "name",
      cell: ({ rowData }) => <div className={classes["exhibition-details__table-name"]}>{rowData.name}</div>,
      sortable: false,
    },
    // {
    //   id: "order_date",
    //   name: t("Exhibition.order_date"),
    //   key: "start_date",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.cartItem?.cart?.order?.created_at
    //         ? moment(rowData?.cartItem?.cart?.order?.created_at).format("DD/MM/YYYY")
    //         : "-"}
    //     </>
    //   ),
    //   sortable: false,
    // },
    {
      id: "size",
      name: t("Exhibition.size"),
      key: "size",
      cell: ({ rowData }) => <>{boothSizeDisplay[rowData.size]}</>,
      sortable: false,
    },
    {
      id: "position",
      name: t("Exhibition.position"),
      key: "position",
      cell: ({ rowData }) => <>{rowData.position}</>,
      sortable: false,
    },
    ...(activeTab === 2
      ? [
          {
            id: "payment_method",
            name: t("Exhibition.payment_method"),
            key: "payment_method",
            cell: ({ rowData }) => {
              return (
                <div className={classes["exhibition-details__table-payment-method"]}>
                  {rowData?.payment?.payment_method === "Bank" ? (
                    <button
                      className={classes["exhibition-details__table-payment-method--banktransfer"]}
                      onClick={() => {
                        bankTransferActionHandler(rowData?.payment);
                      }}
                    >
                      {paymentDisplay[rowData?.payment?.payment_method] || "-"}
                    </button>
                  ) : (
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      {rowData?.payment?.amount === ".00"
                        ? t("free")
                        : paymentDisplay[rowData?.payment?.payment_method] || "-"}
                    </p>
                  )}
                </div>
              );
            },
            sortable: false,
          },
          {
            id: "payment_status",
            name: t("Exhibition.payment_status"),
            key: "payment_status",
            cell: ({ rowData }) => <>{paymentStatusDisplay[Number(rowData?.payment_status)] || "-"}</>,
            sortable: false,
          },
          {
            id: "visitCount",
            name: t("Exhibition.number_of_visitors"),
            key: "visitCount",
            cell: ({ rowData }) => <>{rowData.visit_count !== 0 ? rowData.visit_count : "-"}</>,
            sortable: false,
          },
          {
            id: "rating",
            name: t("Exhibition.rating"),
            key: "rating",
            cell: ({ rowData }) => <StarsRating rating={rowData.rate_avg || 0} />,
            sortable: false,
          },
        ]
      : []),
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <div className={classes["exhibition-details__table__actions"]}>
          {boothActionsList.map((action, index) => {
            const isDraftBoothAction = action.id === "draft-booth";
            const shouldRenderButton = !action.hide && (!isDraftBoothAction || rowData?.edit_status === "5");

            return (
              shouldRenderButton && (
                <button
                  key={action.id}
                  className={
                    action.id === "view-details" && false
                      ? classes["exhibition-details__table__actions-btn-updated"]
                      : classes["exhibition-details__table__actions-btn"]
                  }
                  title={action.tooltip}
                  onClick={() => {
                    action.action(rowData.id);
                  }}
                >
                  {action.icon}
                </button>
              )
            );
          })}
        </div>
      ),
      sortable: false,
    },
    ...(activeTab === 2
      ? [
          {
            id: "status",
            name: t("Exhibition.status_exh"),
            key: "status",
            cell: ({ rowData }) => (
              <SwitchToggle
                status={rowData.status == 1}
                id={""}
                onStatusChange={() => {
                  updateBoothStatusHandler(rowData.id);
                }}
              />
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  return (
    <div className={classes["exhibition-details__tables-booths"]}>
      <MainTable columns={columns} data={booths} isLoading={boothsLoading} sortAction={sortAction} />
      <Pagination
        count={boothsMeta?.totalPages || 0}
        showFirstButton
        showLastButton
        variant="outlined"
        shape="rounded"
        className="main-pagination"
        onChange={(e, page) => {
          setCurrentPage(page);
        }}
      />
    </div>
  );
};

export default BoothTable;

import FilterIcon from "../../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../../assets/icons/xls.svg";

export const filterActions = ({ t, showFilter, exportPdf, exportExcel, walletTransaction }) => {
  const actionsList = [
    ...(walletTransaction
      ? [
          {
            id: "walletTransaction",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #8599B6",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label: t("wallet.label.wallet_transaction"),
            btnTextColor: "#8599B6",
            action: walletTransaction,
          },
        ]
      : []),
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};

import React, {useState} from "react";
// import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import order from "../../../../../assets/image/Order.png";
import trash from "../../../../../assets/image/trash.png";
import gear from "../../../../../assets/image/gear.png";
import edit from "../../../../../assets/image/edit(1).png";
import ContentExplanation from "../../contentExplanation/contentExplanation";
import CourseOutlineList from "../../courseOutlineList/courseOutlineList";
import body from "../../../../../assets/image/images_5wv9.png";
import Drag from "../../drag/drag";

const finalSpaceCharacters = [
  {
    id: "1",
    name: "مثال تجريبي للإجابة",
    thumb: "/images/gary.png",
  },
  {
    id: "2",
    name: "مثال تجريبي للإجابة",
    thumb: "/images/cato.png",
  },
  {
    id: "3",
    name: "مثال تجريبي للإجابة",
    thumb: "/images/kvn.png",
  },
  {
    id: "5",
    name: "مثال تجريبي للإجابة",
    thumb: "/images/mooncake.png",
  },
  {
    id: "6",
    name: "مثال تجريبي للإجابة",
    thumb: "/images/quinn.png",
  },
];

function CourseQuestion() {
  const [selectOption, setSelectOption] = useState("0");
  const [radioValue, setRadioValue] = useState("");
  const [characters, updateCharacters] = useState(finalSpaceCharacters);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  }

  const changeRadio = (event) => {
    setRadioValue(event.target.value);
  };

  const selectOptionHandler = (e) => {
    setSelectOption(e.target.value);
  };

  let selectValue = "";
  switch (selectOption) {
    case "1":
      selectValue = (
        <div className="questios_type">
          <div className="questios_type_header">
            <div className="">
              <div className="questios_type_label">سؤال HTML</div>
              <div className="questios_type_point">5 نقاط تضاف الي رصيدك</div>
            </div>
            <button className="edit_select_chapter_2">
              <img src={edit} alt="" className="edit_select_chapter_icon" />
              <div className="icon_text_new_season">تحرير المحتوي</div>
            </button>
          </div>

          <div className="">
            <label htmlFor="" className="questios_input_label">
              كم يبلغ ارتفاع برج ايفل؟
            </label>
            <input
              type="text"
              name=""
              id=""
              className="question_list_selectBox"
              placeholder="يرجي كتابة الإجابة وسيتم المراجعة من قبل المدرب مثال نص تجريبي"
            />
          </div>
        </div>
      );
      break;

    case "2":
      selectValue = (
        <div className="questios_type">
          <div className="questios_type_header">
            <div className="">
              <div className="questios_type_label">سؤال إختيار من متعدد</div>
              <div className="questios_type_point">5 نقاط تضاف الي رصيدك</div>
            </div>
            <button className="edit_select_chapter_2">
              <img src={edit} alt="" className="edit_select_chapter_icon" />
              <div className="icon_text_new_season">تحرير المحتوي</div>
            </button>
          </div>

          <div className="">
            <label htmlFor="" className="questios_input_label">
              دولة عربية شاركت في كأس العالم خمس مرات متتالية؟
            </label>

            <div className="training_questions_se">
              <div className="">
                <input
                  className="form-check-input training_questions_display"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                  onChange={changeRadio}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  مصر
                </label>
              </div>
              <div className="">
                <input
                  className="form-check-input training_questions_display"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                  onChange={changeRadio}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  المملكة العربية السعودية
                </label>
              </div>

              <div className="">
                <input
                  className="form-check-input training_questions_display"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="option3"
                  onChange={changeRadio}
                />
                <label className="form-check-label" htmlFor="inlineRadio3">
                  الأردن
                </label>
              </div>
            </div>
          </div>
        </div>
      );
      break;

    case "3":
      selectValue = (
        <div className="questios_type">
          <div className="questios_type_header">
            <div className="">
              <div className="questios_type_label">سؤال إختيار من متعدد</div>
              <div className="questios_type_point">5 نقاط تضاف الي رصيدك</div>
            </div>
            <button className="edit_select_chapter_2">
              <img src={edit} alt="" className="edit_select_chapter_icon" />
              <div className="icon_text_new_season">تحرير المحتوي</div>
            </button>
          </div>

          <div className="">
            <label htmlFor="" className="questios_input_label">
              هل شاركت الأردن في كأس العالم 5 مرات متتالية
            </label>

            <div className="personal_radio">
              <div className="form-check form-check-inline ">
                <input
                  className="form-check-input "
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  نعم
                </label>
              </div>
              <div className="form-check form-check-inline second_radio">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  لا
                </label>
              </div>
            </div>
          </div>
        </div>
      );
      break;

    case "4":
      selectValue = (
        <div className="questios_type">
          <div className="questios_type_header">
            <div className="">
              <div className="questios_type_label">سؤال ترتيب الإختيارت</div>
              <div className="questios_type_point">5 نقاط تضاف الي رصيدك</div>
            </div>
            <button className="edit_select_chapter_2">
              <img src={edit} alt="" className="edit_select_chapter_icon" />
              <div className="icon_text_new_season">تحرير المحتوي</div>
            </button>
          </div>

          <div className="">
            <label htmlFor="" className="questios_input_label">
              انقل العناصر التالية الي المجموعة الخاصة بها؟
            </label>

            {/* <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {characters.map((qes, index) => {
                      return (
                        <Draggable
                          key={qes.id}
                          draggableId={qes.id}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="order_question"
                            >
                              <div className="">
                                {qes.id}- {qes.name}
                              </div>
                              <img src={order} alt="" />
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext> */}
          </div>
        </div>
      );
      break;

    case "5":
      selectValue = (
        <div className="questios_type">
          <div className="questios_type_header">
            <div className="">
              <div className="questios_type_label"> سؤال سحب وإفلات </div>
              <div className="questios_type_point">5 نقاط تضاف الي رصيدك</div>
            </div>
            <button className="edit_select_chapter_2">
              <img src={edit} alt="" className="edit_select_chapter_icon" />
              <div className="icon_text_new_season">تحرير المحتوي</div>
            </button>
          </div>

          <Drag />
        </div>
      );
      break;

    case "6":
      selectValue = (
        <div className="questios_type">
          <div className="questios_type_header">
            <div className="">
              <div className="questios_type_label">سؤال hotspot </div>
              <div className="questios_type_point">5 نقاط تضاف الي رصيدك</div>
            </div>
            <button className="edit_select_chapter_2">
              <img src={edit} alt="" className="edit_select_chapter_icon" />
              <div className="icon_text_new_season">تحرير المحتوي</div>
            </button>
          </div>

          <div className="">
            <label htmlFor="" className="questios_input_label">
              حدد علي الصورة التالية أعضاء جسم الانسان مثال؟
            </label>
            <div className="move_question">
              <div className="questios_input_label_small">
                قم بكتابة إسم العضو المشار اليه في الصورة مثال نص تجريبي
              </div>
              <div className="body_question">
                <div className="body_question_form">
                  <label htmlFor="" className="question_list_label">
                    إجابة رقم 1
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="question_list_selectBox"
                    placeholder="اكتب الإجابة هنا"
                  />
                  <label htmlFor="" className="question_list_label">
                    إجابة رقم 2
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="question_list_selectBox"
                    placeholder="اكتب الإجابة هنا"
                  />
                  <label htmlFor="" className="question_list_label">
                    إجابة رقم 3
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="question_list_selectBox"
                    placeholder="اكتب الإجابة هنا"
                  />
                  <label htmlFor="" className="question_list_label">
                    إجابة رقم 4
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="question_list_selectBox"
                    placeholder="اكتب الإجابة هنا"
                  />
                </div>
                <img src={body} alt="" />
              </div>
            </div>
          </div>
        </div>
      );
      break;

    case "7":
      selectValue = <div></div>;
      break;

    default:
      break;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="training_first_label">الفصل الأول / مقدمة</div>
          <div className="course_outline_content">
            <div className="chapter_flex">
              <div className="new_chapter">
                <div className="new_chapter_season">
                  <div className="new_chapter_select_season">
                    إختبار الفصل الأول
                  </div>
                  <div className="new_season_icons">
                    <img src={gear} alt="" className="new_season_icon" />
                    <img src={trash} alt="" className="new_season_icon" />
                    <img src={order} alt="" className="new_season_icon" />
                  </div>
                </div>
                <div className="card card_width">
                  <div className="new_chapter_season">
                    <div className="new_chapter_select_season">إضافة سؤال</div>
                    <div className="new_season_icons_3">
                      <img src={gear} alt="" className="new_season_icon" />
                      <img src={order} alt="" className="new_season_icon" />
                    </div>
                  </div>
                  <div className="question_list">
                    <label htmlFor="">نوع السؤال :</label>
                    <select
                      name=""
                      id=""
                      defaultValue="0"
                      className="question_list_selectBox"
                      onChange={selectOptionHandler}
                    >
                      <option value="0" disabled>
                        -----------إختر نوع السؤال--------------
                      </option>
                      <option value="1"> HTML سؤال</option>
                      <option value="2">إختيار من متعدد</option>
                      <option value="3">صح وخطأ</option>
                      <option value="4">ترتريب الإختيارات</option>
                      <option value="5">سحب وإفلات</option>
                      <option value="6">Hot Spot</option>
                      <option value="7">H5P</option>
                    </select>

                    <label htmlFor="" className="question_list_label">
                      الأهداف التعليمية :
                    </label>
                    <input
                      type="text"
                      name=""
                      id=""
                      className="question_list_selectBox"
                      placeholder="تطوير المهارات,  تنفيذ عملي,  مثال تجريبي,  شكرا لكم"
                    />

                    {selectValue}
                  </div>
                </div>
              </div>
            </div>
            <div className="explain_content">
              <ContentExplanation />
              <CourseOutlineList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseQuestion;

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteFile, uploadFile, uploadMultipleFiles } from "../../../../Services/api/files/filesProvider";
import DocumentIcon from "../../../../assets/icons/document.svg";
import FileIcon from "../../../../assets/icons/file.svg";
import RemoveGrayIcon from "../../../../assets/icons/remove-gray.svg";
import RemoveRedIcon from "../../../../assets/icons/remove-red.svg";
import RemoveWhiteIcon from "../../../../assets/icons/remove-white.svg";
import UploadIcon from "../../../../assets/icons/upload.svg";
import VoiceIcon from "../../../../assets/icons/voice.svg";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import { toast } from "react-toastify";
import Input from "../Input/Input";
import classes from "./inputFile.module.css";
const icon = {
  audio: VoiceIcon,
  document: FileIcon,
  image: FileIcon,
};

const previewIcon = {
  audio: VoiceIcon,
  document: DocumentIcon,
};
const removeIcon = {
  image: RemoveGrayIcon,
  gallery: RemoveGrayIcon,
  audio: RemoveWhiteIcon,
  document: RemoveRedIcon,
};
const InputFile = ({
  placeholder,
  btnText,
  multiple = false,
  name,
  setFieldValue,
  fileIcon,
  accept,
  type,
  documentWithoutTitle,
  removeHandler,
  handleDownload,
  label,
  note,
  maxSize,
  error,
  validate,
  validateMultiple,
  handleChangeAboutMe,
  value,
  uploadDirectly = false,
  disabled = false,
  required = false,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!value) return;
    setUploadedFiles(Array.isArray(value) ? value : [value]);
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleInputChange = (e) => {
    handleChangeAboutMe && handleChangeAboutMe(e);
    const files = e.target.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    setIsLoading(true);
    const fileList = [...files];

    const filesWithPreviews = fileList.map((file) => {
      const previewFile = {
        file,
        preview: URL.createObjectURL(file),
        name: file.name,
      };
      if (type === "document") {
        previewFile.title = "";
        previewFile.status = false;
      }
      return previewFile;
    });

    if (!uploadDirectly) {
      if (!multiple) {
        setUploadedFiles(filesWithPreviews.slice(-1));
        setFieldValue(name, filesWithPreviews[0]);
        setIsLoading(false);
      } else {
        setUploadedFiles((prevFiles) => [...prevFiles, ...filesWithPreviews]);
        setFieldValue(name, [...uploadedFiles, ...filesWithPreviews]);
        setIsLoading(false);
      }
    } else {
      if (!multiple) {
        const valid = validate(files[0], type);
        if (valid) {
          const formData = new FormData();
          formData.append("file", files[0]);
          formData.append("collection_name", "exhibitions");
          formData.append("key", name.split("[")[0]);
          uploadFile(formData)
            .then((res) => {
              setFieldValue(name, res.data.media.file_path);
              setUploadedFiles(filesWithPreviews.slice(-1));
            })
            .catch((err) => {
              if (err.response.status == 413) {
                toast.error(
                  <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    {i18n.language === "ar" ? "حجم الملف كبير جدا" : "The file is too Large"}
                  </span>
                );
              } else {
                toast.error(
                  <>
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "bold",
                        display: "block",
                        paddingBottom: "10px",
                      }}
                    >
                      {err.response.data.msg}
                    </span>
                    <ul>
                      {Object.keys(err.response.data.errors).map((key) => {
                        return (
                          <li>
                            <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                );
              }
            })
            .finally(() => {
              setFieldValue("filesError", {});
              setIsLoading(false);
            });
        } else {
          setFieldValue("filesError", {
            [name.split("[")[0]]: {
              index: name.split("[")[1]?.split("]")[0] ? Number(name.split("[")[1]?.split("]")[0]) : 0,
              error: true,
            },
          });
          setIsLoading(false);
        }
      } else {
        const valid = validateMultiple(files, type);
        if (valid) {
          const formData = new FormData();
          formData.append("collection_name", "exhibitions");
          formData.append("key", name.split("[")[0]);
          for (let i = 0; i < files.length; i++) {
            formData.append("files[]", files[i]);
          }
          uploadMultipleFiles(formData)
            .then((res) => {
              const newFiles = res.data.media.map((file) => {
                if (type === "document") {
                  return {
                    file: file.file_path,
                    title: "",
                    status: false,
                  };
                } else {
                  return {
                    file: file.file_path,
                  };
                }
              });
              setFieldValue(name, [...value, ...newFiles]);
              setUploadedFiles([...uploadedFiles, ...filesWithPreviews]);
            })
            .catch((err) => {})
            .finally(() => {
              setFieldValue("filesError", {});
              setIsLoading(false);
            });
        } else {
          setFieldValue("filesError", {
            [name.split("[")[0]]: {
              index: name.split("[")[1]?.split("]")[0] ? Number(name.split("[")[1]?.split("]")[0]) : 0,
              error: true,
            },
          });
          setIsLoading(false);
        }
      }
    }
  };

  const handleRemove = (index) => {
    fileInputRef.current.value = "";
    setIsLoading(true);
    const file = {
      file_path: multiple
        ? value[index]?.preview
          ? value[index]?.preview.split("exhibitions/")[1]
          : value[index]?.file
        : value?.preview
        ? value?.preview.split("exhibitions/")[1]
        : value,
    };
    deleteFile(file)
      .then((res) => {})
      .catch((err) => {});
    setUploadedFiles((prevFiles) => {
      if (!multiple) {
        setFieldValue(name, null);
        setIsLoading(false);
        return [];
      } else {
        setFieldValue(
          name,
          value.filter((file, i) => i !== index)
        );
        setIsLoading(false);
        return prevFiles.filter((file, i) => i !== index);
      }
    });
  };

  const handleChange = (event, index) => {
    const { name, value, checked } = event.target;
    setFieldValue(`${"add_files"}[${index}][${name}]`, name === "status" ? checked : value);
  };

  const downloadFile = (url) => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = URL.createObjectURL(blob).split("/").slice(-1)[0];
        link.click();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderFilePreview = ({ fileData, index }) => {
    const imgextensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "tiff", "webp"];
    return type === "document" ? (
      <>
        {fileData?.name && (
          <div
            className={`${classes["input-file__container__fields"]}
         row`}
            key={index}
          >
            <div
              className={`${classes["input-file__container__previews__item"]} ${
                classes[`input-file__container__previews__item-${type} col-12`]
              }`}
            >
              <div className={classes["input-file__container__previews__item-img"]}>
                <img
                  src={
                    imgextensions.includes(fileData?.name.split(".").slice(-1)[0])
                      ? fileData.preview || fileData.url
                      : previewIcon[type]
                  }
                  alt=""
                />
              </div>
              {handleChangeAboutMe ? (
                <></>
              ) : (
                <>
                  <button
                    className={classes["file-preview-download"]}
                    type="button"
                    onClick={() => (handleDownload ? handleDownload() : downloadFile(fileData.preview))}
                  >
                    <i className="fa fa-download"></i>
                  </button>
                </>
              )}

              <div className={classes["input-file__container__previews__item-name"]}>{fileData.name}</div>
              {!disabled && (
                <div
                  className={classes["input-file__container__previews__item-remove"]}
                  onClick={() => handleRemove(index)}
                >
                  <img src={removeIcon[type]} alt="" />
                </div>
              )}
            </div>
            {!documentWithoutTitle && (
              <>
                <div className={`${classes["create_exhibition__form__field"]} col-12 col-md-6 col-4`}>
                  <Input
                    label={t("Exhibition.name_doc")}
                    type="text"
                    name="title"
                    placeholder={t("Exhibition.name_doc")}
                    onChange={(event) => {
                      handleChange(event, index);
                    }}
                    error={() => {}}
                    value={value[index]?.title}
                    disabled={disabled}
                  />
                </div>
                {/* <div className={`${classes["create_exhibition__form__field"]}  col-12 col-md-6 col-4`}>
              <Checkbox
                name="status"
                label={t("Exhibition.Document_Availability")}
                onChange={(event) => {
                  handleChange(event, index);
                }}
                checked={value[index]?.status}
              />
            </div> */}
              </>
            )}
          </div>
        )}
      </>
    ) : (
      <div
        className={`${classes["input-file__container__previews__item"]} ${
          classes[`input-file__container__previews__item-${type}`]
        }`}
        key={index}
      >
        <div className={classes["input-file__container__previews__item-img"]}>
          <img
            src={
              type === "image" || type === "gallery"
                ? fileData?.file?.type === "application/pdf"
                  ? previewIcon["document"]
                  : fileData.preview
                : previewIcon[type]
            }
            alt=""
          />
        </div>
        <div className={classes["input-file__container__previews__item-name"]}>{fileData.name}</div>
        <button
          className={classes["file-preview-download"]}
          type="button"
          onClick={() => downloadFile(fileData.preview)}
        >
          <i className="fa fa-download"></i>
        </button>
        {!disabled && (
          <div className={classes["input-file__container__previews__item-remove"]} onClick={() => handleRemove(index)}>
            <img src={removeIcon[type]} alt="" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes["input-file"]} onDragOver={handleDragOver} onDrop={handleDrop}>
      <label htmlFor={name} className={classes["input-file__label"]}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        type="file"
        multiple={multiple}
        style={{ display: "none" }}
        onChange={handleInputChange}
        ref={fileInputRef}
        name={name}
        accept={accept}
        disabled={isLoading || disabled}
      />
      <div className={classes["input-file__container"]}>
        {type === "gallery" ? (
          <>
            <div className={classes["input-file__container__gallery"]} onClick={() => fileInputRef.current.click()}>
              {isLoading ? (
                <div className={classes["input-file__container__gallery-icon"]}>
                  <LoadingSpinner small={true} />
                </div>
              ) : (
                <>
                  <div className={classes["input-file__container__gallery-icon"]}>
                    <img src={UploadIcon} alt="" />
                  </div>
                  <p>{maxSize}</p>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={classes["input-file__container__field"]}>
              <div className={classes["input-file__container__field-item"]}>{placeholder}</div>
              {isLoading ? (
                <div className={classes["input-file__container__field-icon"]}>
                  <LoadingSpinner small={true} />
                </div>
              ) : (
                <button
                  className={classes["input-file__container__field-btn"]}
                  onClick={() => fileInputRef.current.click()}
                  type="button"
                  disabled={isLoading || disabled}
                >
                  <img src={type === "image" ? FileIcon : icon[type]} alt="" />
                  {btnText && <span className={classes["input-file__container__field-text"]}>{btnText}</span>}
                </button>
              )}
            </div>
          </>
        )}
        {error && (
          <p className={`form-input-error-space ${classes["input-file-error"]}`} style={{ paddingTop: "10px " }}>
            {error}
          </p>
        )}
        {note && <div className={classes["input-file__container__note"]}>{note}</div>}
        {uploadedFiles && !!uploadedFiles.length && (
          <div className={classes["input-file__container__previews"]}>
            {uploadedFiles.map((fileData, index) => renderFilePreview({ fileData, index }))}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputFile;

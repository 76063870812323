import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder, default as urlBuilder } from "../../../Services/api/UrlBuilder";
import { exhibitionConstant } from "../../Constants/exhibition/exhibition.constant";

export const getExhibitionBoothsList = ({ id, page, perPage, filter }) => {
  return (dispatch) => {
    dispatch({ type: exhibitionConstant.GET_EXHIBITION_BOOTHS_LIST_REQUEST });
    connector
      .get(URLBuilder(env.exhibition_booths_list, { id: id }), {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_BOOTHS_LIST_SUCCESS,
          payload: response.data.data.booths,
        });
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_BOOTHS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

export const getExhibitionById = (id) => {
  return async (dispatch) => {
    dispatch({ type: exhibitionConstant.GET_EXHIBITION_BY_ID_REQUEST });

    await connector
      .get(urlBuilder(env.get_exhibition_with_dashboard, { id: id }))
      .then((response) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_BY_ID_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_BY_ID_REJECTED,
          payload: err,
        });
      });
  };
};

export const updateBoothStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({ type: exhibitionConstant.UPDATE_BOOTH_STATUS_REQUEST });

    await connector
      .get(URLBuilder(env.update_booth_status, { id: id }))
      .then((response) => {
        dispatch({
          type: exhibitionConstant.UPDATE_BOOTH_STATUS_SUCCESS,
          payload: {
            id: id,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.UPDATE_BOOTH_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};

export const getExhibitionSponsors = (id) => {
  return (dispatch) => {
    dispatch({ type: exhibitionConstant.GET_EXHIBITION_SPONSORS_REQUEST });

    connector
      .get(URLBuilder(env.exhibition_sponsors, { id: id }))
      .then((response) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_SPONSORS_SUCCESS,
          payload:
            response.data.sponsors.length > 0
              ? response.data.sponsors
              : [
                  ...Object.keys(response.data.sponsors).map((key) => {
                    return response.data.sponsors[key];
                  }),
                ],
        });
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_SPONSORS_REJECTED,
          payload: err,
        });
      });
  };
};

export const getExhibitionVisitors = ({ id, page, perPage, filter }) => {
  return (dispatch) => {
    dispatch({ type: exhibitionConstant.GET_EXHIBITION_VISITORS_REQUEST });

    connector
      .get(URLBuilder(env.exhibition_visitors, { id: id }), {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_VISITORS_SUCCESS,
          payload: response.data.data.visitors,
        });
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_VISITORS_REJECTED,
          payload: err,
        });
      });
  };
};

export const approveExhibitionByBooth = ({ boothId, orderId }) => {
  return async (dispatch) => {
    dispatch({
      type: exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_REQUEST,
    });

    await connector
      .post(env.exhibition_approve_payment_by_booth, {
        booth_id: boothId,
        order_id: orderId,
      })
      .then((response) => {
        dispatch({
          type: exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_SUCCESS,
          payload: response.data.msg,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            autoClose: 3000,
          }
        );
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg ? err?.response?.data?.msg : err?.data?.msg ? err?.data?.msg : "Failed"}
          </span>,
          {
            autoClose: 3000,
          }
        );
      });
  };
};
export const rejectExhibitionByBooth = ({ boothId, orderId, cancelReason }) => {
  return async (dispatch) => {
    dispatch({
      type: exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_BOOTH_REQUEST,
    });
    await connector
      .post(env.exhibition_reject_payment_by_booth, {
        booth_id: boothId,
        order_id: orderId,
        cancel_reason: cancelReason,
      })

      .then((response) => {
        dispatch({
          type: exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_BOOTH_SUCCESS,
          payload: response.data.msg,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            autoClose: 3000,
          }
        );
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_BOOTH_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg ? err?.response?.data?.msg : err?.data?.msg ? err?.data?.msg : "Failed"}
          </span>,
          {
            autoClose: 3000,
          }
        );
      });
  };
};

export const approveExhibitionByVisitor = ({ exhibitionId, visitorId }) => {
  return async (dispatch) => {
    dispatch({
      type: exhibitionConstant.APPROVE_EXHIBITION_BY_VISITOR_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.exhibition_approve_by_visitor, {
          id: exhibitionId,
        }),
        {
          exhibition_visit_id: visitorId,
        }
      )
      .then((response) => {
        dispatch({
          type: exhibitionConstant.APPROVE_EXHIBITION_BY_VISITOR_SUCCESS,
          payload: {
            msg: response.data.msg,
            id: visitorId,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.APPROVE_EXHIBITION_BY_VISITOR_REJECTED,
          payload: err,
        });
      });
  };
};

export const rejectExhibitionByVisitor = ({ exhibitionId, visitorId, cancelReason }) => {
  return async (dispatch) => {
    dispatch({
      type: exhibitionConstant.REJECT_EXHIBITION_BY_VISITOR_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.exhibition_reject_by_visitor, {
          id: exhibitionId,
        }),
        {
          exhibition_visit_id: visitorId,
          cancel_reason: cancelReason,
        }
      )
      .then((response) => {
        dispatch({
          type: exhibitionConstant.REJECT_EXHIBITION_BY_VISITOR_SUCCESS,
          payload: {
            msg: response.data.msg,
            id: visitorId,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.REJECT_EXHIBITION_BY_VISITOR_REJECTED,
          payload: err,
        });
      });
  };
};

export const approvePaymentExhibitionByVisitor = ({ exhibitionId, visitorId, orderId }) => {
  return (dispatch) => {
    dispatch({
      type: exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_REQUEST,
    });

    connector
      .post(
        URLBuilder(env.exhibition_approve_payment_by_visitor, {
          id: exhibitionId,
        }),
        {
          exhibition_visits_id: visitorId,
          order_id: orderId,
        }
      )
      .then((response) => {
        dispatch({
          type: exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_SUCCESS,
          payload: response.data.msg,
        });
        dispatch({
          type: exhibitionConstant.UPDATE_EXHIBITION_USER_AFTER_PAYMENT_REQUEST,
          payload: {
            id: visitorId,
          },
        });

        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>, {
          autoClose: 1000,
        });
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_REJECTED,
          payload: err,
        });

        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.msg}</span>);
      });
  };
};
export const rejectPaymentExhibitionByVisitor = ({
  exhibitionId,
  visitorId,
  orderId,
  cancelReason,
  getVisitorsHandler,
}) => {
  return (dispatch) => {
    dispatch({
      type: exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_VISITOR_REQUEST,
    });

    connector
      .post(
        URLBuilder(env.exhibition_reject_payment_by_visitor, {
          id: exhibitionId,
        }),
        {
          exhibition_visits_id: visitorId,
          order_id: orderId,
          cancel_reason: cancelReason,
        }
      )
      .then((response) => {
        dispatch({
          type: exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_VISITOR_SUCCESS,
          payload: response.data.msg,
        });
        dispatch({
          type: exhibitionConstant.UPDATE_EXHIBITION_USER_AFTER_PAYMENT_REQUEST,
          payload: {
            id: visitorId,
          },
        });
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>, {
          autoClose: 1000,
        });
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_VISITOR_REJECTED,
          payload: err,
        });
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.msg}</span>);
      });
  };
};

export function exhibitionVisit(payload) {
  return (dispatch) => {
    dispatch({
      type: exhibitionConstant.GET_EXHIBITION_VISIT_REQUEST,
    });

    connector
      .post(env.exhibition_visit, payload)
      .then((res) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_VISIT_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {});
  };
}

export function addExhibitionVisitToCart(payload) {
  return (dispatch) => {
    dispatch({
      type: exhibitionConstant.GET_EXHIBITION_VISIT_TO_CART_REQUEST,
    });

    connector
      .post(env.add_exhibition_visit_to_cart, payload)
      .then((res) => {
        dispatch({
          type: exhibitionConstant.GET_EXHIBITION_VISIT_TO_CART_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {});
  };
}

export const payAddBoothWithBankTransfer = (data) => {
  return async (dispatch) => {
    dispatch({
      type: exhibitionConstant.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REQUEST,
    });

    await connector
      .post(env.cart_pay, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: exhibitionConstant.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_SUCCESS,
          payload: response,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg ? err?.response?.data?.msg : err?.data?.msg ? err?.data?.msg : "Failed"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      });
  };
};

export const payExhibitionVisiter = (data, history) => {
  return async (dispatch) => {
    dispatch({
      type: exhibitionConstant.PAY_EXHIBITION_VISITER_REQUEST,
    });

    await connector
      .post(env.pay_exhibition_visitor, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: exhibitionConstant.PAY_EXHIBITION_VISITER_SUCCESS,
          payload: response,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
        history.goBack();
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.PAY_EXHIBITION_VISITER_REJECTED,
          payload: err,
        });
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err?.response?.data?.msg ? err?.response?.data?.msg : err?.data?.msg ? err?.data?.msg : "Failed"}
            </span>
            <br />
            <span style={{ fontSize: 12, fontWeight: "normal" }}>
              {err?.response?.data?.errors &&
                Object.keys(err?.response?.data?.errors)?.map((key) => {
                  return (
                    <>
                      <span>{key + " : " + err?.response?.data?.errors[key]}</span>
                      <br />
                    </>
                  );
                })}
            </span>
          </>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      });
  };
};

export const payExhibitionBooth = (data, history) => {
  return async (dispatch) => {
    dispatch({
      type: exhibitionConstant.PAY_EXHIBITION_BOOTH_REQUEST,
    });

    await connector
      .post(env.pay_exhibition_booth, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: exhibitionConstant.PAY_EXHIBITION_BOOTH_SUCCESS,
          payload: response,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
        history.goBack();
      })
      .catch((err) => {
        dispatch({
          type: exhibitionConstant.PAY_EXHIBITION_BOOTH_REJECTED,
          payload: err,
        });
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err?.response?.data?.msg ? err?.response?.data?.msg : err?.data?.msg ? err?.data?.msg : "Failed"}
            </span>
            <br />
            <span style={{ fontSize: 12, fontWeight: "normal" }}>
              {err?.response?.data?.errors &&
                Object.keys(err?.response?.data?.errors)?.map((key) => {
                  return (
                    <>
                      <span>{key + " : " + err?.response?.data?.errors[key]}</span>
                      <br />
                    </>
                  );
                })}
            </span>
          </>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      });
  };
};

export const RateExhibition = (payload) => {
  return (dispatch) => {
    dispatch({
      type: exhibitionConstant.RATE_EXHIBITION_REQUEST,
    });

    connector
      .post(env.exhibition_rate, payload)
      .then((res) => {
        dispatch({ type: exhibitionConstant.RATE_EXHIBITION_SUCCESS, payload: res.data.data });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data.msg ? res.data.msg : "success"}</span>,
          {
            onClose: () => {},
            autoClose: 2000,
          }
        );
      })
      .catch((err) => {
        dispatch({ type: exhibitionConstant.RATE_EXHIBITION_REJECTED });
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>, {
          onClose: () => {},
          autoClose: 2000,
        });
      });
  };
};

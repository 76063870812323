import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import IconsTooltip from "../../Components/tooltips/IconsTooltip";
import toLocalTimezone, { toServerTime } from "../../Services/Timezone";
import { updateEndTimeSurvey } from "../../Services/api/survey/SurveyProvider";
import i18n from "./../../i18n/i18n";
import { Lang } from "./../../utils";

function TraineesModal(props) {
  require("./traineesModal.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [endDate, setEndDate] = useState(new Date(toLocalTimezone(props.endTime)));

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      seconds = "" + t.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return [hours, minutes, seconds].join(":");
  };

  const changeEndTime = () => {
    // let endTime = formatDate(new Date(endDate)) + " " + formatTime(new Date(endDate))
    let data = {
      end_time: toServerTime(endDate),
    };

    const response = updateEndTimeSurvey(data, props.surveyId);
    toast.success(
      <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("survey.messages.changeEndTimeSuccess")}</span>
    );
    props.getSurvies();
    props.traineesModalRef.current.dismissModal();
  };

  return (
    <>
      <div
        className={"tajah-basic-modal-md"}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={"tajah-basic-modal-header"}>
          {props.type == "trainees" ? <h4>{props.title}</h4> : <h6 style={{ color: "red" }}>{props.title}</h6>}
          <div>
            <i
              className="fas fa-times tajah-modal-dismiss"
              onClick={() => {
                props.traineesModalRef.current.dismissModal();
              }}
            ></i>
          </div>
        </div>
        {props.type == "trainees" ? (
          <div className={"tajah-basic-modal-body"}>
            <div
              className="table-responsive"
              style={{
                width: "90%",
              }}
            >
              {props.body.length == 0 ? (
                <p style={{ textAlign: "center" }}>{t("survey.trainee.notFound")}</p>
              ) : (
                <table className="table table-bordered">
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th scope="col">#</th>
                      <th scope="col">{t("survey.trainee.name")}</th>
                      <th scope="col"> {t("survey.trainee.title")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.body.map((survey, index) => {
                      return (
                        <tr style={{ textAlign: "center" }} key={survey.id}>
                          <td scope="row">{index + 1}</td>
                          <td>{i18n.language == Lang.AR ? survey.user.name_ar : survey.user.name_en}</td>
                          <td>
                            <IconsTooltip
                              title={t("modal.survey.buttons.data")}
                              content={
                                <i
                                  className="fal fa-eye"
                                  onClick={() => {
                                    history.push({
                                      pathname: "/admin/survey/trainee-trainer/answers",
                                      state: {
                                        guestName: survey.name,
                                        guestEmail: survey.email,
                                        answers: survey.answers,
                                        survey: survey.survey.questions,
                                        isPublic: survey.survey.is_public,
                                        user: survey.user,
                                      },
                                    });
                                    window.location.reload(false);
                                  }}
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                    backgroundColor: "white",
                                    borderWidth: 0,
                                    fontSize: 20,
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {props.pagination}
            </div>
          </div>
        ) : (
          <div>
            <div className={"tajah-basic-modal-body"}>
              <div className="row" style={{ marginTop: "3%", width: "100%", height: 300 }}>
                <div className="col-md-12">
                  <label htmlFor="start_date" className="personal_label">
                    {t("survey.end_time")}
                  </label>
                  <DatePicker
                    selected={endDate}
                    defaultValue={Date.parse(props.endTime)}
                    minDate={Date.parse(props.endTime)}
                    onChange={(date) => setEndDate(date)}
                    showTimeSelect
                    placeholderText={t("survey.end_time")}
                    popperPlacement={i18n.lang == Lang.AR ? "left-center" : "right-center"}
                    style={{ borderBottom: "1px solid #e7e7e7" }}
                    className="admin_add_input"
                    showTime={{ use12Hours: true }}
                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                  />
                </div>
              </div>
            </div>
            <div className={"tajah-basic-modal-footer"}>
              <button
                style={{
                  marginLeft: document.body.dir == "rtl" ? 10 : 0,
                  marginRight: document.body.dir == "ltr" ? 10 : 0,
                }}
                className={"btn btn-success"}
                onClick={() => {
                  changeEndTime();
                }}
              >
                {t("modal.survey.buttons.edit")}
              </button>
              <button
                type={"button"}
                onClick={() => {
                  props.traineesModalRef.current.dismissModal();
                }}
                className={"btn"}
              >
                {t("modal.common.cancel")}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TraineesModal;

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import alertOctagon from "../../../../assets/icons/alert-octagon (2).svg";
import bankIcon from "../../../../assets/icons/bank (1).svg";
import visaIcon from "../../../../assets/icons/visa (2).svg";
import walletVisa from "../../../../assets/icons/wallet.svg";
import Title from "../Title/Title";
import BankTransfer from "./PaymentWaysComponents/BankTransfer";
import SharedBox from "./PaymentWaysComponents/SharedBox";
import style from "./paymentWay.module.css";

export default function PaymentWay({
  handleChange,
  values,
  errors,
  setFieldValue,
  walletAction,
  cost,
  onSubmit,
  label,
  getTypeFile,
  userWallet = false,
  hasSubmitted,
  currentDate,
  setTransferDateHours,
  setTransferDateMinutes,
}) {
  const { t } = useTranslation();

  const [selectedBox, setSelectedBox] = useState({
    bankSelected: true,
    // visaSelected: false,
    walletSelected: false,
  });
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const [isPaidByWallet, setIsPaidByWallet] = useState(false);

  useEffect(() => {
    if (isPaidByWallet) {
      walletAction();
      setOpen(false);
    }
  }, [isPaidByWallet]);

  const handleSelectPaymentClick = (key) => {
    if (key === "bank" && !selectedBox.bankSelected) {
      setSelectedBox({
        bankSelected: true,
        visaSelected: false,
        walletSelected: false,
      });
    }
    // if (key === "visa" && !selectedBox.visaSelected) {
    //   setSelectedBox({
    //     bankSelected: false,
    //     visaSelected: true,
    //     walletSelected: false,
    //   });
    // }
    if (key === "wallet" && !selectedBox.walletSelected) {
      setSelectedBox({
        bankSelected: false,
        visaSelected: false,
        walletSelected: true,
      });
      setOpen(true);
    }
  };

  useEffect(() => {
    handleSelectPaymentClick();
  }, [selectedBox]);

  return (
    <div>
      <div className={style["header-label-cost"]}>
        <Title title={label} />
        {!userWallet && (
          <div className={style["cost-label"]}>
            <span>{t("Exhibition.popuppaymentcost")}</span>
            <h5 className={style.cost}>
              {cost || 0} {t("Exhibition.rs")}
            </h5>
          </div>
        )}
      </div>

      <label
        className={
          userWallet ? style["payment-way-wallet"] : style["payment-way"]
        }
      >
        {t("Exhibition.popuppaymentpaymentlabel")}
      </label>

      <div className={`${style["payment-type"]}`}>
        <SharedBox
          type="bank"
          icon={bankIcon}
          paymentWay={t("Exhibition.popuppaymentbanktran")}
          width={100}
          selected={selectedBox.bankSelected}
          handleSelectPaymentClick={handleSelectPaymentClick}
        />
        {/* <SharedBox
          type="visa"
          icon={visaIcon}
          paymentWay={t("Exhibition.popuppaymentvisa")}
          width={100}
          selected={selectedBox.visaSelected}
          handleSelectPaymentClick={handleSelectPaymentClick}
        /> */}
        {!userWallet && (
          <SharedBox
            type="wallet"
            icon={walletVisa}
            paymentWay={t("Exhibition.popuppaymentwallet")}
            width={100}
            selected={selectedBox.walletSelected}
            handleSelectPaymentClick={handleSelectPaymentClick}
          />
        )}
      </div>
      {selectedBox.bankSelected && (
        <BankTransfer
          handleChange={handleChange}
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          onSubmit={onSubmit}
          getTypeFile={getTypeFile}
          userWallet={userWallet}
          hasSubmitted={hasSubmitted}
          currentDate={currentDate}
          setTransferDateHours={setTransferDateHours}
          setTransferDateMinutes={setTransferDateMinutes}
        />
      )}
      {selectedBox.walletSelected && (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box className={style.modalContainer}>
                <img
                  className={style.alertOctagon}
                  src={alertOctagon}
                  alt="alertOctagon"
                  width="100px"
                  height="100px"
                />
                <p className={style.confirmPaymentTxt}>
                  {t("Exhibition.popupwalletlabel")}
                  <br />
                  {t("Exhibition.popupwalletdescription")}
                </p>
                <div className={style.buttonsRow}>
                  <button
                    type="button"
                    className={style.yesBtn}
                    onClick={() => setIsPaidByWallet(true)}
                  >
                    {t("Exhibition.popupwalletyes")}
                  </button>
                  <button
                    type="button"
                    className={style.noBtn}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {t("Exhibition.popupwalletno")}
                  </button>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { getProfile } from "../../../../Services/api/profile/profileProvider";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Profile from "./profile/Profile";

function EditProfile() {
  require("./editProfile/editProfile.css");

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getProfile()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setUserData(res.data.user);
          setLoading(false);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  return (
    <div className="container-fluid  mt-5">
      <div className="row">
        <div className="col-12">
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  height: "100vh",
                }}
              >
                <SkeletonCardOverlay />
              </div>
            </div>
          ) : (
            <Profile userData={userData} />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditProfile;

import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getPayments({ page, perPage, filter }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_payments, {
          params: {
            page,
            perPage,
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

// getProgramPaymentsReport
export function getProgramPaymentsReport({ page, perPage, filter, id, paymentId }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(paymentId ? env.get_program_payments_report_payment : env.get_program_payments_report, {
            id: id,
            ...(paymentId && { paymentId: paymentId }),
          }),
          {
            params: {
              page,
              perPage,
              ...filter,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

// paymentAction
export function paymentAction({ id, status, reason }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.payment_action, { id }), {
          status,
          reprocess: null,
          rejection_reason: status === "Rejected" ? reason : null || null,
          accept_reason: status === "Approved" ? reason : null || null,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

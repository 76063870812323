// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { CoursePaymentContext } from "../../../../../Services/api/courses/coursePaymentContext";
import { addToCart, finalPrice, freeCourseRegister } from "../../../../../Services/api/courses/courseProvider";
import cirrus from "../../../../../assets/image/cirrus.png";
import master from "../../../../../assets/image/mastercard.png";
import TrainingCoursePaymentWithBankTransfer from "../TrainingCoursePaymentWithBankTransfer/TrainingCoursePaymentWithBankTransfer";
// import TrainingCoursePaymentWithCard from "../TrainingCoursePaymentWithCard/TrainingCoursePaymentWithCard";

function TrainingCoursePaymentMethod(props) {
  require("./TrainingCoursePaymentMethod.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [coupon, setCoupon] = useState("");
  const [couponPrice, setCouponPrice] = useState();
  const [couponError, setCouponError] = useState("");
  const [toBePaid, setToBePaid] = useState();

  const coursePaymentContext = useContext(CoursePaymentContext);

  useEffect(() => {
    if (!coursePaymentContext.modal) {
      props.paymentModalRef.current.dismissModal();
    }
  }, [coursePaymentContext]);

  // const stripePromise = loadStripe(
  //     "pk_test_51IkbOMDjYjWvzOE3xmohrGoYqRNBiNX7QFtmV9dsKxEwDAcsbYEghtY4VBEYBGcRurpqOOu60S6AngtlilEFsFSm0087UyxRgZ"
  // );

  const finalPriceHandler = async () => {
    try {
      let res = await finalPrice(
        props.giftDataPayload ? props.giftDataPayload : "",
        props.courseDetailsContext.courseDetails.id,
        coupon
      );
      try {
        if (res.status === 200 && res.data.status && coupon !== "") {
          setCouponError("");
          setCouponPrice(res.data.response);
          setToBePaid(res.data.response.to_be_paid);
        } else {
          setToBePaid(1);
          setCouponPrice("");
          setCouponError("");
        }
      } catch (error) {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      }
    } catch (error) {
      let coupon_error = error.response.data.msg;
      toast.error(
        <>
          <span style={{ fontSize: 13, fontWeight: "bold", display: "block", paddingBottom: "10px" }}>
            {error.response.data.msg}
          </span>
          <ul>
            {error.response.data.errors &&
              Object.keys(error.response.data.errors).map((key) => {
                coupon_error += "\n" + error.response.data.errors[key];
                return (
                  <li>
                    <span style={{ fontSize: 11 }}>{error.response.data.errors[key]}</span>
                  </li>
                );
              })}
          </ul>
        </>
      );
      setCouponError(coupon_error);
      setCouponPrice("");
      setToBePaid(1);
    }
  };

  const handleRegistration = async (event) => {
    event.preventDefault();
    try {
      let res = await addToCart(
        props.courseDetailsContext.courseDetails.id,
        coupon,
        props.giftDataPayload ? props.giftDataPayload : ""
      );
      if (res.status === 201 && res.data.status) {
        await freeCourseRegister(res.data.cart.id, "wallet");
        coursePaymentContext.setModal(false);
        !props.giftDataPayload &&
          history.push("/training-courses/" + props.courseDetailsContext.courseDetails.id + "/profile");
        props.giftDataPayload && history.goBack();
      }
    } catch (error) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("modal.trainer.course.messages.title")}</span>);
    }
  };

  return (
    <>
      <div
        className="backdrop"
        onClick={() => {
          coursePaymentContext.setModal(false);
        }}
      />
      <div
        className="modal_flex_card"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal_card_pay">
          {coursePaymentContext.paymentScreen == 0 ? (
            <>
              <div className="modal_head">
                <div className="modal_head_label">
                  <div className="">{t("safepurchase")}</div>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      coursePaymentContext.setModal(false);
                    }}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal_head_label_bottom">{t("allscr")}</div>
                <div className="modal_pay_head_card">
                  <div className="">
                    <img src={props.courseDetailsContext?.courseDetails?.img} alt="..." className="modal_course_img" />
                  </div>

                  <div className="modal_course_des">
                    <div className="modal_course_name">{props.courseDetailsContext?.courseDetails?.name}</div>
                    <div className="modal_coupon">{t("howtouse")}</div>
                    <div className="modal_coupon_form">
                      <form action="" className="modal_form">
                        <input
                          type="text"
                          name="coupon"
                          id="coupon"
                          className="modal_coupon_input"
                          placeholder={t("enternumcoup")}
                          onChange={(e) => setCoupon(e.target.value)}
                          value={coupon}
                        />
                        <input
                          type="button"
                          className="modal_coupon_button"
                          value={t("addCoupon")}
                          style={{ cursor: "pointer" }}
                          onClick={finalPriceHandler}
                        />
                      </form>
                      <p className={"form-input-error-space"} style={{ margin: " -5px 10px" }}>
                        {couponError ? couponError : null}
                      </p>
                      {/*{((couponPrice || props.price && props.price.offer !== 0) || (couponPrice || props.price && props.price.tax !== 0)) && (*/}
                      {/*    <div className="modal_coupon_price" style={{color:"black"}}>*/}
                      {/*    {t("price")} : {couponPrice ? couponPrice.item_real_price : props.price.item_real_price}{" "}*/}
                      {/*    {t("Rial")}*/}
                      {/*    </div>*/}
                      {/*)}*/}
                      <div className="modal_coupon_price">
                        {t("priceCourse")} : {props.courseDetailsContext?.courseDetails?.price} {t("Rial")}
                      </div>
                      {props.discountPrice ? (
                        <div className="modal_coupon_price">
                          {t("deservedAmount")} :{" "}
                          {(props.courseDetailsContext?.courseDetails?.price * props.discountPrice) / 100} {t("Rial")}
                        </div>
                      ) : null}
                      {couponPrice && (
                        <div className="modal_coupon_price">
                          {t("price")} :{" "}
                          {couponPrice
                            ? couponPrice.real_price *
                              (couponPrice.purchaseDiscount / 100) *
                              couponPrice.numberOfPurchase
                            : props.giftDataPayload
                            ? parseFloat(props.price.to_be_paid).toFixed(2)
                            : props.courseDetailsContext?.courseDetails?.price}{" "}
                          {t("Rial")}
                        </div>
                      )}
                      {couponPrice && (
                        <div className="modal_coupon_price" style={{ color: "green" }}>
                          {t("dis_price")} : {couponPrice.discount_price * couponPrice.numberOfPurchase} {t("Rial")}
                        </div>
                      )}
                      {(couponPrice || (props.price && props.price.paid_from_wallet !== 0)) && (
                        <div className="modal_coupon_price" style={{ color: "green" }}>
                          {t("paid_from_wallet")} :{" "}
                          {couponPrice
                            ? parseFloat(couponPrice.paid_from_wallet).toFixed(2)
                            : parseFloat(props.price.paid_from_wallet).toFixed(2)}{" "}
                          {t("Rial")}
                        </div>
                      )}
                      {(couponPrice || (props.price && props.price.tax !== 0)) && (
                        <div className="modal_coupon_price" style={{ color: "green" }}>
                          {t("tax")} :{" "}
                          {couponPrice
                            ? parseFloat(couponPrice.tax).toFixed(2)
                            : parseFloat(props.price.tax).toFixed(2)}{" "}
                          {t("Rial")}
                        </div>
                      )}
                      {(couponPrice || (props.price && props.price.offer !== 0)) && (
                        <div className="modal_coupon_price" style={{ color: "green" }}>
                          {t("offer")} :{" "}
                          {couponPrice
                            ? parseFloat(couponPrice.offer).toFixed(2)
                            : parseFloat(props.price.offer).toFixed(2)}{" "}
                          {t("Rial")}
                        </div>
                      )}
                      {couponPrice && (
                        <div className="modal_coupon_price" style={{ fontSize: "18px" }}>
                          {t("total_price")} : {parseFloat(couponPrice.to_be_paid).toFixed(2)} {t("Rial")}
                        </div>
                      )}
                      {!couponPrice && (
                        <div className="modal_coupon_price" style={{ fontSize: "18px" }}>
                          {t("total_price")} :{" "}
                          {couponPrice
                            ? couponPrice.real_price *
                              (couponPrice.purchaseDiscount / 100) *
                              couponPrice.numberOfPurchase
                            : props.price
                            ? parseFloat(props.price.to_be_paid).toFixed(2)
                            : props.courseDetailsContext?.courseDetails?.price}{" "}
                          {t("Rial")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-body">
                {(props.price && props.price.to_be_paid == 0) || toBePaid == 0 ? (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <button
                      className="btn"
                      type="button"
                      onClick={handleRegistration}
                      style={{ background: "#006D77", color: "#fff", padding: "5px 25px" }}
                    >
                      {props.giftDataPayload ? (
                        <>
                          <i className="fas fa-gift"></i> {t("giftCourse.label.send_gift")}
                        </>
                      ) : (
                        t("Startthecoursenow")
                      )}
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="modal_body_label">{t("selectopt")}</div>
                    <div className="modal_pay_card">
                      <button
                        className="modal_card"
                        onClick={(e) => {
                          e.stopPropagation();
                          coursePaymentContext.setPaymentScreen(1);
                        }}
                      >
                        <img src={master} alt="" className="img_card_pay" />
                        <div className="">{t("visa")}</div>
                      </button>
                      <button
                        className="modal_card"
                        onClick={(e) => {
                          e.stopPropagation();
                          coursePaymentContext.setPaymentScreen(2);
                        }}
                      >
                        <img src={cirrus} alt="" className="img_card_pay" />
                        <div className="">{t("bankTransfers")}</div>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : coursePaymentContext.paymentScreen == 1 ? (
            // <Elements stripe={stripePromise}>
            //     <TrainingCoursePaymentWithCard
            //         giftDataPayload={props.giftDataPayload}
            //         courseDetailsContext={props.courseDetailsContext}
            //         paymentModalRef={props.paymentModalRef}
            //         couponPrice={couponPrice}
            //         coupon={coupon}
            //         price={props.price}
            //     />
            // </Elements>
            <></>
          ) : (
            <TrainingCoursePaymentWithBankTransfer
              giftDataPayload={props.giftDataPayload}
              courseDetailsContext={props.courseDetailsContext}
              paymentModalRef={props.paymentModalRef}
              couponPrice={couponPrice}
              coupon={coupon}
              price={props.price}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TrainingCoursePaymentMethod;

import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { boothConstants } from "../../Constants/Booth/booth.constants";

const fetchExhibitionPositions = (id) => {
  return (dispatch) => {
    dispatch({ type: boothConstants.GET_EXHIBITION_REQUEST });

    connector
      .get(URLBuilder(env.exhibition_positions, { id: id }))
      .then((response) => {
        dispatch({
          type: boothConstants.GET_EXHIBITION_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.GET_EXHIBITION_REJECTED,
          payload: err,
        });
      });
  };
};

const createBooth = (data, history) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.CREATE_BOOTH_REQUEST });
    await connector
      .post(env.booth, data, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: boothConstants.CREATE_BOOTH_SUCCESS,
          payload: response,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            onClose: () => {},
            autoClose: 1500,
          }
        );
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.CREATE_BOOTH_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg ? err.response.data.msg : "Failure in service"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .finally(() => {
        history.push("/Exhibition");
      });
  };
};

const updateBooth = (data, id, exhibitionId, history) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.UPDATE_BOOTH_REQUEST });

    await connector
      .post(URLBuilder(env.update_booth, { id: id }), data, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: boothConstants.UPDATE_BOOTH_SUCCESS,
          payload: response,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
        history.push(`/Admin/exhibition/${exhibitionId}`);
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.UPDATE_BOOTH_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg ? err.response.data.msg : "Failure in service"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      });
  };
};

const updateBoothDraft = (data, t,history) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.UPDATE_BOOTH_DRAFT_REQUEST });

    await connector
      .post(env.update_booth_draft, data, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: boothConstants.UPDATE_BOOTH_DRAFT_SUCCESS,
          payload: response,
        });
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("booth.draftUpdated")}</span>, {
          onClose: () => {},
          autoClose: 1000,
        });
        history.push("/Exhibition");
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.UPDATE_BOOTH_DRAFT_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg ? err.response.data.msg : "Failure in service"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      });
  };
};

const addBoothToCart = (boothId, paymentAmount) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.ADD_BOOTH_TO_CART_REQUEST });

    const formData = new FormData();
    formData.append("booth_id", boothId);
    formData.append("email", localStorage.getItem("email"));
    formData.append("purchase_type", "booth");
    formData.append("booth_amount", paymentAmount);

    await connector
      .post(env.add_booth_to_cart, formData, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: boothConstants.ADD_BOOTH_TO_CART_SUCCESS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.ADD_BOOTH_TO_CART_REJECTED,
          payload: err,
        });
      });
  };
};

const payAddBoothWithBankTransfer = (data, history, isFree) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REQUEST });

    await connector
      .post(env.cart_pay, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        dispatch({
          type: boothConstants.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_SUCCESS,
          payload: response,
        });
        !isFree &&
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg ? response.data.msg : "success"}
            </span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
        history.push(`/Exhibition`);
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg ? err.response.data.msg : "Failure in service"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      });
  };
};

const getBooth = (id) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.GET_BOOTH_REQUEST });

    await connector
      .get(URLBuilder(env.get_booth, { id: id }))
      .then((response) => {
        dispatch({
          type: boothConstants.GET_BOOTH_SUCCESS,
          payload: response.data.booth,
        });
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.GET_BOOTH_REJECTED,
          payload: err,
        });
      });
  };
};

const getDraftBooth = (id) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.GET_BOOTH_DRAFT_REQUEST });

    await connector
      .get(URLBuilder(env.get_booth_draft, { id: id }))
      .then((response) => {
        dispatch({
          type: boothConstants.GET_BOOTH_DRAFT_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.GET_BOOTH_DRAFT_REJECTED,
          payload: err,
        });
      });
  };
};

const getBoothVisitors = ({ id, perPage, page, filter }) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.GET_BOOTH_VISITORS_REQUEST });

    await connector
      .get(URLBuilder(env.get_booth_visitors, { id: id }), {
        params: {
          perPage: perPage,
          page: page,
          ...filter,
        },
      })
      .then((response) => {
        dispatch({
          type: boothConstants.GET_BOOTH_VISITORS_SUCCESS,
          payload: response.data.data.visits,
        });
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.GET_BOOTH_VISITORS_REJECTED,
          payload: err,
        });
      });
  };
};

const approveBooth = ({ boothId, orderId, getBoothsDetails }) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.APPROVE_BOOTH_REQUEST });

    await connector
      .post(env.approve_booth, {
        booth_id: boothId,
        order_id: orderId,
      })
      .then((response) => {
        dispatch({
          type: boothConstants.APPROVE_BOOTH_SUCCESS,
          payload: response.data.msg,
        });
        getBoothsDetails();
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            autoClose: 3000,
          }
        );
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.APPROVE_BOOTH_REJECTED,
          payload: err?.response?.data?.msg,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg ? err?.response?.data?.msg : "Failure in service"}
          </span>,
          {
            autoClose: 3000,
          }
        );
      });
  };
};

const rejectBooth = ({ boothId, orderId, cancelReason, getBoothsDetails }) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.REJECT_BOOTH_REQUEST });

    await connector
      .post(env.reject_booth, {
        booth_id: boothId,
        order_id: orderId,
        cancel_reason: cancelReason,
      })
      .then((response) => {
        dispatch({
          type: boothConstants.REJECT_BOOTH_SUCCESS,
          payload: response.data.msg,
        });
        getBoothsDetails();
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            autoClose: 3000,
          }
        );
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.REJECT_BOOTH_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg ? err?.response?.data?.msg : "Failure in service"}
          </span>,
          {
            autoClose: 3000,
          }
        );
      });
  };
};

const approveDraftBooth = ({ boothId, exhibitionId, history }) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.APPROVE_DRAFT_BOOTH_REQUEST });

    await connector
      .get(URLBuilder(env.approve_draft_booth, { id: boothId }))
      .then((response) => {
        dispatch({
          type: boothConstants.APPROVE_DRAFT_BOOTH_SUCCESS,
          payload: response?.data?.msg,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response?.data?.msg ? response?.data?.msg : "success"}
          </span>,
          {
            autoClose: 3000,
          }
        );
        history.push(`/admin/exhibition/${exhibitionId}`);
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.APPROVE_DRAFT_BOOTH_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg ? err?.response?.data?.msg : "Failure in service"}
          </span>,
          {
            autoClose: 3000,
          }
        );
      });
  };
};

const rejectDraftBooth = ({ boothId, exhibitionId, history }) => {
  return async (dispatch) => {
    dispatch({ type: boothConstants.REJECT_DRAFT_BOOTH_REQUEST });

    await connector
      .get(URLBuilder(env.reject_draft_booth, { id: boothId }))
      .then((response) => {
        dispatch({
          type: boothConstants.REJECT_DRAFT_BOOTH_SUCCESS,
          payload: response?.data?.msg,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            autoClose: 3000,
          }
        );
        history.push(`/admin/exhibition/${exhibitionId}`);
      })
      .catch((err) => {
        dispatch({
          type: boothConstants.REJECT_DRAFT_BOOTH_REJECTED,
          payload: err,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg ? err?.response?.data?.msg : "Failure in service"}
          </span>,
          {
            autoClose: 3000,
          }
        );
      });
  };
};

const resetBooth = () => {
  return (dispatch) => {
    dispatch({ type: boothConstants.RESET_BOOTH });
  };
};

const resetPaymentBooth = () => {
  return (dispatch) => {
    dispatch({ type: boothConstants.RESET_PAYMENT_BOOTH });
  };
};

const resetAddToCart = () => {
  return (dispatch) => {
    dispatch({ type: boothConstants.RESET_ADD_TO_CART });
  };
};

export const boothActions = {
  fetchExhibitionPositions,
  createBooth,
  updateBooth,
  updateBoothDraft,
  addBoothToCart,
  payAddBoothWithBankTransfer,
  getBooth,
  getBoothVisitors,
  approveBooth,
  rejectBooth,
  getDraftBooth,
  approveDraftBooth,
  rejectDraftBooth,
  resetBooth,
  resetPaymentBooth,
  resetAddToCart,
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import IconTooltips from "../../Components/tooltips/IconsTooltip";
import { getTraineesForSponsors, unAssignTrainee } from "../../Services/api/Financial/FinancialProvider";
import noResult from "../../assets/image/Noresult.png";
import { Lang } from "../../utils";

function SponsorModal(props) {
  require("./Sponsor.css");
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [trainee, setTrainee] = useState([]);

  const fetchUser = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      getTraineesForSponsors(id)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setTimeout(() => {
              setTrainee(res.data.data.trainees);
              setLoading(false);
            }, 500);
            resolve();
          }
        })
        .catch((error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.response.data.msg}</span>);
          reject();
        });
    });

    return myPromise;
  };

  useEffect(async () => {
    await fetchUser(props.id);
  }, [props.id]);

  const unAssignUserHandler = async (id) => {
    setLoading(true);
    const payload = {
      target_id: id,
    };
    const response = await unAssignTrainee(payload);
    if (response.status === 200 && response.data.status) {
      await fetchUser(props.id);
      await setLoading(false);
    } else {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg ? response.data.msg : "Failure in service"}
        </span>
      );
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={"tajah-basic-modal-md"}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={"tajah-basic-modal-header"}>
          <h4>{props.title}</h4>
          <div>
            <i
              className="fas fa-times tajah-modal-dismiss"
              onClick={() => {
                props.refuse();
              }}
            ></i>
          </div>
        </div>

        <div className={"tajah-basic-modal-body"}>
          <div
            className="table-responsive"
            style={{
              width: "100%",
            }}
          >
            {loading ? (
              <div className="loading-fade">
                <h5
                  style={{
                    color: "#000",
                    margin: "50px 0",
                  }}
                >
                  {t("Loading")} ...
                </h5>
              </div>
            ) : trainee && trainee.length == 0 ? (
              <span
                className="course-details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "50px",
                }}
              >
                <img src={noResult} alt="" />
                <h5>{t("no_record")}</h5>
              </span>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th scope="col">#</th>
                    <th scope="col">{t("sponsor.user")}</th>
                    {!props.justShow && <th scope="col">{t("sponsor.rels")}</th>}
                  </tr>
                </thead>
                <tbody>
                  {trainee &&
                    trainee.map((user, index) => {
                      return (
                        <tr style={{ textAlign: "center" }} key={index}>
                          <td scope="row">{index + 1}</td>
                          <td>{user.email ? user.email : i18n.language === Lang.AR ? user.name_ar : user.name_ar}</td>
                          {!props.justShow && (
                            <td className="">
                              <IconTooltips
                                title={t("modal.coupon.buttons.delete")}
                                content={
                                  <i
                                    className="fal fa-trash-alt"
                                    onClick={() => unAssignUserHandler(user.id)}
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                      backgroundColor: "white",
                                      borderWidth: 0,
                                      fontSize: 20,
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                }
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SponsorModal;

import { Pagination } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { fetchSystemUsersList } from "../../../Redux/Actions/systemUsers/systemUsersList";
import { exportRoles } from "../../../Services/api/Roles/RolesProvider";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
// import EditRole from "../../Roles/Edit";
import SponsersListFilter from "./SponserListFilter";
import classes from "./SponsersList.module.css";
import { filterActions } from "./SponsersListActions";
import SponsersListTable from "./SponsersListTable";

const SponsersList = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const { systemUsersListMeta } = useSelector(
    (state) => state.systemUsersListReducer
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [rolesModal, setRolesModal] = useState(false);
  const { systemUsersList, systemRolesList } = useSelector(
    (state) => state.systemUsersListReducer
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [currentSponsersFilter, setCurrentSponsersFilter] = useState({});

  const [filterData, setFilterData] = useState({
    name: "",
    email: "",
    alternative_email: "",
    phone: "",
    id_number: "",
    scghs: "",
    is_medical: "",
    gender: "",
    registerDate: "",
    status: "",
    registerType: "",
    major: "",
    country: "",
    nationality: "",
    switchedFromSSO: "",
  });

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      email: "",
      alternative_email: "",
      phone: "",
      id_number: "",
      scghs: "",
      is_medical: "",
      jobs: "",
      gender: "",
      registerDate: "",
      status: "",
      registerType: "",
      major: "",
      country: "",
      nationality: "",
      switchedFromSSO: "",
    });
    setSortColumn("");
    setSortOrder("");
    setIsResetApplied(true);
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.email && (filter.email = filterData.email);
    filterData.alternative_email &&
      (filter.alternative_email = filterData.alternative_email);
    filterData.phone && (filter.phone = filterData.phone);
    filterData.id_number && (filter.id_number = filterData.id_number);
    filterData.scghs && (filter.scghs = filterData.scghs);
    filterData.is_medical && (filter.is_medical = filterData.is_medical);
    filterData.gender && (filter.gender = filterData.gender);
    filterData.registerDate &&
      (filter.register_date = moment(filterData.registerDate).format(
        "YYYY-MM-DD"
      ));
    filterData.status && (filter.status = filterData.status);
    filterData.registerType && (filter.type_login = filterData.registerType);
    filterData.major && (filter.specialty_id = filterData.major);
    filterData.department && (filter.department_id = filterData.department);
    filterData.country && (filter.country = filterData.country);
    filterData.nationality && (filter.nationality = filterData.nationality);
    sortColumn && (filter.sort_column = sortColumn);
    sortOrder && (filter.sort_order = sortOrder);
    filterData.switchedFromSSO &&
      (filter.switched_from_sso = filterData.switchedFromSSO);

    if (filter.switched_from_sso === "false") {
      delete filter.switched_from_sso;
    }
    return filter;
  };

  const getSystemUsersListHandler = () => {
    const filter = getFilterData();
    setDataToExport(filter);

    dispatch(
      fetchSystemUsersList({
        page: currentPage,
        perPage: 10,
        filter,
        isSponser: true,
      })
    );
    setCurrentSponsersFilter(filter);
  };

  useEffect(() => {
    getSystemUsersListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, sortColumn, sortOrder]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "sponser",
      page: t("sidebar.label.sponsers"),
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    const sponsers = true;
    try {
      await exportRoles(currentSponsersFilter, type, sponsers);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["system-users-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["system-users-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("sidebar.label.sponsers")}
                  actions={filterActions({
                    showFilter: showFilterHandler,

                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <SponsersListFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getSystemUsersListHandler}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}

                <SponsersListTable sortAction={sortActionHandler} />
                <Pagination
                  count={systemUsersListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsersList;

import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function TextareaWithLabel({
	name,
	id,
	label,
	error,
	variant,
	labelColor,
	placeholder,
	gap,
	fontWeight,
	handleChange,
	values,
}) {
	const { t } = useTranslation();

	const theme = useTheme();

	return (
		<Stack
			position={"relative"}
			gap={gap || "3px"}
			width={"100%"}
			sx={{
				"& textarea": {
					borderRadius: "6px",
					border: `1.2px solid ${theme.palette.grey[300]}`,
					height: "201px !important",
					resize: "none !important",
					outline: "none !important",
					padding: "16.5px 14px !important",
					"&:hover": {
						borderColor: "black",
					},
					"&:focus": {
						borderColor: theme.palette.primary.main,
					},
				},
			}}
		>
			<Typography
				component={"label"}
				variant={variant || "button"}
				color={labelColor || "grey.200"}
				fontWeight={fontWeight || 700}
			>
				{t(label)}
			</Typography>
			<textarea
				name={name}
				placeholder={t(placeholder) || label}
				id={id}
				value={`${values[name]}`}
				onChange={handleChange}
			></textarea>
			<Typography
				component={"span"}
				variant="button"
				sx={{
					fontWeight: 400,
					textAlign: "end",
					color: "error.main",
					textTransform: "capitalize",
					position: "absolute",
					bottom: "-25px",
					right: "0",
				}}
			>
				{error}
			</Typography>
		</Stack>
	);
}

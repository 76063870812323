import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getNotifications,
  readNotifications,
} from "../../../Services/api/Notifications/notificationsProvider";
import Arrow from "../../../assets/icons/ArrowDownGreen.svg";
import NotificationIcon from "../../../assets/icons/NotificationIcon.svg";
import NotificationIconBill from "../../../assets/icons/notifactionsIcon.svg";
import NotificationIconDot from "../../../assets/icons/notificationDOT.svg";
import "../../Shared/Components/Header/DynamicHeaderWithoutLogin/DynamicHeaderWithoutLogin.css";
import NotificationCard from "./NotificationCard";
import classes from "./notification.module.css";

const Notification = ({ isAdmin, NotAdmin }) => {
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState([]);

  const _getNotifications = async () => {
    localStorage.setItem("notificationLastCall", moment().format());
    let res = await getNotifications({
      getAll: 1,
    });
    if (res.status === 201) {
      let notifications = res.data.response;

      if (Object.keys(notifications).length > 0 || notifications.length > 0) {
        setNotifications(notifications.data);
        setNewNotification(
          notifications.data.filter((not) => not.is_read === 0)
        );
      } else {
        setNotifications([]);
      }
    }
  };

  useEffect(() => {
    _getNotifications();
    const notificationLastCall = localStorage.getItem("notificationLastCall");

    if (
      !notificationLastCall ||
      moment().diff(moment(notificationLastCall), "minutes") > 5
    ) {
      _getNotifications();
    }

    const interval = setInterval(_getNotifications, 120000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const readNotification = (notification) => {
    if (notification.is_read === 0) {
      readNotifications([notification.id]);
      setNotifications(
        notifications.map((not) => {
          if (not.id === notification.id) {
            not.is_read = 1;
          }
          return not;
        })
      );
      setNewNotification(
        newNotification.filter((not) => not.id !== notification.id)
      );
    }
  };

  const getTimeFromNow = (time) => {
    const seconds = Math.floor((new Date() - new Date(time)) / 1000);
    let interval = seconds / 31536000;

    const timeToReturn = (interval, timeType) => {
      return `${
        i18n.language === "en"
          ? `${Math.floor(interval)} ${timeType} ago`
          : `منذ ${Math.floor(interval)} ${timeType}`
      }`;
    };

    if (interval > 1) {
      return timeToReturn(
        interval,
        t(`general.${interval > 1 && interval < 2 ? "year" : "years"}`)
      );
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return timeToReturn(
        interval,
        t(`general.${interval > 1 && interval < 2 ? "month" : "months"}`)
      );
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return timeToReturn(interval, t("general.days"));
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return timeToReturn(interval, t("general.hours"));
    }
    interval = seconds / 60;
    if (interval > 1) {
      return timeToReturn(interval, t("general.minutes"));
    }
    return timeToReturn(seconds, t("general.seconds"));
  };

  return (
    <div className="dropdown">
      <button
        type="button"
        className="btn dropdown-toggle header-dropdown header_nav_link notification"
        data-toggle="dropdown"
        onClick={_getNotifications}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          padding: "0 5px",
        }}
      >
        {/* <i
          className="fa fa-bell"
          style={{ fontSize: "25px", color: "#333" }}
        ></i> */}
        <img
          src={
            newNotification.length > 0
              ? NotificationIconDot
              : NotificationIconBill
          }
          alt=""
          style={{ width: 22, height: 22, minWidth: 22, maxWidth: 22 }}
        />
        <img
          src={Arrow}
          alt=""
          style={{
            width: 8,
            height: 8,
            marginTop: 2,
            minWidth: 8,
            maxWidth: 8,
          }}
        />
        {/* {newNotification.length > 0 ? (
          <>
            {isAdmin ? (
              <span className="notification-recently-admin"></span>
            ) : (
              <span className="notification-recently"></span>
            )}
          </>
        ) : null} */}
      </button>
      <div
        className={`dropdown-menu ${classes["notifications-box"]}`}
        style={{ top: NotAdmin ? "45px !important" : "" }}
      >
        <div className={classes["notification-header"]}>
          <h4> {t("notification")} </h4>
          <div className={classes["notification-count"]}>
            {<span>{newNotification.length}</span>}
          </div>
        </div>
        <div className={`${classes.notifications} inner-scrollbar_blue`}>
          {notifications.length > 0 ? (
            notifications.map((notification) => {
              return (
                <>
                  <NotificationCard
                    notification={notification}
                    readNotification={readNotification}
                    NotificationIcon={NotificationIcon}
                    getTimeFromNow={getTimeFromNow}
                    notifications={notifications}
                  />
                  {/* <div
                    key={notification.id}
                    className={`${classes["notification"]} ${
                      !notification.is_read ? classes.new : null
                    }`}
                    onMouseLeave={() => readNotification(notification)}
                  >
                    <div className={classes["notification-content"]}>
                      <div className={classes["notification-img"]}>
                        <img src={NotificationIcon} alt="" />
                      </div>
                      <p>
                        {i18n.language === "en"
                          ? notification.msg
                            ? notification.msg
                            : notification.msg_ar
                          : notification.msg_ar
                          ? notification.msg_ar
                          : notification.msg}
                      </p>
                    </div>
                    <span className={classes["notification-time"]}>
                      {getTimeFromNow(toLocalTimezone(notification.created_at))}
                    </span>
                  </div>
                  {notifications.indexOf(notification) !==
                  notifications.length - 1 ? (
                    <div className={classes["notification-divider"]}></div>
                  ) : null} */}
                </>
              );
            })
          ) : (
            <div className={classes["no-notification"]}>
              <p>{t("notNotification")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;

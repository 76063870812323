import { useTranslation } from "react-i18next";
import FilterIcon from "../../../assets/icons/filter.svg";
import PdfIcon from "../../../assets/icons/pdf.svg";
import XlsIcon from "../../../assets/icons/xls.svg";

export const FilterActions = ({ showFilter, exportPdf, exportExcel, selectCertificateTemplate }) => {
  const { t, i18n } = useTranslation();

  const actionsList = [
    ...(selectCertificateTemplate
      ? [
          {
            id: "certificateTemplate",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #8599B6",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label: t("certificate.select_certificate_template"),
            btnTextColor: "#8599B6",
            action: selectCertificateTemplate,
          },
        ]
      : []),
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      theme: "filter",
      action: () => {
        showFilter();
      },
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};

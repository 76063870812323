import { useTranslation } from "react-i18next";
import classes from "./exhibitionDetails.module.css";
const ExhibitionDetailsAudios = ({ mainAudio, backgroundAudio }) => {
	const { t, i18n } = useTranslation();

	return (
		<div className={classes["exhibition-details__audios"]}>
			{mainAudio && (
				<div className={classes["exhibition-details__audios-item"]}>
					<div className={classes["exhibition-details__audios-item-title"]}>{t("Exhibition.main_audio")}</div>
					<audio controls>
						<source src={mainAudio} />
					</audio>
				</div>
			)}
			{backgroundAudio && (
				<div className={classes["exhibition-details__audios-item"]}>
					<div className={classes["exhibition-details__audios-item-title"]}>{t("Exhibition.background_audio")}</div>
					<audio controls>
						<source src={backgroundAudio} />
					</audio>
				</div>
			)}
		</div>
	);
};

export default ExhibitionDetailsAudios;

import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { blockConstants } from "../../Constants/block/block.constants";

export const getAllBlocks = ({ page, perPage, filter }) => {
  return async (dispatch) => {
    dispatch({ type: blockConstants.GET_ALL_BLOCKS_REQUEST });
    await connector
      .get(env.all_block, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        dispatch({
          type: blockConstants.GET_ALL_BLOCKS_SUCCESS,
          payload: {
            list: response.data.data.role_blocks.data,
            meta: response.data.data.role_blocks.meta,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: blockConstants.GET_ALL_BLOCKS_REJECTED,
          payload: err,
        });
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err}</span>, {
          autoClose: 1000,
        });
      });
  };
};

export const deleteBlockById = (id) => {
  return async (dispatch) => {
    dispatch({ type: blockConstants.DELETE_BLOCK_BY_ID_REQUEST });
    await connector
      .get(URLBuilder(env.delete_block, { id: id }))
      .then((response) => {
        if (response.status === 200 && response.data.status) {
          dispatch({
            type: blockConstants.DELETE_BLOCK_BY_ID_SUCCESS,
            payload: {
              message: response.data.msg,
              id: id,
            },
          });
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>, {
            autoClose: 1000,
          });
        } else {
          dispatch({
            type: blockConstants.DELETE_BLOCK_BY_ID_REJECTED,
            payload: response.data.msg,
          });
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>, {
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: blockConstants.DELETE_BLOCK_BY_ID_REJECTED,
          payload: err,
        });
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err}</span>, {
          autoClose: 1000,
        });
      });
  };
};

export const getCountries = () => {
  return async (dispatch) => {
    dispatch({ type: blockConstants.GET_COUNTRIES_REQUEST });
    await connector
      .get(env.block_countries)
      .then((response) => {
        if (response.status === 200 && response.data.status) {
          dispatch({
            type: blockConstants.GET_COUNTRIES_SUCCESS,
            payload: response.data.countries,
          });
        } else {
          dispatch({
            type: blockConstants.GET_COUNTRIES_REJECTED,
            payload: response.data.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: blockConstants.GET_COUNTRIES_REJECTED,
          payload: err,
        });
      });
  };
};

export const unblockRequestByIp = (payload) => {
  return async (dispatch) => {
    dispatch({ type: blockConstants.UNBLOCK_REQUEST_BY_IP_REQUEST });
    await connector
      .post(env.unblock_ip, payload)
      .then((response) => {
        if (response.status === 200 && response.data.status) {
          dispatch({
            type: blockConstants.UNBLOCK_REQUEST_BY_IP_SUCCESS,
            payload: response.data.msg,
          });
        } else {
          dispatch({
            type: blockConstants.UNBLOCK_REQUEST_BY_IP_REJECTED,
            payload: response.data.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: blockConstants.UNBLOCK_REQUEST_BY_IP_REJECTED,
          payload: err,
        });
      });
  };
};

import { useTheme } from "@mui/material";

const ContactMail = ({ width, height, fill }) => {
	const theme = useTheme();
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || "55"}
			height={height || "44"}
			viewBox="0 0 55.037 43.573"
		>
			<g id="mail_5_" data-name="mail (5)" transform="translate(2.785 2)">
				<g id="Group_236" data-name="Group 236">
					<path
						id="Path_12202"
						data-name="Path 12202"
						d="M6.947,4H46.519a4.961,4.961,0,0,1,4.947,4.947v29.68a4.961,4.961,0,0,1-4.947,4.947H6.947A4.961,4.961,0,0,1,2,38.626V8.947A4.961,4.961,0,0,1,6.947,4Z"
						transform="translate(-2 -4)"
						fill="none"
						stroke={fill || theme.palette.primary.light}
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="4"
					/>
					<path
						id="Path_12203"
						data-name="Path 12203"
						d="M51.466,6,26.733,23.313,2,6"
						transform="translate(-2 -1.053)"
						fill="none"
						stroke={fill || theme.palette.primary.light}
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="4"
					/>
				</g>
			</g>
		</svg>
	);
};

export default ContactMail;

import { livechatConstant } from "../../Constants/livechat/livechat.constant";

const initialState = {
  livechat_setting: {},
  livechat_send_message: {},
  livechat_close_session: {},

  livechat_sessions: {},
  livechat_autoreply: {},
  livechat_status: {},

  livechat_reply: {},
  livechat_add_reply: {},
  livechat_edit_reply: {},
  livechat_delete_reply: {},
  livechat_reply_status: {},
  livechat_reply_by_id: {},

  livechat_standard_messages: {},

  livechat_support_users: {},
  livechat_update_support_users: {},
  livechat_support_users_status: {},
  livechat_delete_support_users: {},
  livechat_support_users_by_id: {},

  livechat_assign_session: {},
  livechat_view_session: {},
  livechat_messages: {},

  livechat_message_change: {},
  livechat_schedule: {},
};

const livechatReducer = (state = initialState, action) => {
  switch (action.type) {
    case livechatConstant.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case livechatConstant.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    case livechatConstant.GET_LIVECHAT_SETTING:
      return {
        ...state,
        livechat_setting: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_SEND_MESSAGE:
      return {
        ...state,
        livechat_send_message: action.payload,
      };
    case livechatConstant.POST_LIVECHAT_CLOSE_SESSION:
      return {
        ...state,
        livechat_close_session: action.payload,
      };

    case livechatConstant.GET_LIVECHAT_SESSION:
      return {
        ...state,
        livechat_sessions: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_AUTO_REPLY:
      return {
        ...state,
        livechat_autoreply: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_STATUS:
      return {
        ...state,
        livechat_status: action.payload,
      };

    case livechatConstant.GET_LIVECHAT_REPLY:
      return {
        ...state,
        livechat_reply: action.payload,
      };

    case livechatConstant.GET_LIVECHAT_REPLY_BY_ID:
      return {
        ...state,
        livechat_reply_by_id: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_ADD_REPLY:
      return {
        ...state,
        livechat_add_reply: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_EDIT_REPLY:
      return {
        ...state,
        livechat_edit_reply: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_REPLY_STATUS:
      return {
        ...state,
        livechat_reply_status: action.payload,
      };

    case livechatConstant.DELETE_LIVECHAT_REPLY:
      return {
        ...state,
        livechat_delete_reply: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_STANDERMESSAGE:
      return {
        ...state,
        livechat_standard_messages: action.payload,
      };

    case livechatConstant.GET_LIVECHAT_SUPPORT_USERS:
      return {
        ...state,
        livechat_support_users: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_UPDATE_SUPPORT_USERS:
      return {
        ...state,
        livechat_update_support_users: action.payload,
      };

    case livechatConstant.GET_LIVECHAT_SUPPORT_USERS_BY_ID:
      return {
        ...state,
        livechat_support_users_by_id: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_SUPPORT_USERS_STATUS:
      return {
        ...state,
        livechat_support_users_status: action.payload,
      };

    case livechatConstant.DELETE_LIVECHAT_SUPPORT_USERS:
      return {
        ...state,
        livechat_delete_support_users: action.payload,
      };

    case livechatConstant.POST_LIVECHAT_SESSION_ASSIGN:
      return {
        ...state,
        livechat_assign_session: action.payload,
      };

    case livechatConstant.GET_LIVECHAT_SCHEDULE:
      return {
        ...state,
        livechat_schedule: action.payload,
      };

    case livechatConstant.GET_LIVECHAT_VIEW_SESSION:
      return {
        ...state,
        livechat_view_session: action.payload,
      };

    case livechatConstant.GET_LIVECHAT_MESSAGES:
      return {
        ...state,
        livechat_messages: action.payload,
      };

    case livechatConstant.RESET_LIVECHAT:
      return {
        ...state,
        livechat_add_reply: {},
        livechat_edit_reply: {},
        livechat_standard_messages: {},
        livechat_reply_by_id: {},
        livechat_update_support_users: {},
        livechat_support_users_by_id: {},
        livechat_reply: {},
        livechat_support_users: {},
        livechat_view_session: {},
      };

    case livechatConstant.LIVECHAT_CHANGE_MESSAGE:
      return {
        ...state,
        livechat_message_change: true,
      };

    default:
      return state;
  }
};

export default livechatReducer;

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import classes from "./gift.module.css";

const SendGiftTable = ({ loading, listGift }) => {
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const displayMode = (is_program) => {
    if (is_program) {
      return t("general.program");
    } else {
      return t("rate.label.course");
    }
  };
  const displayStatus = {
    Waiting: <span className={classes["waiting"]}>{t("general.waiting")}</span>,
    Approved: <span className={classes["approved"]}>{t("general.approved")}</span>,
    Rejected: <span className={classes["rejected"]}>{t("general.rejected")}</span>,
    Canceled: <span className={classes["canceled"]}>{t("general.canceled")}</span>,
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    // {
    //   id: "id",
    //   name: t("general.id"),
    //   key: "id",
    //   cell: ({ rowData }) => <>{rowData?.id}</>,
    //   sortable: false,
    // },
    {
      id: "parent_type",
      name: t("zoom.type"),
      key: "parent_type",
      cell: ({ rowData }) => (
        <>
          <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.course?.is_program ? "#12C689" : "#8599B6",
              backgroundColor: rowData?.course?.is_program ? "#C1E2C822" : "#C1CEE222",
            }}
          >
            {displayMode(rowData?.course?.is_program) || "-"}
          </span>
        </>
      ),
    },
    {
      id: "name",
      name: t("coupon.label.course_or_program"),
      key: "name",
      cell: ({ rowData }) => <div className={classes["gift-list__table-name"]}>{rowData?.course?.name || "-"}</div>,
    },

    {
      id: "gift_date",
      name: t("gift_date"),
      key: "gift_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
    },
    {
      id: "gifted_to",
      name: t("gifted_to"),
      key: "gifted_to",
      cell: ({ rowData }) => <>{i18n.language === "ar" ? rowData?.trainee?.name_ar : rowData?.trainee?.name_en}</>,
      sortable: false,
    },
    {
      id: "rejected_reason",
      name: t("financial.payments_request.rejected_reason"),
      key: "rejected_reason",
      cell: ({ rowData }) => (
        <>
          <div>
            <p className={classes["note_table"]}>{rowData?.payment?.rejection_reason || "-"}</p>
          </div>
        </>
      ),
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => <>{displayStatus[rowData?.payment?.status] || "-"}</>,
    },
    // {
    //   id: "actions",
    //   name: t("general.actions"),
    //   key: "actions",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.parent_type == 1 ? (
    //         "-"
    //       ) : (
    //         <TableActionsColumn actions={giftActionsList} id={rowData.id} />
    //       )}
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      <MainTable columns={columns} data={listGift || []} isLoading={loading} />
    </>
  );
};

export default SendGiftTable;

import React from "react";
import classes from "./Ads.module.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Defualt_img from "../../../assets/image/defualt_img.png";
export default function AdCard({ adv }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <div
      className={classes.cardMain}
      onClick={() => history.push(`/advertisments/${adv.id}`)}
    >
      <div className={classes.cardImg}>
        <img
          src={adv.image || Defualt_img}
          alt=""
          className={classes.cardImg}
        />
      </div>
      <div className={classes.contentDiv}>
        <h3>{adv.title}</h3>
        <span>
          {moment(adv.from_date).format("YYYY/MM/DD")}

          {/* {adv.from_date} - {adv.to_date} */}
        </span>
        <p>{adv.description.slice(0, 195)}</p>
      </div>
      <div className={classes.seeMore}>
        <p>{t("see_more")}</p>
      </div>
    </div>
  );
}

import axios from "axios";
import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export async function login(email, password) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.login, {
          email: email,
          password: password,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export async function loginSso(email, password) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.login_sso, {
          email: email,
          password: password,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export async function socialAuth(payload) {
  try {
    const response = await connector.post(env.social_auth, payload);
    return response;
  } catch (error) {
    console.error("error");
  }
}

export async function socialSignIn(social) {
  try {
    const response = await connector.get(URLBuilder(env.social_sign_in, { social: social }));
    return response;
  } catch (error) {
    console.error("error");
  }
}

export async function twitterSignIn() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_PATH}${env.twitter_sign_in}`);
    return response;
  } catch (error) {
    console.error("error");
  }
}

export async function register(obj) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.register, {
          name_en: obj.nameEn,
          name_ar: obj.nameAr,
          email: obj.email,
          password: obj.password,
          password_confirmation: obj.confirmPassword,
          country_id: obj.countriesId,
          by_admin: obj.byAdmin,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export async function getUserData(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.user_profile, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function changePassword(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.forgot_password, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function changeToAlternativeEmail(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.change_to_alternative_email, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function confirmationEmailChange(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.update_password, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function checkPasswordResetToken(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.check_password_reset_token, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getAlternativeEmail(email) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.alternative_email, { email: email })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

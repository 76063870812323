import classes from "./tabs.module.css";

const Tabs = ({ tabs, activeTab, onSelectTab, disabled }) => {
  return (
    <ul className={classes["tabs"]}>
      {!!tabs.length &&
        tabs.map(
          (tab, index) =>
            !tab?.disable && (
              <li
                className={`${classes["tabs__item"]} ${tab.key === activeTab && classes["tabs__item-active"]}`}
                key={index}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  onSelectTab(tab.key);
                }}
              >
                {tab.label}
              </li>
            )
        )}
    </ul>
  );
};

export default Tabs;

import { useContext, useEffect } from "react";
import { ModalProviderContext } from "../../../../../Providers/ModalProvider";
import "./BasicModalInstance.css";

function BasicModalInstance(props) {
  const modalContext = useContext(ModalProviderContext);

  useEffect(() => {
    if (modalContext.showModal) {
      document.body.scroll = "no";
      document.body.style.overflow = "hidden";
      document.getElementsByClassName(
        "tajah-modal-background-tint"
      )[0].style.display = "flex";
      setTimeout(() => {
        document.getElementsByClassName(
          "tajah-modal-background-tint"
        )[0].style.opacity = 1;
      }, 1);
    } else {
      document.body.scroll = "yes";
      document.body.style.overflow = "scroll";
      document.getElementsByClassName(
        "tajah-modal-background-tint"
      )[0].style.opacity = 0;
    }
  }, [modalContext.showModal]);

  useEffect(() => {
    const transition = document.getElementsByClassName(
      "tajah-modal-background-tint"
    );
    "transitionend webkitTransitionEnd oTransitionEnd otransitionend"
      .split(" ")
      .forEach((item) => {
        transition[0].addEventListener(item, onRemoveModalTint);
      });
  }, []);

  const onRemoveModalTint = () => {
    if (
      document.getElementsByClassName("tajah-modal-background-tint")[0].style
        .opacity === "0"
    ) {
      document.getElementsByClassName(
        "tajah-modal-background-tint"
      )[0].style.display = "none";
    }
  };

  return (
    <>
      <div
        style={{
          height: document.body.offsetHeight,
        }}
        onClick={() => {
          // ----- Comment it because (When you click the backdrop, it accepts and deletes the row from the database)
          // modalContext.children.props.accept();
        }}
        className={"tajah-modal-background-tint"}
      >
        {modalContext.children}
      </div>
    </>
  );
}

export default BasicModalInstance;

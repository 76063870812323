import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { TrainerProfileContext } from "../../Services/api/toutorials/trainerProfileContext";
import { getTrainerProfile } from "../../Services/api/toutorials/trainerProvider";
import noResult from "../../assets/image/Noresult.png";
import Footer from "../Shared/Components/footer/footer";
import AddOverview from "../trainersCreateCourses/addOverview/addOverview";
import TrainersCCHeader from "../trainersCreateCourses/trainersCCHeader/trainersCCHeader";
import List from "./Course/List/List";
import ListComplet from "./Course/ListComplet/ListComplet";

function Profile() {
  require("./trainerprofile.css");
  const { t } = useTranslation();
  const trainerProfileContext = useContext(TrainerProfileContext);

  let trainerId =
    trainerProfileContext.trainersProfile.info &&
    trainerProfileContext.trainersProfile.info.id;

  useEffect(() => {
    getTrainerProfile()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          trainerProfileContext.setTrainersProfile(res.data.response);
          trainerProfileContext.setIsContentLoaded(true);
        }
      })
      .catch((error) => {
        
      });
  }, []);

  return (
    <>
      <TrainersCCHeader />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 profiles">
            <ul className="nav nav-tabs profile ">
              <li className="active links pri_profile">
                <a data-toggle="tab" href="#personalInfo">
                  {t("privatepro")}
                </a>
              </li>
              <li className="links pri_profile margin_tabs">
                <a data-toggle="tab" href="#accountDe">
                  {t("overview")}
                </a>
              </li>
              <li className="links pri_profile ">
                <a data-toggle="tab" href="#accountCr">
                  {t("statsandfigures")}
                </a>
              </li>
            </ul>
            <div className="profile_click_here">
              {t("clickhere")}
              <NavLink
                to={`/trainers/trainer-details/${trainerId}`}
                className="click_here"
              >
                {" "}
                {t("clickherebtn")}
              </NavLink>
            </div>

            <div className="tab-content">
              <div id="personalInfo" className="tab-pane active">
                <div className="card-body">
                  <>
                    <div className="overview_add_top my-3">
                      {t("CurrentCourses")}
                    </div>
                    <List />
                  </>
                </div>
                <div className="card-body">
                  <>
                    <div className="overview_add_top my-3">
                      {t("completecourse")}
                    </div>
                    {trainerProfileContext.trainersProfile.coursesFinished &&
                    trainerProfileContext.trainersProfile.coursesFinished
                      .length == 0 ? (
                        <span
                          className="course-details"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: "50px"
                          }}
                        >
                          <img src={noResult} alt="" />
                          <h5>{t("no_record")}</h5>
                        </span>
                    ) : (
                      <ListComplet />
                    )}
                  </>
                </div>
              </div>
              <div id="accountDe" className="tab-pane fade">
                <div className="card-body">
                  <>
                    <AddOverview />
                  </>
                </div>
              </div>
              <div id="accountCr" className="tab-pane fade">
                <div className="card-body">
                  <>
                    <div className="card-body"></div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Profile;

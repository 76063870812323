import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { listPayment } from "../../../../Services/api/Gift/giftProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./PaymentDone.module.css";
import { filterActions } from "./PaymentDoneActions";
import PaymentDoneFilter from "./PaymentDoneFilter";
import PaymentDoneTable from "./PaymentDoneTable";

function PaymentDone() {
  const { t } = useTranslation();
  const [paymentList, setPaymentList] = useState([]);
  const [giftId, setGiftId] = useState();
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false); //////////////////
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({
    trainee_name: "",
    start_date: "",
    end_date: "",
    // parent_type: "",
    course_id: "",
  });
  const changeHandler = (e, field) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name || field]: value });
  };
  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      trainee_name: "",
      start_date: "",
      end_date: "",
      // parent_type: "",
      course_id: "",
    });
    setIsResetApplied(true);
  };
  const getFilterData = () => {
    const filter = { perPage: 10, page: currentPage };

    filterData.trainee_name && (filter.trainee_name = filterData.trainee_name);
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);
    // filterData.parent_type && (filter.parent_type = filterData.parent_type);
    filterData.course_id && (filter.course_id = filterData.course_id);
    // (filterData.course || filterData.program) &&
    //   (filterData.parent_type == 1
    //     ? (filter.course_id = filterData.course)
    //     : (filter.program_id = filterData.program));

    return filter;
  };

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { not_in_program: 1, is_program: 0 } : {}),
      mode: "Paid",
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      })
      .finally(() => {
        setIsCoursesLoading(false);
        setIsProgramsLoading(false);
      });
  };

  useEffect(() => {
    // getAllCoursesProgramsHandler({
    //   is_program: false,
    // });
    getAllCoursesProgramsHandler({
      is_program: true,
    });
  }, []);

  const getPaymentList = () => {
    return new Promise((resolve, reject) => {
      const filter = getFilterData();
      listPayment(filter)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setPaymentList(res.data.response.payments);
            setMeta(res.data.response.meta);
            setLoading(false);
            resolve();
          } else {
            reject();
            toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
          }
        })
        .catch((err) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
        });
    });
  };

  useEffect(() => {
    getPaymentList();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "privateProfile",
      page: t("sidebar.label.private_profile"),
      pagePath: "/private-profile",
    },
    {
      id: `sent_gift`,
      page: t("private_profile.sent_gift"),
      pagePath: "/private-profile/sent-gift",
    },
    {
      id: `sent_gift`,
      page: t("payment_program"),
      pagePath: "#",
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  // const exportHandler = async (type) => {
  //   setIsExportLoading(true);
  //   try {
  //     // await exportTaxList(currentTaxFilter, type);
  //     setIsExportLoading(false);
  //   } catch (e) {
  //     setIsExportLoading(false);
  //     toast.error(
  //       <span style={{ fontSize: 13, fontWeight: "bold" }}>
  //         {t("failed_fetching")}
  //       </span>
  //     );
  //   }
  // };

  return (
    <div className={classes["gift-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      {loading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["gift-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <MainBox>
                <MainBoxHead
                  title={t("payment_program")}
                  actions={filterActions({
                    showFilter: showFilterHandler,

                    // exportPdf: () => {
                    //   exportHandler("pdf");
                    // },
                    // exportExcel: () => {
                    //   exportHandler("xlsx");
                    // },
                  })}
                />
                {showFilter && (
                  <PaymentDoneFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getPaymentList}
                    resetFilterHandler={resetFilterHandler}
                    courses={courses}
                    programs={programs}
                  />
                )}
                <PaymentDoneTable loading={loading} paymentList={paymentList} />
                <Pagination
                  count={meta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDone;

import { useTranslation } from "react-i18next";
import TrainerPhoto from "../../../../assets/image/TrainerPic.png";
import SharedSlider from "../../../Shared/SharedSlider/SharedSlider";
import UserCard from "./UserCard/UserCard";
import "./newTrainers.css";
import classes from "./newTrainers.module.css";
import { useEffect, useState } from "react";
import { getTrainerHome } from "../../../../Services/api/toutorials/trainerProvider";
import SkeletonSpinnerOverlay from "../../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import i18n from "../../../../i18n/i18n";

const NewTrainers = () => {
  const { t } = useTranslation();
  const [trainers, settrainers] = useState([]);
  const [loading, setloading] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    const apiCall = getTrainerHome;
    apiCall()
      .then((res) => {
        if (res.status && res.status === 200) {
          settrainers(res.data.response.data);
        }
        setloading(true);
      })
      .catch((err) => {
        console.log(err);
        setloading(true);
      });
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className={classes.trainersSectionCont}>
            <div className={classes.trainersSection}>
              <h2 className={classes.sectionTitle}>
                {t("home.professional")}&nbsp;
                <span>{t("home.trainers")}</span>
              </h2>
              {/* <p>{t("home.trainersSubHeading")}</p> */}
              {trainers.length <= 4 && width > 1200 ? (
                <div className={classes.staticCont}>
                  {trainers
                    .sort((a, b) =>
                      i18n.language === "ar"
                        ? a.rating - b.rating
                        : b.rating - a.rating
                    )
                    .map((trainer, index) => (
                      <UserCard
                        key={index}
                        index={index}
                        img={trainer.img}
                        name={
                          i18n.language === "ar"
                            ? trainer.name_ar
                            : trainer.name_en
                        }
                        rate={trainer.rating}
                        postionTitle={trainer.postionTitle}
                        description={trainer.description}
                        countCourses={trainer.countCourses}
                        countUsers={trainer.countUsers}
                        trainer={trainer}
                        loading={loading}
                        totalCards={trainers.length}
                        delay={0.3 * (index % 4)}
                      />
                    ))}
                </div>
              ) : trainers.length <= 3 && width > 900 ? (
                <div className={classes.staticCont}>
                  {trainers
                    .sort((a, b) =>
                      i18n.language === "ar"
                        ? a.rating - b.rating
                        : b.rating - a.rating
                    )
                    .map((trainer, index) => (
                      <UserCard
                        key={index}
                        index={index}
                        img={trainer.img}
                        name={
                          i18n.language === "ar"
                            ? trainer.name_ar
                            : trainer.name_en
                        }
                        rate={trainer.rating}
                        postionTitle={trainer.postionTitle}
                        description={trainer.description}
                        countCourses={trainer.countCourses}
                        countUsers={trainer.countUsers}
                        trainer={trainer}
                        loading={loading}
                        totalCards={trainers.length}
                        delay={0.3 * (index % 4)}
                      />
                    ))}
                </div>
              ) : trainers.length <= 2 && width > 600 ? (
                <div className={classes.staticCont}>
                  {trainers
                    .sort((a, b) =>
                      i18n.language === "ar"
                        ? a.rating - b.rating
                        : b.rating - a.rating
                    )
                    .map((trainer, index) => (
                      <UserCard
                        key={index}
                        index={index}
                        img={trainer.img}
                        name={
                          i18n.language === "ar"
                            ? trainer.name_ar
                            : trainer.name_en
                        }
                        rate={trainer.rating}
                        postionTitle={trainer.postionTitle}
                        description={trainer.description}
                        countCourses={trainer.countCourses}
                        countUsers={trainer.countUsers}
                        trainer={trainer}
                        loading={loading}
                        totalCards={trainers.length}
                        delay={0.3 * (index % 4)}
                      />
                    ))}
                </div>
              ) : trainers.length === 1 ? (
                <div className={classes.staticCont}>
                  {trainers
                    .sort((a, b) =>
                      i18n.language === "ar"
                        ? a.rating - b.rating
                        : b.rating - a.rating
                    )
                    .map((trainer, index) => (
                      <UserCard
                        key={index}
                        index={index}
                        img={trainer.img}
                        name={
                          i18n.language === "ar"
                            ? trainer.name_ar
                            : trainer.name_en
                        }
                        rate={trainer.rating}
                        postionTitle={trainer.postionTitle}
                        description={trainer.description}
                        countCourses={trainer.countCourses}
                        countUsers={trainer.countUsers}
                        trainer={trainer}
                        loading={loading}
                        totalCards={trainers.length}
                        delay={0.3 * (index % 4)}
                      />
                    ))}
                </div>
              ) : (
                <SharedSlider
                  slidesToShow={4}
                  slidesToScroll={4}
                  noArrows={true}
                  dots={true}
                  classes={`${classes.slider} trainers-slider`}
                  responsive={[
                    {
                      breakpoint: 2000,
                      settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                      },
                    },
                    {
                      breakpoint: 1920,
                      settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                      },
                    },
                    {
                      breakpoint: 1600,
                      settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                      },
                    },
                    {
                      breakpoint: 1400,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 960,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 380,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                >
                  {width <= 600
                    ? trainers
                        .sort((a, b) =>
                          i18n.language === "ar"
                            ? a.rating - b.rating
                            : b.rating - a.rating
                        )
                        .slice(0, 4)
                        .map((trainer, index) => (
                          <UserCard
                            key={index}
                            index={index}
                            img={trainer.img}
                            name={
                              i18n.language === "ar"
                                ? trainer.name_ar
                                : trainer.name_en
                            }
                            rate={trainer.rating}
                            postionTitle={trainer.postionTitle}
                            description={trainer.description}
                            countCourses={trainer.countCourses}
                            countUsers={trainer.countUsers}
                            trainer={trainer}
                            loading={loading}
                            delay={0.3 * (index % 4)}
                            totalCards={4}
                          />
                        ))
                    : trainers
                        .sort((a, b) =>
                          i18n.language === "ar"
                            ? a.rating - b.rating
                            : b.rating - a.rating
                        )
                        .slice(0, 16)
                        .map((trainer, index) => (
                          <UserCard
                            key={index}
                            index={index}
                            img={trainer.img}
                            name={
                              i18n.language === "ar"
                                ? trainer.name_ar
                                : trainer.name_en
                            }
                            rate={trainer.rating}
                            postionTitle={trainer.postionTitle}
                            description={trainer.description}
                            countCourses={trainer.countCourses}
                            countUsers={trainer.countUsers}
                            trainer={trainer}
                            loading={loading}
                            delay={0.3 * (index % 4)}
                            totalCards={trainers.slice(0, 16).length}
                          />
                        ))}
                </SharedSlider>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <div
              style={{
                height: "400px",
                width: "90%",
              }}
            >
              <SkeletonSpinnerOverlay
                backdrop={true}
                skeletonWidth="100"
                skeletonHight="100"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NewTrainers;

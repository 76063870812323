import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { toServerTime } from "../../Services/Timezone";
import { ListTypes, addZoomSession } from "../../Services/api/Zoom/ZoomProvider";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";

export default function AddSession() {
  require("./zoom.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { courseId, id } = useParams();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [types, setTypes] = useState([]);
  const [reminderSent, setReminderSent] = useState("");
  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);

  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);

  useEffect(() => {
    currentDate();
    setIsLoading(false);
    async function executeOnLoad() {
      let response = await ListTypes();
      if (response.status && response.status == 200) {
        setTypes(response.data.data);
      }
    }

    executeOnLoad();
  }, []);

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate);
  };
  const currentDateEnd = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
  };
  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const formatTime = (t) => {
    var now = new Date(t);
    var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    let hours = "" + utc.getHours(),
      minutes = "" + utc.getMinutes(),
      seconds = "" + utc.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return [hours, minutes, seconds].join(":");
  };

  return (
    <div>
      <div className="header_profile">
        <div className="container-fluid profile_wrapper">
          <div className="profile_grid">
            <div className="profile_dhead">
              <h3>{t("zoom.Add_virtual_session")}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="row">
          <div className="col-12">
            <div className="admin_label">
              <p style={{ padding: "0 5px" }}>/</p>
              <NavLink to="/">{t("Home")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("zoom.Add_virtual_session")}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="row">
          <div className="col-12">
            <div className={isLoading ? null : "table_bank_tran"}>
              <h3 className="table_header">{t("zoom.Add_virtual_session")}</h3>
              {isLoading ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <div className="admin_input_sponsor">
                  <Formik
                    initialValues={{
                      type_id: "1",
                      topic: "",
                      restrict_number: "",
                      percentage: "",
                      duration: "",
                      start_time: setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
                      time: "",
                      timing_type: "minutes",
                      password: "",
                      title: "",
                      end: setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        setIsLoading(true);
                        let dataToBeUploaded = {
                          agenda: values.topic,
                          start: "",
                          ...values,
                        };

                        dataToBeUploaded.start_time = toServerTime(dataToBeUploaded.start_time);
                        dataToBeUploaded.start = dataToBeUploaded.start_time;
                        dataToBeUploaded.end = toServerTime(dataToBeUploaded.end);

                        if (dataToBeUploaded.type_id == 1) {
                          delete dataToBeUploaded.password;
                          delete dataToBeUploaded.start;
                          delete dataToBeUploaded.title;
                          delete dataToBeUploaded.end;
                        }
                        if (dataToBeUploaded.reminder_sent) {
                          if (dataToBeUploaded.reminder_sent.length > 0) {
                            if (dataToBeUploaded.reminder_sent[0] == "on") {
                              dataToBeUploaded.reminder_sent = 1;
                            }
                          } else {
                            delete dataToBeUploaded.reminder_sent;
                            delete dataToBeUploaded.time;
                            delete dataToBeUploaded.timing_type;
                          }
                        } else {
                          delete dataToBeUploaded.reminder_sent;
                          delete dataToBeUploaded.time;
                          delete dataToBeUploaded.timing_type;
                        }
                        if (dataToBeUploaded.type_id) {
                          dataToBeUploaded.type_id = parseInt(dataToBeUploaded.type_id);
                        }
                        //remove field reminder_sent
                        delete dataToBeUploaded.reminder_sent;

                        const response = await addZoomSession(id, dataToBeUploaded);
                        if (response.status === 201 && response.data.status) {
                          history.push(`/trainer/course/${courseId}/lesson/${id}`);
                          //setIsLoading(false);
                        } else {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {response.data.msg ? response.data.msg : "Failure in service"}
                            </span>
                          );
                          setIsLoading(false);
                        }
                      } catch (err) {
                        setIsLoading(false);
                        toast.error(
                          <>
                            <span
                              style={{
                                fontSize: 13,
                                fontWeight: "bold",
                                display: "block",
                                paddingBottom: "10px",
                              }}
                            >
                              {err.response.data.msg}
                            </span>
                            <ul>
                              {Object.keys(err.response.data.errors).map((key) => {
                                return (
                                  <li>
                                    <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        );
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.type_id) {
                        errors.type_id = t("crud.errors.required");
                      }
                      if (!values.topic) {
                        errors.topic = t("crud.errors.required");
                      }

                      if (!values.restrict_number) {
                        errors.restrict_number = t("crud.errors.required");
                      }

                      if (!values.percentage) {
                        errors.percentage = t("crud.errors.required");
                      }

                      if (values.duration < 30) {
                        errors.duration = t("zoom.error_duration_session");
                      }
                      if (!values.duration) {
                        errors.duration = t("crud.errors.required");
                      }
                      if (!values.start_time) {
                        errors.start_time = t("crud.errors.required");
                      }
                      if (values.reminder_sent == "on") {
                        if (!values.time) {
                          errors.time = t("crud.errors.required");
                        }
                        if (!values.timing_type) {
                          errors.timing_type = t("crud.errors.required");
                        }
                      }
                      if (values.type_id && values.type_id != 1) {
                        if (!values.title) {
                          errors.title = t("crud.errors.required");
                        }
                        if (!values.password) {
                          errors.password = t("crud.errors.required");
                        }
                        if (!values.end) {
                          errors.end = t("crud.errors.required");
                        }
                      }
                      return errors;
                    }}
                  >
                    {({ values, errors, handleChange, handleSubmit, isSubmitting, handleBlur, setFieldValue }) => (
                      <form onSubmit={handleSubmit} className="admin_add_form">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="name" className="admin_add_label">
                                  {t("zoom.Name_training_course")}
                                </label>
                                <select
                                  name="type_id"
                                  id="type_id"
                                  className="admin_add_input"
                                  value={values.type_id}
                                  onChange={handleChange}
                                >
                                  {types.length > 0 &&
                                    types.map((type, key) => {
                                      return (
                                        <option key={key} value={type.id}>
                                          {" "}
                                          {i18n.language == "en" ? type.name.en : type.name.ar}{" "}
                                        </option>
                                      );
                                    })}
                                </select>
                                <p className={"form-input-error-space"}>{errors.type_id ? errors.type_id : null}</p>
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="name" className="admin_add_label">
                                  {t("zoom.topic_session")}
                                </label>
                                <input
                                  id="topic_session"
                                  type="text"
                                  name="topic"
                                  className="admin_add_input"
                                  onChange={handleChange}
                                  value={values.topic}
                                />
                                <p className={"form-input-error-space"}>{errors.topic ? errors.topic : null}</p>
                              </div>
                            </div>
                          </div>
                          {values.type_id && values.type_id != 1 ? (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="name" className="admin_add_label">
                                    {t("title")}
                                  </label>
                                  <input
                                    type="text"
                                    name="title"
                                    className="admin_add_input"
                                    onChange={handleChange}
                                    // defaultValue ={20}
                                    value={values.title}
                                  />
                                  <p className={"form-input-error-space"}>{errors.title ? errors.title : null}</p>
                                </div>

                                <div className="col-md-4">
                                  <label htmlFor="name" className="admin_add_label">
                                    {t("password")}
                                  </label>
                                  <input
                                    type="password"
                                    name="password"
                                    className="admin_add_input"
                                    onChange={handleChange}
                                    value={values.password}
                                  />
                                  <p className={"form-input-error-space"}>{errors.password ? errors.password : null}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-md-12">
                            <div className="row">
                              {/* <div className="col-md-4">
                                <label htmlFor="name" className="admin_add_label">
                                  {t("zoom.number_registere")}
                                </label>
                                <input
                                  type="number"
                                  name="restrict_number"
                                  className="admin_add_input"
                                  onChange={handleChange}
                                  // defaultValue ={20}
                                  value={values.restrict_number}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.restrict_number ? errors.restrict_number : null}
                                </p>
                              </div> */}

                              <div className="col-md-4">
                                <label htmlFor="name" className="admin_add_label">
                                  {t("zoom.Percentage_attendance")}
                                </label>
                                <input
                                  type="number"
                                  name="percentage"
                                  className="admin_add_input"
                                  onChange={handleChange}
                                  // defaultValue ={"50%"}
                                  value={values.percentage}
                                />
                                <span className="span-input">%</span>
                                <p className={"form-input-error-space"}>
                                  {errors.percentage ? errors.percentage : null}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="start_day" className="admin_add_label">
                                  {t("zoom.start_date")}
                                </label>
                                <DatePicker
                                  selected={values.start_time}
                                  onChange={(date) => {
                                    setFieldValue("start_time", date);

                                    let CurrentDate = new Date();
                                    let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                    let DataPickerValue = date.toISOString().split("T")[0];
                                    if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                      setHoursDatepicker(0);
                                      setMinutesDatepicker(0);
                                    } else {
                                      currentDate();
                                    }
                                  }}
                                  className="admin_add_input"
                                  dateFormat={"yyyy-MM-dd h:mm aa"}
                                  showTimeSelect
                                  name="start_time"
                                  minDate={new Date()}
                                  minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.start_time ? errors.start_time : null}
                                </p>
                              </div>
                              {values.type_id && values.type_id != 1 ? (
                                <div className="col-md-4">
                                  <label htmlFor="start_day" className="admin_add_label">
                                    {t("end_date")}
                                  </label>
                                  <DatePicker
                                    selected={values.end}
                                    onChange={(date) => {
                                      setFieldValue("end", date);

                                      let CurrentDate = new Date();
                                      let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                      let DataPickerValue = date.toISOString().split("T")[0];
                                      if (
                                        new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()
                                      ) {
                                        setHoursDatepickerEnd(0);
                                        setMinutesDatepickerEnd(0);
                                      } else {
                                        currentDateEnd();
                                      }
                                    }}
                                    className="admin_add_input"
                                    dateFormat={"yyyy-MM-dd h:mm aa"}
                                    showTimeSelect
                                    name="end"
                                    minDate={new Date()}
                                    minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                                    maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                  />
                                  <p className={"form-input-error-space"}>{errors.end ? errors.end : null}</p>
                                </div>
                              ) : null}
                              <div className="col-md-4">
                                <label htmlFor="name" className="admin_add_label">
                                  {t("zoom.duration_session")}
                                </label>
                                <input
                                  id="duration"
                                  type="number"
                                  name="duration"
                                  className="admin_add_input"
                                  onChange={handleChange}
                                  value={values.duration}
                                />
                                <span className="span-input">{t("zoom.minute")} </span>
                                <p className={"form-input-error-space"}>{errors.duration ? errors.duration : null}</p>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <input
                              type="checkbox"
                              className="checkbox_zoom"
                              name="reminder_sent"
                              onChange={handleChange}
                            />
                            <p className="parg_zoom">{t("zoom.minutes_before_appointment")}</p>
                          </div>
                          {values.reminder_sent == "on" ? (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-1">
                                  <input
                                    type="number"
                                    className="admin_add_input"
                                    name="time"
                                    onChange={handleChange}
                                    placeholder={i18n.language == "en" ? "2 hours" : "2 ساعه"}
                                    value={values.time}
                                  />
                                  <p className={"form-input-error-space"}>{errors.time ? errors.time : null}</p>
                                </div>
                                <div className="col-md-2">
                                  <select
                                    name="timing_type"
                                    id="timing_type"
                                    className="admin_add_input"
                                    defaultValue={"minutes"}
                                    onChange={handleChange}
                                  >
                                    <option value="days"> {i18n.language == "en" ? "days" : "يوم"} </option>
                                    <option value="hours"> {i18n.language == "en" ? "hours" : "ساعه"} </option>
                                    <option value="minutes"> {i18n.language == "en" ? "minutes" : "دقيقة"} </option>
                                  </select>
                                  <p className={"form-input-error-space"}>
                                    {errors.timing_type ? errors.timing_type : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-md-12">
                            <button type="submit" disabled={isSubmitting} className="admin_add_button btn_zoom">
                              {t("zoom.addition")}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import { departmentListConstants } from "../../Constants/departments/departmentList.contants";


// GET DEPARTMENT LIST
export const fetchDepartmentList = ({ page, perPage, filter }) => {

	const payload = {};
	page && (payload.page = page);
	perPage && (payload.perPage = perPage);
	

  return async (dispatch) => {
		dispatch({
			type: departmentListConstants.GET_DEPARTMENT_LIST_REQUEST,
		});

		await connector
			.get(env.get_all_department, {
				params: {
					...payload,
					...filter,
				},
			})
			.then((response) => {
				if (response?.status === 200 && response?.data?.status) {
					dispatch({
						type: departmentListConstants.GET_DEPARTMENT_LIST_SUCCESS,
						payload: {
							departments: response.data.data.departments,
						},
					});
				} else {
					toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
					dispatch({
						type: departmentListConstants.GET_DEPARTMENT_LIST_REJECTED,
						payload: response?.data?.msg,
					});
				}
			})
			.catch((err) => {
				
				toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
				dispatch({
					type: departmentListConstants.GET_DEPARTMENT_LIST_REJECTED,
					payload: err,
				});
			});
	};
}

 // UPDATE DEPARTMENT STATUS
 export const updateCourseStatus = ({ id,status }) => {
	return async (dispatch) => {
		dispatch({
			type: departmentListConstants.UPDATE_DEPARTMENT_STATUS_REQUEST,
		});

		await connector
		.post(
			URLBuilder(env.update_department_status, {
				id,
			}), {
				status
			})
			.then((response) => {
				if (response?.status === 200 && response?.data?.status) {
					dispatch({
						type: departmentListConstants.UPDATE_DEPARTMENT_STATUS_SUCCESS,
						payload: {
							id,
						},
					});
				} else {
					toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
					dispatch({
						type: departmentListConstants.UPDATE_DEPARTMENT_STATUS_REJECTED,
						payload: response?.data?.msg,
					});
				}
			})
			.catch((err) => {
				
				toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
				dispatch({
					type: departmentListConstants.UPDATE_DEPARTMENT_STATUS_REJECTED,
					payload: err,
				});
			});
	};
};
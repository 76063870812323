export const specialtiesListConstants = {
  // GET SPECIALTIES LIST
  GET_SPECIALTIES_LIST_REQUEST: "GET_SPECIALTIES_LIST_REQUEST",
  GET_SPECIALTIES_LIST_SUCCESS: "GET_SPECIALTIES_LIST_SUCCESS",
  GET_SPECIALTIES_LIST_REJECTED: "GET_SPECIALTIES_LIST_REJECTED",

  // UPDATE SPECIALTY STATUS
  UPDATE_SPECIALTY_LIST_STATUS_REQUEST: "UPDATE_SPECIALTY_LIST_STATUS_REQUEST",
  UPDATE_SPECIALTY_LIST_STATUS_SUCCESS: "UPDATE_SPECIALTY_LIST_STATUS_SUCCESS",
  UPDATE_SPECIALTY_LIST_STATUS_REJECTED:
    "UPDATE_SPECIALTY_LIST_STATUS_REJECTED",
};

import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getRoles(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_roles, {
          params: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getAllUsers(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_all_users, {
          params: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportRoles(data, export_type, sponsers = false, userWallet = false) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(sponsers ? env.get_sponsors_list : env.get_roles, {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (sponsers) {
              link.setAttribute("download", "Sponsers" + "." + export_type); //or any other extension
            } else if (userWallet) {
              link.setAttribute("download", "Users Wallet" + "." + export_type); //or any other extension
            } else {
              link.setAttribute("download", "roles" + "." + export_type); //or any other extension
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

// downloadUsersExcelTemplate
export function downloadUsersExcelTemplate() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.download_users_excel_template, {
          responseType: "blob",
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "users_template.xlsx");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportPartenrs(data, export_type, sponsers = false) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_partners_list, {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (sponsers) {
              link.setAttribute("download", "Sponsers" + "." + export_type); //or any other extension
            } else {
              link.setAttribute("download", "partners" + "." + export_type); //or any other extension
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportProgramPayment(data, export_type, sponsers = false, program = false) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_program_payment_list, {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (sponsers) {
              link.setAttribute("download", "Sponsers" + "." + export_type); //or any other extension
            } else if (program) {
              link.setAttribute("download", "program_payment" + "." + export_type); //or any other extension
            } else {
              link.setAttribute("download", "partners" + "." + export_type); //or any other extension
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportPercentageTrainersList(data, export_type, sponsers = false) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_per_trainers_list, {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (sponsers) {
              link.setAttribute("download", "Sponsers" + "." + export_type); //or any other extension
            } else {
              link.setAttribute("download", "Percentage for Trainers" + "." + export_type); //or any other extension
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportUserWalletDetails(data, export_type, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.user_wallet_id, { id: id }), {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "wallet" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportUserWallet(data, export_type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.users_wallet, {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "wallet" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getUserRoles(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_user_roles, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getUser(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_user, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function userRoles(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.assign_user_role, {
          user_id: id,
          role_id: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function sendBulkEmails(formData) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.send_bulk_emails, formData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getUsers() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.user_list)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTrainersRate(trainerId) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_trainers_rate, {
          params: { rateable_id: trainerId },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addRate(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_rate, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { peymentRequestsConstants } from "../../Constants/peymentRequest/peymentRequest.constants";

// GET PEYMENTREQUESTS LIST
export const fetchPeymentRequestsList = ({
  page = 1,
  perPage = 10,
  filter,
}) => {
  return async (dispatch) => {
    dispatch({
      type: peymentRequestsConstants.GET_PEYMENTREQUESTS_LIST_REQUEST,
    });

    await connector
      .get(env.get_payment_request, {
        params: {
          page,
          perPage,
          ...filter,
        }, /////////////////////
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: peymentRequestsConstants.GET_PEYMENTREQUESTS_LIST_SUCCESS,
            payload: {
              peymentRequests: response?.data?.payouts, //////
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: peymentRequestsConstants.GET_PEYMENTREQUESTS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: peymentRequestsConstants.GET_PEYMENTREQUESTS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

// DELETE PEYMENTREQUEST
export const deletePeymentRequest = (id) => {
  return async (dispatch) => {
    dispatch({ type: peymentRequestsConstants.DELETE_PEYMENTREQUEST_REQUEST });
    await connector
      .delete(URLBuilder(env.delete_tax, { id: id })) ///////////////////////////
      .then((response) => {
        if (
          response.status &&
          response.status === 200 &&
          response.data.status
        ) {
          dispatch({
            type: peymentRequestsConstants.DELETE_PEYMENTREQUEST_SUCCESS,
            payload: { id },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: peymentRequestsConstants.DELETE_PEYMENTREQUEST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: peymentRequestsConstants.DELETE_PEYMENTREQUEST_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};

// UPDATE PEYMENTREQUEST STATUS
export const updatePeymentRequestStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: peymentRequestsConstants.UPDATE_PEYMENTREQUEST_STATUS_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.tax_status_update, {
          ///////////////////
          id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: peymentRequestsConstants.UPDATE_PEYMENTREQUEST_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: peymentRequestsConstants.UPDATE_PEYMENTREQUEST_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: peymentRequestsConstants.UPDATE_PEYMENTREQUEST_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};

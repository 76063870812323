import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { addCourseCategory } from "../../../../Services/api/courses/courseProvider";
import ManageSpecialties from "./ManageSpecialties";

const CreateSpecialties = () => {
  const { t, i18n } = useTranslation();
	const history = useHistory();

	const submitHandler = async (values) => {
		let response = await addCourseCategory(values);
		if (response.status === 200 && response.data.status) {
			history.push("/admin/category");
			toast.success(
				<span style={{ fontSize: 13, fontWeight: "bold" }}>
					{response.data.msg ? response.data.msg : t("specialties_manage.specialty_created_successfully")}
				</span>,
				{
					onClose: () => {},
					autoClose: 1000,
				}
			);
		} else {
			toast.error(
				<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("specialties_manage.failed_creating_specialty")}</span>
			);
		}
	};

	return <ManageSpecialties type={"create"} submitHandler={submitHandler} />;
};

export default CreateSpecialties;

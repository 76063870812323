import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { saveWatchingProgress } from "../../../../Redux/Actions/courses/coursesList.action";
import { addActivityRate } from "../../../../Services/api/ActivityRating";
import { answerLessonActivity } from "../../../../Services/api/courses/courseProvider";
import PassIcon from "../../../../assets/icons/CheckLightgreen.svg";
import Corner from "../../../../assets/icons/Untitled.png";
import ArrowIcon from "../../../../assets/icons/arrow.png";
import AudioIcon from "../../../../assets/icons/audio-speaker.png";
import FailedIcon from "../../../../assets/icons/delete.svg";
import FileIcon from "../../../../assets/icons/file.png";
import PDFIcon from "../../../../assets/icons/pdf-file.png";
import exit from "../../../../assets/icons/remove-red.svg";
import VideoIcon from "../../../../assets/icons/video-player.png";
import { getLocaleTime, utcToLocal } from "../../../../utils/utcToLocal";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";
import AudioView from "./ContentView/AudioView";
import FileView from "./ContentView/FileView";
import PdfView from "./ContentView/PdfView";
import VideoView from "./ContentView/VideoView";
import H5pQuestionView from "./QuestionsTypes/H5pQuestionView";
import HTMLQuestionView from "./QuestionsTypes/HTMLQuestionView";
import HotSpotQuestionView from "./QuestionsTypes/HotSpotQuestionView";
import ScormQuestionView from "./QuestionsTypes/ScormQuestionView";
import XApiQuestionView from "./QuestionsTypes/XApiQuestionView";
import RatingForm from "./RatinfForm/RatingForm";
import classes from "./trainingCourseContentLessons.module.css";

export default function CourseContentLessonsSlider({
  slide,
  refresh,
  courseId,
  lessonId,
  subChapterId,
  chapterId,
  isClosed,
}) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const slideModelRef = useRef();
  const [showSlideModel, setShowSlideModel] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [ratingForm, setRatingForm] = useState(false);
  const [isRatingLoading, setIsRatingLoading] = useState(false);
  const [activityId, setActivityId] = useState(null);

  const openSlideModel = (slide) => {
    setSelectedSlide(slide.id);
    setShowSlideModel(true);
    slideModelRef.current.showModal();
  };

  const openActivity = (item) => {
    history.push(
      `/training-courses/${courseId}/content/${chapterId}/${subChapterId}/lesson/${lessonId}/activity/${item.id}`
    );
  };

  const closeSlideModel = () => {
    setSelectedSlide(null);
    setShowSlideModel(false);
    slideModelRef.current.dismissModal();
  };

  const displayMeetingType = (type) => {
    switch (type) {
      case 1:
        return t("trainer.course.lesson.zoom");
      case 2:
        return t("trainer.course.lesson.webex");
      case 3:
        return t("trainer.course.lesson.microsoft_teams");
      default:
        return null;
    }
  };

  const handleSubmitRatting = async (rating, trainerId) => {
    try {
      setIsRatingLoading(true);

      const dataToBeUploaded = {
        rateable_id: activityId,
        description: rating.description,
        stars: rating.stars,
      };

      let response = await addActivityRate(dataToBeUploaded);

      if (response.status === 201 && response.data.status) {
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("rate.label.success")}</span>);
        setIsRatingLoading(false);
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
        setIsRatingLoading(false);
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {err.response.data.msg ? err.response.data.msg : "Failure in service"}
        </span>
      );
      setIsRatingLoading(false);
    }
    setRatingForm(false);
    closeSlideModel();
    refresh();
  };

  useEffect(() => {
    const slideElement = document.querySelector(`.${classes["course-slider-model-body-slide"]}`);
    if (slideElement) {
      slideElement.scrollTo(0, 0);
    }

    if (selectedSlide) {
      saveWatchingProgressAPI({
        watchable_id: selectedSlide,
        watchable_type: watchableType(slide),
      });
    }
  }, [selectedSlide]);

  const saveWatchingProgressAPI = async ({ watchable_id, watchable_type }) => {
    try {
      if (slide?.data?.find((item) => Number(item.id) === Number(watchable_id))?.is_watched || isClosed) {
        return;
      }
      const payload = {
        course_id: courseId,
        lesson_id: lessonId,
        // sub_chapter_id: subChapterId,
        // chapter_id: chapterId,
        watchable_id,
        watchable_type,
      };
      dispatch(saveWatchingProgress(payload));
    } catch (err) {
      
      
    }
  };

  const watchableType = (slide) => {
    if (slide.id === "content") {
      return "content";
    }
    if (slide.id === "activities") {
      return "activity";
    }
    if (slide.id === "zoom") {
      return "session";
    }
    if (slide.id === "discussions") {
      return "discussion";
    }
  };

  return (
    <>
      <BasicModal ref={slideModelRef}>
        {showSlideModel && (
          <MainBox className={`p-0 rounded ${classes["course-slider-model-container"]}`}>
            <div className={classes["course-slider-model"]}>
              <div className={classes["course-slider-model-header"]}>
                <h2 className={classes["course-slider-model-title"]}>{slide.title}</h2>
                <img src={exit} alt="exit" className={classes["course-slider-model-exit"]} onClick={closeSlideModel} />
              </div>
              <div className={classes["course-slider-model-body"]}>
                <div className={classes["course-slider-model-body-slides"]}>
                  {slide?.data?.map((item, index) => (
                    <div
                      key={item.id}
                      className={`${classes["course-slider-model-body-slides-item"]} ${
                        selectedSlide === item.id && classes["course-slider-model-body-slides-item-selected"]
                      }`}
                      onClick={() => setSelectedSlide(item.id)}
                    >
                      {slide.id === "content" ? (
                        <>
                          <div className={classes["course-slider-item-header-inner"]}>
                            <p className={classes["card_title-inner"]}>{item.title}</p>
                            {item.include_view_per ? (
                              item.is_watched ? (
                                <div className={classes["watched"]} title={t("watched")} />
                              ) : (
                                <div className={classes["not-watched"]} title={t("not_watched")} />
                              )
                            ) : null}
                          </div>
                          {item.type === "video" ? (
                            <>
                              <div className={classes["content-icon-container-inner"]}>
                                <img src={VideoIcon} alt="" />
                              </div>
                            </>
                          ) : item.type === "audio" ? (
                            <>
                              <div className={classes["content-icon-container-inner"]}>
                                <img src={AudioIcon} alt="" />
                              </div>
                            </>
                          ) : item.type === "pdf" ? (
                            <>
                              <div className={classes["content-icon-container-inner"]}>
                                <img src={PDFIcon} alt="" />
                              </div>
                            </>
                          ) : item.type === "file" ? (
                            <>
                              <div className={classes["content-icon-container-inner"]}>
                                <img src={FileIcon} alt="" />
                              </div>
                            </>
                          ) : (
                            <div
                              className={classes["course-slider-item-content-inner"]}
                              dangerouslySetInnerHTML={{
                                __html: item.content,
                              }}
                            ></div>
                          )}
                        </>
                      ) : slide.id === "activities" ? (
                        <>
                          <div
                            className={`${classes["course-slider-item-header-inner"]} `}
                            style={
                              item.answered
                                ? {
                                    backgroundColor: "rgb(129,185,194)",
                                    color: "#fff",
                                  }
                                : {}
                            }
                          >
                            <p className={`${classes["card_title-inner"]} ${item.answered ? "!tw-text-white" : ""}`}>
                              {item.title}
                            </p>
                            {item.answered ? (
                              <div className={classes["answered"]} title={t("answered")} />
                            ) : item.include_view_per ? (
                              item.is_watched ? (
                                <div className={classes["watched"]} title={t("watched")} />
                              ) : (
                                <div className={classes["not-watched"]} title={t("not_watched")} />
                              )
                            ) : null}
                          </div>
                          <div
                            className={classes["course-slider-item-content-inner"]}
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          ></div>
                        </>
                      ) : slide.id === "zoom" ? (
                        <>
                          <div className={classes["course-slider-item-header-inner"]}>
                            <p className={classes["card_title-inner"]}>{item.title}</p>
                            {item.include_view_per ? (
                              item.is_watched ? (
                                <div className={classes["watched"]} title={t("watched")} />
                              ) : (
                                <div className={classes["not-watched"]} title={t("not_watched")} />
                              )
                            ) : null}
                          </div>
                          <div className={classes["course-slider-item-content"]}>
                            <p>{moment(getLocaleTime(new Date(item.start_time))).format("DD-MM-YYYY hh:mm A")}</p>
                            <p>{displayMeetingType(Number(item.meeting_type))}</p>
                          </div>{" "}
                        </>
                      ) : (
                        <>
                          <div className={classes["course-slider-item-header-inner"]}>
                            <p className={classes["card_title-inner"]}>{item.name}</p>
                            {item.include_view_per ? (
                              item.is_watched ? (
                                <div className={classes["watched"]} title={t("watched")} />
                              ) : (
                                <div className={classes["not-watched"]} title={t("not_watched")} />
                              )
                            ) : null}
                          </div>
                          <div
                            className={classes["course-slider-item-content-inner"]}
                            dangerouslySetInnerHTML={{ __html: item.desc }}
                          ></div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                <div className={classes["course-slider-model-body-slide-container"]}>
                  <div
                    className={classes["course-slider-model-body-slide-arrow-left"]}
                    onClick={() => {
                      const data = slide.data.filter((item) => !(item.answered && slide.id === "activities"));
                      let index = data.findIndex((item) => item.id === selectedSlide);
                      if (index !== 0) {
                        index--;
                      }
                      setSelectedSlide(data[index].id);
                    }}
                  >
                    <img src={ArrowIcon} alt="arrow" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {slide?.data?.map((item) => (
                      <div
                        key={item.id}
                        className={`${classes["course-slider-model-body-slide-item"]} ${
                          selectedSlide === item.id && classes["course-slider-model-body-slide-item-selected"]
                        }`}
                      >
                        {slide.id === "content" ? (
                          <h4>{item.title}</h4>
                        ) : slide.id === "zoom" ? (
                          <h4>{item.title}</h4>
                        ) : slide.id === "activities" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <h4>{item.title}</h4>
                              {item.answered && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border:
                                      item.my_result >= Number(item.success_mark) / 2
                                        ? "1px solid #22C55E"
                                        : "1px solid red",
                                    borderRadius: "6px",
                                    textAlign: "center",
                                    width: 150,
                                    color: item.my_result >= Number(item.success_mark) / 2 ? "#22C55E" : "red",
                                    height: 30,
                                    gap: 10,
                                  }}
                                >
                                  {t("mark")}{" "}
                                  <span>
                                    {" "}
                                    {parseFloat(item.my_result).toFixed(2)} {"/"} {item.success_mark}
                                  </span>{" "}
                                  {/* {item.my_result >=
                                  Number(item.success_mark) ? (
                                    <img
                                      alt=" "
                                      src={PassIcon}
                                      style={{
                                        width: 20,
                                        height: 20,
                                      }}
                                    />
                                  ) : (
                                    <img
                                      alt=" "
                                      src={FailedIcon}
                                      style={{
                                        width: 20,
                                        height: 20,
                                      }}
                                    />
                                  )} */}
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h4>{item.name}</h4>
                            <div
                              style={{
                                display: "flex",
                                gap: 20,
                                flexDirection: "row-reverse",
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: "#046C77",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  padding: 10,

                                  color: "#fff",
                                }}
                                onClick={() => {
                                  saveWatchingProgressAPI({
                                    watchable_id: item.id,
                                    watchable_type: "discussion",
                                  });
                                  history.push(`/course/${courseId}/activity/${item.id}`);
                                  closeSlideModel();
                                }}
                              >
                                {t("add_comment_add")}
                              </button>
                              <div
                                style={{
                                  border: "1px solid #22C55E",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                  padding: 10,
                                  color: "#22C55E",
                                }}
                              >
                                {t("comment_num")}
                                {` (${item.comments_number}) `}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <div className={classes["course-slider-model-body-slide"]}>
                      {slide?.data?.map((item) => (
                        <>
                          <div
                            key={item.id}
                            className={`${classes["course-slider-model-body-slide-item"]} ${
                              selectedSlide === item.id && classes["course-slider-model-body-slide-item-selected"]
                            }`}
                          >
                            {slide.id === "content" ? (
                              <>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.content,
                                  }}
                                ></p>
                                {item.type === "video" ? (
                                  <>
                                    <div>
                                      <VideoView question={item} />
                                    </div>
                                  </>
                                ) : item.type === "audio" ? (
                                  <>
                                    <div>
                                      <AudioView question={item} />
                                    </div>
                                  </>
                                ) : item.type === "pdf" ? (
                                  <>
                                    <div>
                                      <PdfView question={item} />
                                    </div>
                                  </>
                                ) : item.type === "file" ? (
                                  <>
                                    <div>
                                      <FileView question={item} />
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className={classes["course-slider-item-content-inner-body"]}
                                    dangerouslySetInnerHTML={{
                                      __html: item.content,
                                    }}
                                  ></div>
                                )}
                              </>
                            ) : slide.id === "activities" ? (
                              <>
                                <ActivityView
                                  item={item}
                                  onAnswered={() => {
                                    closeSlideModel();
                                    refresh();
                                  }}
                                  isAnswered={item.answered}
                                />
                              </>
                            ) : slide.id === "zoom" ? (
                              <>
                                <div className={classes["course-slider-zoom-item"]}>
                                  <div className={classes["course-slider-zoom-item-inner"]}>
                                    <div className={classes["course-slider-zoom-item-inner-title"]}>
                                      {t("zoom.meeting_agenda")}
                                    </div>
                                    <p className={classes["course-slider-zoom-item-inner-value"]}>
                                      {item.meeting_agenda}
                                    </p>
                                  </div>
                                  <div className={classes["course-slider-zoom-item-inner"]}>
                                    <div className={classes["course-slider-zoom-item-inner-title"]}>
                                      {t("zoom.Course_name")}
                                    </div>
                                    <p className={classes["course-slider-zoom-item-inner-value"]}>
                                      {displayMeetingType(Number(item.meeting_type))}
                                    </p>
                                  </div>
                                  <div className={classes["course-slider-zoom-item-inner"]}>
                                    <div className={classes["course-slider-zoom-item-inner-title"]}>
                                      {t("zoom.starting_date")}
                                    </div>
                                    <div className={classes["course-slider-zoom-item-inner-value"]}>
                                      {moment(getLocaleTime(new Date(item.start_time))).format("DD-MM-YYYY hh:mm A")}
                                    </div>
                                  </div>
                                  <div className={classes["course-slider-zoom-item-inner"]}>
                                    <div className={classes["course-slider-zoom-item-inner-title"]}>
                                      {t("zoom.session_duration")}
                                    </div>
                                    <div className={classes["course-slider-zoom-item-inner-value"]}>
                                      {item.duration} {t("zoom.minute")}
                                    </div>
                                  </div>
                                  {/* <div className={classes["course-slider-zoom-item-inner"]}>
                                    <div className={classes["course-slider-zoom-item-inner-title"]}>
                                      {t("zoom.Number_registered")}
                                    </div>
                                    <div className={classes["course-slider-zoom-item-inner-value"]}>
                                      {item.max_registrants}
                                    </div>
                                  </div> */}
                                  {item.is_active ? (
                                    <CustomButton
                                      value={t("zoom.join")}
                                      action={() => {
                                        window.open(item.start_url, "_blank");
                                      }}
                                      type="button"
                                      colors="#036c77"
                                      variant="outlined"
                                      disable={false}
                                      classes={classes["course-slider-zoom-item-inner-btn"]}
                                    />
                                  ) : null}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={classes["course-slider-item-content-inner-body"]}
                                  dangerouslySetInnerHTML={{
                                    __html: item.desc,
                                  }}
                                ></div>
                              </>
                            )}
                          </div>
                        </>
                      ))}
                    </div>
                    {slide?.data?.map((item) => (
                      <div
                        key={item.id}
                        className={`${classes["course-slider-model-body-slide-item"]} ${
                          selectedSlide === item.id && classes["course-slider-model-body-slide-item-selected"]
                        }`}
                      >
                        {slide.id === "activities" ? (
                          <>
                            <p>التقييم</p>
                            <div className={classes["training-course-content-rating"]}>
                              <StarsRating rating={item.ratings_avg_stars || 0} cutStyle={classes["stars-font-size"]} />
                              <CustomButton
                                value={t("rate_now")}
                                action={() => {
                                  setRatingForm(!ratingForm);
                                  setActivityId(item.id);
                                }}
                                colors="#036c77"
                                disable={false}
                                classes={classes["rate-now-btn"]}
                              />
                            </div>
                            {ratingForm && (
                              <RatingForm
                                handleCancel={() => setRatingForm(false)}
                                handleSubmit={handleSubmitRatting}
                              />
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                  </div>
                  <div
                    className={classes["course-slider-model-body-slide-arrow-right"]}
                    onClick={() => {
                      const data = slide.data.filter((item) => !(item.answered && slide.id === "activities"));
                      let index = data.findIndex((item) => item.id === selectedSlide);
                      if (index !== data.length - 1) {
                        index++;
                      }
                      setSelectedSlide(data[index].id);
                    }}
                  >
                    <img src={ArrowIcon} alt="arrow" />
                  </div>
                </div>
              </div>
            </div>
          </MainBox>
        )}
      </BasicModal>
      <div className={classes["course-slider"]}>
        {slide?.data?.map((item) => (
          <div
            key={item.id}
            className={`${classes["course-slider-item"]} `}
            onClick={() => (slide.id === "activities" ? openActivity(item) : openSlideModel(item))}
          >
            {slide.id === "content" ? (
              <div style={{ overflow: "hidden" }}>
                <div className={classes["course-slider-item-header"]}>
                  <p className={classes["card_title"]}>{item.title}</p>
                  {item.include_view_per ? (
                    item.is_watched ? (
                      <div className={classes["watched"]} title={t("watched")} />
                    ) : (
                      <div className={classes["not-watched"]} title={t("not_watched")} />
                    )
                  ) : null}
                </div>
                {item.type === "video" ? (
                  <>
                    <div className={classes["content-icon-container"]}>
                      <img src={VideoIcon} alt="" />
                    </div>
                  </>
                ) : item.type === "audio" ? (
                  <>
                    <div className={classes["content-icon-container"]}>
                      <img src={AudioIcon} alt="" />
                    </div>
                  </>
                ) : item.type === "pdf" ? (
                  <>
                    <div className={classes["content-icon-container"]}>
                      <img src={PDFIcon} alt="" />
                    </div>
                  </>
                ) : item.type === "file" ? (
                  <>
                    <div className={classes["content-icon-container"]}>
                      <img src={FileIcon} alt="" />
                    </div>
                  </>
                ) : (
                  <div
                    className={classes["course-slider-item-content"]}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                )}
              </div>
            ) : slide.id === "activities" ? (
              <div style={{ overflow: "hidden" }}>
                <div
                  className={`${classes["course-slider-item-header"]} `}
                  style={
                    item.answered
                      ? {
                          backgroundColor: "rgb(129,185,194)",
                          color: "#fff",
                        }
                      : {}
                  }
                  title={item.answered ? t("answered") : ""}
                >
                  <p className={`${classes["card_title"]} ${item.answered ? "tw-text-white" : ""}`}>{item.title}</p>
                  {item.answered ? (
                    <div className={classes["answered"]} title={t("answered")} />
                  ) : item.include_view_per ? (
                    item.is_watched ? (
                      <div className={classes["watched"]} title={t("watched")} />
                    ) : (
                      <div className={classes["not-watched"]} title={t("not_watched")} />
                    )
                  ) : null}
                </div>
                <div
                  className={classes["course-slider-item-content"]}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>{" "}
              </div>
            ) : slide.id === "zoom" ? (
              <div style={{ overflow: "hidden" }}>
                <div className={classes["course-slider-item-header"]}>
                  <p className={classes["card_title"]}>{item.title}</p>
                  {item.include_view_per ? (
                    item.is_watched ? (
                      <div className={classes["watched"]} title={t("watched")} />
                    ) : (
                      <div className={classes["not-watched"]} title={t("not_watched")} />
                    )
                  ) : null}
                </div>
                <div className={classes["course-slider-item-content"]}>
                  <p>{moment(getLocaleTime(new Date(item.start_time))).format("DD-MM-YYYY hh:mm A")}</p>
                  <p>{displayMeetingType(Number(item.meeting_type))}</p>
                  <p>{item.meeting_agenda}</p>
                </div>
              </div>
            ) : slide.id === "discussions" ? (
              <div style={{ overflow: "hidden" }}>
                <div className={classes["course-slider-item-header"]}>
                  <p className={classes["card_title"]}>{item.name}</p>
                  {item.include_view_per ? (
                    item.is_watched ? (
                      <div className={classes["watched"]} title={t("watched")} />
                    ) : (
                      <div className={classes["not-watched"]} title={t("not_watched")} />
                    )
                  ) : null}
                </div>
                <div
                  className={classes["course-slider-item-content"]}
                  dangerouslySetInnerHTML={{ __html: item.desc }}
                ></div>{" "}
              </div>
            ) : null}
            <div className={classes["corner-img"]}>
              <img src={Corner} alt="" style={i18n.language === "ar" ? { transform: "scaleX(-1)" } : {}} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function ActivityView({ item, onAnswered, isAnswered }) {
  const { t } = useTranslation();
  const QuestionTypes = [
    {
      type: "hotspot",
      viewComponent: HotSpotQuestionView,
    },
    {
      type: "h5p",
      viewComponent: H5pQuestionView,
    },
    {
      type: "html",
      viewComponent: HTMLQuestionView,
    },
    {
      type: "scorm",
      viewComponent: ScormQuestionView,
    },
    {
      type: "xapi",
      viewComponent: XApiQuestionView,
    },
  ];
  const ViewComponent = QuestionTypes.find((questionType) => item.type === questionType.type).viewComponent;
  return (
    <Formik
      initialValues={{
        answer: [],
      }}
      onSubmit={async (values, { setErrors }) => {
        let data = {
          activity_id: item.id,
          answer: values.answer,
        };
        try {
          const res = await answerLessonActivity(item.id, data);

          if (res.status && res.status === 201 && res.data.status) {
            onAnswered();
          } else {
            throw res;
          }
        } catch (err) {
          toast.error(
            <span
              style={{
                fontSize: 13,
                fontWeight: "bold",
              }}
            >
              {err.response.data?.msg ?? "Your answer to registration failed"}
            </span>
          );
        }
      }}
      validate={(values) => {
        // setHasSubmitted(true);
        const errors = {};

        return errors;
      }}
      // validateOnChange={hasSubmitted}
    >
      {({
        setFieldValue,
        setValues,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handlevalidate,
        isSubmitting,
        resetForm,
        submitForm,
        validateForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="tw-space-y-3 tw-mb-9">
            <div className="tw-flex  tw-space-s-6 tw-py-3 tw-w-full" style={{ flexDirection: "row-reverse" }}>
              <button
                disabled={isSubmitting || isAnswered}
                className="tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-py-2 tw-px-16 tw-rounded tw-text-white"
              >
                {isAnswered ? t("already_answerd") : t("submit_answer")}
              </button>
            </div>
            <ViewComponent
              key={item.id}
              question={item}
              values={values}
              setFieldValue={setFieldValue}
              isActivity={true}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: item.content,
              }}
            ></p>
          </div>
        </form>
      )}
    </Formik>
  );
}

import React, { useEffect } from "react";
import classes from "./DialogModal.module.css";
const DialogModal = ({ onCancel, className, children }) => {
  const modalRef = React.useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className={classes["modal"]}>
      <div className={className} ref={modalRef}>
        {children}
      </div>
    </div>
  );
};

export default DialogModal;

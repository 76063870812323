import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import DocumentIcon from "../../assets/icons/document.svg";
import CloseIcon from "../../assets/icons/remove-red.svg";
import { downloadFile } from "../../utils/downloadFile";
import Input from "../Shared/Components/Input/Input";
import classes from "./payments.module.css";

const BankModal = ({ closeBankModalHandler, paymentData }) => {
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeBankModalHandler();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const inputFields = [
    {
      label: t("financial.refund.account_holder_name"),
      type: "text",
      name: "name",
      value: paymentData?.name,
      readonly: true,
    },
    {
      label: t("financial.refund.phone_number"),
      type: "text",
      name: "mobile",
      value: paymentData?.mobile,
      readonly: true,
    },
    {
      label: t("financial.refund.bank_name"),
      type: "text",
      name: "bank",
      value: paymentData?.bank,
      readonly: true,
    },
    {
      label: t("financial.refund.IBAN_number"),
      type: "text",
      name: "iban",
      value: paymentData?.iban,
      readonly: true,
    },
    {
      label: t("payments.transfer_date"),
      type: "text",
      name: "transfer_date",
      value: paymentData?.transfer_date,
      readonly: true,
    },
  ];

  return (
    <div className={classes["bank-modal"]}>
      <div className={classes["bank-modal__container"]} ref={modalRef}>
        <div
          className={classes["bank-modal__close"]}
          onClick={() => {
            closeBankModalHandler();
          }}
        >
          <img src={CloseIcon} alt="" />
        </div>
        <>
          <div className={classes["bank-modal__header"]}>
            <div className={classes["bank-modal-title"]}>{t("Exhibition.bank_transfers")}</div>
            <div className={classes["bank-modal-value"]}>
              <div className={classes["bank-modal-value-label"]}>{t("Exhibition.amount_required")}</div>

              <div className={classes["bank-modal-value-box"]}>
                {paymentData?.paid_amount}
                <sub>{t("Exhibition.sar_currency")}</sub>
              </div>
            </div>
          </div>
          <div className={classes["bank-modal__content"]}>
            {inputFields.map((input) => (
              <div className={classes["bank-modal__field"]} key={input.name}>
                <Input label={input.label} type={input.type} name={input.name} value={input.value} readonly />
              </div>
            ))}
            {paymentData?.payment_proof.includes(".pdf") ? (
              <div
                className={`${classes["input-file__container__previews__item"]} ${
                  classes[`input-file__container__previews__item-document`]
                }`}
              >
                <div className={classes["input-file__container__previews__item-img"]}>
                  <img src={DocumentIcon} alt="" />
                </div>
                <button
                  className={classes["file-preview-download"]}
                  type="button"
                  onClick={() => downloadFile(paymentData?.payment_proof)}
                >
                  <i className="fa fa-download"></i>
                </button>
              </div>
            ) : (
              <div className={classes["bank-modal__image"]}>
                <img src={paymentData?.payment_proof} alt="" />
                <button
                  className={classes["bank-modal__image-download"]}
                  onClick={() => downloadFile(paymentData?.payment_proof)}
                >
                  <i className="fa fa-download"></i>
                </button>
              </div>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default BankModal;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { exportProductsBooth } from "../../../../Services/api/Exhibition/ExhibitionProvider";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import TableHeadActions from "../../../Shared/Components/TableHeadActions/TableHeadActions";
import BootDetailsBodyTable from "./BootDetailsBodyTable";
import { filterActions } from "./BootLIstTableActions";
import classes from "./bootDetails.module.css";

const BootDetailsTable = ({ id, type, tableData, setIsExportLoading }) => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [isResetApplied, setIsResetApplied] = useState(false);

  // const { boothsLoading, boothsError, booths, boothsMeta } = useSelector(
  //   (state) => state.exhibitionReducer
  // );

  const history = useHistory();

  const [filterData, setFilterData] = useState({
    nameProduct: "",
    addressProduct: "",
    LinkProducts: "",
    status: "",
    startDate: "",
    endDate: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      nameProduct: "",
      addressProduct: "",
      LinkProducts: "",
      status: "",
      startDate: "",
      endDate: "",
    });
    setIsResetApplied(true);
  };
  const getBootList = async () => {};

  const filterFields = [
    {
      id: "nameProduct",
      component: "input",
      label: "اسم المنتج",
      type: "text",
      placeholder: "اسم المنتج",
      name: "nameProduct",
      value: filterData.nameProduct,
      classes: "col-12 col-md-6",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "addressProduct",
      label: "عنوان الرابط",
      component: "input",
      placeholder: "عنوان الرابط",
      initialValue: "عنوان الرابط",
      name: "addressProduct",
      value: filterData.addressProduct,
      classes: "col-12 col-md-2",
      onchange: changeHandler,
    },
    {
      id: "LinkProducts",
      label: "رابط المنتج",
      component: "input",
      placeholder: "رابط المنتج",
      initialValue: "رابط المنتج",
      name: "LinkProducts",
      value: filterData.LinkProducts,
      classes: "col-12 col-md-2",
      onchange: changeHandler,
    },

    {
      id: "status",
      label: "الحالة",
      component: "select",
      placeholder: "الحالة",
      initialValue: "الحالة",
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-2",
      onchange: changeHandler,
      options: [
        {
          id: "active",
          value: "1",
          label: t("Exhibition.active"),
        },
        {
          id: "inactive",
          value: "0",
          label: t("Exhibition.inactive"),
        },
      ],
    },
    {
      id: "startDate",
      label: t("Exhibition.search_start_date"),
      component: "datePicker",
      placeholder: t("Exhibition.search_start_date"),
      name: "startDate",
      value: filterData.startDate,
      classes: "col-12 col-md-2",
      onchange: changeHandler,
    },
    {
      id: "endDate",
      label: t("Exhibition.search_end_date"),
      component: "datePicker",
      placeholder: t("Exhibition.search_end_date"),
      name: "endDate",
      value: filterData.endDate,
      classes: "col-12 col-md-2 ",
      onchange: changeHandler,
    },

    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-2   offset-xs-0 offset-xl-4 border-button ",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action_search",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2 ",
      action: () => {
        getBootList();
      },
    },
  ];

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    await exportProductsBooth(id, type)
      .then((res) => {})
      .catch((err) => {
        
      })
      .finally(() => {
        setIsExportLoading(false);
      });
  };

  return (
    <div className={classes["boot-details__tables"]}>
      <div className={classes["boot-details__tables__header"]}>
        <h3>{t("booth.products_list")}</h3>
        {type !== "review" ? (
          <>
            <TableHeadActions
              actions={filterActions({
                showFilter: () => {
                  setShowFilter((prev) => !prev);
                },
                exportPdf: () => {
                  exportHandler("pdf");
                },
                exportExcel: () => {
                  exportHandler("xlsx");
                },
              })}
            />
          </>
        ) : (
          <></>
        )}
      </div>

      {type !== "review" ? showFilter && <FilterBox fields={filterFields} /> : null}

      <BootDetailsBodyTable type={type || ""} tableData={tableData} />
    </div>
  );
};

export default BootDetailsTable;

import { Field, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import IconTooltips from "../../../../Components/tooltips/IconsTooltip";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { getProgramDetails } from "../../../../Services/api/courses/courseProvider";
import {
  blockCountries,
  getBlockedInfo,
  getUnblockList,
  lessonBlock,
  unblockRequest,
  updateBlocked,
} from "../../../../Services/api/ipBlocked/ipBlockedProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import DeleteIcon from "../../../../assets/image/alert-octagon.png";
import userX from "../../../../assets/image/user-x.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

function BlockDetails({ type = "review" }) {
  require("./BlockDetails.css");
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [blockInfo, setBlockInfo] = useState([]);
  const [radioValue, setRadioValue] = useState(blockInfo.action);
  const [coursesBlock, setCoursesBlock] = useState([]);
  const [programsBlock, setProgramsBlock] = useState([]);
  const [programCoursesBlock, setProgramCoursesBlock] = useState([]);
  const [lessonsBlock, setLessonsBlock] = useState([]);
  const [countries, setCountries] = useState([]);
  const [unblockList, setUnblockList] = useState([]);
  const [ignoreModal, setIgnoreModal] = useState(false);
  const [rowID, setRowID] = useState();
  const [rowIp, setRowIp] = useState();
  const [unblockRequestByIpLoading, setUnblockRequestByIpLoading] = useState(false);
  const [method, setMethod] = useState("site");
  const [course, setCourse] = useState("0");
  const [program, setProgram] = useState("0");
  const [programCourse, setProgramCourse] = useState();
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);

  const radioValueHandler = (e) => {
    setRadioValue(e.target.value);
  };

  function ValidateIPaddress(IPAddress) {
    let ipformat =
      IPAddress &&
      IPAddress.match(
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
      );

    return ipformat !== null;
  }

  const methodHandler = (e) => {
    e.preventDefault();
    setMethod(e.target.value);

    if (e.target.value == "site") {
      setCourse("0");
      setProgram("0");
      setLessonsBlock([]);
    } else if (e.target.value == "course") {
      setCourse(coursesBlock[0]?.id);
      setLessonsBlock([]);
    } else if (e.target.value == "program") {
      setProgram(programsBlock[0]?.id);
      setProgramCourse("0");
      setLessonsBlock([]);
    }
  };

  const courseHandle = (e) => {
    method === "program" ? setProgramCourse(e.target.value) : setCourse(e.target.value);
    lessonBlock(e.target.value)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setLessonsBlock(res.data.lessons);
        }
      })
      .catch((err) => {});
  };

  const programHandle = (e) => {
    setProgram(e.target.value);
    setProgramCourse("0");
    getProgramCoursesHandler(e.target.value);
  };

  const getProgramCoursesHandler = async (programId) => {
    try {
      const res = await getProgramDetails(programId);
      if (res.status && res.status == 200 && res.data.status) {
        setProgramCoursesBlock(res.data.program.courses);
        setLessonsBlock([]);
      }
    } catch (err) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
    }
  };

  const getAllCoursesProgramsHandler = async () => {
    try {
      const [coursesRes, programsRes] = await Promise.all([
        getAllCourses({ paginated: 0, perPage: 500, not_in_program: 1, is_program: 0 }),
        getAllCourses({ paginated: 0, perPage: 500, is_program: 1 }),
      ]);

      if (coursesRes.status && coursesRes.status == 200 && coursesRes.data.status) {
        setCoursesBlock(coursesRes?.data?.data?.courses?.data || []);
        setIsCoursesLoading(false);
      }

      if (programsRes.status && programsRes.status == 200 && programsRes.data.status) {
        setProgramsBlock(programsRes?.data?.data?.courses?.data || []);
        setIsProgramsLoading(false);
      }
    } catch (err) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
    } finally {
    }
  };

  useEffect(() => {
    getBlockedInfoHandler();
    getUnblockListHandler();
    getCountriesHandler();
    getAllCoursesProgramsHandler();
  }, []);

  const getBlockedInfoHandler = async () => {
    getBlockedInfo(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setBlockInfo(res.data.block);
          setRadioValue(res.data.block.action);

          if (res.data.block.method === "site") {
            setMethod("site");
          } else {
            if (res.data.block.is_program) {
              setMethod("program");
              getProgramDetails(res.data.block.course)
                .then((res) => {
                  if (res.status && res.status == 200 && res.data.status) {
                    if (res.data.program.program_id) {
                      setProgram(res.data.program.program_id);
                      setProgramCourse(res.data.program.id);
                      getProgramDetails(res.data.program.program_id).then((res) => {
                        if (res.status && res.status == 200 && res.data.status) {
                          setProgramCoursesBlock(res.data.program.courses);
                        }
                      });
                    } else {
                      setProgram(res.data.block.program.id);
                      setProgramCourse(res.data.block.courses);
                    }
                  }
                })
                .catch((err) => {});
            } else {
              setMethod("course");
              setCourse(res.data.block.course);
            }
            getLessonsBlockHandler(res.data.block.course);
          }
        }
      })
      .catch((err) => {});
  };

  const getLessonsBlockHandler = async (course) => {
    lessonBlock(course)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setLessonsBlock(res.data.lessons);
        }
      })
      .catch((err) => {});
  };

  const getUnblockListHandler = async () => {
    getUnblockList(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setUnblockList(res.data.data);
        }
      })
      .catch((err) => {});
  };

  const getCountriesHandler = async () => {
    blockCountries()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCountries(Object.keys(res.data.countries).map((key) => [key, res.data.countries[key]]));
        }
      })
      .catch((err) => {});
  };

  const ignoreHandler = (id, ip) => {
    setIgnoreModal(true);
    setRowID(id);
    setRowIp(ip);
  };

  const ignoreRow = async (id, ip) => {
    setUnblockRequestByIpLoading(true);
    await unblockRequest({
      request_id: id,
      ip,
      allow: 0,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("block.massages.reblock_success")}</span>
          );
          setUnblockList(unblockList.filter((item) => item.id !== id));
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
      })
      .finally(() => {
        setIgnoreModal(false);
        setUnblockRequestByIpLoading(false);
      });
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "TypeFile_label",
      page: t("block.label.block_list"),
      pagePath: "/admin/ip-blocked",
    },
    {
      id: "TypeFile",
      page: t("block.label.block_site"),
      active: true,
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      sortable: false,
      key: "id",
      cell: ({ rowData }) => <>{rowData.id}</>,
    },
    {
      id: "name",
      name: t("block.table.name"),
      sortable: false,
      key: "name",
      cell: ({ rowData }) => <>{rowData.name}</>,
    },
    {
      id: "email",
      name: t("block.table.email"),
      sortable: false,
      key: "email",
      cell: ({ rowData }) => <>{rowData.email}</>,
    },
    {
      id: "message",
      name: t("block.table.message"),
      sortable: false,
      key: "message",
      cell: ({ rowData }) => (
        <div className="block-message">
          <div dangerouslySetInnerHTML={{ __html: rowData.message }} />
        </div>
      ),
    },
    {
      id: "ip",
      name: t("block.table.range"),
      sortable: false,
      key: "ip",
      cell: ({ rowData }) => <>{rowData.ip}</>,
    },
    {
      id: "date",
      name: t("block.table.date"),
      sortable: false,
      key: "date",
      cell: ({ rowData }) => <>{moment(rowData.created_at).format("DD/MM/YYYY")}</>,
    },
    {
      id: "actions",
      name: t("block.table.edit"),
      key: "actions",
      cell: ({ rowData }) => (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
          onClick={() => ignoreHandler(rowData.id, rowData.ip)}
        >
          <IconTooltips
            title={t("block.label.reblock")}
            content={<img className="cursor-pointer" src={userX} alt="" />}
          />
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pt-5 pb-5">
              <Breadcrumb list={breadcrumbList} />
            </div>

            {(method === "course" && isCoursesLoading) || (method === "program" && isProgramsLoading) ? (
              <SkeletonCardOverlay skeletonWidth="100" />
            ) : null}
            <div className="main_box">
              <div className="main_inside_box">
                <Formik
                  initialValues={{
                    name: blockInfo.name !== "" ? blockInfo.name : "",
                    method: method,
                    course: blockInfo.course !== "" ? blockInfo.course : "",
                    lesson: blockInfo.lesson !== 0 ? blockInfo.lesson : null,
                    range: blockInfo.range !== "" ? blockInfo.range : "",
                    country: blockInfo.country !== "" ? blockInfo.country : "",
                    countryCode: blockInfo.countryCode !== "" ? blockInfo.countryCode : "",
                    reason_block: blockInfo.reason_block !== "" ? blockInfo.reason_block : "",
                    ip: blockInfo.ip !== "" ? blockInfo.ip : "",
                    start_ip: blockInfo.start_ip !== "" ? blockInfo.start_ip : "",
                    end_ip: blockInfo.end_ip !== "" ? blockInfo.end_ip : "",
                    allow_unblock: blockInfo.allow_unblock || 0,
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      let dataToBeUploaded = {
                        ...values,
                      };

                      dataToBeUploaded.action = radioValue;
                      dataToBeUploaded.method = method === "site" ? "site" : "course";
                      dataToBeUploaded.course =
                        method == "program" ? (programCourse !== "0" ? programCourse : program) : course;

                      if (radioValue == "allow") {
                        dataToBeUploaded.allow_unblock = 1;
                      }

                      if (values.range == "country") {
                        delete dataToBeUploaded.ip;
                        delete dataToBeUploaded.start_ip;
                        delete dataToBeUploaded.end_ip;
                      } else if (values.range == "ip") {
                        delete dataToBeUploaded.country;
                        delete dataToBeUploaded.start_ip;
                        delete dataToBeUploaded.end_ip;
                      } else if (values.range == "iprange") {
                        delete dataToBeUploaded.country;
                        delete dataToBeUploaded.ip;
                      }

                      if (method == "site") {
                        delete dataToBeUploaded.lesson;
                        delete dataToBeUploaded.course;
                      }

                      if (dataToBeUploaded.lesson == "0") {
                        dataToBeUploaded.lesson = null;
                      }

                      let response = await updateBlocked(id, dataToBeUploaded);
                      if (response.status === 200 && response.data.status) {
                        history.goBack();
                      } else {
                        alert("Failure");
                        setSubmitting(false);
                      }
                    } catch (err) {
                      setSubmitting(false);
                      alert(err.response.data && err.response.data.msg ? err.response.data.msg : "Failure in service");
                    }
                  }}
                  validateOnChange={hasSubmitted}
                  enableReinitialize
                  validate={(values) => {
                    setHasSubmitted(true);
                    const errors = {};
                    if (!values.name) {
                      errors.name = t("crud.errors.required");
                    }

                    if (method == "0") {
                      errors.method = t("crud.errors.required");
                    }

                    if (method === "course" && course == "0") {
                      errors.course = t("crud.errors.required");
                    }

                    if (method === "program" && program == "0") {
                      errors.program = t("crud.errors.required");
                    }

                    if (values.range == "0") {
                      errors.range = t("crud.errors.required");
                    }

                    if (values.range == "country") {
                      if (values.countryCode == "0") {
                        errors.countryCode = t("crud.errors.required");
                      }
                    } else if (values.range == "ip") {
                      if (!values.ip) {
                        errors.ip = t("crud.errors.required");
                      }
                      if (!ValidateIPaddress(values.ip)) {
                        errors.ip = t("block.errors.invalid_ip");
                      }
                    } else if (values.range == "iprange") {
                      if (!values.start_ip) {
                        errors.start_ip = t("crud.errors.required");
                      }
                      if (!ValidateIPaddress(values.start_ip)) {
                        errors.start_ip = t("block.errors.invalid_ip");
                      }

                      if (!values.end_ip) {
                        errors.end_ip = t("crud.errors.required");
                      }

                      if (!ValidateIPaddress(values.end_ip)) {
                        errors.end_ip = t("block.errors.invalid_ip");
                      }
                    }

                    if (!values.reason_block) {
                      errors.reason_block = t("crud.errors.required");
                    }
                    if (values.reason_block.length > 191) {
                      errors.reason_block = t("block.errors.massage_max_length");
                    }

                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    getFieldProps,
                  }) => (
                    <form onSubmit={handleSubmit} className="main_box_form">
                      <div className="main_label">{t("block.label.block_site")}</div>

                      <div className="">
                        <label htmlFor="name" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("block.label.block_name")} :
                        </label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          className="main_box_input_add"
                          onChange={handleChange}
                          placeholder={t("block.placeholders.block_name")}
                          value={values?.name || ""}
                          disabled={type === "review" ? true : false}
                        />
                        <p className={"form-input-error-space"}>{errors.name ? errors.name : null}</p>
                      </div>

                      <div className="custom_main_width">
                        <label htmlFor="action" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("block.label.desired_action")} :
                        </label>

                        <div className="admin_ipblock_radio">
                          <div className="">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio7"
                              value="block"
                              onChange={radioValueHandler}
                              checked={radioValue == "block"}
                              disabled={type === "review" ? true : false}
                            />
                            <label className="form-check-label-ipblock" htmlFor="inlineRadio7">
                              {t("block.label.blo")}
                            </label>
                            {/* <span className="gender_name">{t("block.label.blo")}</span> */}
                          </div>
                          <div className="">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio8"
                              value="allow"
                              onChange={radioValueHandler}
                              checked={radioValue == "allow"}
                              disabled={type === "review" ? true : false}
                            />
                            <label className="form-check-label-ipblock" htmlFor="inlineRadio8">
                              {t("block.label.Allow_only")}
                            </label>
                            {/* <span className="gender_name">{t("block.label.Allow_only")}</span> */}
                          </div>
                        </div>
                      </div>
                      <p className={"form-input-error-space"}></p>
                      {radioValue === "block" ? (
                        <div style={{ display: "flex", gap: "5%" }} className="field-group">
                          <div style={{ width: "30%" }} className="field-item">
                            <label htmlFor="method" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                              {t("block.label.Customize__ban")} :
                            </label>
                            <Field
                              id="method"
                              name="method"
                              as="select"
                              className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                              placeholder="نوع الاستبيان"
                              value={method}
                              onBlur={handleBlur}
                              onChange={methodHandler}
                              disabled={type === "review" ? true : false}
                            >
                              <option value="site">{t("block.label.Restrict_platform")}</option>
                              <option value="course">{t("block.label.Restricting_course")}</option>
                              <option value="program">{t("block.label.Restricting_program")}</option>
                            </Field>
                            <p className={"form-input-error-space"}>{errors.method ? errors.method : null}</p>
                          </div>
                          {method == "course" ? (
                            <>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="course" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.Choose_course")} :
                                </label>
                                <Field
                                  id="course"
                                  name="course"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  value={course}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    courseHandle(e);
                                  }}
                                  disabled={type === "review" ? true : false}
                                >
                                  {coursesBlock.map((course) => {
                                    return (
                                      <option key={course.id} value={course.id}>
                                        {course.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <p className={"form-input-error-space"}>{errors.course ? errors.course : null}</p>
                              </div>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="lesson" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.choose_lesson")} :
                                </label>
                                <Field
                                  id="lesson"
                                  name="lesson"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  value={values?.lesson}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled={type === "review" ? true : false}
                                >
                                  <option value={"0"}>{t("block.label.all")}</option>
                                  {lessonsBlock.map((lesson) => {
                                    return (
                                      <option key={lesson.id} value={lesson.id}>
                                        {lesson.name}
                                      </option>
                                    );
                                  })}
                                </Field>

                                <p className={"form-input-error-space"}>{errors.lesson ? errors.lesson : null}</p>
                              </div>
                            </>
                          ) : method == "program" ? (
                            <>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="program" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.Choose_program")} :
                                </label>
                                <Field
                                  id="program"
                                  name="program"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  value={program}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    programHandle(e);
                                    setFieldValue("lesson", "0");
                                  }}
                                >
                                  {programsBlock.map((program) => {
                                    return (
                                      <option key={program.id} value={program.id}>
                                        {program.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <p className={"form-input-error-space"}>{errors.program ? errors.program : null}</p>
                              </div>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="course" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.Choose_course")} :
                                </label>
                                <Field
                                  id="course"
                                  name="course"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  value={programCourse}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    courseHandle(e);
                                    setFieldValue("lesson", "0");
                                  }}
                                >
                                  <option value="0">{t("block.label.all")}</option>
                                  {programCoursesBlock.map((course) => {
                                    return (
                                      <option key={course.id} value={course.id}>
                                        {course.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </div>
                              <div style={{ width: "30%" }} className="field-item">
                                <label htmlFor="lesson" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t("block.label.choose_lesson")} :
                                </label>
                                <Field
                                  id="lesson"
                                  name="lesson"
                                  as="select"
                                  className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  // placeholder="نوع الاستبيان"
                                  value={values?.lesson}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                >
                                  <option value={"0"}>{t("block.label.all")}</option>
                                  {lessonsBlock.map((lesson) => {
                                    return (
                                      <option key={lesson.id} value={lesson.id}>
                                        {lesson.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                              </div>
                            </>
                          ) : null}
                        </div>
                      ) : null}

                      <label htmlFor="range" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                        {t("block.label.Ban_scope")} :
                      </label>
                      <div style={{ display: "flex", gap: "5%" }} className="field-group">
                        <div style={{ width: "30%" }} className="field-item">
                          <Field
                            id="range"
                            name="range"
                            as="select"
                            className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                            value={values.range}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={type === "review" ? true : false}
                          >
                            <option disabled value="0"></option>
                            <option value="country">{t("block.label.country")}</option>
                            <option value="ip">IP</option>
                            <option value="iprange">IP Range</option>
                          </Field>
                          <p className={"form-input-error-space"}>{errors.range ? errors.range : null}</p>
                        </div>
                        {values.range == "country" ? (
                          <div style={{ width: "65%" }} className="field-item">
                            <Field
                              id="country"
                              name="country"
                              as="select"
                              className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                              value={values?.countryCode}
                              onChange={(e) => {
                                setFieldValue("countryCode", e.target.value);
                                setFieldValue("country", e.target.options[e.target.selectedIndex].text);
                              }}
                              onBlur={handleBlur}
                              disabled={type === "review" ? true : false}
                            >
                              <option disabled value="0"></option>
                              {countries.map((item) => {
                                return <option value={item[0]}>{item[1]}</option>;
                              })}
                            </Field>
                            {/* <select
                                id="country"
                                name="country"
                                value={values.countryCode}
                                onChange={(e) => {
                                  setFieldValue('countryCode', e.target.value)
                                  setFieldValue('country', e.target.options[e.target.selectedIndex].text)
                                }}
                                onBlur={handleBlur}
                                className="admin_add_input"
                              >
                                <option value="0"></option>
                                {countries.map((item) => {
                                  return (
                                    <option value={item[0]}>{item[1]}</option>
                                  );
                                })}
                              </select> */}
                            <p className={"form-input-error-space"}>{errors.countryCode ? errors.countryCode : null}</p>
                          </div>
                        ) : values.range == "ip" ? (
                          <div style={{ width: "65%" }} className="field-item">
                            <input
                              id="ip"
                              type="text"
                              name="ip"
                              className="main_box_input_add"
                              onChange={handleChange}
                              placeholder="IP Address"
                              value={values?.ip || ""}
                              disabled={type === "review" ? true : false}
                            />
                            <p className={"form-input-error-space"}>{errors.ip ? errors.ip : null}</p>
                          </div>
                        ) : values.range == "iprange" ? (
                          <div style={{ width: "65%", display: "flex", gap: "2%" }} className="field-item">
                            <div style={{ width: "48%" }}>
                              <input
                                id="start_ip"
                                type="text"
                                name="start_ip"
                                className="main_box_input_add"
                                onChange={handleChange}
                                placeholder="IP Address"
                                value={values?.start_ip || ""}
                                disabled={type === "review" ? true : false}
                              />
                              <p className={"form-input-error-space"}>{errors?.start_ip ? errors?.start_ip : null}</p>
                            </div>
                            <span className="slash-symbol">-</span>
                            <div style={{ width: "48%" }}>
                              <input
                                id="end_ip"
                                type="text"
                                name="end_ip"
                                className="main_box_input_add"
                                onChange={handleChange}
                                placeholder="IP Address"
                                value={values.end_ip || ""}
                                disabled={type === "review" ? true : false}
                              />
                              <p className={"form-input-error-space"}>{errors.end_ip ? errors.end_ip : null}</p>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {radioValue === "allow" ? null : (
                        <div className="row personal_check mb-4">
                          <div className="form-check checkbox">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="Check1"
                              checked={values.allow_unblock == 1 ? true : false}
                              onChange={(e) => {
                                setFieldValue("allow_unblock", e.target.checked ? 1 : 0);
                              }}
                              disabled={type === "review" ? true : false}
                            />
                            <label className="form-check-label" htmlFor="Check1">
                              {t("block.label.Allow_unblocking_request")}
                            </label>
                          </div>
                        </div>
                      )}

                      <div className="">
                        <label htmlFor="reason_block" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("block.label.msg_block")}
                        </label>
                        <input
                          id="reason_block"
                          type="text"
                          name="reason_block"
                          className="main_box_input_add"
                          onChange={handleChange}
                          placeholder={t("block.placeholders.msg_block")}
                          value={values.reason_block || ""}
                          disabled={type === "review" ? true : false}
                        />
                        {/* <SunEditor
                            value={values.reason_block || ""}
                            defaultValue={values.reason_block || ""}
                            onChange={(e) => {
                              handleChange({ target: { name: "reason_block", value: e } });
                            }}
                            setOptions={{
                              height: 200,
                              buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle", "blockquote"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "subscript",
                                  "superscript",
                                  "fontColor",
                                  "hiliteColor",
                                  "textStyle",
                                  "removeFormat",
                                ],
                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image", "video", "audio"],
                                ["fullScreen", "showBlocks", "codeView"],
                                ["preview", "print"],
                                ["save", "template"],
                              ],
                            }}
                            setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px; text-align: right; direction: rtl"
                            disable={type === "review" ? true : false}
                          /> */}
                        <p className={"form-input-error-space"}>{errors.reason_block ? errors.reason_block : null}</p>
                      </div>
                      {type === "review" ? (
                        <div style={{ margin: "5% 0" }}>
                          <label htmlFor="reason_block" className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t("block.label.unblock_list")}
                          </label>
                          <MainTable columns={columns} data={unblockList} loading={isCoursesLoading} />
                        </div>
                      ) : null}
                      {type === "review" ? null : (
                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="main_box_add_button"
                            style={{
                              width: 250,
                              height: 75,
                              padding: "4px",
                              borderRadius: "6px",
                              display: "inline-block",
                            }}
                          >
                            {t("block.label.edit")}
                          </button>
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
                {ignoreModal && (
                  <SharedModal
                    icon={DeleteIcon}
                    title={t("confirmIgnore")}
                    name={t("reblockBody")}
                    // confirmMsg={t("ignoreBody")}

                    confirmText={t("modal.common.confirm")}
                    cancelText={t("modal.common.cancel")}
                    onCancel={() => {
                      setIgnoreModal(false);
                    }}
                    onConfirm={() => {
                      ignoreRow(rowID, rowIp);
                    }}
                    isLoading={unblockRequestByIpLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockDetails;

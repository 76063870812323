import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import IconsTooltip from "../../Components/tooltips/IconsTooltip";
import { saveWatchingProgress } from "../../Redux/Actions/courses/coursesList.action";
import i18n from "./../../i18n/i18n";
import { Lang } from "./../../utils";

function SurveysModal(props) {
  require("./surveysModal.css");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const openCourse = (survey) => {
    // if (survey.is_watched) {
    //   history.push({
    //     pathname: `/survey/${survey.id}/statistics`,
    //   });
    // } else {
    // if (!props.isTrainer) {
    //   const payload = {
    //     course_id: props.courseId,
    //     watchable_id: survey.id,
    //     watchable_type: "survey",
    //   };
    //   props.lessonId && (payload.lesson_id = props.lessonId);
    //   dispatch(saveWatchingProgress(payload));
    // }

    history.push({
      pathname: `/survey/${survey.id}/course/${props.courseId}`,
    });
    // }
  };

  return (
    <>
      <div
        className={"tajah-basic-modal-md"}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={"tajah-basic-modal-header"}>
          <h4>{props.title}</h4>
          <div>
            <i
              className="fas fa-times tajah-modal-dismiss"
              onClick={() => {
                props.surveysModalRef.current.dismissModal();
                props.accept();
              }}
            ></i>
          </div>
        </div>

        <div className={"tajah-basic-modal-body"}>
          <div className="table-responsive tw-px-1">
            {props.body.length == 0 ? (
              <p style={{ textAlign: "center" }}>{t("survey.notFound")}</p>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th scope="col">{t("general.id")}</th>
                    <th scope="col">{t("survey.list.name")}</th>
                    {props.courseId ? (
                      <>
                        <th scope="col">{t("include_achivement_per")}</th>
                        <th scope="col">{t("include_view_per")}</th>
                        <th scope="col">{t("is_watched")}</th>
                      </>
                    ) : null}
                    {/* {props.isClosed ?  */}
                    <th scope="col"> {t("survey.list.title")}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.body.map((survey) => {
                    return (
                      <tr style={{ textAlign: "center" }} key={survey.id}>
                        <td scope="row">{survey.id}</td>
                        <td>
                          {i18n.language === Lang.AR
                            ? survey.name
                            : survey.name}
                        </td>
                        {props.courseId ? (
                          <>
                            <td>
                              {survey.include_achivement_per
                                ? t("yes")
                                : t("no")}
                            </td>
                            <td>
                              {survey.include_view_per ? t("yes") : t("no")}
                            </td>
                            <td>{survey.is_watched ? t("yes") : t("no")}</td>
                          </>
                        ) : null}
                        <td className="tw-w-64">
                          <button
                            type="button"
                            disabled={survey.user_answered && !survey.is_shared}
                            onClick={() => {
                              props.surveysModalRef.current?.dismissModal();
                              props.accept();
                              if (survey.user_answered) {
                                history.push({
                                  pathname: `/survey/${survey.id}/statistics`,
                                });
                              } else {
                                if (props.courseId) {
                                  openCourse(survey);
                                } else if (props.exhibitionId) {
                                  history.push(
                                    `/survey/${survey.id}/exhibition/${props.exhibitionId}`
                                  );
                                }
                              }
                            }}
                            className={`tw-bg-teal-700 tw-p-2 tw-rounded-lg tw-text-white disabled:tw-bg-white disabled:tw-border-2 disabled:tw-border-teal-700 disabled:tw-text-gray-700 tw-w-full`}
                          >
                            {t(
                              survey.user_answered
                                ? survey.is_shared
                                  ? "view_survey_results"
                                  : "survey_has_been_answered"
                                : "enter_survey"
                            )}
                          </button>
                        </td>
                        {/* ) : null} */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SurveysModal;

import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  deleteLivechatSupportUsers,
  getlivechatSupportUsers,
  postLivechatSupportUsersStatus,
  resetLivechat,
} from "../../../Redux/Actions/livechat/livechat.action";
import deleteIcon from "../../../assets/icons/delete.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import editExhIcon from "../../../assets/icons/pen.svg";
import DeleteIcon from "../../../assets/icons/warning.svg";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../Shared/Components/SwitchToggle/SwitchToggle";
import { filterActions } from "./SupportUsersTableAction";
import classes from "./supportUsers.module.css";

const SupportUsers = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    livechat_support_users,
    livechat_support_users_status,
    livechat_delete_support_users,
  } = useSelector((state) => state.livechatReducer);
  const [showFilter, setShowFilter] = useState(false);
  const [supportUsers, setSupportUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [filterData, setFilterData] = useState({
    name: "",
    email: "",
  });
  const [deletedId, setDeletedId] = useState();
  const [changeStatusId, setChangeStatusId] = useState();

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const statusHandler = async (id, status) => {
    try {
      dispatch(postLivechatSupportUsersStatus(id));
    } catch (err) {}
  };

  const deleteObjectById = (idToDelete) => {
    const updatedArray = supportUsers.filter((obj) => obj.id !== idToDelete);
    setSupportUsers(updatedArray);
  };

  const deleteHandler = async (id) => {
    dispatch(deleteLivechatSupportUsers(id));
    setIsLoading(true);
    setShowDeleteModal(false);
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      email: "",
    });
    setIsResetApplied(true);
    setIsLoading(true);
    dispatch(getlivechatSupportUsers());
  };

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const updateObjectById = (id, newObject) => {
    setSupportUsers((prevObjects) =>
      prevObjects.map((obj) => (obj.id === id ? newObject : obj))
    );
  };

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  useEffect(() => {
    if (livechat_delete_support_users.status === 200) {
      deleteObjectById(deletedId);
      setIsLoading(false);
    }
  }, [livechat_delete_support_users]);

  useEffect(() => {
    setIsResetApplied(false);
  }, [currentPage, isResetApplied]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getlivechatSupportUsers());
  }, []);

  useEffect(() => {
    if (livechat_support_users.status === 200) {
      setSupportUsers(livechat_support_users.data.data.users.data);
      setTotalPages(livechat_support_users.data.data.users.meta.totalPages);
      setIsLoading(false);
    }
  }, [livechat_support_users]);

  useEffect(() => {
    if (
      livechat_support_users_status.status === 200 &&
      livechat_support_users_status.data.status
    ) {
      updateObjectById(changeStatusId, livechat_support_users_status.data.user);
    }
  }, [livechat_support_users_status]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "chat",
      page: t("chat.title"),
      pagePath: "/admin/livechat",
    },
    {
      id: "supportuser",
      page: t("chat.supportusers"),
      active: true,
    },
  ];

  const actionsList = [
    {
      id: "edit",
      icon: <img src={editExhIcon} alt="" />,
      action: (id) => {
        history.push(`/admin/livechat/edit-support-users/${id}`);
        dispatch(resetLivechat());
      },
    },
    {
      id: "delete",
      icon: <img src={deleteIcon} alt="" />,
      action: (id) => {
        setDeletedId(id);
        setShowDeleteModal(true);
      },
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData.id}</>,
    },

    {
      id: "img",
      name: t("chat.img"),
      key: "img",
      cell: ({ rowData }) => (
        <img className={classes.img} src={rowData.user.img} alt={"icon"} />
      ),
      sortable: false,
    },

    {
      id: "name",
      name: t("chat.username"),
      key: "name",
      cell: ({ rowData }) => (
        <div>
          {i18n.language === Lang.AR
            ? rowData.user.name_ar
            : rowData.user.name_en}
        </div>
      ),
      sortable: false,
    },
    {
      id: "email",
      name: t("chat.useremail"),
      key: "email",
      cell: ({ rowData }) => <>{rowData.user.email}</>,
      sortable: false,
    },

    {
      id: "adding_date",
      name: t("chat.addingdate"),
      key: "adding_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.start_time).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.start_time)}
        </>
      ),
      sortable: false,
    },

    {
      id: "actions",
      name: t("chat.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <div className={classes["chat_list__actions"]}>
          {actionsList.map((action, index) => {
            return (
              <button
                key={action.id}
                className={classes["chat_list__actions-btn"]}
                onClick={() => {
                  if (
                    action.id === "edit" &&
                    new Date(rowData.date) <= Date.now()
                  ) {
                    return;
                  }
                  action.action(rowData.id);
                }}
                disabled={
                  action.id === "edit" && new Date(rowData.date) <= Date.now()
                }
              >
                {action.icon}
              </button>
            );
          })}
        </div>
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("chat.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.active ? true : false}
          id={rowData.id}
          onStatusChange={() => {
            setChangeStatusId(rowData.id);
            statusHandler(rowData.user_id);
          }}
        />
      ),
      sortable: false,
    },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("chat.username"),
      type: "text",
      placeholder: t("chat.username"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-5",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "email",
      component: "input",
      label: t("chat.useremail"),
      type: "email",
      placeholder: t("chat.useremail"),
      name: "email",
      value: filterData.email,
      classes: "col-12 col-md-3",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("chat.reset"),
      classes: "col-12 col-md-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("chat.search"),
      classes: "col-12 col-md-2",
      action: () => {
        dispatch(getlivechatSupportUsers(filterData));
        setCurrentPage(1);
      },
    },
  ];

  return (
    <div className={classes["chat_list"]}>
      <div className="container-fluid">
        <div className={classes["chat_list__container"]}>
          <div className={`row ${classes.breadcrumbs}`}>
            <Breadcrumb list={breadcrumbList} />
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("chat.supportusers")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    addSupportUsers: () => {
                      history.push("/admin/livechat/create-support-users");
                      dispatch(resetLivechat());
                    },
                  })}
                />
                {showFilter && <FilterBox fields={filterFields} />}
                <MainTable
                  columns={columns}
                  data={supportUsers}
                  isLoading={isLoading}
                  sortAction={sortActionHandler}
                />
                <Pagination
                  count={totalPages}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
              {showDeleteModal && (
                <SharedModal
                  icon={DeleteIcon}
                  title={t("chat.delete_title")}
                  confirmMsg={t("chat.delete_confirmMsg")}
                  confirmText={t("chat.confirm")}
                  cancelText={t("chat.cancel")}
                  onCancel={() => {
                    setShowDeleteModal(false);
                  }}
                  onConfirm={() => {
                    deleteHandler(deletedId);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportUsers;

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import SetDegreeForAnswerToQuestionModal from "../../../../../Modals/SetDegreeForAnswerToQuestionModal/SetDegreeForAnswerToQuestionModal";
import { setQuestionGrade } from "../../../../../Services/api/exams/ExamsProvider";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";

function QuestionAnswerPanel(props) {
  require("./QuestionAnswerPanel.css");
  const { t } = useTranslation();
  const { trainee_id, exam_id } = useParams();
  const [grade, setGrade] = useState(props.item.grade);

  const modalRef = useRef();
  // const modalHandler = () => modalRef.current.dismissModal();

  const onDegreeAdded = async (gd, feedback) => {
    try {
      gd = parseFloat(gd).toFixed(2);
      let payload = {
        trainee_id: trainee_id,
        revision: [
          {
            question_id: props.item.question.id,
            grade: gd,
            feedback: feedback,
          },
        ],
      };
      await setQuestionGrade(exam_id, payload);
      setGrade(gd);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.quiz.modal.add_grade.toast.success")}
        </span>
      );
      modalRef.current.dismissModal();
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.quiz.modal.add_grade.toast.failure")}
        </span>
      );
    }
  };

  return (
    <>
      <BasicModal ref={modalRef}>
        <SetDegreeForAnswerToQuestionModal
          title={t("trainer.quiz.modal.add_grade.label")}
          label={t("trainer.quiz.modal.add_grade.accept")}
          accept={onDegreeAdded}
          question={props.item.question}
          modalRef={modalRef}
        />
      </BasicModal>
      <div className={"tajah-question-answer-panel"}>
        <div className={"tajah-form-row tajah-form-row-qa"}>
          <div
            className={"tajah-form-row-element-v2 tajah-question-info-section"}
          >
            <div className={"tajah-form-row tajah-question-info-section-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <label htmlFor="title" className="admin_add_label">
                    {t("trainer.quiz.crud.question_title")}
                  </label>
                  <input
                    value={props.item.question.title}
                    className="admin_filter_input"
                    disabled={true}
                  />
                </div>
              </div>

              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <label htmlFor="title" className="admin_add_label">
                    {t("trainer.quiz.crud.grade")}
                  </label>
                  <input
                    value={grade + "/" + props.item.question.degree}
                    className="admin_filter_input"
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {props.item.is_manual ? (
            <div className={"tajah-form-row-element-v2"}>
              <button
                className={"btn btn-success"}
                onClick={() => {
                  modalRef.current.showModal();
                }}
              >
                <i className={"fa fa-cog"} />
              </button>
            </div>
          ) : null}
        </div>
        {props.children}
      </div>
    </>
  );
}

export default QuestionAnswerPanel;

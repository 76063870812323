import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import { getTrainerProfile } from "../../../Services/api/toutorials/trainerProvider";
import noResult from "../../../assets/image/Noresult.png";
import edit from "../../../assets/image/edit(1).png";
import logo from "../../../assets/image/logo.png";
import courserun from "../../../assets/image/run.png";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import CourseCardSpinner from "./CourseCardSpinner";

function AllcoursesFinished(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [coursesFinished, setCoursesFinished] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    authContext.roles.includes("trainer") &&
      getTrainerProfile()
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setCoursesFinished(res.data.response.coursesFinished);
            setLoading(false);
          }
        })
        .catch((error) => {
          
        });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="head_box mt-5">
              <div className="head_lable">{t("completecourse")}</div>
            </div>
            <div className={coursesFinished.length !== 0 ? "courses_card_grid" : ""}>
              {loading == false ? (
                coursesFinished.length == 0 ? (
                  <span
                    className="course-details"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "50px"
                    }}
                  >
                    <img src={noResult} alt="" />
                    <h5>{t("no_record")}</h5>
                  </span>
                ) : (
                  coursesFinished.map((item) => {
                    return (
                      <div className="courses_card_courses" key={item.id}>
                        <div
                          className="courses_card_first_courses"
                          style={{
                            backgroundImage: "url(" + item.img + ")",
                            cursor: "pointer",
                          }}
                        >
                          <div className="courses_name_course">
                            <div className="create_courses_name">
                              <div className="create_couses_edit">
                                <img
                                  src={logo}
                                  alt=""
                                  className="logo_hover_width"
                                />
                                     <div className=""
                                   onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(
                                      "trainer/course/edit/" + item.id
                                    );
                                    // history.push("trainer/course/edit/" + item.id)
                                  }}
                                >
                                  <img
                                    src={courserun}
                                    alt=""
                                    className="menu_icon"
                                  />
                                  {t("course-run")}
                                </div>
                              </div>
                              <div className="">{item.name}</div>
                            </div>
                            <div className="create_couses_edit">
                              <div className="">
                                {t("department")} :{" "}
                                {i18n.language === Lang.AR
                                  ? item.department_name_ar
                                  : item.department_name_en}
                              </div>
                              <div
                                className=""
                                onClick={() => {
                                  history.push(
                                    "trainer/course/edit/" + item.id
                                  );
                                }}
                              >
                                <img src={edit} alt="" className="menu_icon" />
                                {t("modification")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )
              ) : (
                <>
                  <CourseCardSpinner />
                  <CourseCardSpinner />
                  <CourseCardSpinner />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllcoursesFinished;

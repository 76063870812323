import FilePreview from "../../../Shared/Components/FilePreview/FilePreview";
import classes from "./exhibitionDetails.module.css";
const ExhibitionDetailsDocuments = ({ documents }) => {
  return (
    <div className={classes["exhibition-details__documents"]}>
      <h2 className={classes["exhibition-details__documents-title"]}>مرفقات المعرض</h2>
      <div className={classes["exhibition-details__documents__lists"]}>
        {documents &&
          !!documents.length &&
          documents.map((document) => (
            <div className={classes["exhibition-details__documents-item"]} key={document.id}>
              <FilePreview name={document.title} file={document.file} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ExhibitionDetailsDocuments;

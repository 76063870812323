import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogModal from "../../Modules/Shared/Components/DialogModal/DialogModal";
import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";

export default function RatingModal({ handleSubmit, handleCancel, rating }) {
  const { t } = useTranslation();
  const [over, setOver] = useState(0);
  return (
    <DialogModal
      onCancel={handleCancel}
      className="tw-bg-white sm:tw-rounded-lg tw-shadow-xl tw-p-4 tw-space-y-4 tw-w-full sm:tw-w-2/3 lg:tw-w-1/2"
    >
      <div>
        <div className="tw-text-lg sm:tw-text-xl tw-text-teal-600">{t("اضافة تقييم")}</div>
      </div>
      <div>
        <Formik
          initialValues={{
            stars: rating ? rating.stars : 0,
            description: rating ? rating.description : "",
          }}
          onSubmit={async (values) => {
            const data = {
              ...values,
            };
            await handleSubmit(data);
          }}
          validateOnChange={true}
          validate={(values) => {
            const errors = {};
            if (!values.stars) {
              errors.stars = t("crud.errors.required");
            }

            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setErrors,
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div
                  className="tw-py-4 tw-px-2 tw-flex tw-items-center tw-space-s-3 tw-w-full"
                  onMouseLeave={() => setOver(0)}
                >
                  {[...Array(5).keys()].map((item, index) => (
                    <button
                      type="button"
                      key={index}
                      onMouseOver={() => setOver(index + 1)}
                      onClick={() => {
                        setFieldValue(`stars`, index + 1);
                      }}
                    >
                      <StarIcon
                        className={`tw-h-10 tw-w-10 hover:tw-w-12 hover:tw-h-12 hover:-tw-m-1 ${
                          (over ? over : values.stars) > index
                            ? "tw-fill-teal-700 tw-stroke-teal-700"
                            : "tw-fill-white tw-stroke-gray-500"
                        }`}
                      />
                    </button>
                  ))}
                </div>
                <div className={"form-input-error-space"}>{errors.stars ? errors.stars : null}</div>
              </div>
              <div>
                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("general.comment")}</div>
                <textarea
                  placeholder={t("general.comment")}
                  value={values.description}
                  onChange={(e) => {
                    setFieldValue("description", e.target.value);
                  }}
                  rows={5}
                  className="tw-block tw-w-full tw-py-2 tw-px-3 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="tw-flex tw-items-center tw-justify-end tw-space-s-4 tw-pt-8">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white tw-w-full"
                >
                  {t("ok")}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div></div>
    </DialogModal>
  );
}

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import toLocalTimezone from "../../../../../Services/Timezone";
import { ReactComponent as ExamIcon } from "../../../../../assets/icons/ExamIcon.svg";
import LessonItem from "./LessonItem";

export default function LessonQuizItem({ courseId, chapterId, unitId, lessonId, quiz, onOpen, isCourseClosed }) {
  const { t } = useTranslation();
  const history = useHistory();

  const params = {
    icon: <ExamIcon className="tw-h-5" />,
    name: t("trainer.course.lesson.exams"),
    title: quiz.title,
    description: quiz.monitored ? `(${t("monitored")})` : `( ${t("questions_count")} ${quiz.questions_count})`,
    columns: [
      {
        label: t("answer_attempts"),
        value: `${quiz.my_answered_count}/${quiz.answer_attempts}`,
      },
      {
        label: t("passPercent"),
        value: `${Math.round(parseFloat(quiz.success_rate) * 100) / 100}%`,
      },
      {
        label: t("quiz_grade"),
        value: `${Math.round(parseFloat(quiz.total_grade) * 100) / 100}`,
      },
    ],
    buttons: [
      {
        label: t("participate"),
        disabled:
          quiz.my_answered_count >= quiz.answer_attempts ||
          new Date(toLocalTimezone(quiz.start_date)) > new Date() ||
          new Date(toLocalTimezone(quiz.end_date)) < new Date() ||
          isCourseClosed,
        onClick: () => {
          onOpen();
          history.push(`/trainees/course/${courseId}/chapter/${chapterId}/exam/${quiz.id}`);
        },
      },
      {
        label: t("results"),
        disabled: quiz.my_answered_count <= 0,
        onClick: () => {
          history.push(`/trainees/course/${courseId}/chapter/${chapterId}/exam/${quiz.id}/result`);
        },
        secondary: true,
      },
    ],
    footer: {
      flags: {
        include_view: quiz.include_view_per,
        include_achivement: quiz.include_achivement_per,
        finished:
          quiz.my_answered_count >= quiz.answer_attempts || new Date(toLocalTimezone(quiz.end_date)) < new Date(),
        is_watched: quiz.is_watched,
        is_achieved: quiz.is_achieved,
      },
      columns: [
        {
          label: t("time_for"),
          value: `${quiz.period} ${t("min")}`,
        },
        {
          label: t("quiz_grading"),
          value: quiz.is_my_answer_corrected ? t("completed") : t("not_completed"),
          color: quiz.is_my_answer_corrected ? "green" : "gray",
        },
        {
          label: t("avialible_from"),
          value: moment(toLocalTimezone(quiz.start_date)).format("YYYY-MM-DD hh:mm A"),
          rows: [
            {
              label: t("To"),
              value: moment(toLocalTimezone(quiz.end_date)).format("YYYY-MM-DD hh:mm A"),
            },
          ],
        },
      ],
    },
  };
  return <LessonItem {...params} />;
}

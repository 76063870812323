import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { decrypt } from "../../../../Helpers/Secret";
import { deleteTax, updateTaxStatus } from "../../../../Redux/Actions/Tax/taxList.action";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "./Tax.module.css";

const TaxTable = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [taxId, setTaxId] = useState(null);
  const history = useHistory();

  const { taxsList, taxsListLoading } = useSelector((state) => state.taxsListReducer);

  const updateCourseStatusHandler = (id) => {
    dispatch(
      updateTaxStatus({
        id,
      })
    );
  };

  const deleteHandler = (id) => {
    setShowDeleteModal(true);
    setTaxId(id);
  };

  const confirmDeleteTaxHandler = () => {
    if (!taxId) return;
    dispatch(deleteTax(taxId));
    setShowDeleteModal(false);
  };

  const displayMode = (parent_type) => {
    if (parent_type === 1) {
      return t("general.course");
    } else if (parent_type === 2) {
      return t("general.program");
    } else if (parent_type === 3) {
      return t("general.exhibition_booth");
    } else if (parent_type === 4) {
      return t("general.exhibition_visitor");
    }
  };

  const TaxActionsList = [
    {
      id: "edit",
      icon: <img src={editIcon} alt="" />,
      action: (id) => {
        history.push(
          `${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/tax/edit/${id}`
        );
      },
      tooltip: t("general.edit"),
    },

    {
      id: "delete",
      icon: <img src={DeleteIcon} alt="" />,
      action: (id) => {
        deleteHandler(id);
      },
      tooltip: t("general.delete"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "name",
      name: t("financial.tax.name"),
      key: "name",
      cell: ({ rowData }) => <div className={classes["tax-list__table-name"]}>{rowData?.name || "-"}</div>,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "tax_mechanism",
      name: t("machinsim_tax"),
      key: "tax_mechanism",
      cell: ({ rowData }) => <>{rowData?.mechanism == 1 ? t("amount_tax") : t("coupon.label.per_value")}</>,
    },

    {
      id: "tax_value",
      name: t("financial.tax.value_tax"),
      key: "tax_value",
      cell: ({ rowData }) => <>{rowData?.mechanism == 1 ? rowData?.value + t("general.sar") : rowData?.value + "%"}</>,
    },

    {
      id: "parent_type",
      name: t("zoom.type"),
      key: "parent_type",
      cell: ({ rowData }) => (
        <>
          <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.parent_type == 1 ? "#8599B6" : "#12C689",
              backgroundColor: rowData?.parent_type == 1 ? "#C1CEE222" : "#C1E2C822",
            }}
          >
            {displayMode(rowData?.parent_type) || "-"}
          </span>
        </>
      ),
    },
    {
      id: "course_or_program",
      name: t("coupon.label.parent_name"),
      key: "course_or_program",
      cell: ({ rowData }) => (
        <span
          style={{
            color: "#046c77",
          }}
        >
          {rowData?.parent?.name || "-"}
        </span>
      ),
    },
    {
      id: "note_for_user",
      name: t("note_for_user"),
      key: "note_for_user",
      cell: ({ rowData }) => (
        <>
          {
            <div style={{ width: 300 }}>
              <p className={classes["note_table"]}>{rowData?.notes || "-"}</p>
            </div>
          }
        </>
      ),
    },
    {
      id: "start_date",
      name: t("general.start_date"),
      key: "start_date",
      cell: ({ rowData }) => (
        <>
          {rowData?.start_date ? moment(rowData?.start_date).format("DD/MM/YYYY") : "-"}
          <br />
          {rowData?.start_date ? utcToLocal(rowData?.start_date) : "-"}
        </>
      ),
    },
    {
      id: "end_date",
      name: t("general.end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {rowData?.end_date ? moment(rowData?.end_date).format("DD/MM/YYYY") : "-"}
          <br />
          {rowData?.end_date ? utcToLocal(rowData?.end_date) : "-"}
        </>
      ),
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {rowData?.created_at ? moment(rowData?.created_at).format("DD/MM/YYYY") : "-"}
          <br />
          {rowData?.created_at ? utcToLocal(rowData?.created_at) : "-"}
        </>
      ),
    },
    {
      id: "nationality_id",
      name: t("nationality"),
      key: "nationality_id",
      cell: ({ rowData }) => (
        <>
          {rowData.excluded_nationalities.map((country) => (
            <>
              <p>{i18n.language === "en" ? country.name_en : country.name_ar}</p>
            </>
          ))}
        </>
      ),
    },

    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => <TableActionsColumn actions={TaxActionsList} id={rowData.id} />,
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status}
          id={rowData.id}
          onStatusChange={() => {
            updateCourseStatusHandler(rowData.id);
          }}
        />
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <MainTable columns={columns} data={taxsList || []} isLoading={taxsListLoading} />
      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon}
          title={t("financial.tax.label_popup")}
          confirmMsg={t("financial.tax.msg_popup")}
          confirmText={t("general.confirm")}
          cancelText={t("general.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            confirmDeleteTaxHandler();
          }}
          isLoading={false}
        />
      )}
    </>
  );
};

export default TaxTable;

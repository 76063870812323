import React, { useEffect } from "react";
import MainCarousel from "../../../Shared/Components/MainCarousel/MainCarousel";
import classes from "./bootDetails.module.css";

const BootDetailsCarousel = ({ photos, title, type }) => {
  const [imagesList, setImagesList] = React.useState([]);

  useEffect(() => {
    const images = photos.map((photo) => ({
      id: photo?.image?.lastModified,
      file: photo?.image,
    }));
    setImagesList(images);
  }, [photos]);

  return (
    <div className={classes["boot-details__carousel"]}>
      <div className={classes["boot-details__carousel-title"]}>{title}</div>
      {/*{!!photos.length && <MainCarousel imagesList={photos} type={type} />}*/}
      {!!photos.length && <MainCarousel imagesList={imagesList} type={type} />}
    </div>
  );
};

export default BootDetailsCarousel;

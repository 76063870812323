import Paper from "@mui/material/Paper";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { TrainerAddExamContext } from "../../../../../../Providers/TrainerAddExamProvider";
import SkeletonCardOverlay from "../../../../../Shared/Components/Spinner/SkeletonCardOverlay";

function AddedQuestionPanel(props) {
    require("./AddedQuestion.css");
    const {t} = useTranslation();
    const {id, quizable_id, exam_id} = useParams();
    const history = useHistory();

    const trainerAddExamContext = useContext(TrainerAddExamContext);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitExam = async () => {
        setIsSubmitting(true);
        try{
            await trainerAddExamContext.submitExamEdits(null, exam_id);
            setIsSubmitting(false);
            toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    {t('trainer.quiz.edited_successfully')}
                </span>
            )
        } catch (e) {
            setIsSubmitting(false);
            toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    {t('trainer.quiz.failed_editing')}
                </span>
            )
        }
    }

    return (
        <>

            {isSubmitting ?<SkeletonCardOverlay skeletonWidth="100"/>:<div></div>}
            <div className={"question-canvas-for-question-bank-in-exam"}>
                <Paper variant={"outlined"}
                       sx={{
                           width: '100%',
                           height: 'auto',
                           borderWidth: 0
                       }}>
                    <div className={"tajah-form-container"} style={{
                        boxShadow: "none",
                        borderRadius: 0
                    }}>
                        <div className={"tajah-pre-table-header"}>
                            <span>{t("trainer.quiz.added_questions")}</span>
                        </div>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t("trainer.quiz.crud.question_title")}</th>
                                <th scope="col">{t("trainer.quiz.crud.question_type")}</th>
                                <th scope="col">{t("crud.list.actions")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {trainerAddExamContext.addedQuestions.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.title}</td>
                                        <td>{document.body.dir == 'rtl' ? item.type?.name_en : item.type?.name_ar}</td>
                                        <td>
                                            <i
                                                onClick={() => {
                                                    
                                                    trainerAddExamContext.setIsEdit(false);
                                                    trainerAddExamContext.setQuestion({})
                                                    setTimeout(() => {
                                                        trainerAddExamContext.setQuestion(item)
                                                        trainerAddExamContext.setIsEdit(true);
                                                    }, 0);
                                                    trainerAddExamContext.setSelectedQuestionPanel(0)
                                                    Promise.all([
                                                        trainerAddExamContext.fetchQuestionTypes(),
                                                        trainerAddExamContext.fetchCourseObjectives(id),
                                                        trainerAddExamContext.fetchQuestionData(item.id),
                                                    ]).then((values) => {
                                                        trainerAddExamContext.setIsLoading(false);
                                                    });
                                                }}
                                                title={"Edit"}
                                                style={{
                                                    fontSize: 20,
                                                    color:'black',
                                                    margin: "auto 4px"

                                                }} className="fal fa-edit"></i>
                                            <i
                                                onClick={() => {
                                                    const tempAddedQuestions = [];
                                                    trainerAddExamContext.addedQuestions.forEach(entity => {
                                                        if (entity.id != item.id) {
                                                            tempAddedQuestions.push(entity);
                                                        }
                                                    })
                                                    if(item.id == trainerAddExamContext.question.id) {
                                                        trainerAddExamContext.setIsEdit(false);
                                                        trainerAddExamContext.setQuestion({});
                                                    }
                                                    trainerAddExamContext.setAddedQuestions(tempAddedQuestions);
                                                }}
                                                title={"Delete"}
                                                style={{
                                                    fontSize: 20,
                                                    color:'black',
                                                    margin: "auto 4px"

                                                }} className="fal fa-trash"></i>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className={"tajah-question-bank-submission-buttons"}>
                        <button
                            type="submit"
                            className="admin_add_button"
                            disabled={isSubmitting}
                            onClick={() => {
                                submitExam();
                            }}
                        >
                            {t("crud.placeholders.save")}
                        </button>

                        <button
                            type="button"
                            className="admin_add_button"
                            disabled={isSubmitting}
                            style={{
                                backgroundColor: '#CDCDCD',
                                color: '#000000',
                                marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                marginLeft: document.body.dir == 'ltr' ? 10 : 0
                            }}
                            onClick={() => {
                                trainerAddExamContext.setActivePanel(0);
                            }}
                        >
                            {t("crud.placeholders.previous")}
                        </button>
                    </div>
                </Paper>
            </div>
        </>
    )
}

export default AddedQuestionPanel;

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import { Lang } from "../../../../utils";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "./specialtiesDetails.module.css";
import { updateSpecialtyStatus } from "../../../../Redux/Actions/specialties/specialtiesDetails.action";

const SpecialtiesDetailsTable = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { specialty, specialtyLoading } = useSelector(
    (state) => state.specialtiesDetailsReducer
  );

  const updateSpecialtyStatusHandler = (id, status) => {
    dispatch(
      updateSpecialtyStatus({
        id,
        status: status ? 0 : 1,
      })
    );
  };

  const specialtiesDetailsActionsList = [
    {
      id: "edit",
      icon: <img src={editIcon} alt="" />,
      action: (id) => {
        history.push(`/admin/department/edit/${id}`, {
          specialtyId: specialty?.id,
          specialtyName:
            i18n.language == Lang.AR ? specialty?.name_ar : specialty?.name_en,
        });
      },
      tooltip: t("general.edit"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "name",
      name: t("department_list.name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["specialties-details__table-name"]}>
          {rowData?.name || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "description",
      name: t("department_list.description"),
      key: "description",
      cell: ({ rowData }) => (
        <div
          className={classes["specialties-details__table-desc"]}
          dangerouslySetInnerHTML={{
            __html: rowData?.description,
          }}
        ></div>
      ),
      sortable: false,
      align: "start",
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: false,
    },
    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn
          actions={specialtiesDetailsActionsList}
          // cantEdit={new Date(rowData.end_time) <= Date.now()}
          id={rowData.id}
        />
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status}
          id={rowData.id}
          onStatusChange={() => {
            updateSpecialtyStatusHandler(rowData.id, rowData.status);
          }}
        />
      ),
      sortable: false,
    },
  ];

  return (
    <div className={classes["specialties-details__table"]}>
      <MainTable
        columns={columns}
        data={specialty || []}
        isLoading={specialtyLoading}
      />
    </div>
  );
};

export default SpecialtiesDetailsTable;

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as QuizezIcon } from "../../../../../assets/icons/QuizezIcon.svg";
import LessonItem from "./LessonItem";

export default function LessonSurveyItem({ courseId, chapterId, unitId, lessonId, survey, onOpen, isCourseClosed }) {
  const { t } = useTranslation();
  const history = useHistory();

  const params = {
    icon: <QuizezIcon className="tw-h-5" />,
    name: t("trainer.course.lesson.surveies"),
    title: survey.name,
    buttons: [
      {
        label: t("participate"),
        disabled: survey.user_answered || isCourseClosed,
        onClick: () => {
          onOpen();
          history.push({
            pathname: `/survey/${survey.id}/course/${courseId}`,
          });
        },
      },
      {
        label: t("results"),
        disabled: !survey.user_answered || !survey.is_shared,
        onClick: () => {
          history.push(`/survey/${survey.id}/statistics`);
        },
        secondary: true,
      },
    ],
    footer: {
      flags: {
        include_view: survey.include_view_per,
        include_achivement: survey.include_achivement_per,
        is_watched: survey.is_watched,
        is_achieved: survey.is_achieved,
      },
    },
  };
  return <LessonItem {...params} />;
}

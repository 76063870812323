import React from "react";
import classes from "./exhibitionDetails.module.css";
const ExhibitionDetailsPref = ({ title, description }) => {
  return (
    <div className={classes["exhibition-details__pref"]}>
      <h2 className={classes["exhibition-details__pref-title"]}>{title}</h2>
      <div
        className={classes["exhibition-details__pref-desc"]}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
};

export default ExhibitionDetailsPref;

import React from "react";
import { useTranslation } from "react-i18next";
import FilePreview from "../../../Shared/Components/FilePreview/FilePreview";
import VoiceIcon from "../../../../assets/icons/voice.svg";
import classes from "./bootDetails.module.css";

const BootDetailsDocument = ({ document, audio }) => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div className={classes["boot-details__documents"]}>
        <h2 className={classes["boot-details__documents-title"]}>
          {t("booth.document")}
        </h2>
        <div className={classes["boot-details__documents-item"]} key={document}>
          <FilePreview name={""} file={document} />
        </div>
      </div>
      {audio ? (
        <>
          <div className={classes["boot-details__documents"]}>
            <h2 className={classes["boot-details__documents-title"]}>
              {t("booth.audio")}
            </h2>
            <div
              className={classes["boot-details__documents-item"]}
              key={audio}
            >
              <FilePreview
                name={""}
                file={audio}
                icon={VoiceIcon}
                backgroundColor={"#24b3b9"}
                ImgbackgroundColor={"#046c77"}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BootDetailsDocument;

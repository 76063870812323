import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import SearchIcon from "./../../../../assets/icons/search.svg";
import profileAvatar from "./../../../../assets/image/profile-avatar.png";

import { useDispatch, useSelector } from "react-redux";
import { postLivechatSessionAssign } from "../../../../Redux/Actions/livechat/livechat.action";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import classes from "./chatsList.module.css";

function ChatsList({
  chats,
  changeSeen,
  changeAssign,
  fetchMessages,
  setSessionId,
  setIsReply,
  setsessionOwnerId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedID, setSelectedID] = useState("");
  const [tooltipID, setTooltipID] = useState("");
  const [tooltipAssignID, setTooltipAssignID] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isAssignHovered, setIsAssignHovered] = useState(false);
  const [filteredChats, setFilteredChats] = useState(chats);
  const [prevFilter, setPrevFilter] = useState("");

  const handleMouseEnter = (id) => {
    setTooltipID(id);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleAssignMouseEnter = (id) => {
    setTooltipAssignID(id);
    setIsAssignHovered(true);
  };

  const handleAssignMouseLeave = () => {
    setIsAssignHovered(false);
  };

  const handleIsAssignee = (id) => {
    dispatch(postLivechatSessionAssign(id));
    fetchMessages(id);
  };

  const handleFilter = (e) => {
    setPrevFilter(e.target.value);
    const filtered = chats.filter(
      (chat) =>
        chat?.user?.name
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        chat?.user?.email
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        chat?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        chat?.email?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredChats(filtered);
  };

  useEffect(() => {
    setFilteredChats(chats);
    handleFilter({ target: { value: prevFilter } });
  }, [chats]);

  return (
    <div className={classes.containerUsersList}>
      <p className={classes.usersListTitle}>{t("chat.currentChats")}</p>
      <div className={classes.usersSearch}>
        <img src={SearchIcon} alt="" className={classes.searchIcon} />
        <input
          type="text"
          placeholder={t("chat.searchUser")}
          className={classes.searchInput}
          onChange={handleFilter}
        />
      </div>
      {filteredChats &&
        filteredChats.map((chat, index) => (
          <div
            className={`${classes.card} ${
              selectedID == chat.id && classes.selected
            } ${!chat.is_seen && classes.newmsg} `}
            key={index}
            onClick={() => {
              setSelectedID(chat.id);
              changeSeen(chat.id);
              fetchMessages(chat.id);
              setSessionId(chat.id);
              setIsReply(chat.can_reply);
              setsessionOwnerId(chat?.user?.id);
            }}
          >
            <div className={classes.flexContainer}>
              <div className={classes.relativePosition}>
                <div
                  className={classes.iconContainer}
                  onMouseEnter={() => handleMouseEnter(chat.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className={classes.activeIcon}>
                    {chat.user.img ? (
                      <img
                        src={chat.user.img}
                        alt=""
                        className={classes.admin_img}
                      />
                    ) : (
                      <img
                        src={profileAvatar}
                        alt=""
                        className={classes.admin_img}
                      />
                    )}
                  </div>
                  <div
                    className={
                      chat.active
                        ? classes["active-indicator"]
                        : classes["inactive-indicator"]
                    }
                  ></div>
                </div>

                {isHovered && chat.id === tooltipID && (
                  <div className={classes["tooltip"]}>
                    <div className={classes["arrow"]}></div>
                    <div className={classes["hover-content"]}>
                      <div>
                        {chat.name
                          ? chat.name
                          : i18n.language === Lang.AR
                          ? chat.user.name_ar
                          : chat.user.name_en}
                      </div>
                      <div>{chat.email ? chat.email : chat.user.email}</div>
                      {chat.user.phone && <div>{chat.user.phone}</div>}
                      <div>
                        {chat.user.roles
                          ? chat.user.roles.map((role) => (
                              <span className={classes.rolelabel}>
                                {role.label}{" "}
                              </span>
                            ))
                          : t("chat.guest")}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={classes.contentText}>
                <span className={classes.chatName}>
                  {chat.name
                    ? chat.name
                    : i18n.language === Lang.AR
                    ? chat.user.name_ar
                    : chat.user.name_en}
                  <br />
                  <small
                    className={`${classes.email} ${
                      selectedID == chat.id && classes.selectedEmail
                    }`}
                  >
                    {chat.email ? chat.email : chat.user.email}
                  </small>

                  <div className={classes.followUp}>
                    {chat.is_assigned ? (
                      <>
                        <div
                          className={classes.isAssignee}
                          onMouseEnter={() => handleAssignMouseEnter(chat.id)}
                          onMouseLeave={handleAssignMouseLeave}
                        >
                          {t("chat.followedup")}
                        </div>
                      </>
                    ) : (
                      <CustomButton
                        value={t("chat.addToMyChat")}
                        colors={selectedID == chat.id ? "#fff" : "#24b3b9"}
                        type={"button"}
                        variant={"outlined"}
                        action={() => {
                          handleIsAssignee(chat.id);
                          changeAssign(chat.id);
                        }}
                        classes={classes["btn-style"]}
                      />
                    )}
                  </div>
                </span>

                <div className={classes.relativePositionAssign}>
                  {isAssignHovered && chat.id === tooltipAssignID && (
                    <div className={classes["tooltipAssign"]}>
                      <div className={classes["arrowAssign"]}></div>
                      <div className={classes["hover-content-assign"]}>
                        <div>{chat.assignee.name}</div>
                        <div>{chat.assignee.email}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default ChatsList;

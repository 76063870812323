import React, {useState} from "react";
import "react-circular-progressbar/dist/styles.css";
import {Formik} from "formik";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useHistory} from "react-router";

import {AddWebxandTeamsToken} from "../../../Services/api/Zoom/ZoomProvider";
import {NavLink} from "react-router-dom";


export default function AddToken() {

    const { t } = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="faq-add-pg">
            <div className="container-fluid profile_wrapper">
                <div className="admin_label">
                   <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    <NavLink to="/admin/faq-list">{t('zoom.Webx_teams')}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                   {t('zoom.add_token')}
                </div>
            </div>
            <div className="container-fluid profile_wrapper">
                <div className={isLoading ? null : 'table_bank_tran faq_box_white'}>

                    {
                        isLoading ?
                            <div style={{
                                height: "65vh",
                                width: '100%'
                            }}>
                                <SkeletonCard />
                            </div>
                            :
                            <>
                                <div className="box_faq" style={{width: "90%"}}>
                                    <div>
                                        <Formik
                                            initialValues={{
                                                provider: '',
                                                token:'',
                                                refresh_token:""
                                            }}
                                            onSubmit={async (values, { setSubmitting }) => {
                                                try {
                                                    const response = await AddWebxandTeamsToken(values);
                                                    if (response.status === 200 && response.data.status) {

                                                        toast.success(
                                                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                                               {t('zoom.Added_successfully')}
                                                            </span>,
                                                            {
                                                                onClose: () => history.push(`/admin/webx/list`),
                                                                autoClose: 1000
                                                            }
                                                        )
                                                    }
                                                } catch (err) {
                                                    setIsLoading(false);
                                                    toast.error(
                                                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                                            {err.response.data && err.response.data.msg ? err.response.data.msg : "Failure in service"}
                                                        </span>
                                                    );
                                                }
                                            }}
                                            // validateOnChange={}
                                            validate={(values) => {
                                                const errors = {};
                                                if (!values.provider) {
                                                    errors.provider = t("crud.errors.required");
                                                }
                                                if (!values.token) {
                                                    errors.token = t("crud.errors.required");
                                                }
                                                if (!values.refresh_token) {
                                                    errors.token = t("crud.errors.required");
                                                }

                                                return errors;
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                handleChange,
                                                handleSubmit,
                                                isSubmitting,
                                                handleBlur,
                                                setFieldValue,
                                            }) => (
                                                <form onSubmit={handleSubmit} className="admin_add_form">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label htmlFor="name" className="admin_add_label">
                                                                {t('zoom.type')}
                                                            </label>
                                                            <select
                                                                name="provider"
                                                                id="provider"
                                                                className="admin_add_input"
                                                                onChange={handleChange}
                                                            >
                                                                <option value=''> -- </option>
                                                                <option value='webx'> webx </option>
                                                                <option value='teams'> teams</option>
                                                            </select>
                                                            <p className={"form-input-error-space"}>
                                                                {errors.provider ? errors.provider : null}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label htmlFor="name" className="admin_add_label">
                                                                {t('zoom.add_token')}
                                                            </label>
                                                            <input name="token"
                                                                className="admin_add_input"
                                                                onChange={handleChange}/>

                                                            <p className={"form-input-error-space"}>
                                                                {errors.token ? errors.token : null}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label htmlFor="name" className="admin_add_label">
                                                                {t('zoom.add_refreshToken')}
                                                            </label>
                                                            <input name="refresh_token"
                                                                className="admin_add_input"
                                                                onChange={handleChange}/>

                                                            <p className={"form-input-error-space"}>
                                                                {errors.refresh_token ? errors.refresh_token : null}
                                                            </p>
                                                        </div>
                                                       <div className="col-md-12">
                                                            <button
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                                className="admin_add_button btn_zoom"
                                                            >
                                                                {t('zoom.addition')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>

                                    </div>
                                </div>
                            </>

                    }
                </div>
            </div>
        </div>

    )
}

import SearchIcon from "@material-ui/icons/Search";
import { Box } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import { CourseContext } from "../../../Services/api/courses/courseContext";
import { getAllCourses } from "../../../Services/api/courses/courseProvider";
import { DepartmentContext } from "../../../Services/api/toutorials/categoryContext";
import { ClassificationContext } from "../../../Services/api/toutorials/classificationContext";
import { getClassification } from "../../../Services/api/toutorials/classificationProvider";
import { Lang } from "../../../utils";


function MainSearch() {
   require("./mainSearch.css");
  const { t, i18n } = useTranslation();
  const classificationContext = useContext(ClassificationContext);
  const departmentsContext = useContext(DepartmentContext);
  const courseContext = useContext(CourseContext);
  const authContext = useContext(AuthContext);

  const [inputSearchValue, setInputSearchValue] = useState("");


  useEffect(() => {
    getClassification()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          classificationContext.setClassifications(res.data.data);
        }
      })
      .catch((error) => {
        
      });

    if (
      parseInt(classificationContext.allClassifications) == 0 &&
      inputSearchValue == ""
    ) {
      getAllCourses()
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            courseContext.setCoursesSearch(res.data.courses);
          }
        })
        .catch((err) => {
          
          
        });
    }
  }, []);

  const handleChange = (e) => {
    classificationContext.setAllClassifications(e.target.value);
  };

  const handleKeyDown = (event) => {
    const myPromise = new Promise(async (resolve, reject) => {
      if (event.key === "Enter") {

        let inputVal = "";
        if (inputSearchValue !== "") {
          inputVal = inputSearchValue;
        } else {
          inputVal = "";
        }
        getAllCourses({
          classification: parseInt(classificationContext.allClassifications) == 0 ? "" : parseInt(classificationContext.allClassifications),
          search: inputVal,
        })
          .then((res) => {
            
            if (res.status && res.status == 200 && res.data.status) {
              courseContext.setCoursesSearch(res.data.courses);
              courseContext.setCourseFilter(res.data.courses);
            }
            resolve();
          })
          .catch((err) => {
            
            
            resolve();
          });
      } else {
        resolve();
      }
    });

    return myPromise;
  };

  const handleClick = () => {
    const myPromise = new Promise(async (resolve, reject) => {
      let inputVal = "";
      if (inputSearchValue !== "") {
        inputVal = inputSearchValue;
      } else {
        inputVal = "";
      }
      getAllCourses({
        classification: parseInt(classificationContext.allClassifications) == 0 ? "" : parseInt(classificationContext.allClassifications),
        search: inputVal,
      })
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            courseContext.setCoursesSearch(res.data.courses);
            courseContext.setCourseFilter(res.data.courses);
          }
          resolve();
        })
        .catch((err) => {
          
          
          resolve();
        });
    });

    return myPromise;
  };

  const handleSelectDepartmentCourses = (departmentId) => {
    let courseDep = courseContext.coursesSearch.filter((id) => {
      return id.department_id == departmentId;
    });

    let newCourseFilterDep = [];
    courseDep.forEach((item) => {
      newCourseFilterDep.push(item);
    });

    courseContext.setDepartment(departmentId);

    if (departmentId == 0) {
      courseContext.setCourseFilter(courseContext.coursesSearch);
    } else {
      if (courseDep.length == 0) {
        courseContext.setCourseFilter([]);
      } else {
        courseContext.setCourseFilter(newCourseFilterDep);
      }
    }
  };

  const goClassification = (department) => {
    handleSelectDepartmentCourses(department.id);
  };

  return (
    <div className="search_box">
      <div className="container-fluid">
        <Box sx={{
          flexGrow: 1,
          "& .MuiSelect-select": {
            color: "#fff",
          }
        }}>
        <div className="row">
          <div className="col-12">
            <div className="search_box_wrapper">
              <div className="search_box_inner">
                {authContext.auth.token ? (
                  <div className="search_box_desc">
                    {t("hello")}
                    {i18n.language === Lang.AR
                      ? localStorage.getItem("name_ar")
                      : localStorage.getItem("name_en")}
                  </div>
                ) : (
                  <>
                    <div className="search_box_header">
                      {t("HeaderTextOne")}
                    </div>
                    <div className="search_box_desc">{t("HeaderTextTwo")}</div>
                  </>
                )}

                <div className="search_box_search">
                  <Select
                    value={classificationContext.allClassifications}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    className="search_box_select"

                  >
                      <MenuItem className="menuList" value={0}>{t("AllQualifiers")}</MenuItem>
                     {classificationContext.classifications.map((option) => (
                       <MenuItem className="menuList"  key={option.id} value={option.id}>
                            {i18n.language === Lang.AR
                                ? option.name_ar
                                : option.name_en}
                            </MenuItem>
                      ))}
                 </Select>


                  <div className="search_box_input">
                    <SearchIcon className="icon" onClick={handleClick} />
                    <input
                      value={inputSearchValue}
                      onChange={(e) => setInputSearchValue(e.target.value)}
                      type="text"
                      placeholder={t("SearchInput")}
                      onKeyDown={handleKeyDown}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Box>
      </div>
    </div>
  );
}

export default MainSearch;

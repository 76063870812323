import { useTranslation } from "react-i18next";
import HomeIcon from "../../../../assets/icons/home.svg";
import classes from "./departmentsTrainees.module.css";

import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { decrypt } from "../../../../Helpers/Secret";
import { exportTraineesRegList } from "../../../../Services/api/TraineesRegList";
import { getCourseDetails, getProgramDetails } from "../../../../Services/api/courses/courseProvider";
import { getTraineesReg } from "../../../../Services/api/toutorials/assignToCourseProvider";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import AddCourseToTraineeModal from "./AddCourseToTraineeModal";
import DepartmentsTraineesFilter from "./DepartmentsTraineesFilter";
import DepartmentsTraineesTable from "./DepartmentsTraineesTable";
import { filterActions } from "./DepartmentsTraineesTableActions";

const DepartmentsTrainees = ({ isProgram, programCourses, backPath }) => {
  const { t, i18n } = useTranslation();
  const { id, courseId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const roles = JSON.parse(decrypt(localStorage.getItem("roles")) || "[]");
  const role = location?.state?.role
    ? location?.state?.role
    : roles.includes("admin")
    ? "admin"
    : roles.includes("manager")
    ? "manager"
    : roles.includes("trainer")
    ? "trainer"
    : "partner";

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Approved");
  const [showAddCourseModal, setShowAddCourseModal] = useState(false);
  const [courseDetails, setCourseDetails] = useState(null);
  const [trainees, setTrainees] = useState([]);
  const [traineesMeta, setTraineesMeta] = useState({});
  const [loading, setLoading] = useState(false);

  const [filterData, setFilterData] = useState({
    name: "",
    email: "",
    completion_rate: "",
    certificate: "",
    assigned: "",
    enrolment_date: "",
    payment_method: "",
    status: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `${role === "admin" ? "content_development" : "private_profile"}`,
      page: t(`sidebar.label.${role === "admin" ? "content_development" : "private_profile"}`),
      pagePath: role === "admin" ? "#" : "/private-profile",
    },
    {
      id: `${isProgram || programCourses ? "programs" : "courses"}`,
      page: t(`sidebar.label.${isProgram || programCourses ? "program" : "courses"}`),
      pagePath:
        role === "trainer" && programCourses
          ? "#"
          : `/${
              role === "admin"
                ? "admin"
                : role === "manager"
                ? "manager"
                : role === "trainer"
                ? "trainer"
                : role === "partner"
                ? "partner"
                : ""
            }/${
              isProgram || programCourses
                ? backPath === "programs-history"
                  ? "programs-history"
                  : role === "manager" || role === "partner"
                  ? "programs"
                  : "program/list"
                : backPath === "courses-history"
                ? "courses-history"
                : "courses"
            }`,
    },
    ...(programCourses
      ? [
          {
            id: "courses",
            page: t("sidebar.label.courses"),
            pagePath:
              role === "manager" || role === "partner"
                ? backPath === "programs-history"
                  ? `/${role}/programs-history/program/${id}/courses`
                  : `/${role}/program/${id}/courses`
                : role === "trainer"
                ? backPath === "programs-history"
                  ? `/trainer/programs-history`
                  : "/trainer/programs"
                : `/admin/program/${id}/courses`,
          },
        ]
      : []),
    {
      id: `${isProgram ? "program-name" : "course-name"}`,
      page: courseDetails?.name,
      pagePath: "#",
    },
    {
      id: "departments-trainers",
      page: t(`department_trainees.${isProgram ? "program_trainees" : "course_trainees"}`),
      active: true,
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      email: "",
      completion_rate: "",
      certificate: "",
      assigned: "",
      enrolment_date: "",
      payment_method: "",
      status: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.email && (filter.email = filterData.email);
    filterData.completion_rate && (filter.completion_rate = filterData.completion_rate);
    filterData.certificate && (filter.certificate = filterData.certificate);
    filterData.assigned && (filter.assigned = filterData.assigned);
    filterData.enrolment_date && (filter.enrolment_date = filterData.enrolment_date);
    filterData.payment_method && (filter.payment_method = filterData.payment_method);
    filterData.status && (filter.status = filterData.status);

    // filter.approved = 1;
    filter.order_status = activeTab;
    return filter;
  };

  useEffect(() => {
    const getCourseAPI = isProgram ? getProgramDetails : getCourseDetails;
    getCourseAPI(programCourses ? courseId : id)
      .then((res) => {
        setCourseDetails(isProgram ? res.data.program : res.data.course);
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      });
  }, []);

  const getTrainees = () => {
    setLoading(true);
    const filter = getFilterData();
    const type = isProgram ? "program" : "course";
    const chosenId = programCourses ? courseId : id;

    getTraineesReg({
      id: chosenId,
      type,
      page: currentPage,
      perPage: 10,
      filter,
    })
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setTrainees(
            res.data.data.trainees.filter((trainee) => {
              return (
                trainee.enrolments[0]?.payment_id === null ||
                [...trainee.payments, ...trainee.gift_payments].some((payment) => {
                  return payment.status === "Approved";
                })
              );
            })
          );

          setTraineesMeta(res.data.data.meta);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTrainees();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, activeTab]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      filter.approved = 1;
      await exportTraineesRegList(
        filter,
        type,
        programCourses ? courseId : id,
        isProgram ? "program" : "course",
        programCourses
      );
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  return (
    <div className={classes["departments-trainees"]}>
      <div className="container-fluid">
        {loading && <SkeletonCardOverlay skeletonWidth="100" />}
        {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
        <div className={classes["departments-trainees__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t(`department_trainees.${isProgram ? "program_trainees" : "course_trainees"}`)}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    [role !== "partner" && role !== "trainer" && !programCourses ? "addAction" : ""]: () => {
                      setShowAddCourseModal(true);
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <DepartmentsTraineesFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getTrainees}
                    resetFilterHandler={resetFilterHandler}
                    activeTab={activeTab}
                    role={role}
                  />
                )}

                <DepartmentsTraineesTable
                  trainees={trainees}
                  role={role}
                  getTraineesHandler={getTrainees}
                  courseId={programCourses ? courseId : id}
                  courseDetails={courseDetails}
                  programCourses={programCourses}
                  isProgram={isProgram}
                  backPath={backPath}
                />
                <Pagination
                  count={traineesMeta.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
              {showAddCourseModal && (
                <AddCourseToTraineeModal
                  closeModal={setShowAddCourseModal}
                  setLoading={setLoading}
                  getTraineesReg={() => {
                    getTrainees();
                  }}
                  trainees={trainees}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentsTrainees;

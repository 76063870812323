import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useRouteMatch } from "react-router";

function Header(props) {
  require("./header.css");
  const { t } = useTranslation();
  const { id } = useParams();
  const { path } = useRouteMatch();

  return (
    <div className="header_profile">
      <div
        className="container-fluid profile_wrapper"
        style={{
          padding: "0 30px",
        }}
      >
        <div className="row">
          <div className="col-12">
            <div className="show_certificate_grid">
              <div className="profile_dtop">{props.title ? props.title : t("discussionBoard.discussion")}</div>
              {path === "/course/discussion-board/:id" ? (
                props.addBtnDiscussion ? (
                  <div className="sec_edu">
                    <a className="btn btn_edu" href={`/trainer/course/discussion-board/add/${id}`}>
                      {" "}
                      + {t("discussionBoard.addDiscussion")}{" "}
                    </a>
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

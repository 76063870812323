import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BootDetailsCarousel from "../../Admin/Exhibition/BoothDetails/BootDetailsCarousel";
import BootDetailsTable from "../../Admin/Exhibition/BoothDetails/BootDetailsTable";
import ImageContainer from "../../Admin/Exhibition/BoothDetails/ImageContainer";
import "./ReviewAndCreation.css";

export default function ReviewAndCreation({ value, boothSize, boothPosition }) {
  // Function to handle audio play
  const { t, i18n } = useTranslation();

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [boothProducts, setBoothProducts] = useState([]);

  const sortActionHandler = async (columnKey) => {
    if (sortColumn === columnKey) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    }
    setSortColumn(columnKey);
  };

  const handlePageClick = (event) => {
    const toBePagination = {};
    toBePagination.page = event.selected + 1;

    // getRegTrainer(toBePagination);
  };

  useEffect(() => {
    setBoothProducts([
      ...value?.products?.map((product) => {
        return {
          ...product,
          description_html: product.description,
        };
      }),
    ]);
  }, [value?.products]);

  const booth = {
    booth_main_img: value?.booth_main_img?.preview
      ? value.booth_main_img.preview
      : `${process.env.REACT_APP_SERVER_PATH}storage/exhibitions/${value?.booth_main_img}`,
    logo: value?.logo.preview
      ? value.logo.preview
      : `${process.env.REACT_APP_SERVER_PATH}storage/exhibitions/${value?.logo}`,
    name: value?.name,
    size: boothSize,
    position: boothPosition,
  };

  return (
    <>
      <ImageContainer booth={booth} type="sponsop" />

      <div className="col-md-12" style={{ margin: "42px 0" }}>
        <h3 className="booth-desc-head">{t("booth.Description")}</h3>
        <div dangerouslySetInnerHTML={{ __html: value.description }} />
      </div>

      {value?.audio && (
        <div className="exhibition-details__audios-item ">
          <div className="exhibition-details__audios-item-title">{t("Exhibition.main_audio")}</div>
          <audio controls>
            <source
              src={
                value?.audio.preview
                  ? value.audio.preview
                  : `${process.env.REACT_APP_SERVER_PATH}storage/exhibitions/${value?.audio}`
              }
              type="audio/mpeg"
            />
          </audio>
        </div>
      )}

      <BootDetailsCarousel photos={value?.banners} title={t("booth.corner_banners")} type="review" />

      <BootDetailsTable type="review" tableData={boothProducts} />
    </>
  );
}

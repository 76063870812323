import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {getPreferences, setPreferences} from "../../../Services/api/Preferences/PreferencesService";
import {NavLink} from "react-router-dom";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import {Formik} from "formik";
import Select from "react-select";


function SystemPreferences(props) {
    require("./Preferences.css")
    const {t} = useTranslation();
    const [settings, setSettings] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [globalIsSubmitting, setGlobalIsSubmitting] = useState(false)
    const [selectionOptions, setSelectionOptions] = useState([
        {text_ar: "غير فعال", text_en : "Inactive" ,value: 0},
        {text_ar: "فعال", text_en : "Active" ,value: 1}
    ])

    const fetchPreferences = () => {
        const myPromise = new Promise((resolve, reject) => {
            getPreferences().then((res) => {
                if (res.status == 200 || res.status == 201) {
                    setSettings(res.data.setting);
                }
                setIsLoading(false);
                resolve();
            }, error => {
                toast.error(
                    <span style={{fontSize: 13, fontWeight: "bold"}}>
                        {t("failed_fetching")}
                    </span>
                );
                reject();
            })
        })

        return myPromise;
    }

    useEffect(() => {
        fetchPreferences();
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="admin_label">
                            <NavLink to="/admin">
                                {t("Main")}
                            </NavLink>
                            <p style={{padding: "0 5px"}}>/</p>
                            {t("sidebar.label.system_preferences")}
                        </div>
                        {isLoading ?
                            <div style={{
                                height: "65vh"
                            }}>
                                <SkeletonCard/>
                            </div>
                            :
                            <div style={{
                                position: 'relative',
                                backgroundColor: '#FFF',
                                width: '100%',
                                boxShadow: '2px 2px 10px #ddd',
                                borderRadius: 20,
                                padding: 30
                            }}>
                                {
                                    globalIsSubmitting ?
                                        <SkeletonCardOverlay borderRadius={20}/> : null
                                }
                                <Formik
                                    initialValues={{
                                        chat_service: settings.chat_service ? 1 : 0,
                                        support_tickets_service: settings.support_tickets_service ? 1 : 0,
                                        welcome_message_service: settings.welcome_message_service ? 1 : 0,
                                        support_tickets_faq_service: settings.support_tickets_faq_service ? 1 : 0,
                                        courses_faq_service: settings.courses_faq_service ? 1 : 0,
                                    }}
                                    onSubmit={async (values, {setSubmitting}) => {
                                        try {
                                            setGlobalIsSubmitting(true);
                                            const alteringResponse = await setPreferences(values);
                                            if (alteringResponse.status == 200 || alteringResponse.status == 201) {
                                                toast.success(
                                                    <span style={{fontSize: 13, fontWeight: "bold"}}>
                                                        {t("system_preferences.submission.success")}
                                                    </span>
                                                );
                                                window.location.reload();
                                            } else {
                                                throw alteringResponse;
                                            }
                                        } catch (err) {
                                            setGlobalIsSubmitting(false);
                                            toast.error(
                                                <span style={{fontSize: 13, fontWeight: "bold"}}>
                                                    {t("system_preferences.submission.failure")}
                                                </span>
                                            )
                                        }
                                    }}
                                    validateOnChange={false}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue,
                                      }) => (
                                        <form onSubmit={handleSubmit} className="admin_add_form">
                                            <div className={"tajah_system_preference_form_row"}>
                                                <div className={"tajah_system_preference_form_entity"}>
                                                    <label htmlFor="chat_service" className="admin_add_label">
                                                        {t("crud.placeholders.select.chat_service_availability")}
                                                    </label>
                                                    <Select
                                                        name="chat_service"
                                                        id="chat_service"
                                                        options={selectionOptions}
                                                        getOptionLabel={(option) => document.body.dir == 'rtl' ? option.text_ar : option.text_en}
                                                        getOptionValue={(option) => option.value}
                                                        onChange={(item) => {
                                                            setFieldValue('chat_service', item.value)
                                                        }}
                                                        defaultValue={selectionOptions[values.chat_service]}
                                                        placeholder={t("crud.placeholders.select.chat_service_availability")}/>
                                                </div>

                                                <div className={"tajah_system_preference_form_entity"}>
                                                    <label htmlFor="support_tickets_service" className="admin_add_label">
                                                        {t("crud.placeholders.select.support_tickets_service_availability")}
                                                    </label>
                                                    <Select
                                                        name="support_tickets_service"
                                                        id="support_tickets_service"
                                                        options={selectionOptions}
                                                        getOptionLabel={(option) => document.body.dir == 'rtl' ? option.text_ar : option.text_en}
                                                        getOptionValue={(option) => option.value}
                                                        onChange={(item) => {
                                                            setFieldValue('support_tickets_service', item.value)
                                                        }}
                                                        defaultValue={selectionOptions[values.support_tickets_service]}
                                                        placeholder={t("crud.placeholders.select.support_tickets_service_availability")}/>
                                                </div>
                                            </div>

                                            <div className={"tajah_system_preference_form_row"}>
                                                <div className={"tajah_system_preference_form_entity"}>
                                                    <label htmlFor="welcome_message_service" className="admin_add_label">
                                                        {t("crud.placeholders.select.welcome_message_service_availability")}
                                                    </label>
                                                    <Select
                                                        name="welcome_message_service"
                                                        id="welcome_message_service"
                                                        options={selectionOptions}
                                                        getOptionLabel={(option) => document.body.dir == 'rtl' ? option.text_ar : option.text_en}
                                                        getOptionValue={(option) => option.value}
                                                        onChange={(item) => {
                                                            setFieldValue('welcome_message_service', item.value)
                                                        }}
                                                        defaultValue={selectionOptions[values.welcome_message_service]}
                                                        placeholder={t("crud.placeholders.select.welcome_message_service_availability")}/>
                                                </div>

                                                <div className={"tajah_system_preference_form_entity"}>
                                                    <label htmlFor="support_tickets_faq_service" className="admin_add_label">
                                                        {t("crud.placeholders.select.support_tickets_faq_service_availability")}
                                                    </label>
                                                    <Select
                                                        name="support_tickets_faq_service"
                                                        id="support_tickets_faq_service"
                                                        options={selectionOptions}
                                                        getOptionLabel={(option) => document.body.dir == 'rtl' ? option.text_ar : option.text_en}
                                                        getOptionValue={(option) => option.value}
                                                        onChange={(item) => {
                                                            setFieldValue('support_tickets_faq_service', item.value)
                                                        }}
                                                        defaultValue={selectionOptions[values.support_tickets_faq_service]}
                                                        placeholder={t("crud.placeholders.select.support_tickets_faq_service_availability")}/>
                                                </div>
                                            </div>

                                            <div className={"tajah_system_preference_form_row"}>
                                                <div className={"tajah_system_preference_form_entity"}>
                                                    <label htmlFor="courses_faq_service" className="admin_add_label">
                                                        {t("crud.placeholders.select.courses_faq_service_availability")}
                                                    </label>
                                                    <Select
                                                        name="courses_faq_service"
                                                        id="courses_faq_service"
                                                        options={selectionOptions}
                                                        getOptionLabel={(option) => document.body.dir == 'rtl' ? option.text_ar : option.text_en}
                                                        getOptionValue={(option) => option.value}
                                                        onChange={(item) => {
                                                            setFieldValue('courses_faq_service', item.value)
                                                        }}
                                                        defaultValue={selectionOptions[values.courses_faq_service]}
                                                        placeholder={t("crud.placeholders.select.courses_faq_service_availability")}/>
                                                </div>
                                            </div>

                                            <div className={"tajah_system_preference_form_submission_button"}>
                                                <button
                                                    type="submit"
                                                    className="admin_add_button"
                                                    disabled={isSubmitting}
                                                >
                                                    {t("crud.placeholders.submit")}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SystemPreferences;

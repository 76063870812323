import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import i18n from "../../../../i18n/i18n";
import en from "../../../../assets/image/united-kingdom.png";
import ar from "../../../../assets/image/saudi-arabia.png";
import DropDown from "../dorpdown/Dropdown";
import { useHistory } from "react-router-dom";
function DynamicHeaderWithLogin() {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  document.body.dir = i18n.dir();

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };

  const languageOptions = [
    {
      label: "English",
      onClick: () => changeLanguage("en"),
    },
    {
      label: "العربية",
      onClick: () => changeLanguage("ar"),
    },
  ];

  const loginOptions = [
    {
      label: t("Login"),
      onClick: () => history.push("/login"),
    },
    {
      label: t("JoinUs"),
      onClick: () => history.push("/register"),
    },
  ];

  let buttonLang = <div onClick={() => changeLanguage("ar")}>AR</div>;
  if (document.body.dir === "rtl") {
    buttonLang = (
      <div className="lang_style">
        <DropDown options={languageOptions} isLanguage={true} />
      </div>
    );
  } else {
    buttonLang = (
      <div className="lang_style">
        <DropDown options={languageOptions} isLanguage={true} />
      </div>
    );
  }
  const loginNav = (
    <>
      {/* <NavLink className="header_btn_color header_nav_link" to="/register">
        <span className="header_btn">{t("JoinUs")}</span>
      </NavLink>

      <NavLink
        className="header_btn_style header_nav_link header_text"
        to="/login"
      >
        {t("Login")}
      </NavLink> */}
      <div className="">
        <DropDown options={loginOptions} isLogin={true} />
      </div>
    </>
  );

  return (
    <div className="header_dinamic">
      {buttonLang}
      {loginNav}
    </div>
  );
}

export default DynamicHeaderWithLogin;

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Lang } from "../../../utils";
import i18n from "../../../i18n/i18n";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import classes from "./trainerDetailsHeader.module.css";
import Title from "../../Shared/Components/Title/Title";
import couicon from "../../../assets/icons/digital-book.png";
import studentsicon from "../../../assets/icons/white-stu.png";
import StarsRating from "../../Shared/Components/StarsRating/StarsRating";

function TrainersDetailsHeader({ trainerDetails, loading }) {
  require("./trainerDetailsHeader.css");
  const { t } = useTranslation();

  const [types, setTypes] = useState([]);

  useEffect(() => {
    setTypes([
      {
        id: 1,
        name_ar: "مساق",
        name_en: "Courses",
        color: "black",
        number: trainerDetails && trainerDetails.countCourses,
        icon: couicon,
      },
      {
        id: 2,
        name_ar: "متدرب",
        name_en: "Trainees",
        color: "black",
        number: trainerDetails && trainerDetails.countUsers,
        icon: studentsicon,
      },
    ]);
  }, [trainerDetails, loading]);

  return (
    <div className={classes.headercontainer}>
      <div className="container-fluid trainers_wrapper">
        <div className={`row ${classes.rowflex}`}>
          {loading ? (
            <div className={classes["loading-container"]}>
              <div className={classes["loading-w-h"]}>
                <SkeletonSpinnerOverlay
                  backdrop={true}
                  skeletonWidth="100"
                  skeletonHight="100"
                />
              </div>
              <div className={classes["loading-w"]}>
                <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
                <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
              </div>
            </div>
          ) : (
            <div className={classes.headercontent}>
              <img
                src={trainerDetails && trainerDetails.img}
                alt="..."
                className={classes.image}
              />
              <div className={classes["header-container"]}>
                <div className={classes.mb40}>
                  <div className={classes.title}>
                    <Title
                      title={
                        i18n.language === Lang.AR
                          ? trainerDetails && trainerDetails.name_ar
                          : trainerDetails && trainerDetails.name_en
                      }
                      titleColor="#fff"
                      fontSize={24}
                    />

                    {trainerDetails.rating !== 0 && (
                      <div className={classes["flip-star"]}>
                        <StarsRating
                          rating={trainerDetails.rating || 0}
                          cutStyle={classes["star-font-size"]}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.mt10}>
                    <Title
                      title={
                        i18n.language === Lang.AR
                          ? trainerDetails && trainerDetails.job_title_ar
                          : trainerDetails && trainerDetails.job_title_en
                      }
                      titleColor="#fff"
                      fontSize={16}
                    />
                  </div>
                </div>
                <div className={classes["type-container"]}>
                  {types.map((type, index) => (
                    <div className={classes.type} key={type.id}>
                      <img
                        src={type.icon}
                        alt=""
                        style={{
                          width: "22px",
                        }}
                      />
                      <span className={classes["d-flex"]}>
                        {type.number}{" "}
                        {i18n.language === Lang.AR
                          ? type && type.name_ar
                          : type && type.name_en}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TrainersDetailsHeader;

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import IconTooltips from "../../../../Components/tooltips/IconsTooltip";
import { decrypt } from "../../../../Helpers/Secret";
import SponsorModal from "../../../../Modals/Sponsor/Sponsor";
import { getSponsors } from "../../../../Services/api/Financial/FinancialProvider";
import { Lang } from "../../../../utils";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";

function Sponsor() {
  require("./Sponsor.css");
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [sponsor, setSponsor] = useState([]);
  const [id, setIds] = useState(null);
  const modalRef = useRef();

  const fetchList = () => {
    setIsLoading(true);
    const myPromise = new Promise((resolve, reject) => {
      getSponsors()
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setSponsor(res.data.data);
            setIsLoading(false);
            resolve();
          }
        })
        .catch((error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.response.data.msg}</span>);
          reject();
        });
    });

    return myPromise;
  };

  useEffect(() => {
    fetchList();
  }, []);

  const modalHandler = () => {
    fetchList();
    modalRef.current.dismissModal();
  };

  const sponsorTrainee = (payload) => {
    setIds(payload);
    setTimeout(() => {
      modalRef.current.showModal();
    }, 200);
  };

  return (
    <>
      <BasicModal ref={modalRef}>
        <SponsorModal title={t("sponsor.traineeName")} id={id} refuse={modalHandler} modalRef={modalRef} />
      </BasicModal>
      <div
        className="container-fluid"
        style={{
          marginBottom: "100px",
        }}
      >
        <div className="admin_flex">
          <div className="admin_label">
            <NavLink to={`${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}`}>
              {t("admin.label.admin_label")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("sponsor.sponsor")}
          </div>
          <div className="admin_button_flex">
            <NavLink
              to={`${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/add/sponsor`}
              className="text-white admin_button_green"
              style={{ padding: "10px" }}
            >
              <i className="fas fa-plus"></i>
              <span>{t("sponsor.addSponsor")}</span>
            </NavLink>
          </div>
        </div>
        {isLoading == false ? (
          <>
            <div className={`table_bank_tran`} style={{ marginBottom: "100px" }}>
              <h3 className="table_header">
                <div>{t("sponsor.sponsor")}</div>
              </h3>
              <div
                className="table-responsive"
                style={{
                  width: "90%",
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{t("sponsor.name")}</th>
                      <th scope="col">{t("sponsor.type")}</th>
                      <th scope="col">{t("sponsor.course")}</th>
                      <th scope="col">{t("sponsor.date")}</th>
                      <th scope="col">{t("sponsor.countTrainee")}</th>
                      <th scope="col">{t("sponsor.traineeName")}</th>
                      <th scope="col">{t("sponsor.total")}</th>
                      <th scope="col">{t("sponsor.dis")}</th>
                      <th scope="col">{t("sponsor.totalAfterOffer")}</th>
                      <th scope="col">{t("sponsor.addNewTrainee")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sponsor && sponsor.length == 0 ? (
                      <tr>
                        <td colSpan={15}>{t("no_record")}</td>
                      </tr>
                    ) : (
                      sponsor.map((spon, index) => {
                        return (
                          <tr key={spon.id}>
                            <td scope="col">{index + 1}</td>
                            <td scope="col">{i18n.language === Lang.AR ? spon.name_ar : spon.name_en}</td>
                            <td scope="col">{spon.course ? t("sponsor.typeCourse") : t("sponsor.typeProgram")}</td>
                            <td scope="col">{spon.course ? spon.course.name : spon.program.title}</td>
                            <td scope="col">{spon.accreditation_at.split(" ")[0]}</td>
                            <td scope="col">{spon.actual_trainee_count}</td>
                            <td scope="col">
                              <div className="release_icon">
                                <IconTooltips
                                  title={t("modal.coupon.buttons.data")}
                                  content={
                                    <i
                                      className="fal fa-eye"
                                      onClick={() => sponsorTrainee(spon.id)}
                                      style={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                        backgroundColor: "white",
                                        borderWidth: 0,
                                        fontSize: 20,
                                        color: "black",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  }
                                />
                              </div>
                            </td>
                            <td scope="col">
                              {spon.course == null
                                ? spon.program.price * spon.actual_trainee_count
                                : spon.course.price * spon.actual_trainee_count}{" "}
                              {t("Rial")}
                            </td>
                            <td scope="col">{spon.offer}%</td>
                            <td scope="col">
                              {/* {
                                spon.course == null ?
                                spon.program.price?
                                    spon.program.price * spon.actual_trainee_count - (
                                    spon.program.price *
                                    spon.actual_trainee_count *
                                    (spon.offer / 100)) : spon.program.price * spon.actual_trainee_count -
                                    (spon.program.price *
                                    spon.actual_trainee_count *
                                    (spon.offer / 100))
                                    : null
                                } */}
                              {spon.course == null
                                ? spon.program.price * spon.actual_trainee_count -
                                  (spon.program.price * spon.actual_trainee_count * spon.offer) / 100
                                : spon.course.price * spon.actual_trainee_count -
                                  (spon.course.price * spon.actual_trainee_count * spon.offer) / 100}{" "}
                              {t("Rial")}
                            </td>
                            <td>
                              <div className="release_icon">
                                <IconTooltips
                                  title={t("sponsor.addT")}
                                  content={
                                    <i
                                      className="fal fa-plus"
                                      onClick={() =>
                                        history.push(
                                          `${
                                            decrypt(localStorage.getItem("type")) == "accountant"
                                              ? `/accountant/add/sponsor/${spon.id}`
                                              : `/admin/add/sponsor/${spon.id}`
                                          }`
                                        )
                                      }
                                      style={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                        backgroundColor: "white",
                                        borderWidth: 0,
                                        fontSize: 20,
                                        color: "darkgreen",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "50vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default Sponsor;

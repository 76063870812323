import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./cookiesModal.module.css";

const CookiesModal = ({ handleClose }) => {
  const { t } = useTranslation();

  const handleAction = (action) => {
    if (action === "accept") {
      localStorage.setItem("cookies", "accepted");
    } else {
      localStorage.setItem("cookies", "rejected");
    }
    handleClose();
  };

  return (
    <div className={classes["modal"]}>
      <div className={classes["modal__container"]}>
        <div className={classes["modal__header"]}>
          <h2>{t("cookies.title")}</h2>
        </div>
        <div className={classes["modal__content"]}>
          <p>{t("cookies.content")}</p>
        </div>
        <div className={classes["modal__buttons"]}>
          <button className={classes["modal__buttons__accept"]} onClick={() => handleAction("accept")}>
            {t("cookies.accept")}
          </button>
          <button className={classes["modal__buttons__reject"]} onClick={() => handleAction("reject")}>
            {t("cookies.reject")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesModal;

import FilterIcon from "../../../assets/icons/filter.svg";
import PdfIcon from "../../../assets/icons/pdf.svg";
import XlsIcon from "../../../assets/icons/xls.svg";

export const filterActions = ({ exportPdf, exportExcel, showFilter }) => {
  const actionsList = [
    ...(showFilter
      ? [
          {
            id: "filter",
            icon: <img src={FilterIcon} alt="" />,
            action: showFilter,
          },
        ]
      : []),
    ...(exportPdf
      ? [
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            action: exportPdf,
          },
        ]
      : []),
    ...(exportExcel
      ? [
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            action: exportExcel,
          },
        ]
      : []),
  ];
  return actionsList;
};

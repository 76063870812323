import { Formik } from "formik";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "suneditor/dist/css/suneditor.min.css";
import { paymentAction } from "../../../Services/api/toutorials/PaymentsProvaider";
import exit from "../../../assets/icons/remove-red.svg";
import ApproveIcon from "../../../assets/image/user-check.svg";
import RejectIcon from "../../../assets/image/user-x.svg";
import { utcToLocal } from "../../../utils/utcToLocal";
import BankModal from "../../Payments/BankModal";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";
import Tabs from "../../Shared/Components/Tabs/Tabs";
import classes from "./bankTransfers.module.css";

const BankTransfersTable = ({
  activeTab,
  setActiveTab,
  setFilterData,
  bankTransfers,
  getBankTransfers,
  setLoading,
  isLoading,
}) => {
  const { t, i18n } = useTranslation();
  // const approvePaymentModalRef = useRef(null);
  // const rejectPaymentModalRef = useRef(null);
  // const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false);
  // const [showRejectPaymentModal, setShowRejectPaymentModal] = useState(false);
  const paymentActionModalRef = useRef(null);
  const bankModalRef = useRef(null);
  const [showPaymentActionModal, setShowPaymentActionModal] = useState(false);
  const [paymentActionData, setPaymentActionData] = useState(null);
  console.log("paymentActionData :", paymentActionData);
  const [showBankModal, setShowBankModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const paymentsActionsList = [
    {
      id: "approve",
      icon: <img src={ApproveIcon} alt="" />,
      action: (payment) => {
        openPaymentActionModal(payment, "Approved");
      },
      tooltip: t("general.approve"),
    },
    {
      id: "reject",
      icon: <img src={RejectIcon} alt="" />,
      action: (payment) => {
        openPaymentActionModal(payment, "Rejected");
      },
      tooltip: t("general.reject"),
    },
  ];

  const openPaymentActionModal = (payment, status) => {
    setShowPaymentActionModal(true);
    setPaymentActionData({ payment, id: payment.id, status });
    paymentActionModalRef.current.showModal();
  };

  const closePaymentActionModal = () => {
    paymentActionModalRef.current.dismissModal();
    setShowPaymentActionModal(false);
    setPaymentActionData(null);
  };

  const openBankModalHandler = (payment) => {
    setShowBankModal(true);
    bankModalRef.current.showModal();
    setPaymentData(payment);
  };

  const closeBankModalHandler = () => {
    setShowBankModal(false);
    bankModalRef.current.dismissModal();
    setPaymentData(null);
  };

  const paymentDisplay = {
    Bank: t("general.bank"),
    Wallet: t("general.wallet"),
    tebX: t("general.tebX"),
  };

  const displayGift = (is_gift) => {
    if (is_gift) {
      return t("yes");
    }
    return t("no");
  };

  const tabs = [
    {
      id: "waiting_requests",
      key: 1,
      label: t("financial.payments_request.waiting_req"),
    },

    {
      id: "requests_approved",
      key: 2,
      label: t("financial.payments_request.approved_req"),
    },
    {
      id: "requests_rejected",
      key: 3,
      label: t("financial.payments_request.rejected_req"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "photo",
      name: t("general.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["departments-trainees__table-photo"]}>
          {rowData?.user?.img ? (
            <img src={rowData?.user?.img} alt="" />
          ) : (
            <i className="fa fa-user" aria-hidden="true"></i>
          )}
        </div>
      ),
    },
    {
      id: "name",
      name: t("user_name"),
      key: "name",
      cell: ({ rowData }) => <>{i18n.language === "ar" ? rowData?.user?.name_ar : rowData?.user?.name_en || "-"}</>,
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => (
        <a className={classes["departments-trainees__table-email"]} href={`mailto: ${rowData?.user?.email}`}>
          {rowData?.user?.email || "-"}
        </a>
      ),
    },
    {
      id: "roles",
      name: t("roles"),
      key: "roles",
      cell: ({ rowData }) => <>{rowData?.user?.roles?.map((role) => role.label).join(" | ") || "-"}</>,
    },
    {
      id: "transaction_type",
      name: t("wallet.label.WDtransactiontype"),
      key: "transaction_type",
      cell: ({ rowData }) => <div>{rowData?.transaction_type || "-"}</div>,
      sortable: false,
    },
    {
      id: "payment_type",
      name: t("payments.payable_type"),
      key: "payment_type",
      cell: ({ rowData }) => <>{rowData.payable_target || "-"}</>,
    },
    {
      id: "payment_method",
      name: t("payments.payment_method"),
      key: "payment_method",
      cell: ({ rowData }) => (
        <div className={classes["payment-method"]}>
          {rowData.payment_method === "Bank" && Object.keys(rowData.wallet_transaction).length === 0 ? (
            <button
              className={classes["payment-method--bank"]}
              onClick={() => {
                openBankModalHandler(rowData);
              }}
            >
              {t("general.bank")}
            </button>
          ) : (
            <p
              style={{
                margin: 0,
              }}
            >
              {Object.keys(rowData.wallet_transaction).length > 0
                ? paymentDisplay[rowData.wallet_transaction.transfer_type] || "-"
                : paymentDisplay[rowData.payment_method] || "-"}
            </p>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      id: "payable_name",
      name: t("payments.payable_name"),
      key: "payable_name",
      cell: ({ rowData }) => (
        <>
          {rowData?.coupon_courses ? (
            Array.isArray(rowData?.coupon_courses) ? (
              <p>{rowData?.coupon_courses?.map((course) => course.name).join(" | ")}</p>
            ) : (
              <p>{rowData?.coupon_courses?.name || "-"}</p>
            )
          ) : null}
          <p>{rowData?.payable_name || "-"}</p>
        </>
      ),
    },
    {
      id: "payment_courses",
      name: t("payments.payment_courses"),
      key: "payment_courses",
      cell: ({ rowData }) =>
        rowData?.covered_courses?.length > 0 ? (
          <p>{rowData?.covered_courses?.map((course) => course.name).join(" | ")}</p>
        ) : (
          <p>-</p>
        ),
    },
    {
      id: "gift",
      name: t("gift"),
      key: "gift",
      cell: ({ rowData }) => <>{displayGift(rowData?.is_gift) || "-"}</>,
    },
    {
      id: "origianl_price",
      name: t("payments.original_price"),
      key: "origianl_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.origianl_price)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "discount_price",
      name: t("payments.discount_price"),
      key: "discount_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.offer_amount)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "coupon_price",
      name: t("payments.coupon_price"),
      key: "coupon_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.coupon_amount)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "coupon_include_tax",
      name: t("payments.coupon_include_tax"),
      key: "coupon_include_tax",
      cell: ({ rowData }) => (
        <>{rowData?.payable_include_tax && rowData?.payable_include_tax !== "0" ? t("yes") : t("no")}</>
      ),
    },
    {
      id: "net_price",
      name: t("payments.net_price"),
      key: "net_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.net_ammount)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "tax_price",
      name: t("payments.tax_price"),
      key: "tax_price",
      cell: ({ rowData }) => (
        <div className={classes["price-minus"]}>{`${Number(rowData?.tax_amount)} ${t("rs")}` || "-"}</div>
      ),
    },
    {
      id: "payment_price",
      name: t("payments.payment_price"),
      key: "payment_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>{`${Number(rowData?.paid_amount)} ${t("rs")}` || "-"}</div>
      ),
    },
    ...(activeTab === 2 || activeTab === 3
      ? [
          {
            id: "processed_by",
            name: t("general.processed_by"),
            key: "processed_by",
            cell: ({ rowData }) => (
              <>
                {rowData?.processor
                  ? i18n.language === "ar"
                    ? rowData.processor.name_ar
                    : rowData.processor.name_en
                  : "-"}
              </>
            ),
          },
        ]
      : []),
    ...(activeTab === 3
      ? [
          {
            id: "rejection_reason",
            name: t("financial.payments_request.rejected_reason"),
            key: "rejection_reason",
            cell: ({ rowData }) => (
              <div
                className={classes["rejection-reason"]}
                dangerouslySetInnerHTML={{
                  __html: rowData?.rejection_reason || "-",
                }}
              ></div>
            ),
          },
        ]
      : []),
    ...(activeTab === 2
      ? [
          {
            id: "accept_note",
            name: t("financial.payments_request.accept_note"),
            key: "accept_note",
            cell: ({ rowData }) => (
              <div
                className={classes["rejection-reason"]}
                dangerouslySetInnerHTML={{
                  __html: rowData?.accept_reason || "-",
                }}
              ></div>
            ),
          },
        ]
      : []),
    {
      id: "transfer_date",
      name: t("payments.transfer_date"),
      key: "transfer_date",
      cell: ({ rowData }) =>
        rowData?.transfer_date ? <>{moment(rowData?.transfer_date).format("DD/MM/YYYY")}</> : "-",
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) =>
        rowData?.created_at ? (
          <>
            {moment(rowData?.created_at).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.created_at)}
          </>
        ) : (
          "-"
        ),
    },
    ...(activeTab === 2 || activeTab === 3
      ? [
          {
            id: "processed_at",
            name: t("general.processed_at"),
            key: "processed_at",
            cell: ({ rowData }) =>
              rowData?.processed_at ? (
                <>
                  {moment(rowData?.processed_at).format("DD/MM/YYYY")}
                  <br />
                  {utcToLocal(rowData?.processed_at)}
                </>
              ) : (
                "-"
              ),
          },
        ]
      : []),
    ...(activeTab === 1
      ? [
          {
            id: "actions",
            name: t("general.actions"),
            key: "actions",
            cell: ({ rowData }) => <TableActionsColumn actions={paymentsActionsList} id={rowData} />,
          },
        ]
      : []),
  ];

  return (
    <div className={classes["departments-trainees__tables"]}>
      <Tabs
        tabs={tabs}
        disabled={isLoading}
        activeTab={activeTab}
        onSelectTab={(key) => {
          setFilterData({
            payer_name: "",
            payer_email: "",
            payable_type: "",
            role: "",
            payable_name: "",
            payable_serial_number: "",
            payable_code: "",
          });
          setActiveTab(key);
        }}
      />
      <MainTable columns={columns} data={bankTransfers || []} isLoading={isLoading} />
      <BasicModal ref={paymentActionModalRef}>
        {showPaymentActionModal && (
          <MainBox className="p-0 rounded">
            <div className={classes["payment-action-modal"]}>
              <div className={classes["payment-action-modal__header"]}>
                <h3>{t(`financial.payments_request.${paymentActionData.status.toLowerCase()}_title`)}</h3>
                <img src={exit} alt="" onClick={closePaymentActionModal} />
              </div>
              <div className={classes["payment-action-modal__body"]}>
                <Formik
                  initialValues={{
                    note: "",
                  }}
                  onSubmit={(values) => {
                    setLoading(true);
                    const data = {
                      id: paymentActionData.id,
                      status: paymentActionData.status,
                      reason: values.note,
                    };
                    paymentAction(data)
                      .then((res) => {
                        toast.success(res.data.msg);
                        getBankTransfers();
                      })
                      .catch((err) => {
                        toast.error(
                          <>
                            <span
                              style={{
                                fontSize: 13,
                                fontWeight: "bold",
                                display: "block",
                                paddingBottom: "10px",
                              }}
                            >
                              {err.response.data.msg}
                            </span>
                            <ul>
                              {Object.keys(err.response.data.errors).map((key) => {
                                return (
                                  <li>
                                    <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        );
                      })
                      .finally(() => {
                        closePaymentActionModal();
                        setLoading(false);
                      });
                  }}
                  validate={(values) => {
                    const errors = {};
                    // if (!values.note) {
                    //   errors.note = t("crud.errors.required");
                    // }
                    return errors;
                  }}
                  validateOnChange={true}
                >
                  {({ values, handleChange, handleSubmit, setFieldValue, errors }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="note">
                          {t(`financial.payments_request.${paymentActionData.status.toLowerCase()}_note`)}
                        </label>
                        <textarea
                          id="note"
                          name="note"
                          style={{
                            border: "1px solid rgb(201, 200, 200)",
                            display: "block",
                            width: "500px",
                            borderRadius: "6px",
                            padding: "15px",
                          }}
                          maxLength={3000}
                          value={values.note}
                          onChange={handleChange}
                          placeholder={t(`financial.payments_request.${paymentActionData.status.toLowerCase()}_note`)}
                          defaultValue={values.note}
                        />
                        <p className={"form-input-error-space"}>{errors.note ? errors.note : ""}</p>
                      </div>
                      {paymentActionData?.payment?.is_gift &&
                        paymentActionData?.payment?.payable_type === "program_payment" && (
                          <div className="tw-pb-4 tw-text-sm">
                            {t(
                              `financial.payments_request.gift_program_payment_${paymentActionData.status.toLowerCase()}`
                            )}
                          </div>
                        )}
                      <div className={classes["payment-action-modal__submit"]}>
                        <CustomButton
                          value={t("submit")}
                          type="submit"
                          action={handleSubmit}
                          colors="#036c77"
                          disabled={false}
                          classes={classes["payment-action-modal__submit-btn"]}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </MainBox>
        )}
      </BasicModal>
      <BasicModal ref={bankModalRef}>
        {showBankModal && <BankModal closeBankModalHandler={closeBankModalHandler} paymentData={paymentData} />}
      </BasicModal>
    </div>
  );
};

export default BankTransfersTable;

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import SurveysModal from "../../../../Modals/surveyModal/SurveysModal";
import {
  fetchCourseLessonDetails,
  fetchCourseSubChapterDetails,
} from "../../../../Redux/Actions/courses/coursesList.action";
import { getSurveysForLessonOrCourse } from "../../../../Services/api/survey/SurveyProvider";
import LoadingSpinner from "../../../Shared/Components/LoadingSpinner/LoadingSpinner";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CourseContentLessonsDetails from "./CourseContentLessonsDetails";
import CourseContentLessonsSyllabus from "./CourseContentLessonsSyllabus";
import classes from "./trainingCourseContentLessons.module.css";

export default function TrainingCourseContentLessons() {
  const { t } = useTranslation();
  const { id, chapterId, supChapterId } = useParams();
  const { subChapterDetails, subChapterDetailsLoading, lessonDetails, lessonDetailsLoading } = useSelector(
    (state) => state.coursesListReducer
  );
  const surveysModalRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedLesson, setSelectedLesson] = useState(0);
  const [survies, setSurvies] = useState([]);
  const [isCourseClosed, setIsCourseClosed] = useState(true);

  useEffect(() => {
    dispatch(fetchCourseSubChapterDetails(supChapterId));
  }, []);

  useEffect(() => {
    if (subChapterDetails && subChapterDetails?.lessons?.length > 0 && subChapterDetails.id === Number(supChapterId)) {
      setSelectedLesson(subChapterDetails?.lessons[0]?.id);
      setIsCourseClosed(
        subChapterDetails?.lessons[0]?.status === "Closed" || subChapterDetails?.lessons[0]?.status === "مغلقة"
      );
    }
  }, [subChapterDetails]);

  useEffect(() => {
    if (selectedLesson && subChapterDetails?.lessons?.length > 0) {
      dispatch(fetchCourseLessonDetails(selectedLesson || subChapterDetails?.lessons[0]?.id));
    }
  }, [selectedLesson]);

  const getSurveysForLesson = () => {
    setSurvies([]);
    getSurveysForLessonOrCourse(null, selectedLesson)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setSurvies(res.data.surveys);
        }
      })
      .catch((err) => {
        
        
      });
  };

  const assignmentAction = (quizList) => {
    history.push({
      pathname: `/trainees/course/${id}/lesson/${selectedLesson}/exams`,
      state: {
        quizes: quizList,
        supChapterId: supChapterId,
        chapterId: chapterId,
      },
    });
  };

  const surveyAction = () => {
    getSurveysForLesson(selectedLesson);
    surveysModalRef.current.showModal();
  };

  return (
    <div className="container-fluid" style={{ marginTop: "2rem" }}>
      <BasicModal ref={surveysModalRef}>
        <SurveysModal
          label={t("survey.surviesLabel")}
          title={t("survey.surviesLabel")}
          body={survies}
          courseId={id}
          lessonId={selectedLesson}
          surveysModalRef={surveysModalRef}
          accept={() => surveysModalRef.current.dismissModal()}
          isClosed={isCourseClosed}
        />
      </BasicModal>
      <div className="row">
        {subChapterDetailsLoading ? (
          <SkeletonCardOverlay />
        ) : (
          <MainBox className="border-8" style={{ width: "100%", minHeight: "100vh" }}>
            <div className={classes["training-course-content"]}>
              <CourseContentLessonsSyllabus
                subChapter={subChapterDetails}
                setSelectedLesson={setSelectedLesson}
                selectedLesson={selectedLesson}
              />
              {lessonDetailsLoading ? (
                <div className={classes["loading__container"]}>
                  <LoadingSpinner />
                </div>
              ) : (
                <CourseContentLessonsDetails
                  subChapterTitle={subChapterDetails?.lessons?.find((item) => item.id == selectedLesson)?.title}
                  lessonDetails={lessonDetails}
                  lesson={subChapterDetails?.lessons?.find(
                    (item) => item.id == selectedLesson
                  )}
                  refresh={() =>
                    dispatch(fetchCourseLessonDetails(selectedLesson || subChapterDetails?.lessons[0]?.id))
                  }
                  assignmentAction={assignmentAction}
                  surveyAction={
                    subChapterDetails?.lessons?.find(
                      (item) => item.id == selectedLesson
                    )?.available_surveys_count
                      ? surveyAction
                      : null
                  }
                  courseId={id}
                  chapterId={chapterId}
                  supChapterId={supChapterId}
                  lessonId={selectedLesson}
                  isClosed={isCourseClosed}
                />
              )}
            </div>
          </MainBox>
        )}
      </div>
    </div>
  );
}

import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { programsPayments } from "./programContext";

const ProgramContext = createContext();

function ProgramProvider(props) {
    const {id} = useParams()
    const [programs, setPrograms] = useState([]);
    const [programsPayment, setProgramsPayment] = useState([])
    const [courses, setCourses] = useState([]);
    const [isContentLoaded, setIsContentLoaded] = useState(true);

    useEffect(() => {
        if(id)
        programsPayments(id)
            .then((res) => {
                if (res.status && res.status == 200 && res.data.status) {
                    setProgramsPayment(res.data.payments);
                    setIsContentLoaded(false);
                }
            })
            .catch((err) => {
                
                
            });
    }, []);

    return (
        <ProgramContext.Provider
            value={{
                programs,
                setPrograms,
                isContentLoaded,
                setIsContentLoaded,
                courses,
                setCourses,
                programsPayment,
                setProgramsPayment
            }}
        >
            {props.children}
        </ProgramContext.Provider>
    );
}

export { ProgramContext, ProgramProvider };


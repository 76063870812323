import { useTheme } from "@material-ui/core/styles";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import { decrypt } from "../../../Helpers/Secret";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import BurgerMenuIcon from "../../../assets/icons/BurgerMenu.svg";
import HeaderLogo from "../../../assets/icons/newHeader2024.svg";
import i18n from "../../../i18n/i18n";
import UserDropDown from "../../Shared/Components/Header/DynamicHeaderWithoutLogin/UserDropDwon/UserDropDown";
import DropDown from "../../Shared/Components/dorpdown/Dropdown";
import Sidebar from "../sidebar/sidebar";
import Notification from "./Notification";
import classes from "./adminHeader.module.css";
function AdminHeader(props) {
  const history = useHistory();
  require("./adminHeader.css");
  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  const theme = useTheme();
  const type_login = localStorage.getItem("type_login");
  const authContext = useContext(AuthContext);
  const [sidebar, setSidebar] = useState(false);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenChat");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("type");
    localStorage.removeItem("name_ar");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("phone");
    localStorage.removeItem("type");
    localStorage.removeItem("completed_profile");
    localStorage.removeItem("type_login");
    localStorage.removeItem("userId");
    localStorage.removeItem("chatId");
    localStorage.removeItem("chat_username");
    localStorage.removeItem("chat_email");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
  };

  const sidebarHandler = (event) => {
    event.preventDefault();
    setSidebar(!sidebar);
  };

  const [addAnimationSide, setAddAnimationSide] = useState(false);

  const onConfirm = () => {
    setAddAnimationSide(true);
    setTimeout(() => {
      setSidebar(false);
    }, 300);
  };

  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [sidebar]);

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };
  const languageOptions = [
    {
      label: "English",
      onClick: () => changeLanguage("en"),
    },
    {
      label: "العربية",
      onClick: () => changeLanguage("ar"),
    },
  ];
  let buttonLang = <div onClick={() => changeLanguage("ar")}>AR</div>;
  if (document.body.dir === "rtl") {
    buttonLang = (
      <div className="lang_style">
        <DropDown options={languageOptions} isLanguage={true} />
      </div>
    );
  } else {
    buttonLang = (
      <div className="lang_style">
        <DropDown options={languageOptions} isLanguage={true} />
      </div>
    );
  }

  const userOptions = [
    ...(authContext.roles.includes("admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/admin"),
          },
        ]
      : authContext.roles.includes("accountant")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/accountant"),
          },
        ]
      : []),

    {
      label: t("edit_personal_profile"),
      onClick: () => history.push("/edit-profile"),
    },
    {
      label: t("account_info"),
      onClick: () => history.push("/private-profile"),
    },
    {
      label: t("calendar"),
      onClick: () => history.push("/calendar"),
    },
    {
      label: t("admin.label.list_tickets"),
      onClick: () =>
        history.push(`${decrypt(localStorage.getItem("type")) === "admin" ? "/admin/tickets" : "/tickets"}`),
    },

    {
      label: t("sidebar.label.live_chat"),
      onClick: () => history.push("/livechat"),
    },

    ...(type_login === "normal"
      ? [
          {
            label: t("accountde"),
            onClick: () => history.push("/update-password"),
          },
        ]
      : []),

    {
      label: t("logout"),
      onClick: logout,
    },
  ];

  return (
    <div className={classes["header_shadow"]}>
      {sidebar === true ? (
        <>
          <Sidebar
            addAnimationSide={addAnimationSide}
            setAddAnimationSide={setAddAnimationSide}
            dismissSideBar={onConfirm}
            roles={props.roles}
          />
          <div className={classes.backdrop_admin} onClick={onConfirm} />
        </>
      ) : null}

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <nav className={classes.adminNav}>
              <div className={classes.navbar_logo_admin}>
                <div className={classes["hamburger-pointer"]} onClick={sidebarHandler}>
                  <img
                    src={BurgerMenuIcon}
                    alt=""
                    style={{
                      transform: `${i18n.language === "ar" && "rotateY(180deg)"}`,
                      width: 30,
                      minWidth: 30,
                      height: 40,
                      minHeight: 40,
                    }}
                  />
                </div>
                <NavLink exact to="/">
                  <img src={HeaderLogo} className={classes.image_logo_new} alt="..." />
                </NavLink>
              </div>

              <div className={classes.flex_menu}>
                <Notification isAdmin={true} />
                {buttonLang}
                <UserDropDown options={userOptions} />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AdminHeader);

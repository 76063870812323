import { refundssListConstants } from "../../Constants/refundSetting/refundSetting.constants";

const initialState = {
  refundsList: [],
  refundsListMeta: {},
  refundsListLoading: false,
  refundsListError: null,
  refundsListSuccess: false,

  addRefundStatusLoading: false,
  addRefundStatusError: null,
  addRefundStatusSuccess: false,
  addRefundStatusResponse: {},

  editRefundStatusLoading: false,
  editRefundStatusError: null,
  editRefundStatusSuccess: false,
  editRefundStatusResponse: {},

  updateRefundStatusLoading: false,
  updateRefundStatusError: null,
  updateRefundStatusSuccess: false,

  deleteRefundLoading: false,
  deleteRefundError: null,
  deleteRefundSuccess: false,

  statusRefundLoading: false,
  statusRefundError: null,
  statusRefundSuccess: false,
  statusResponse: {},

  get_refund_by_id: {},
};

const refundsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case refundssListConstants.GET_REFUNDS_LIST_REQUEST:
      return {
        ...state,
        refundsListLoading: true,
      };
    case refundssListConstants.GET_REFUNDS_LIST_SUCCESS:
      return {
        ...state,
        refundsListLoading: false,
        refundsListSuccess: true,
        refundsList: action.payload.refunds.data,
        refundsListMeta: action.payload.refunds.meta,
      };
    case refundssListConstants.GET_REFUNDS_LIST_REJECTED:
      return {
        ...state,
        refundsListLoading: false,
        refundsListError: action.payload,
        refundsListSuccess: false,
      };

    // ADD REFUND
    case refundssListConstants.ADD_REFUND_REQUEST:
      return {
        ...state,
        addRefundLoading: true,
      };

    case refundssListConstants.ADD_REFUND_SUCCESS:
      return {
        ...state,
        addRefundLoading: false,
        addRefundSuccess: true,
        addRefundError: null,

        addRefundStatusResponse: action.payload,
      };

    case refundssListConstants.ADD_REFUND_REJECTED:
      return {
        ...state,
        addRefundLoading: false,
        addRefundError: action.payload,
        addRefundSuccess: false,
      };

    // EDIT REFUND
    case refundssListConstants.EDIT_REFUND_REQUEST:
      return {
        ...state,
        editRefundLoading: true,
      };

    case refundssListConstants.EDIT_REFUND_SUCCESS:
      return {
        ...state,
        editRefundLoading: false,
        editRefundSuccess: true,
        editRefundError: null,

        editRefundStatusResponse: action.payload,
      };

    case refundssListConstants.EDIT_REFUND_REJECTED:
      return {
        ...state,
        editRefundLoading: false,
        editRefundError: action.payload,
        editRefundSuccess: false,

        editRefundStatusResponse: {},
      };

    // DELETE REFUND
    case refundssListConstants.DELETE_REFUND_REQUEST:
      return {
        ...state,
        deleteRefundLoading: true,
      };

    case refundssListConstants.DELETE_REFUND_SUCCESS:
      return {
        ...state,
        deleteRefundLoading: false,
        deleteRefundSuccess: true,
        deleteRefundError: null,

        refundsList: state.refundsList.filter(
          (refund) => refund.id !== action.payload.id
        ),
      };

    case refundssListConstants.DELETE_REFUND_REJECTED:
      return {
        ...state,
        deleteRefundLoading: false,
        deleteRefundError: action.payload,
        deleteRefundSuccess: false,
      };

    // UPDATE REFUND STATUS
    case refundssListConstants.UPDATE_REFUND_STATUS_REQUEST:
      return {
        ...state,
        updateRefundStatusLoading: true,
      };

    case refundssListConstants.UPDATE_REFUND_STATUS_SUCCESS:
      return {
        ...state,
        updateRefundStatusLoading: false,
        updateRefundStatusSuccess: true,
        updateRefundStatusError: null,

        refundsList: state.refundsList.map((refund) => {
          if (refund.id === action.payload.id) {
            return {
              ...refund,
              status: !refund.status,
            };
          }
          return refund;
        }),
      };

    case refundssListConstants.UPDATE_REFUND_STATUS_REJECTED:
      return {
        ...state,
        updateRefundStatusLoading: false,
        updateRefundStatusError: action.payload,
        updateRefundStatusSuccess: false,
      };

    case refundssListConstants.GET_REFUND_BY_ID:
      return {
        ...state,
        get_refund_by_id: action.payload,
      };

    case refundssListConstants.GET_REFUNDS_RESET:
      return {
        ...state,
        addRefundStatusResponse: {},
        editRefundStatusResponse: {},
        get_refund_by_id: {},
        statusResponse: {},
      };

    default:
      return state;
  }
};

export default refundsListReducer;

import CircularProgress from "@mui/material/CircularProgress";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { addAttachementFileToQuestion } from "../../../../../../../../../Services/api/exams/ExamsProvider";

function DragAndDropProposedAnswersItem(props) {

    const inputFile = useRef(null);
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <tr>
                <td>{props.name}</td>
                <td>
                    {
                        isLoading == true ?
                            <CircularProgress /> :
                        !isLoading && props.image == null ?
                            <i
                                onClick={() => {
                                    if (inputFile) {
                                        inputFile.current.click();
                                    }
                                }}
                                style={{
                                    fontSize: 20,
                                    color:'black',

                                }} className="fal fa-edit">
                            </i>
                            : <img src={props.image} width="50" height="50" />
                    }
                    <input
                        id="file"
                        name="file"
                        type="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        onChange={(event) => {
                            props.setSubmitting(true);
                            setIsLoading(true);
                            let file = event.target.files[0];
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                                let formData = new FormData();
                                formData.append('file', event.target.files[0]);
                                addAttachementFileToQuestion(formData).then(async (res) => {
                                    
                                    props.setImage(res.data.response.link, props.index);
                                    props.setSubmitting(false);
                                    setIsLoading(false);
                                }).catch((err) => {
                                    props.setSubmitting(false);
                                    setIsLoading(false);
                                    toast.error(
                                        <span style={{fontSize: 13, fontWeight: "bold"}}>
                                            {t("trainer.quiz.alerts.cannot_add_image")}
                                        </span>
                                    );
                                });
                            };
                            reader.onerror = function (error) {
                                
                            };
                        }}
                    />
                </td>
                <td>
                    <i
                        onClick={() => {
                            props.deleteItem(props.index);
                        }}
                        title={"Delete"}
                        style={{
                            fontSize: 20,
                            color:'red',

                        }} className="fal fa-trash"></i>
                </td>
            </tr>
        </>
    )
}

export default DragAndDropProposedAnswersItem;
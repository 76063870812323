import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
	edittermsAndConditionsAdmin,
	termsAndConditions,
} from "../../../Services/api/toutorials/termsAndConditionsProvider";
import { SunEditorText } from "../../Shared/Components";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";

function TermsAndConditions(props) {
	require("./termsAndConditions.css");
	const { t } = useTranslation();
	const history = useHistory();

	const [termsConditionsAr, setTermsConditionAr] = useState("");
	const [termsConditionEn, setTermsConditionEn] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		termsAndConditions()
			.then((res) => {
				if (res.status && res.status == 200 && res.data.status) {
					setTermsConditionAr(res.data.terms.content_ar);
					setTermsConditionEn(res.data.terms.content_en);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				
				
			});
	}, []);

	const editTermsAndConditions = () => {
		setIsLoading(true);
		const dataValue = {
			content_ar: termsConditionsAr,
			content_en: termsConditionEn,
		};

		edittermsAndConditionsAdmin(dataValue)
			.then((res) => {
				if (res.status && res.status == 200 && res.data.status) {
					history.push("/admin");
					setIsLoading(false);
				}
			})
			.catch((err) => {
				
				
				setIsLoading(false);
				toast.error(
					<span style={{ fontSize: 13, fontWeight: "bold" }}>
						{typeof err === "string" ? (
							<span
								style={{ fontSize: 12, fontWeight: "500", display: "block" }}
							>
								{err}
							</span>
						) : err.data ? (
							<>
								<span
									style={{ fontSize: 13, fontWeight: "bold", display: "block" }}
								>
									{err.data.msg}
								</span>
								<ul>
									{Object.keys(err.data.errors).map((key) => {
										return (
											<li>
												<span style={{ fontSize: 11 }}>
													{err.data.errors[key]}
												</span>
											</li>
										);
									})}
								</ul>
							</>
						) : (
							t("Failure_in_service")
						)}
					</span>
				);
			});
	};
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<div className="admin_label">
						<NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
						<p style={{ padding: "0 5px" }}>/</p>
						{t("sidebar.label.TermsAndConditions")}
					</div>
					<div className="admin_card">
						<>
							{isLoading ? (
								<div
									style={{
										height: "34.5vh",
									}}
								>
									<SkeletonCard />
								</div>
							) : (
								<Stack
									sx={{
										width: "90%",
										mt: "32px",
										mb: "12px",
										"& .se-tooltip": {
											gap: "10px",
										},
									}}
								>
									<label htmlFor="">{t("TermsAndConditionsAr")}:</label>
									<SunEditorText
										value={termsConditionsAr}
										setValue={setTermsConditionAr}
										dirEditor={"rtl"}
									/>
								</Stack>
							)}

							{isLoading ? (
								<div
									className="my-5"
									style={{
										height: "34.5vh",
									}}
								>
									<SkeletonCard />
								</div>
							) : (
								<Stack
									sx={{
										width: "90%",
										mt: "32px",
										mb: "12px",
										"& .se-tooltip": {
											gap: "10px",
										},
									}}
								>
									<label
										htmlFor=""
										style={{
											marginTop: "20px",
										}}
									>
										{t("TermsAndConditionsEn")}:
									</label>
									<SunEditorText
										value={termsConditionEn}
										setValue={setTermsConditionEn}
										dirEditor={"ltr"}
									/>
								</Stack>
							)}
						</>

						{isLoading ? (
							<div
								style={{
									height: "5vh",
									width: "200px",
								}}
							>
								<SkeletonCard />
							</div>
						) : (
							<div className="overview_button_div" style={{ width: "90%" }}>
								<button
									className={"overview_button"}
									onClick={(e) => {
										e.stopPropagation();
										editTermsAndConditions();
									}}
								>
									{t("SaveTermsAndConditions")}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TermsAndConditions;

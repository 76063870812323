import { combineReducers } from "redux";
import boothReducer from "./Booth/booth.reducer";
import partnersListReducer from "./PartnersTebx/partnerTebx.reducer";
import activityCommentsListReducer from "./activityComment/activityComent.reducer";
import blockReducer from "./block/block.reducer";
import certificatesTemplateReducer from "./certificates/certificates-template.reducer";
import traineeCertificatesReducer from "./certificates/trainee-certificates.reducer";
import couponsListReducer from "./coupons/couponsList.reducer";
import coursesListReducer from "./courses/coursesList.reducer";
import departmentListReducer from "./departments/departmentList.reducer";
import educationalGoalsReducer from "./educationalGoals/educationalGoals.reducer";
import exhibitionPaymentReducer from "./exhibition/exhibition-payment.reducer";
import exhibitionReducer from "./exhibition/exhibition.reducer";
import faqReducer from "./faq/faq.reducer";
import faqSupportTicketsReducer from "./faq/faqSupportTickets.reducer";
import fundReducer from "./fund/fund.reducer";
import livechatReducer from "./livechat/livechat.reducer";
import offerReducer from "./offer/offer.reducer";
import paymentReducer from "./payment/payment.reducer";
import percentageTrainersListReducer from "./percentageTrainersList/percentageTrainersList.reducer";
import peymentRequestsReducer from "./peymentRequest/peymentRequest.reducer";
import peymentRequestsAdminReducer from "./peymentRequestAdmin/peymentRequestAdmin.reducer";
import programPaymentListReducer from "./programPaymentList/programPaymentList.reducer";
import refundsListReducer from "./refundSetting/refundSetting.reducer";
import searchReducer from "./search.reducer";
import specialtiesDetailsReducer from "./specialties/specialtiesDetails.reducer";
import specialtiesListReducer from "./specialties/specialtiesList.reducer";
import supportTicketsReducer from "./supportTickets/supportTickets.reducer";
import systemUsersListReducer from "./systemUsers/systemUsersList";
import taxsListReducer from "./tax/taxList.reducer";
import trainerProfileReducer from "./trainerProfile/trainerProfile.reducer";
import userWalletReducer from "./wallet/wallet.reducer";
import advertisementsListReducer from "./Advertisements/advertisements.reducer";
const rootReducer = combineReducers({
  boothReducer,
  exhibitionReducer,
  exhibitionPaymentReducer,
  paymentReducer,
  faqReducer,
  blockReducer,
  faqSupportTicketsReducer,
  livechatReducer,
  systemUsersListReducer,
  educationalGoalsReducer,
  coursesListReducer,
  specialtiesListReducer,
  departmentListReducer,
  trainerProfileReducer,
  specialtiesDetailsReducer,
  supportTicketsReducer,
  offerReducer,
  couponsListReducer,
  taxsListReducer,
  refundsListReducer,
  searchReducer,
  peymentRequestsReducer,
  peymentRequestsAdminReducer,
  userWalletReducer,
  activityCommentsListReducer,
  partnersListReducer,
  programPaymentListReducer,
  percentageTrainersListReducer,
  fundReducer,
  certificatesTemplateReducer,
  traineeCertificatesReducer,
  advertisementsListReducer,
});

export default rootReducer;

import React from "react";
import PricePreviewBox from "../../../Shared/Components/PricePreviewBox/PricePreviewBox";
import classes from "./exhibitionDetails.module.css";
const ExhibitionDetailsPrices = ({ prices }) => {
  return (
    <div className={classes["exhibition-details__prices"]}>
      {!!prices.length &&
        prices.map((item, index) => (
          <div className={classes["exhibition-details__prices-item"]} key={index}>
            <PricePreviewBox
              key={index}
              label={item.label}
              price={item.price}
              backgroundColor={item.backgroundColor}
              type={item.type}
            />
          </div>
        ))}
    </div>
  );
};

export default ExhibitionDetailsPrices;

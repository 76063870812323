import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";

export const filterActions = ({ showFilter, exportPdf, exportExcel, addAction, addUsersByExcel }) => {
  const actionsList = [
    ...(addUsersByExcel
      ? [
          {
            id: "addUsersByExcel",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #8599B6",
              borderRadius: 6,
              width: i18n.language === "ar" ? 275 : 175,
              height: 50,
            },
            label: i18n.language === "ar" ? "إضافة مستخدمين بواسطة ملف اكسل" : "Add users by excel",
            btnTextColor: "#8599B6",
            action: addUsersByExcel,
          },
        ]
      : []),
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    {
      id: "add",
      icon: <img src={AddIcon} alt="" />,
      theme: "add",
      action: addAction,
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};

import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getEventPerMonth } from "../../Services/api/calendar/calendarProvider";
import LoadingSpinner from "../Shared/Components/LoadingSpinner/LoadingSpinner";
import MainBox from "../Shared/Components/MainBox/MainBox";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";

import EventCard from "./EventCard";

import "./Calendar.css";
import classes from "./Calender.module.css";

export default function CalendarPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const currentDate = moment().format("YYYY-MM-DD");
  const [events, setEvents] = useState([]);

  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [trainerCourses, setTrainerCourses] = useState([]);
  const [trainerPrograms, setTrainerPrograms] = useState([]);
  const [quizes, setQuizes] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const [discussions, setDiscussions] = useState([]);
  const [meetings, setMeetings] = useState([]);

  //   const [eventsOfDay, setEventsOfDay] = useState([]);
  const [value, setValue] = useState(currentDate);
  const [isLoading, setIsLoading] = useState(true);
  const [isEventLoading, setIsEventLoading] = useState(true);
  const [endOfMonth, setEndOfMonth] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [startOfMonth, setStartOfMonth] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const getEventsOfMonth = (start, end) => {
    // setIsLoading(true);
    getEventPerMonth(start, end)
      .then((res) => {
        if (res.status === 200) {
          setEvents([
            ...res.data.data.surveys,
            ...res.data.data.quizes,
            ...res.data.data.trainerPrograms,
            ...res.data.data.trainerCourses,
            ...res.data.data.programs,
            ...res.data.data.courses,
            ...res.data.data.exhibitions,
            ...res.data.data.discussions,
            ...res.data.data.meetings,
          ]);
        }
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setIsLoading(false);
      });
  };
  const getEventsOfDay = (date) => {
    setIsEventLoading(true);
    getEventPerMonth(date, date)
      .then((res) => {
        if (res.status === 200) {
          //    setEventsOfDay(res.data.data);
          setSurveys(res.data.data.surveys);
          setQuizes(res.data.data.quizes);
          setTrainerPrograms(res.data.data.trainerPrograms);
          setTrainerCourses(res.data.data.trainerCourses);
          setPrograms(res.data.data.programs);
          setCourses(res.data.data.courses);
          setExhibitions(res.data.data.exhibitions);
          setDiscussions(res.data.data.discussions);
          setMeetings(res.data.data.meetings);
          setIsEventLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsEventLoading(false);
      });
  };
  useEffect(() => {
    getEventsOfDay(moment().format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    getEventsOfMonth(startOfMonth, endOfMonth);
  }, [startOfMonth, endOfMonth]);

  const handleChange = (value) => {
    setValue(value);

    getEventsOfDay(moment(value).format("YYYY-MM-DD"));
    // console.log(value);
  };
  const handleNextPrev = (value) => {
    // console.log(value);
    setStartOfMonth(moment(value.activeStartDate).format("YYYY-MM-DD"));
    setEndOfMonth(
      moment(value.activeStartDate).endOf("month").format("YYYY-MM-DD")
    );
  };
  return (
    <div className="container-fluid">
      <div className="row" style={{ margin: "40px 10px" }}>
        <div
          style={{
            display: "flex",
            gap: "15px",
            flex: "2 1 auto",
            alignItems: "center",
          }}
        >
          <p className={classes["title"]}>{t("calendar")}</p>
        </div>
      </div>
      <MainBox style={{ paddingTop: "3rem" }}>
        {isEventLoading ? (
          <div className={classes["loading__container"]}>
            <SkeletonCardOverlay skeletonWidth="100" />
          </div>
        ) : (
          <>
            <div className={classes.calenderCont}>
              <Calendar
                calendarType="islamic"
                value={value}
                minDetail="year"
                onActiveStartDateChange={(value) => handleNextPrev(value)}
                onChange={(newValue) => handleChange(newValue)}
                tileClassName={({ date, view }) => {
                  if (
                    events.find(
                      (x) =>
                        moment(x.start_date).format("DD-MM-YYYY") ===
                          moment(date).format("DD-MM-YYYY") ||
                        moment(x.end_date).format("DD-MM-YYYY") ===
                          moment(date).format("DD-MM-YYYY")
                    )
                  ) {
                    return `eventDay`;
                  }
                }}
              />
            </div>
            {isEventLoading ? (
              <div className={classes["loading__container"]}>
                <LoadingSpinner />
              </div>
            ) : (
              <div className={classes["event__container"]}>
                {courses.map((item) => (
                  <EventCard
                    name={item.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    img={item.img}
                    tag={t("course")}
                    clickedDate={value}
                    startMsg={t("start_course")}
                    endMsg={t("end_course")}
                    startEndMsg={t("start_end_course")}
                  />
                ))}
                {programs.map((item) => (
                  <EventCard
                    name={item.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    img={item.img}
                    tag={t("program_1")}
                    clickedDate={value}
                    startMsg={t("start_program")}
                    endMsg={t("end_program")}
                    startEndMsg={t("start_end_program")}
                  />
                ))}
                {trainerCourses.map((item) => (
                  <EventCard
                    name={item.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    img={item.img}
                    tag={t("course")}
                    clickedDate={value}
                    startMsg={t("start_course")}
                    endMsg={t("end_course")}
                    startEndMsg={t("start_end_course")}
                  />
                ))}
                {trainerPrograms.map((item) => (
                  <EventCard
                    name={item.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    img={item.img}
                    tag={t("program_1")}
                    clickedDate={value}
                    startMsg={t("start_program")}
                    endMsg={t("end_program")}
                    startEndMsg={t("start_end_program")}
                  />
                ))}
                {quizes.map((item) => (
                  <EventCard
                    name={item.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    img={item?.img}
                    tag={t("quizes")}
                    clickedDate={value}
                    course_name={item.course_name}
                    startMsg={t("start_quiz_cal")}
                    endMsg={t("end_quiz_cal")}
                    startEndMsg={t("start_end_quiz")}
                  />
                ))}
                {surveys.map((item) => (
                  <EventCard
                    name={item.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    img={item?.img}
                    course_name={item.course_name}
                    tag={t("surveys")}
                    clickedDate={value}
                    startMsg={t("start_survey_cal")}
                    endMsg={t("end_survey_cal")}
                    startEndMsg={t("start_end_survey")}
                  />
                ))}
                {meetings.map((item) => (
                  <EventCard
                    name={item.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    img={item?.img}
                    course_name={item.course_name}
                    duration={item.duration}
                    tag={t("meetings")}
                    clickedDate={value}
                    startMsg={t("start_meetings")}
                    endMsg={t("end_meetings")}
                    startEndMsg={t("start_end_meeting")}
                  />
                ))}
                {discussions.map((item) => (
                  <EventCard
                    name={item.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    img={item?.img}
                    course_name={item.course_name}
                    tag={t("discussions")}
                    clickedDate={value}
                    startMsg={t("start_discussions")}
                    endMsg={t("end_discussions")}
                    startEndMsg={t("start_end_discussion")}
                  />
                ))}
                {exhibitions.map((item) => (
                  <EventCard
                    name={item.name}
                    startDate={item.start_date}
                    endDate={item.end_date}
                    img={item?.img}
                    tag={t("exhibition")}
                    clickedDate={value}
                    startMsg={t("start_exhibition")}
                    endMsg={t("end_exhibition")}
                    startEndMsg={t("start_end_exhibition")}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </MainBox>
    </div>
  );
}

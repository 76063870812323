import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import HeaderLogo from "../../../../assets/icons/newHeader2024.svg";
import DynamicHeaderWithLogin from "./DynamicHeaderWithLogin";
import DynamicHeaderWithOutLogin from "./DynamicHeaderWithoutLogin/DynamicHeaderWithOutLogin";
// import HeaderLogo from "../../../../assets/image/Header.png";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import BurgerMenuIcon from "../../../../assets/icons/BurgerMenu.svg";
import classes from "./Links.module.css";
import MainDrawer from "./MainDrawer/MainDrawer";
import SearchInput from "./SearchInput";

function Links(props) {
  require("./header.css");
  const { t, i18n } = useTranslation();
  const type_login = localStorage.getItem("type_login");
  const { livechat_setting } = useSelector((state) => state.livechatReducer);
  const authContext = useContext(AuthContext);
  document.body.dir = i18n.dir();
  const [chatAllowed, setChatAllowed] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [addAnimationSide, setAddAnimationSide] = useState(false);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);
  const location = useLocation();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenChat");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("name_er");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("type");
    localStorage.removeItem("phone");
    localStorage.removeItem("completed_profile");
    localStorage.removeItem("type_login");
    localStorage.removeItem("userId");
    localStorage.removeItem("chatId");
    localStorage.removeItem("chat_username");
    localStorage.removeItem("chat_email");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
  };

  const toggleDrawerHandler = (event) => {
    event.preventDefault();
    setShowDrawer((prev) => !prev);
  };

  const onConfirm = () => {
    setAddAnimationSide(true);
    setTimeout(() => {
      setShowDrawer(false);
    }, 300);
  };

  useEffect(() => {
    if (showDrawer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showDrawer]);

  useEffect(() => {
    if (livechat_setting.status === 200) {
      setChatAllowed(livechat_setting.data.setting.chat_allowed);
    }
  }, [livechat_setting]);

  return (
    <div className={classes["header_shadow"]}>
      {showDrawer && (
        <>
          <MainDrawer
            addAnimationSide={addAnimationSide}
            setAddAnimationSide={setAddAnimationSide}
            dismissSideBar={onConfirm}
            roles={authContext.roles}
            chatAllowed={props.chatAllowed}
          />
          <div className={classes.backdrop_main} onClick={onConfirm} />
        </>
      )}
      <div className={`container-fluid`}>
        <div className="row">
          <>
            {location.pathname === "/" && width <= 1050 && (
              <div className={classes.imageContMobile}>
                <NavLink exact to="/">
                  <img src={HeaderLogo} className={classes.image_mobile} alt="..." />
                </NavLink>
              </div>
            )}
          </>
        </div>
        <div className="row">
          <div className="col-12">
            <nav className={classes.mainNav}>
              <div className={classes.navbar_logo_main}>
                {width <= 1200 && (
                  <div className={classes["hamburger-pointer"]} onClick={toggleDrawerHandler}>
                    <img
                      src={BurgerMenuIcon}
                      alt=""
                      style={{
                        transform: `${i18n.language === "ar" && "rotateY(180deg)"}`,
                        width: 30,
                        minWidth: 30,
                        height: 40,
                        minHeight: 40,
                      }}
                    />
                  </div>
                )}
                {width > 1050 && (
                  <NavLink exact to="/">
                    <img src={HeaderLogo} className={classes.image_logo_new} alt="..." />
                  </NavLink>
                )}
              </div>
              <div className={classes.flex_menu}>
                {location.pathname === "/" && width > 710 && (
                  <>
                    <SearchInput />
                  </>
                )}
                {!authContext.auth.token ? (
                  <DynamicHeaderWithLogin />
                ) : (
                  <DynamicHeaderWithOutLogin NotAdmin={true} chatAllowed={props.chatAllowed} />
                )}
              </div>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {location.pathname === "/" && width <= 710 && (
              <div
                style={{
                  width: "100%",
                  marginBottom: "15px",
                  marginTop: "0px",
                }}
              >
                <SearchInput />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Links;

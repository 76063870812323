import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import LeftArrowIcon from "../../../../assets/icons/arrow-left.svg";
import RightArrowIcon from "../../../../assets/icons/arrow-right.svg";
import classes from "./counterNumbers.module.css";

function CounterNumbers({ link, image, number, label }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const linkHandler = () => {
    if (!link) return;
    if (link == "/admin/bank-transfers" || link == "/accountant/bank-transfers") {
      history.push({
        pathname: `${link}`,
        state: {
          status: 3,
        },
      });
    } else if (link == "/admin/tickets" || link == "/accountant/tickets") {
      history.push({
        pathname: `${link}`,
        state: {
          status: "closed",
        },
      });
    } else if (link == "/admin/roles/trainer" || link == "/admin/roles/trainer") {
      history.push({
        pathname: `/admin/roles`,
        state: {
          status: "trainer",
        },
      });
    } else if (link == "/admin/roles/trainee" || link == "/admin/roles/trainee") {
      history.push({
        pathname: `/admin/roles`,
        state: {
          status: "trainee",
        },
      });
    } else {
      history.push(`${link}`);
    }
  };

  return (
    <div className={`${classes["card-counter"]} ${link ? classes["link-cursor"] : ""}`} onClick={linkHandler}>
      <div className={classes["card-top"]}>
        <div className={classes["card-image"]}>
          <img src={image} alt="" />
        </div>
        <div className={classes["card-number"]}>{number}</div>
      </div>
      <div className={classes["card-bottom"]}>
        <div className={classes["card-label"]}>{label}</div>
        {link ? (
          <div className={classes["card-link"]}>
            {t("more")}
            <div className={classes["arrow"]}>
              <img src={i18n.language === "en" ? RightArrowIcon : LeftArrowIcon} alt="" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CounterNumbers;

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CourseContext } from "../../../../../Services/api/courses/courseContext";
import SkeletonSpinnerOverlay from "../../../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import classes from "./trainingCourseContent.module.css";

export default function CourseVideos() {
  const { t } = useTranslation();
  const courseDetailsContext = useContext(CourseContext);

  const extractVideoKey = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  };

  return courseDetailsContext.courseDetails.video ? (
    courseDetailsContext.isLoading ? (
      <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" skeletonHight="50" />
    ) : (
      <div>
        <div className={classes["training_youtube_title_container"]}>
          <h2 className={classes["training_youtube_title"]}>{t("introductoryvideocourse")}</h2>
        </div>
        <div
          className="embed-responsive embed-responsive-16by9"
          style={{
            width: "100%",
            margin: "0 auto",
          }}
        >
          <iframe
            title="Video"
            className="embed-responsive-item"
            rel="0"
            src={`https://www.youtube.com/embed/${extractVideoKey(courseDetailsContext.courseDetails.video)}?rel=0`}
          />
        </div>
      </div>
    )
  ) : null;
}

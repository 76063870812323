import React from "react";
import { useTranslation } from "react-i18next";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";

const ProgramPaymentsReportFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  resetFilterHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  programPaymentsStatus,
}) => {
  const { t } = useTranslation();

  const yesNoOptions = [
    { value: "1", label: t("yes") },
    { value: "0", label: t("no") },
  ];

  const filterFields = [
    {
      id: "trainee_search",
      component: "input",
      label: t("payments.trainee_search"),
      type: "text",
      placeholder: t("payments.trainee_search"),
      name: "trainee_search",
      value: filterData.trainee_search,
      classes: "col-12 col-md-12 col-xl-6",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "payment_status",
      component: "select",
      label: t("payments.payment_status"),
      placeholder: t("payments.payment_status"),
      initialValue: t("payments.payment_status"),
      name: "payment_status",
      value: filterData.payment_status,
      options: [
        ...programPaymentsStatus.map((status) => ({
          value: status.value,
          label: status.text,
        })),
      ],
      classes: "col-12 col-md-6 col-xl-3",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "is_paid",
      component: "select",
      label: t("payments.is_paid"),
      placeholder: t("payments.is_paid"),
      initialValue: t("payments.is_paid"),
      name: "is_paid",
      value: filterData.is_paid,
      options: yesNoOptions,
      classes: "col-12 col-md-6 col-xl-3",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "is_payment_due",
      component: "select",
      label: t("payments.is_payment_due"),
      placeholder: t("payments.is_payment_due"),
      initialValue: t("payments.is_payment_due"),
      name: "is_payment_due",
      value: filterData.is_payment_due,
      options: yesNoOptions,
      classes: "col-12 col-md-6 col-xl-3",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "is_payment_overdue",
      component: "select",
      label: t("payments.is_payment_overdue"),
      placeholder: t("payments.is_payment_overdue"),
      initialValue: t("payments.is_payment_overdue"),
      name: "is_payment_overdue",
      value: filterData.is_payment_overdue,
      options: yesNoOptions,
      classes: "col-12 col-md-6 col-xl-3",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default ProgramPaymentsReportFilter;

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import clearEmptyProp from "../../../../../Helpers/ClearEmptyProp";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";

import { Pagination } from "@mui/material";
import moment from "moment";
import { IconsTooltip } from "../../../../../Components";
import toLocalTimezone from "../../../../../Services/Timezone";
import {
  exportTicketTypesList,
  ticketTypesDelete,
  ticketTypesList,
  toggleTicketTypeAccessibility,
} from "../../../../../Services/api/Tickets/TicketsProvider";
import deleteIcon from "../../../../../assets/icons/delete.svg";
import FilterIcon from "../../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../../assets/icons/home.svg";
import PdfIcon from "../../../../../assets/icons/pdf.svg";
import editExhIcon from "../../../../../assets/icons/pen.svg";
import AddIcon from "../../../../../assets/icons/plus.svg";
import { ReactComponent as UserGroupIcon } from "../../../../../assets/icons/user-group.svg";
import DeleteIcon from "../../../../../assets/icons/warning.svg";
import XlsIcon from "../../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./exhibitionList.module.css";
import { utcToLocal } from "../../../../../utils/utcToLocal";

function TicketTypes({ isTrainer = false, fromTrainer = false }) {
  require("./List.css");

  const { t } = useTranslation();

  const history = useHistory();
  const traineesModalRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [payload, setPayload] = useState({});

  const [ticketTypeIndex, setTicketTypeIndex] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const [survies, setTicketTypes] = useState([]);
  const [filterData, setFilterData] = useState({
    status: "",
    name: "",
    serial_prefix: "",
    start_date: "",
    end_date: "",
  });
  const [currentTicketTypeFilter, setCurrentTicketTypeFilter] = useState({});

  const [ticketType, setTicketType] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [iseDeleteLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const exportHandler = async (type) => {
    setIsUpdating(true);
    try {
      const filter = getFilterData();
      await exportTicketTypesList(filter, type);
      setIsUpdating(false);
    } catch (e) {
      setIsUpdating(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: () => {
        setIsFilter((val) => !val);
      },
    },
    {
      id: "add",
      icon: <img src={AddIcon} alt="" />,
      theme: "add",
      action: () => {
        history.push(`/admin/ticket/type/add`);
      },
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: () => {
        exportHandler("pdf");
      },
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: () => {
        exportHandler("xlsx");
      },
    },
  ];

  const ticketTypeActionsList = [
    {
      id: "group",
      icon: <UserGroupIcon className="tw-h-5 tw-w-5 tw-stroke-[#8599B6]" />,
      title: t("support.tickets_type.support_representatives"),
      action: (id) => {
        history.push(`/admin/ticket/type/assign/${id}`);
      },
    },
    {
      id: "edit",
      icon: <img src={editExhIcon} alt="" />,
      title: t("general.edit"),
      action: (id) => {
        history.push(`/admin/ticket/type/edit/${id}`);
      },
    },
    // {
    // 	id: "delete",
    // 	icon: <img src={deleteIcon} alt="" />,
    // 	title: t("delete"),
    // 	action: (id, index = null) => {
    // 		const selectedTicketType = survies.find((ticketType) => ticketType.id == id);
    // 		if (!selectedTicketType) return;
    // 		setTicketType(selectedTicketType);
    // 		setTicketTypeIndex(index);
    // 		setShowDeleteModal(true);
    // 	},
    // },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>
      ),
    },
    {
      id: "name",
      name: t("crud.list.name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className="cell-font tw-w-full text2lines">{rowData.name}</div>
      ),
    },
    {
      id: "sequence_opening",
      name: t("crud.list.sequence_opening"),
      key: "sequence_opening",
      cell: ({ rowData }) => (
        <span className="cell-font">{rowData.serial_prefix}</span>
      ),
    },
    {
      id: "created_at",
      name: t("crud.list.creation_date"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {rowData.created_at ? (
            <span className="cell-font">
              <div>{moment(rowData.created_at).format("DD/MM/YYYY")}</div>
              <div>{utcToLocal(rowData.created_at)}</div>
            </span>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData, rowIndex }) => (
        <div className="tw-flex tw-items-center tw-justify-center tw-space-s-4">
          {ticketTypeActionsList.map((action, index) => (
            <IconsTooltip
              title={action.title}
              content={
                <button
                  key={action.id}
                  className={classes["exhibition_list__actions-btn"]}
                  onClick={() => {
                    action.action(rowData.id, rowIndex);
                  }}
                >
                  {action.icon}
                </button>
              }
            />
          ))}
        </div>
      ),
    },
    {
      id: "status",
      name: t("Exhibition.status_exh"),
      key: "status",
      sortable: false,
      cell: ({ rowData, rowIndex }) => (
        <SwitchToggle
          key={rowData.id}
          status={rowData.status?.value == "active"}
          id={rowData.id}
          onStatusChange={() => {
            disableOrEnableTicketType(rowData, rowIndex);
          }}
        />
      ),
    },
  ];

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      serial_prefix: "",
      start_date: "",
      end_date: "",
      status: "",
    });
  };

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("general.name"),
      type: "text",
      placeholder: t("general.name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: filterChangeHandler,
      onReset: resetHandler,
    },
    {
      id: "serial_prefix",
      component: "input",
      label: t("crud.list.sequence_opening"),
      type: "text",
      placeholder: t("crud.list.sequence_opening"),
      name: "serial_prefix",
      value: filterData.serial_prefix,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: filterChangeHandler,
      onReset: resetHandler,
    },
    {
      id: "start_date",
      label: t("general.search_start_date"),
      component: "datePicker",
      placeholder: t("general.search_start_date"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: filterChangeHandler,
    },
    {
      id: "end_date",
      label: t("general.search_end_date"),
      component: "datePicker",
      placeholder: t("general.search_end_date"),
      name: "end_date",
      value: filterData.end_date,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: filterChangeHandler,
    },
    {
      id: "status",
      component: "select",
      placeholder: t("general.status"),
      label: t("general.status"),
      initialValue: t("general.status"),
      name: "status",
      value: filterData.status ?? "",
      classes: "col-12 col-md-6 col-xl-2",
      onchange: filterChangeHandler,
      options: payload.statuses?.map((item) => ({
        id: item.value,
        value: item.value,
        label: item.text,
      })),
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`general.reset`),
      classes: "col-12 col-md-6 col-xl-2 tw-ms-auto",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        resetFilterHandler();
        if (currentPage == 1) {
          refetchTicketTypes({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6 col-xl-2",
      action: () => {
        if (currentPage == 1) {
          refetchTicketTypes();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.text = filterData.name);
    filterData.serial_prefix &&
      (filter.serial_prefix = filterData.serial_prefix);
    if (filterData.start_date || filterData.end_date) {
      filter.start_date = filterData.start_date || filterData.end_date;
      filter.end_date = filterData.end_date || filterData.start_date;
    }
    filterData.status && (filter.status = filterData.status);

    return filter;
  };

  const fetchTicketTypes = (params = getFilterData()) => {
    params = {
      ...params,
      perPage: 10,
    };
    setCurrentTicketTypeFilter(params);

    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await ticketTypesList(clearEmptyProp(params));
        if (response.status == 200) {
          setTicketTypes(response.data.data.ticketTypes.data);
          setMeta(response.data.data.ticketTypes.meta);
          setPayload(response.data.data.filter);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
        setIsLoading(false);
        setIsUpdating(false);
      }
    });

    return myPromise;
  };

  const removeTicketType = (id, index) => {
    setIsUpdating(true);
    ticketTypesDelete(id)
      .then(() => {
        setShowDeleteModal(false);
        const newSurvies = survies.filter((ticketType) => ticketType.id !== id);
        setTicketTypes(newSurvies);
        traineesModalRef.current.dismissModal();
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("ticketType.successDeleted")}
          </span>
        );
        setIsUpdating(false);
      })
      .catch((err) => {
        setShowDeleteModal(false);
        setIsUpdating(false);
        traineesModalRef?.current?.dismissModal();
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.data.message}
          </span>
        );
      });
  };

  const disableOrEnableTicketType = (row, index) => {
    let isActive = row.status?.value == "active";
    setIsUpdating(true);
    toggleTicketTypeAccessibility(row.id, {
      status: isActive ? "inactive" : "active",
    })
      .then(() => {
        refetchTicketTypes();
        setIsUpdating(false);
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {!isActive
              ? t("support.tickets_type.enabled")
              : t("support.tickets_type.disabled")}
          </span>
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.data?.msg}
          </span>
        );
        setIsUpdating(false);
      });
  };

  const refetchTicketTypes = async (params = getFilterData()) => {
    try {
      let customFilter = { ...params, page: currentPage };
      await fetchTicketTypes(customFilter);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  useEffect(() => {
    refetchTicketTypes();
  }, [currentPage]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: t("admin.label.admin_label"),
      page: t("admin.label.admin_label"),
      pagePath: "/admin",
    },
    {
      id: t("admin.label.list_tickets"),
      page: t("admin.label.list_tickets"),
      pagePath: "/admin/tickets",
    },
    {
      id: t("admin.label.list_ticket_types"),
      page: t("admin.label.list_ticket_types"),
      active: true,
    },
  ];

  return (
    <>
      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon}
          title={t("modal.support.ticket_types.delete.title")}
          name={ticketType.name}
          deleteMsg={t("modal.support.ticket_types.delete.body")}
          confirmText={t("survey.delete_aprove")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            removeTicketType(ticketType.id, ticketTypeIndex);
          }}
          isLoading={iseDeleteLoading}
        />
      )}
      <div className={"container-fluid"} id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <MainBox>
            <MainBoxHead
              title={t("admin.label.list_ticket_types")}
              actions={actionsList}
            />
            {isFilter && <FilterBox fields={filterFields} />}
            <MainTable columns={columns} data={survies} />
            <Pagination
              count={meta.totalPages}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              className="main-pagination"
              page={currentPage}
              onChange={(e, page) => {
                setCurrentPage(page);
              }}
            />
          </MainBox>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default TicketTypes;

export const boothConstants = {
  // GET EXHIBITION
  GET_EXHIBITION_REQUEST: "GET_EXHIBITION_REQUEST",
  GET_EXHIBITION_SUCCESS: "GET_EXHIBITION_SUCCESS",
  GET_EXHIBITION_REJECTED: "GET_EXHIBITION_REJECTED",

  // CREATE BOOTH
  CREATE_BOOTH_REQUEST: "CREATE_BOOTH_REQUEST",
  CREATE_BOOTH_SUCCESS: "CREATE_BOOTH_SUCCESS",
  CREATE_BOOTH_REJECTED: "CREATE_BOOTH_REJECTED",

  // UPDATE BOOTH
  UPDATE_BOOTH_REQUEST: "UPDATE_BOOTH_REQUEST",
  UPDATE_BOOTH_SUCCESS: "UPDATE_BOOTH_SUCCESS",
  UPDATE_BOOTH_REJECTED: "UPDATE_BOOTH_REJECTED",

  // UPDATE BOOTH DRAFT
  UPDATE_BOOTH_DRAFT_REQUEST: "UPDATE_BOOTH_DRAFT_REQUEST",
  UPDATE_BOOTH_DRAFT_SUCCESS: "UPDATE_BOOTH_DRAFT_SUCCESS",
  UPDATE_BOOTH_DRAFT_REJECTED: "UPDATE_BOOTH_DRAFT_REJECTED",

  // ADD BOOTH TO CART
  ADD_BOOTH_TO_CART_REQUEST: "ADD_BOOTH_TO_CART_REQUEST",
  ADD_BOOTH_TO_CART_SUCCESS: "ADD_BOOTH_TO_CART_SUCCESS",
  ADD_BOOTH_TO_CART_REJECTED: "ADD_BOOTH_TO_CART_REJECTED",

  // PAY ADD BOOTH WITH BANK TRANSFER
  PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REQUEST: "PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REQUEST",
  PAY_ADD_BOOTH_WITH_BANK_TRANSFER_SUCCESS: "PAY_ADD_BOOTH_WITH_BANK_TRANSFER_SUCCESS",
  PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REJECTED: "PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REJECTED",

  // GET BOOTH
  GET_BOOTH_REQUEST: "GET_BOOTH_REQUEST",
  GET_BOOTH_SUCCESS: "GET_BOOTH_SUCCESS",
  GET_BOOTH_REJECTED: "GET_BOOTH_REJECTED",

  // GET BOOTH DRAFT
  GET_BOOTH_DRAFT_REQUEST: "GET_BOOTH_DRAFT_REQUEST",
  GET_BOOTH_DRAFT_SUCCESS: "GET_BOOTH_DRAFT_SUCCESS",
  GET_BOOTH_DRAFT_REJECTED: "GET_BOOTH_DRAFT_REJECTED",

  // GET BOOTH VISITORS
  GET_BOOTH_VISITORS_REQUEST: "GET_BOOTH_VISITORS_REQUEST",
  GET_BOOTH_VISITORS_SUCCESS: "GET_BOOTH_VISITORS_SUCCESS",
  GET_BOOTH_VISITORS_REJECTED: "GET_BOOTH_VISITORS_REJECTED",

  // APPROVE BOOTH
  APPROVE_BOOTH_REQUEST: "APPROVE_BOOTH_REQUEST",
  APPROVE_BOOTH_SUCCESS: "APPROVE_BOOTH_SUCCESS",
  APPROVE_BOOTH_REJECTED: "APPROVE_BOOTH_REJECTED",

  // REJECT BOOTH
  REJECT_BOOTH_REQUEST: "REJECT_BOOTH_REQUEST",
  REJECT_BOOTH_SUCCESS: "REJECT_BOOTH_SUCCESS",
  REJECT_BOOTH_REJECTED: "REJECT_BOOTH_REJECTED",

  // APPROVE DRAFT BOOTH
  APPROVE_DRAFT_BOOTH_REQUEST: "APPROVE_DRAFT_BOOTH_REQUEST",
  APPROVE_DRAFT_BOOTH_SUCCESS: "APPROVE_DRAFT_BOOTH_SUCCESS",
  APPROVE_DRAFT_BOOTH_REJECTED: "APPROVE_DRAFT_BOOTH_REJECTED",

  // REJECT DRAFT BOOTH
  REJECT_DRAFT_BOOTH_REQUEST: "REJECT_DRAFT_BOOTH_REQUEST",
  REJECT_DRAFT_BOOTH_SUCCESS: "REJECT_DRAFT_BOOTH_SUCCESS",
  REJECT_DRAFT_BOOTH_REJECTED: "REJECT_DRAFT_BOOTH_REJECTED",

  // RESET BOOTH
  RESET_BOOTH: "RESET_BOOTH",

  // RESET PAYMENT BOOTH
  RESET_PAYMENT_BOOTH: "RESET_PAYMENT_BOOTH",

  // RESET ADD BOOTH TO CART
  RESET_ADD_TO_CART: "RESET_ADD_TO_CART",
};

export const partnersListConstant = {
  // GET SYSTEM USERS LIST
  GET_PARTNERS_LIST_REQUEST: "GET_PARTNERS_LIST_REQUEST",
  GET_PARTNERS_LIST_SUCCESS: "GET_PARTNERS_LIST_SUCCESS",
  GET_PARTNERS_LIST_REJECTED: "GET_PARTNERS_LIST_REJECTED",

  // UPDATE SYSTEM USER STATUS
  UPDATE_PARTNER_STATUS_REQUEST: "UPDATE_PARTNER_STATUS_REQUEST",
  UPDATE_PARTNER_STATUS_SUCCESS: "UPDATE_PARTNER_STATUS_SUCCESS",
  UPDATE_PARTNER_STATUS_REJECTED: "UPDATE_PARTNER_STATUS_REJECTED",
};

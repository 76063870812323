import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";
import IconTooltips from '../../../../Components/tooltips/IconsTooltip';
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import { exportReport, getReportTraineePrograms } from "../../../../Services/api/report/ReportProvider";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Xls from './../../../../assets/image/rating/xls.png';
import ReportModal from "./ReportModal";

function ReportTraineePrograms(props) {
  require("./reportsTrainee.css");
  const {t} = useTranslation();
  const history = useHistory();
  const reportsModalRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] = useState(false)
  const [pageCount, setPageCount] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [type,setType] = useState("")
  const [isUpdating, setIsUpdating] = useState(false);
  const [statuses, setStatuses] = useState([
    {text_en: "Current", text_ar:'الحالية',value: 'current'},
    {text_en: "Finished", text_ar:'المنتهية',value: 'finished'},
    {text_en: "All", text_ar:'الكل',value: 'all'}
  ]);
  const [currentFilter, setCurrentFilter] = useState({})
  const [reportsTraineePrograms, setReportsTraineePrograms] = useState([])


  const getReportsTraineePrograms = (params = null) => {
    setIsUpdating(true)
    return new Promise((resolve, reject) => {
      getReportTraineePrograms(clearEmptyProp(params))
          .then((res) => {
            if (res.status && res.status == 200 && res.data.status) {
              setReportsTraineePrograms(res.data.users.data);
              let pageCount = res.data.users.total / res.data.users.per_page
              setPageCount(pageCount);
              setIsLoading(false);
              setIsUpdating(false)
              resolve();
            } else {
              reject("Error");
            }
          })
          .catch((err) => {
            toast.error(
                <span style={{fontSize: 13, fontWeight: "bold"}}>
                    {t("failed_fetching")}
                </span>
            );
          });
    });
  };

  useEffect(() => {
    getReportsTraineePrograms(currentFilter);
  }, []);




  useEffect(() => {
    if (!isLoading) {
      document.getElementById('content-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
          parseInt(document.getElementById('content-header-bar').offsetHeight) + 40
      ).toString() + 'px';

      document.getElementById('content-body-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight)
      ).toString() + 'px'
    }
  }, [isLoading])

  useEffect(() => {
    if (!isLoading) {
      reAlignElementsHeights(showFilters)
    }
  }, [showFilters])


  const reAlignElementsHeights = (isFilterShown) => {
    if (!isFilterShown) {
      document.getElementById('content-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
          parseInt(document.getElementById('content-header-bar').offsetHeight) + 40
      ).toString() + 'px';

      document.getElementById('content-body-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight)
      ).toString() + 'px'
    } else {
      document.getElementById('content-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) +
          parseInt(document.getElementById('content-header-bar').offsetHeight) +
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) + 80
      ).toString() + 'px';

      document.getElementById('content-body-section').style.height = (
          parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
          parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) + 40
      ).toString() + 'px'
    }
  }

  const handlePageClick = (event) => {
    const toBeUpdatedFilters = currentFilter
    toBeUpdatedFilters.page = event.selected + 1
    setCurrentFilter(toBeUpdatedFilters);

    getReportsTraineePrograms(toBeUpdatedFilters)
};


  const handleReportsModal=(type)=>{
    setType(type)
    reportsModalRef.current.showModal();
}

  return (
      <>
        <div className="container-fluid" id={"content-section"} style={{
          position: 'relative',
          padding: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}>
               <BasicModal ref={reportsModalRef}>
                    <ReportModal
                        label={type == 'degrees' ? t("modal.report.label.degrees"): t("modal.report.label.financial")}
                        title={type == 'degrees' ? t("modal.report.label.degrees"): t("modal.report.label.financial")}
                        body={type == 'degrees' ? ['degree1','degree2'] : ['financial1','financial2']}
                        type={type}
                        reportsModalRef={reportsModalRef}
                        handleReportsModal={handleReportsModal}
                    />
                </BasicModal>
          <div className="admin_flex" id="content-header-bar">
            <div className="admin_label">
              <NavLink to="/admin">
                {t("admin.label.admin_label")}
              </NavLink>
              <p style={{padding:"0 5px"}}>/</p>
              {t("report.label.trainee_report_programs")}
            </div>
          </div>
          {isUpdating ?<SkeletonCardOverlay skeletonWidth="100"/>:<div></div>}
          {isLoading == false ? (
              <div id="content-body-section" style={{
                position: 'absolute',
                width: '100%'
              }}>
                {
                  isPerformingBackgroundOperation ?
                      <SkeletonCardOverlay borderRadius={10} top={40}/> : null
                }
                <div className={"tajah_table_fade_change"} style={{
                  opacity: showFilters ? 1 : 0
                }}>
                  <Formik
                      initialValues={{
                        course_status:"",
                        name:""
                      }}

                      onSubmit={async (values, {setSubmitting}) => {
                        try {
                          // setIsPerformingBackgroundOperation(true);
                          setCurrentFilter(values);
                          await getReportsTraineePrograms(values);
                          reAlignElementsHeights(showFilters);
                          // setIsPerformingBackgroundOperation(false);
                        } catch (e) {
                          toast.error(
                              <span style={{fontSize: 13, fontWeight: "bold"}}>
                                            {t("failed_fetching")}
                                        </span>
                          );
                        }
                      }}
                      validateOnChange={false}
                  >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                      }) => (
                        <form onSubmit={handleSubmit} className={"table_bank_tran"}>
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            width: '90%',
                            borderBottomWidth: 1,
                            borderBottomColor: "rgba(0,0,0,0.3)",
                            borderBottomStyle: "solid",
                            height: 40
                          }}>
                            <h5>{t("Filter")}</h5>
                          </div>

                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: '90%',
                            marginTop: 30
                          }}>
                              <div style={{
                                                flex: 1,
                                                marginLeft: document.body.dir == 'rtl' ? 10 : 0,
                                                marginRight: document.body.dir == 'ltr' ? 10 : 0,
                                            }}>
                                                <label>{t("crud.placeholders.name")}</label>
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    className="admin_filter_input"
                                                    onChange={(e) => {
                                                         setFieldValue("name", e.target.value)
                                                    }}
                                                    placeholder={t("crud.placeholders.name")}
                                                    value={values.name}
                                                />
                                            </div>
                              <div style={{
                                                flex: 1,
                                                marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                                marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                                            }}>
                                                <label>{t("crud.placeholders.select.status")}</label>
                                                <Select
                                                    name="course_status"
                                                    id="course_status"
                                                    options={statuses}
                                                    getOptionLabel={(option) => i18n.language == Lang.AR ?  option.text_ar : option.text_en}
                                                    getOptionValue={(option) => option.value}
                                                    isClearable={true}
                                                    onChange={(item) => {
                                                        if (item) {
                                                            setFieldValue('course_status', item.value);
                                                        } else {
                                                            setFieldValue('course_status', null);
                                                        }
                                                    }}
                                                    placeholder={t("crud.placeholders.select.status")}/>
                                            </div>



                          </div>


                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            width: '90%',
                            marginTop: 20
                          }}>
                            <button
                                style={{
                                  width: '100%'
                                }}
                                type="submit"
                                className="admin_add_button"
                            >
                              {t("crud.placeholders.submit")}
                            </button>
                          </div>
                        </form>
                    )}
                  </Formik>
                </div>

                <div className="table_bank_tran tajah_table_vertical_movement" style={{
                  top: showFilters ? parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) + 40 : 0
                }}>
                  <div className="tajah_card_title_container">
                    <div>{t("report.label.trainee_report_programs")}</div>
                    <div className={"tajah_card_title_container_actions"}>
                      <button style={{borderRadius:10,padding:10,borderWidth:0}} className={showFilters ? "btn btn-sm btn-danger" : "btn-bg-filter"}
                              onClick={() => {
                                setShowFilters(!showFilters);
                              }}>
                              <IconTooltips
                              title={t('modal.coupon.hover.search')}
                              content={<i className={"fas fa-filter tajah_ticket_list_table_filter_icon"}/>}
                              />

                      </button>
                      <button className={"btn btn-sm btn-primary color-button-excel"} style={{
                        marginRight: document.body.dir == 'rtl' ? 10 : 0,
                        marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        borderWidth:0
                      }} onClick={async () => {
                        try{
                          setIsPerformingBackgroundOperation(true);
                          await exportReport('traineePrograms',clearEmptyProp(currentFilter));
                          setIsPerformingBackgroundOperation(false);
                        } catch (e) {
                          setIsPerformingBackgroundOperation(false);
                          toast.error(
                              <span style={{fontSize: 13, fontWeight: "bold"}}>
                                                    {t("failed_fetching")}
                                                </span>
                          );
                        }
                      }}>
                      <IconTooltips
                      title={t('modal.coupon.hover.titleDownloadExcel')}
                      content={<img src={Xls} style={{width:25}}/>}
                      />
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive" style={{
                    width: '90%'
                  }}>
                    <table className="table table-bordered">
                      <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t("report.label.trainee")}</th>
                        <th scope="col"> {t("report.label.program")}</th>
                        <th scope="col">{t("report.label.price")}</th>
                        <th scope="col">{t("report.label.paid")}</th>
                        <th scope="col">{t("report.label.remaining")}</th>
                        <th scope="col">{t("report.label.start_date")}</th>
                        <th scope="col">{t("report.label.end_date")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {reportsTraineePrograms.length == 0 ? (
                       <tr>
                      <td
                        colSpan={7}
                        style={{ fontSize: "20px", textAlign: "center" }}
                      >
                        {t("no_record")}
                      </td>
                    </tr>
                    ) : (reportsTraineePrograms.map((report,index) => {
                        return (
                            <tr key={index}>
                              <td scope="row">{index+1}</td>
                              <td>{i18n.language === Lang.AR ? report.name_ar : report.name_en}</td>
                              <td>{report.title}</td>
                              <td>{report.price}{"        "}{i18n.language === Lang.AR ?  "ر.س" : "SAR"}</td>
                              <td>{report.total_paid}{"        "}{i18n.language === Lang.AR ?  "ر.س" : "SAR"}</td>
                              <td>{report.price - report.total_paid}{"        "}{i18n.language === Lang.AR ?  "ر.س" : "SAR"}</td>
                              <td>{report.start_date}</td>
                              <td>{report.end_date}</td>
                            </tr>
                        );
                      }))}
                      </tbody>
                    </table>
                    <ReactPaginate
                    nextLabel={t("next")}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={t("previous")}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
                  </div>
                </div>
              </div>
          ) : (
              <div className="mt-5" style={{
                height: "65vh"
              }}>
                <SkeletonCard/>
              </div>
          )}
        </div>
      </>
  )
}

export default ReportTraineePrograms;

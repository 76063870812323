import classes from "./usersProfile.module.css";
import LeftArrowIcon from "../../../assets/icons/arrow-left.svg";
import RightArrowIcon from "../../../assets/icons/arrow-right.svg";
import { useTranslation } from "react-i18next";

export default function UsersProfileItemBox({ onClick, title, icon }) {
  const { t, i18n } = useTranslation();

  return (
    <div onClick={onClick} className={classes["card-counter"]}>
      <div className={classes["card-top"]}>
        <div className={classes["card-image"]}>
          <img src={icon} alt="" />
        </div>
      </div>
      <div className={classes["card-bottom"]}>
        <div className={classes["card-label"]}>{title}</div>
        <div className={classes["card-link"]}>
          {t("more")}
          <div className={classes["arrow"]}>
            <img src={i18n.language === "en" ? RightArrowIcon : LeftArrowIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

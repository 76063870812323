import { useTranslation } from "react-i18next";
import HomeIcon from "../../../../assets/icons/home.svg";
import classes from "./PaymentRequests.module.css";

import { Pagination } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchPeymentRequestsAdminList } from "../../../../Redux/Actions/peymentRequestAdmin/peymentRequestAdmin.action";
import {
  exportPeymentRequestsCourses,
  exportPeymentRequestsPrograms,
} from "../../../../Services/api/PaymentRequestsCourses";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";

import PaymentRequestsCoursesProgramsFilter from "./PaymentRequestsFilter";

import { getAllCourses } from "../../../../Services/api/CoursesList";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import PaymentRequestsCoursesTable from "./PaymentRequestsCoursesTable";
import PaymentRequestsProgramsTable from "./PaymentRequestsProgramsTable";
import { filterActions } from "./PaymentRequestsTableActions";
import { getProgramDetails } from "../../../../Services/api/courses/courseProvider";

const PeymentRequestsCoursesPrograms = ({ isProgram }) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [currentPaymentRequestsFilter, setCurrentPaymentRequestsFilter] =
    useState({
      ...(isProgram ? { program_or_in_program: 1 } : {}),
      ...(!isProgram ? { not_in_program: 1 } : {}),
      ...(!isProgram ? { is_program: 0 } : {}),
      ...(activeTab === 1
        ? { status: "Waiting" }
        : activeTab === 2
        ? { status: "Accepted" }
        : { status: "Rejected" }),
    });

  const { peymentRequestsMeta, peymentRequests, peymentRequestsLoading } =
    useSelector((state) => state.peymentRequestsAdminReducer);

  const [filterData, setFilterData] = useState({
    user_name: "",
    email: "",
    user_role: "",
    course_id: "",
    course_code: "",
    run_serial_number: "",
    processor_name: "",
    program_code: "",
    program_id: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("admin.label.admin_label"),
      pagePath: "#",
    },
    {
      id: `content_development_tax`,
      page: isProgram
        ? t("sidebar.label.payment_requests_programs")
        : t("sidebar.label.payment_requests_courses"),
      pagePath: "#",
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      user_name: "",
      email: "",
      role: "",
      course_id: "",
      course_code: "",
      run_serial_number: "",
      processor_name: "",
      program_code: "",
      program_id: "",
    });
    setIsResetApplied(true);
  };

  const getProgramCoursesHandler = async (programId) => {
    try {
      const res = await getProgramDetails(programId);
      if (res.status && res.status == 200 && res.data.status) {
        setCourses(res.data.program.courses);
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {err.response.data.msg}
        </span>
      );
    }
  };
  useEffect(() => {
    if (filterData.program_id) {
      getProgramCoursesHandler(filterData.program_id);
    }
  }, [filterData]);

  const getFilterData = () => {
    const filter = {
      ...(isProgram ? { program_or_in_program: 1 } : {}),
      ...(!isProgram ? { not_in_program: 1 } : {}),
      ...(!isProgram ? { is_program: 0 } : {}),
      ...(activeTab === 1
        ? { status: "Waiting" }
        : activeTab === 2
        ? { status: "Accepted" }
        : { status: "Rejected" }),
      // role: "admin",
    };
    filterData.user_name && (filter.user_name = filterData.user_name);
    filterData.email && (filter.email = filterData.email);
    filterData.role && (filter.role = filterData.role);
    filterData.course_id && (filter.course_id = filterData.course_id);
    filterData.course_code && (filter.course_code = filterData.course_code);
    filterData.run_serial_number &&
      (filter.run_serial_number = filterData.run_serial_number);
    filterData.processor_name &&
      (filter.processor_name = filterData.processor_name);
    isProgram &&
      filterData.program_code &&
      (filter.program_code = filterData.program_code);
    isProgram &&
      filterData.program_id &&
      (filter.program_id = filterData.program_id);

    // filter.order_status = activeTab;
    return filter;
  };

  const getAllPaymentRequestsCoursesPrograms = () => {
    const filter = getFilterData();

    dispatch(
      fetchPeymentRequestsAdminList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
    setCurrentPaymentRequestsFilter(filter);
  };

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { is_program: 0 } : {}),
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.msg}
          </span>
        );
      })
      .finally(() => {
        setIsCoursesLoading(false);
        setIsProgramsLoading(false);
      });
  };
  useEffect(() => {
    getAllCoursesProgramsHandler({
      is_program: false,
    });
    getAllCoursesProgramsHandler({
      is_program: true,
    });
  }, []);

  useEffect(() => {
    getAllPaymentRequestsCoursesPrograms();

    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, activeTab]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      isProgram
        ? await exportPeymentRequestsPrograms(
            currentPaymentRequestsFilter,
            type
          )
        : await exportPeymentRequestsCourses(
            currentPaymentRequestsFilter,
            type
          );
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["departments-trainees"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["departments-trainees__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={
                    isProgram
                      ? t("sidebar.label.payment_requests_programs")
                      : t("sidebar.label.payment_requests_courses")
                  }
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    addAction: () => {},
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                    isAdmin: authContext.inAdminPanel,
                  })}
                />
                {showFilter && (
                  <PaymentRequestsCoursesProgramsFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getAllPaymentRequestsCoursesPrograms}
                    resetFilterHandler={resetFilterHandler}
                    activeTab={activeTab}
                    courses={courses}
                    programs={programs}
                    isProgram={isProgram}
                  />
                )}
                {isProgram ? (
                  <PaymentRequestsProgramsTable
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    setFilterData={setFilterData}
                    paymentRequests={peymentRequests}
                    peymentRequestsLoading={peymentRequestsLoading}
                    isProgram={isProgram}
                  />
                ) : (
                  <PaymentRequestsCoursesTable
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    setFilterData={setFilterData}
                    paymentRequests={peymentRequests}
                    peymentRequestsLoading={peymentRequestsLoading}
                    isProgram={isProgram}
                  />
                )}

                <Pagination
                  count={peymentRequestsMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeymentRequestsCoursesPrograms;

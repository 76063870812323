import React, { useState } from "react";
// import TrainingCoursePaymentWithBankTransfer from "../trainingCourses/trainingCourseDetails/trainingCoursePaymentModal/TrainingCoursePaymentWithBankTransfer";
// import TrainingCoursePaymentWithCard from "../trainingCourses/trainingCourseDetails/trainingCoursePaymentModal/TrainingCoursePaymentWithCard";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { CoursePaymentProvider } from "../../../Services/api/courses/coursePaymentContext";
import stripe from "../../../assets/image/stripe.png";
import transfer from "../../../assets/image/transfer-money.png";
import BankTransfer from "../BankTransfer/BankTransfer";
// import StripeMethod from "../StripeMethod/StripeMethod";

// const stripePromise = loadStripe(
//   "pk_test_51IkbOMDjYjWvzOE3xmohrGoYqRNBiNX7QFtmV9dsKxEwDAcsbYEghtY4VBEYBGcRurpqOOu60S6AngtlilEFsFSm0087UyxRgZ"
// );

function PaymentMethod(props) {
  require("./PaymentMethod.css");
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState("");

  const changeRadio = (event) => {
    setRadioValue(event.target.value);
  };

  return (
    <div className="program_padding">
      <label className="program_payment_label">{t("selectopt")}</label>

      <div>
        <input
          className="form-check-input program_radio"
          type="radio"
          name="payment"
          id="stripe"
          value="stripe"
          onChange={changeRadio}
        />
        <label className="form-check-label" htmlFor="stripe">
          <img src={stripe} alt="" className="payment_method" />
          {t("programs.label.visa")}
        </label>
        <input
          className="form-check-input"
          type="radio"
          name="payment"
          id="bankTransfer"
          value="bankTransfer"
          onChange={changeRadio}
        />
        <label className="form-check-label" htmlFor="bankTransfer">
          <img src={transfer} alt="" className="payment_method" />
          {t("programs.label.banktrasfer")}
        </label>
      </div>

      {radioValue == "stripe" ? (
        <CoursePaymentProvider>
          {/* <Elements stripe={stripePromise}>
            <StripeMethod paymentEntity={props.paymentEntity} />
          </Elements> */}
        </CoursePaymentProvider>
      ) : radioValue == "bankTransfer" ? (
        <CoursePaymentProvider>
          <BankTransfer paymentEntity={props.paymentEntity} />
        </CoursePaymentProvider>
      ) : null}
    </div>
  );
}

export default PaymentMethod;

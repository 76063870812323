import React, { useState } from "react";
import { getCourseObjectives, getQuestion, getQuestionTypes } from "../Services/api/exams/ExamsProvider";

export const AddQuestionToQuestionBankContext = React.createContext();

export function AddQuestionToQuestionBankProvider(props) {
  const [questionTypes, setQuestionTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [question, setQuestion] = useState({});
  const [objectives, setObjectives] = useState([]);

  const fetchQuestionTypes = () => {
    const myPromise = new Promise((resolve, reject) => {
      getQuestionTypes()
        .then((res) => {
          setQuestionTypes(res.data.types);
          resolve();
        })
        .catch((error) => {
          
          reject();
        });
    });

    return myPromise;
  };

  const fetchQuestionData = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      getQuestion(id)
        .then((res) => {
          setQuestion(res.data.Question);
          resolve();
        })
        .catch((error) => {
          
          reject();
        });
    });

    return myPromise;
  };

  const fetchCourseObjectives = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      getCourseObjectives(id)
        .then((res) => {
          setObjectives(res.data.objectives);
          resolve();
        })
        .catch((error) => {
          
          reject();
        });
    });

    return myPromise;
  };

  return (
    <AddQuestionToQuestionBankContext.Provider
      value={{
        isLoading,
        setIsLoading,
        questionTypes,
        setQuestionTypes,
        isEdit,
        setIsEdit,
        question,
        setQuestion,
        objectives,
        setObjectives,
        fetchQuestionTypes,
        fetchQuestionData,
        fetchCourseObjectives,
      }}
    >
      {props.children}
    </AddQuestionToQuestionBankContext.Provider>
  );
}

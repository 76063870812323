import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { addCourse } from "../../../../Services/api/courses/courseProvider";
import CourseForm from "../CourseForm/CourseForm";

export default function AddCourse({ isProgram, programCourses, role }) {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  const submitHandler = async (formData) => {
    try {
      programCourses && formData.append("program_id", id);
      const response = await addCourse(formData);
      if (response.status === 201 && response.data.status) {
        history.push(
          programCourses
            ? `/${role}/program/${id}/courses`
            : isProgram
            ? role === "manager"
              ? `/${role}/programs`
              : `/${role}/program/list`
            : `/${role}/courses`
        );
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("add_success")}
          </span>,
          {
            autoClose: 1000,
          }
        );
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
      }
    } catch (err) {
      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response.data.msg}
          </span>
          <ul>
            {Object.keys(err.response.data.errors).map((key) => {
              return (
                <li>
                  <span style={{ fontSize: 11 }}>
                    {err.response.data.errors[key]}
                  </span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  return (
    <CourseForm
      submitHandler={submitHandler}
      type="create"
      isProgram={isProgram}
      role={role}
      programCourses={programCourses}
      programId={id}
    />
  );
}

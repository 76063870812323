import classes from "./ContentView.module.css";

function VideoView({ question }) {
  const getYouTubeSrc = (videoUrl) => {
    // Function to extract the video ID from the YouTube URL
    const getYouTubeId = (url) => {
      const match = url.match(
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
      );
      return match && match[1];
    };

    // Extract video ID from the provided YouTube URL
    const videoId = getYouTubeId(videoUrl);

    // If a valid video ID is found, generate the src link
    return videoId
      ? `https://www.youtube.com/embed/${videoId}`
      : "Invalid YouTube URL";
  };
  const iframeSrc = getYouTubeSrc(question.link);
  return (
    <div>
      <div style={{ paddingBottom: 15 }}>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          className={classes["course-slider-item-content-inner-body"]}
        />
      </div>
      <iframe
        src={iframeSrc}
        width="100%"
        height="500"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        title={question.title}
      ></iframe>
    </div>
  );
}
export default VideoView;

import React from "react";
import { CourseProvider } from "../../../../Services/api/courses/courseContext";
import TrainingCoursesDetails from "../TrainingCourseDetails/TrainingCourseDetails";

function TrainingCoursesDetailsProvider({ isProgram, backPath }) {
  require("./TrainingCoursesDetailsProvider.css");
  return (
    <CourseProvider>
      <TrainingCoursesDetails isProgram={isProgram} backPath={backPath} />
    </CourseProvider>
  );
}

export default TrainingCoursesDetailsProvider;

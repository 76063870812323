import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { privacyPolicyApi } from "../../Services/api/toutorials/termsAndConditionsProvider";
import i18n from "../../i18n/i18n";
import { Lang } from "../../utils";
import { BoxCard, ContainerBox } from "../Shared/Components";

function PrivacyPolicy(props) {
  const { t } = useTranslation();

  const [privacyPolicyContent, setPrivacyPolicyContent] = useState("");

  useEffect(() => {
    privacyPolicyApi()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setPrivacyPolicyContent(res.data.data);
        }
      })
      .catch((err) => {
        
      });
  }, []);
  return (
    <Stack component={"section"}>
      <ContainerBox>
        <BoxCard sx={{ mt: "52px" }}>
          <Typography
            component={"h1"}
            variant="h2"
            color={"primary.main"}
            fontWeight={500}
            mb={"32px"}
            fontFamily="dinNextRegular"
          >
            {t("PrivacyPolicyTitle")}
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.language == Lang.AR ? privacyPolicyContent.content_ar : privacyPolicyContent.content_en,
            }}
          ></div>
        </BoxCard>
      </ContainerBox>
    </Stack>
  );
}

export default PrivacyPolicy;

import { Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  edittermsAndConditionsAdmin,
  termsAndConditions,
} from "../../Services/api/toutorials/termsAndConditionsProvider";
import i18n from "../../i18n/i18n";
import { Lang } from "../../utils";
import { BoxCard, ContainerBox } from "../Shared/Components";

function TermsAndConditions(props) {
  const { t } = useTranslation();

  // require("./termsAndConditions.css");
  const [termsCondition, setTermsCondition] = useState("");

  useEffect(() => {
    termsAndConditions()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTermsCondition(res.data.terms);
        }
      })
      .catch((err) => {
        
        
      });
  }, []);
  return (
    <Stack component={"section"}>
      <ContainerBox>
        <BoxCard sx={{ mt: "52px" }}>
          <Typography
            component={"h1"}
            variant="h2"
            color={"primary.main"}
            fontWeight={500}
            mb={"32px"}
            fontFamily="dinNextRegular"
          >
            {t("TermsAndConditionsTitle")}
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html:
                i18n.language == Lang.AR
                  ? termsCondition.content_ar
                  : termsCondition.content_en,
            }}
          ></div>
        </BoxCard>
      </ContainerBox>
    </Stack>
  );
}

export default TermsAndConditions;

import moment from "moment";
import { useTranslation } from "react-i18next";
import CertificateIcon from "../../../assets/icons/badge.png";
import { utcToLocal } from "../../../utils/utcToLocal";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import classes from "./certificates.module.css";

const CertificatesTable = ({
  certificates,
  isLoading,
  handleOpenExportCertificateModal,
}) => {
  const { t } = useTranslation();

  const coursesTableActionsList = () => {
    return [
      {
        id: "download",
        icon: <img src={CertificateIcon} alt="Download Certificate" />,
        action: (id) => {
          handleOpenExportCertificateModal(id);
        },
        tooltip: t("general.download_certificate"),
      },
    ];
  };

  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
      sortable: false,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "name",
      name: t("general.course_or_program_name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["courses-list__table-name"]}>
          {rowData?.course?.name}
        </div>
      ),
      sortable: false,
    },
    {
      id: "course_id",
      name: t("general.course_id"),
      key: "course_id",
      cell: ({ rowData }) => <>{rowData?.course?.id}</>,
      sortable: false,
    },
    {
      id: "certificate_serial",
      name: t("general.certificate_serial"),
      key: "certificate_serial",
      cell: ({ rowData }) => <>{rowData?.serial}</>,
      sortable: false,
    },
    {
      id: "certificate_date",
      name: t("general.certificate_date"),
      key: "certificate_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
      sortable: false,
    },
    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <div className={classes["certificates__table__actions"]}>
          {coursesTableActionsList().map((action) => {
            return (
              <button
                key={action.id}
                className={classes["certificates__table__actions-btn"]}
                title={action.tooltip}
                onClick={() => {
                  action.action(Number(rowData.certificable_id));
                }}
              >
                {action.icon}
              </button>
            );
          })}
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <MainTable columns={columns} data={certificates} isLoading={isLoading} />
  );
};

export default CertificatesTable;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ContentLoadingSpinner from "../../Shared/Components/ContentLoadingSpinner/ContentLoadingSpinner";
import SearchInput from "./SearchInput";
import SearchSaveEmails from "./SearchSaveEmails";
import SearchTabs from "./SearchTabs";
import classes from "./saveSearchModal.module.css";
import searchClasses from "./search.module.css";
const SearchSaveModal = ({
  title,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  isLoading,
  label,
  value,
  onChange,
  error,
  searchReport,
  setSearchReport,
  onEmailChange,
  emailValue,
  emailError,
  emails,
  addEmailHandler,
  deleteEmailHandler,
}) => {
  const modalRef = React.useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const reportTabs = [
    {
      value: "1",
      text: t("general.weekly"),
    },
    {
      value: "2",
      text: t("general.monthly"),
    },
    {
      value: "0",
      text: t("undefined"),
    },
  ];

  const searchReportTabHandler = (value) => {
    setSearchReport((prev) => (prev == value ? "0" : value));
  };

  return (
    <div className={classes["modal"]}>
      <div className={classes["modal__container"]} ref={modalRef}>
        <div className={classes["modal__content"]}>
          <div className={classes["modal__content__title"]}>{title}</div>
          <div className={searchClasses["search__box__item-fields"]}>
            <div className={searchClasses["search__box__item-label"]}>{label}</div>
            <SearchInput
              name={"save-search"}
              placeholder={label}
              type={"text"}
              value={value}
              onChange={(value) => {
                onChange(value);
              }}
            />
            {<p className={`form-input-error-space`}>{error && error}</p>}
            <div className={searchClasses["search__box__item-label"]}>{t("search.send_report")}</div>
            <SearchTabs tabs={reportTabs} activeTab={searchReport} onChange={searchReportTabHandler} size={"small"} />
            {searchReport && searchReport != "0" && (
              <SearchSaveEmails
                onEmailChange={onEmailChange}
                emailValue={emailValue}
                emailError={emailError}
                emails={emails}
                addEmailHandler={addEmailHandler}
                deleteEmailHandler={deleteEmailHandler}
              />
            )}
          </div>
        </div>
        <div className={classes["modal__buttons"]}>
          <button className={classes["modal__buttons__cancel"]} onClick={onCancel}>
            {cancelText}
          </button>
          <button className={classes["modal__buttons__confirm"]} onClick={onConfirm} disabled={isLoading}>
            {isLoading ? <ContentLoadingSpinner /> : confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchSaveModal;

import React from "react";
import LinkPreview from "../../../Shared/Components/LinkPreview/LinkPreview";
import classes from "./exhibitionDetails.module.css";
const ExhibitionDetailsLinks = ({ links }) => {
  const newLinks = links?.map((link) => {
    return {
      id: link.id,
      link:
        (link.link && link.link.startsWith("http")) || (link.link && link.link?.startsWith("https"))
          ? link.link
          : `https://${link.link}`,
      linkPreview: link.name,
    };
  });

  return (
    <div className={classes["exhibition-details__links"]}>
      <h2 className={classes["exhibition-details__links-title"]}>الندوات</h2>
      <div className={classes["exhibition-details__links__lists"]}>
        {newLinks &&
          !!newLinks.length &&
          newLinks.map((link) => (
            <div
              className={classes["exhibition-details__links-item"]}
              key={link.id}
              onClick={() => {
                window.open(link.link, "_blank");
              }}
            >
              <LinkPreview link={link.linkPreview} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ExhibitionDetailsLinks;

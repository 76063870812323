import { useTranslation } from "react-i18next";
import {
  DOC_PDF,
  JPEG_TYPE,
  JPG_TYPE,
  MP3_TYPE,
  PNG_TYPE,
  acceptAudios,
  acceptDocuments,
  acceptImages,
  fromKbToMb,
} from "../../../Services/Validator";
import Input from "../../Shared/Components/Input/Input";
import InputFile from "../../Shared/Components/InputFile/InputFile";
import Links from "../../Shared/Components/Links/Links";

export function BasicInformation({ handleChange, errors, setFieldValue, values, getTypeFile, validateFile }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="col-md-12" style={{ marginBottom: 30 }}>
        <Input
          name="name"
          label={t("booth.name")}
          placeholder={t("booth.name")}
          type="text"
          onChange={handleChange}
          error={errors.name}
          required={true}
          value={values.name}
        />
      </div>
      <div className="col-md-12" style={{ paddingBottom: "30px" }}>
        <InputFile
          name="booth_main_img"
          type="gallery"
          documentWithoutTitle={true}
          accept={acceptImages()}
          label={t("Exhibition.input_upload_main_img")}
          maxSize={`${t("Exhibition.upload_max_size")} ${fromKbToMb(
            Math.max(
              getTypeFile(PNG_TYPE)[0]?.max_size,
              getTypeFile(JPEG_TYPE)[0]?.max_size,
              getTypeFile(JPG_TYPE)[0]?.max_size
            )
          )} ${t("booth.megabyte")}`}
          setFieldValue={setFieldValue}
          onChange={handleChange}
          error={errors?.booth_main_img}
          validate={validateFile}
          required={true}
          value={values.booth_main_img}
          uploadDirectly={true}
          note={`${t("Exhibition.png_note")} ${fromKbToMb(
            Math.max(
              getTypeFile(PNG_TYPE)[0]?.max_size,
              getTypeFile(JPEG_TYPE)[0]?.max_size,
              getTypeFile(JPG_TYPE)[0]?.max_size
            )
          )} ${t("booth.megabyte")}`}
        />
      </div>
      <div className="row col-md-12 d-flex justify-content-between" style={{ paddingBottom: "56px" }}>
        <div className="col-md-5" style={{ margin: 0, padding: 0 }}>
          <InputFile
            name="logo"
            type="image"
            accept={acceptImages()}
            label={t("Exhibition.add_logo")}
            placeholder={t("Exhibition.drag_image")}
            maxSize={`${t("Exhibition.upload_max_size")} ${fromKbToMb(
              Math.max(
                getTypeFile(PNG_TYPE)[0]?.max_size,
                getTypeFile(JPEG_TYPE)[0]?.max_size,
                getTypeFile(JPG_TYPE)[0]?.max_size
              )
            )} ${t("booth.megabyte")}`}
            setFieldValue={setFieldValue}
            error={errors.logo}
            validate={validateFile}
            required={true}
            value={values?.logo}
            uploadDirectly={true}
            note={`${t("Exhibition.png_note")} ${fromKbToMb(
              Math.max(
                getTypeFile(PNG_TYPE)[0]?.max_size,
                getTypeFile(JPEG_TYPE)[0]?.max_size,
                getTypeFile(JPG_TYPE)[0]?.max_size
              )
            )} ${t("booth.megabyte")}`}
          />
        </div>
        <div className="col-md-5" style={{ margin: 0, padding: 0 }}>
          <InputFile
            name="audio"
            type="audio"
            accept={acceptAudios()}
            label={t("Exhibition.add_main_audio")}
            placeholder={t("Exhibition.drag_audio")}
            setFieldValue={setFieldValue}
            error={errors.audio}
            validate={validateFile}
            required={true}
            value={values?.audio}
            uploadDirectly={true}
            note={`${t("Exhibition.mp3_note")} ${fromKbToMb(getTypeFile(MP3_TYPE)[0]?.max_size)} ${t(
              "booth.megabyte"
            )}`}
          />
        </div>
      </div>

      <div className="col-md-12" style={{ paddingBottom: "38px" }}>
        <Links
          label={t("Exhibition.webinars")}
          name="webinars"
          placeholder={t("Exhibition.webinars")}
          namePlaceholder={t("Exhibition.webinar_name")}
          linkPlaceholder={t("Exhibition.webinar_link")}
          setFieldValue={setFieldValue}
          required={true}
          error={errors.webinars}
          value={values?.webinars}
        />
      </div>

      <div className="col-md-12" style={{ paddingBottom: "38px" }}>
        <InputFile
          name="document"
          type="document"
          documentWithoutTitle={true}
          accept={acceptDocuments()}
          label={t("Exhibition.drag_files_pdf")}
          placeholder={t("Exhibition.drag_file")}
          setFieldValue={setFieldValue}
          error={errors.document}
          validate={validateFile}
          required={true}
          value={values?.document}
          uploadDirectly={true}
          note={`${t("Exhibition.pdf_note")} ${fromKbToMb(getTypeFile(DOC_PDF)[0]?.max_size)} ${t("booth.megabyte")}`}
        />
      </div>
    </>
  );
}

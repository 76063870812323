import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  getAllAvailableCourses,
  getAllAvailablePrograms,
} from "../../Services/api/HomeCoursesProvider";
import SearchIcon from "../../assets/icons/search icon.svg";
import CourseCard from "../Shared/Components/CourseCard/CourseCard";
import MainBox from "../Shared/Components/MainBox/MainBox";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import SharedSlider from "../Shared/SharedSlider/SharedSlider";
import "./homeCourses.css";
import classes from "./homeCourses.module.css";

export default function HomeCourses({ isProgram }) {
  const location = useLocation();
  const searchAutoComplete = useRef(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [specialties, setSpecialties] = useState([]);
  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    location.state?.specialty_id ? location.state.specialty_id : "all"
  );
  const [departmentDescription, setDepartmentDescription] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [sortValue, setSortValue] = useState("1");

  useEffect(() => {
    const apiCall = isProgram
      ? getAllAvailablePrograms
      : getAllAvailableCourses;

    apiCall()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          const specialties = res.data.data.specialties.data;
          setSpecialties(specialties);
          setDepartments(specialties.flatMap((item) => item.departments));

          setSpecialtiesOptions(
            specialties.map((specialty) => ({
              id: specialty.id,
              name: specialty.name,
              value: specialty.id,
            }))
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (specialties.length > 0 && departments.length > 0) {
      changeSpecialty({ target: { value: selectedSpecialty } });
    }
  }, [specialties, departments, selectedSpecialty]);

  const changeSpecialty = (e) => {
    setSelectedSpecialty(e.target.value);
    if (e.target.value == "all") {
      const newOptions = departments;
      setDepartmentOptions(newOptions);
      changeDepartment("all");
      setDepartmentDescription("");
    } else {
      const newOptions = departments.filter(
        (item) => Number(item.specialty_id) === Number(e.target.value)
      );
      setDepartmentOptions(newOptions);
      changeDepartment("all");
    }
  };

  const changeDepartment = (e) => {
    const id = e?.target?.value ? e.target.value : e;
    // console.log(id, departments, selectedSpecialty);

    setSelectedDepartment(id);
    if (id == "all") {
      const newCourses = isProgram
        ? departments.flatMap((department) =>
            selectedSpecialty === "all"
              ? department.programs
              : department.specialty_id === selectedSpecialty
              ? department.programs
              : []
          )
        : departments.flatMap((department) =>
            selectedSpecialty === "all"
              ? department.courses
              : department.specialty_id === selectedSpecialty
              ? department.courses
              : []
          );
      setAvailableCourses(newCourses);
      setFilteredCourses(newCourses);
    } else {
      setDepartmentDescription(
        departments.find((department) => department.id == id).description
      );
      const newCourses = isProgram
        ? departments.find((department) => department.id == id).programs
        : departments.find((department) => department.id == id).courses;
      setAvailableCourses(newCourses);
      setFilteredCourses(newCourses);
    }

    setInputSearchValue("");
  };

  const handleSearch = () => {
    setFilteredCourses(
      availableCourses.filter((course) => {
        return (
          course.name.toLowerCase().includes(inputSearchValue.toLowerCase()) ||
          course.brief_description
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          course.tags?.some((tag) =>
            tag.toLowerCase().includes(inputSearchValue.toLowerCase())
          )
        );
      })
    );
  };

  const handleReset = () => {
    setInputSearchValue("");
    setFilteredCourses(availableCourses);
    handleSort({ target: { value: "1" } });
  };

  const displayLevel = (level) => {
    if (level === 1) {
      return t("levels.beginner");
    } else if (level === 2) {
      return t("levels.medium");
    } else if (level === 3) {
      return t("levels.advanced");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchAutoComplete.current &&
        !searchAutoComplete.current.contains(event.target)
      ) {
        setIsSearching(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleEnter = (e) => {
      if (e.key === "Enter") {
        handleSearch();
      }
    };

    if (isSearching) {
      document.addEventListener("keydown", handleEnter);
    }

    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, [isSearching, inputSearchValue]);

  const handleSort = (e) => {
    setSortValue(e.target.value);
    if (e.target.value === "1") {
      setFilteredCourses((prev) =>
        [...prev].sort(
          (a, b) => new Date(b.created_at) - new Date(a.start_date)
        )
      );
    } else if (e.target.value === "2") {
      setFilteredCourses((prev) =>
        [...prev].sort(
          (a, b) => new Date(a.created_at) - new Date(b.start_date)
        )
      );
    } else if (e.target.value === "3") {
      setFilteredCourses((prev) =>
        [...prev].sort((a, b) => b.ratings_avg_stars - a.ratings_avg_stars)
      );
    } else if (e.target.value === "4") {
      setFilteredCourses((prev) => [...prev].sort((a, b) => a.price - b.price));
    }
  };

  return (
    <>
      {!loading ? (
        <div className="container-fluid">
          <div style={{ marginTop: "20px" }}>
            <div className={classes["main__filter__container"]}>
              <div className={classes["main__filter"]}>
                <div className={classes["main__filter__search"]}>
                  <img src={SearchIcon} alt="" />
                  <div
                    className={classes["main__filter__search-field"]}
                    ref={searchAutoComplete}
                  >
                    <input
                      type="search"
                      placeholder={t("home.search_by_name")}
                      value={inputSearchValue}
                      onChange={(e) => {
                        setInputSearchValue(e.target.value);
                        if (e.target.value.length > 0) {
                          setIsSearching(true);
                        } else {
                          setIsSearching(false);
                        }
                      }}
                    />
                    {isSearching && (
                      <div
                        className={
                          classes["main__filter__search-field__auto-complete"]
                        }
                      >
                        <ul>
                          {availableCourses
                            .filter(
                              (course) =>
                                course.name
                                  .toLowerCase()
                                  .includes(inputSearchValue.toLowerCase()) ||
                                course.brief_description
                                  .toLowerCase()
                                  .includes(inputSearchValue.toLowerCase()) ||
                                course.tags?.some((tag) =>
                                  tag
                                    .toLowerCase()
                                    .includes(inputSearchValue.toLowerCase())
                                )
                            )
                            .map((course) => (
                              <li
                                onClick={() => {
                                  setInputSearchValue(course.name);
                                  setIsSearching(false);
                                }}
                                key={course.id}
                              >
                                {course.name}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className={classes["main__filter__search-btn"]}
                  onClick={handleSearch}
                >
                  <p>{t("general.search")}</p>
                </button>
              </div>
            </div>
            <div className={classes["home_courses_header"]}>
              <h1 className={classes["home_courses_title"]}>
                {t(`home.${isProgram ? "programs" : "courses"}`)}
              </h1>
              <div className={classes["main__filter__actions"]}>
                <select
                  className={classes["main__filter__sort-select"]}
                  onChange={handleSort}
                  value={sortValue}
                >
                  <option value="1">{t("general.newest")}</option>
                  <option value="2">{t("general.oldest")}</option>
                  <option value="3">{t("general.rating_high_to_low")}</option>
                  <option value="4">{t("general.price_low_to_high")}</option>
                </select>
                <button
                  className={classes["main__filter__rest-btn"]}
                  onClick={handleReset}
                >
                  {t("general.clear")}
                </button>
              </div>
            </div>
            <div className={classes["training_specialties"]}>
              <select
                className={classes["training_specialties_select"]}
                onChange={changeSpecialty}
                options={specialtiesOptions}
              >
                <option value="all" key="all">
                  {t("all_specialties")}
                </option>
                {specialtiesOptions.map((item) => (
                  <option
                    value={item.id}
                    key={item.id}
                    selected={selectedSpecialty == item.id}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
              <select
                className={classes["training_specialties_select"]}
                onChange={changeDepartment}
                options={departmentOptions}
              >
                <option
                  value="all"
                  key="all"
                  selected={selectedDepartment === "all"}
                >
                  {t("all_departments")}
                </option>
                {departmentOptions.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <MainBox className="border-8" style={{ width: "100%" }}>
              <div className={classes["department_container"]}>
                <p
                  className={classes["home_course_desc_dep"]}
                  dangerouslySetInnerHTML={{
                    __html: departmentDescription,
                  }}
                ></p>
                {filteredCourses?.length > 0 ? (
                  <div className={classes["courses_card_grid"]}>
                    {filteredCourses.map((course) => (
                      <CourseCard
                        key={course.id}
                        levelbadge={displayLevel(Number(course.level))}
                        mode={course.mode}
                        price={course.price}
                        offer={
                          course.offers.length > 0 && course.offers[0].status
                            ? course.offers[0]
                            : false
                        }
                        img={course.img}
                        title={course.name}
                        briefDesc={course.brief_description}
                        date={course.start_date.split(" ")[0]}
                        seat={course.available_seats}
                        trainers={!isProgram && course.trainers}
                        hour={course.number_hours}
                        handleSubmit={() => {
                          history.push(
                            `/${
                              isProgram
                                ? "training-programs"
                                : "training-courses"
                            }/${course.id}/details`
                          );
                          // localStorage.setItem(
                          //   "prev_page",
                          //   isProgram ? "training-programs" : "training-courses"
                          // );
                        }}
                        btn={
                          isProgram ? t("program_details") : t("course_details")
                        }
                      />
                    ))}
                  </div>
                ) : (
                  <div className={classes["no_courses"]}>
                    <p>{t(`home.no_${isProgram ? "programs" : "courses"}`)}</p>
                  </div>
                )}
              </div>
            </MainBox>
          </div>
        </div>
      ) : (
        <SkeletonCardOverlay />
      )}
    </>
  );
}

import { useState } from "react";
import { useTranslation } from "react-i18next";
import CounterNumbersListTop from "./counterNumbers/CounterNumbersListTop";

function Dashboard(props) {
  require("./Dashboard.css");
  const { t } = useTranslation();
  let str = props.location.pathname;
  const locationStr = str.split("/");
  const [isLoading, setIsLoading] = useState(true);
  const [counter, setCounter] = useState();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="admin_label-heading tw-py-8">
            {locationStr[1] == "accountant" ? t("admin.label.accountant_label") : t("admin.label.admin_label")}
          </div>
          <CounterNumbersListTop counter={counter} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

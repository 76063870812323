import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { profileInfo } from "../../../../../Services/api/toutorials/AdminUserProvider";
import noResult from "../../../../../assets/image/Noresult.png";
import i18n from "../../../../../i18n/i18n";
import TrainingCourseSpinnerCard from "../../../../TrainingCourses/TrainingCoursesLayout/TrainingCourseSpinnerCard/TrainingCourseSpinnerCard";

import { useHistory } from "react-router";

function List(props) {
  require("./list.css");
  document.body.dir = i18n.dir();
  const history = useHistory();
  const { t } = useTranslation();
  const [allPrograms, setAllPrograms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    profileInfo()
      .then((res) => {
        if (res.status && res.status == 201 && res.data.status) {
          setLoading(false);
          setAllPrograms(res.data.response);
        }
      })
      .catch((err) => {
        
        
      });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="head_box mt-5">
              <div className="head_lable">{t("programteach")}</div>
            </div>
            <div className="programs_card_grid">
              {loading == true ? (
                <>
                  <TrainingCourseSpinnerCard />
                  <TrainingCourseSpinnerCard />
                  <TrainingCourseSpinnerCard />
                </>
              ) : allPrograms.programs && allPrograms.programs.length == 0 ? (
                <span
                  className="course-details"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "50px"
                  }}
                >
                  <img src={noResult} alt="" />
                  <h5>{t("no_record")}</h5>
                </span>
              ) : (
                allPrograms.programs &&
                allPrograms.programs.map((program) => (
                  <div className="programs_card_std" key={program.id}>
                    <div
                      className="programs_card_first_std"
                      style={{
                        backgroundImage: "url(" + program.cover + ")",
                      }}
                    >
                      <div className="programs_name">
                        <div className="logo_hover">
                        </div>
                        <div className="">{program.title}</div>
                      </div>
                    </div>
                    <div className="programs_link">
                    <div
                      className="programs_card_button"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/program/${program.id}`);
                      }}
                    >
                      <button className="programs_card_button_in">
                        {t("contstudy")}
                      </button>
                    </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default List;

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../Components";
import DeleteModal from "../../../../Modals/DeleteModal/DeleteModal";
import UploadFileModal from "../../../../Modals/UploadFileModal/UploadFileModal";
import {
  deleteExam,
  editExamXML,
  exportQuiz,
  exportQuizXML,
  exportQuizes,
  getExams,
  importExam,
  importExamXML,
  sendMarks,
} from "../../../../Services/api/exams/ExamsProvider";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

function ListExams(props) {
  require("./List.css");
  const { t } = useTranslation();
  const { id, quizable_id } = useParams();
  const history = useHistory();

  const modalRef = useRef();
  const modalHandler = () => modalRef.current.dismissModal();

  const [exams, setExams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [selectedExam, setSelectedExam] = useState(null);
  const [modalContent, setModalContent] = useState(0);
  const [params, setParams] = useState({});
  const [selectedExamForEdit, setSelectedExamForEdit] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (modalContent != 0) {
      modalRef.current.showModal();
    }
  }, [modalContent]);

  const localEditExamXML = async (formData) => {
    try {
      formData.append("quizable_id", quizable_id);
      formData.append("quizable_type", props.quizable);
      setIsLoading(true);
      await editExamXML(formData, selectedExamForEdit.id);
      fetchExams(params);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.quiz.exam_imported_successfully")}
        </span>
      );
      setSelectedExamForEdit(null);
    } catch (e) {
      setIsLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {e.response.data.msg}
        </span>
      );
    }
  };

  const localImportExamXML = async (formData) => {
    try {
      formData.append("quizable_id", quizable_id);
      formData.append("quizable_type", props.quizable);
      setIsLoading(true);
      await importExamXML(formData);
      fetchExams(params);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.quiz.exam_edited_successfully")}
        </span>
      );
    } catch (e) {
      setIsLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.quiz.failed_to_edit_exam")}
        </span>
      );
    }
  };

  const localImportExam = async (formData) => {
    try {
      formData.append("quizable_id", quizable_id);
      formData.append("quizable_type", props.quizable);
      setIsLoading(true);
      await importExam(formData);
      fetchExams(params);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.quiz.exam_imported_successfully")}
        </span>
      );
    } catch (e) {
      setIsLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.quiz.failed_to_import_exam")}
        </span>
      );
    }
  };

  const localDeleteExam = async (examId) => {
    try {
      setIsLoading(true);
      setIsUpdating(true);
      await deleteExam(examId);
      setIsUpdating(false);
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {err.response?.data?.msg ?? t("failed_deleting")}
        </span>
      );
      setIsUpdating(false);
      setIsLoading(false);
    }

    fetchExams();
  };

  const renderModalContent = () => {
    if (modalContent == 1) {
      return (
        <DeleteModal
          label={t("trainer.quiz.modal.delete.accept")}
          title={t("trainer.quiz.modal.delete.label")}
          deleteMsg={t("trainer.quiz.modal.delete.description")}
          refuse={modalHandler}
          accept={() => localDeleteExam(selectedExam)}
          deleteModalRef={modalRef}
        />
      );
    } else if (modalContent == 2 || modalContent == 3 || modalContent == 4) {
      return (
        <>
          <UploadFileModal
            onFileUpload={
              modalContent == 2
                ? localImportExam
                : modalContent == 3
                ? localImportExamXML
                : localEditExamXML
            }
            importModalRef={modalRef}
          />
        </>
      );
    }
  };

  const fetchExams = (params = {}) => {
    getExams(props.quizable, quizable_id)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setMeta(res.data.response.quiz_list.meta);
          setExams(res.data.response.quiz_list.data);
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("failed_fetching")}
            </span>
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("failed_fetching")}
          </span>
        );
        setIsLoading(false);
      });
  };

  const localExportQuizes = async () => {
    setIsLoading(true);
    try {
      await exportQuizes(props.quizable, quizable_id);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchExams();
  }, []);

  useEffect(() => {
    fetchExams(params);
  }, [params]);

  const handlePageClick = (event) => {
    const currentParams = {};
    currentParams.page = event.selected + 1;
    setParams(currentParams);
  };

  return (
    <>
      <BasicModal ref={modalRef}>{renderModalContent()}</BasicModal>
      <div className={"question-canvas"}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div className="admin_label" style={{ marginBottom: 30 }}>
            <NavLink to={`/trainer/course/${id}`}>
              {t("trainer.quiz.to_course")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("trainer.quiz.exams")}
          </div>
        </div>

        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        <Paper
          variant={"outlined"}
          sx={{
            width: "100%",
            boxShadow: "2px 2px 10px #ddd",
            height: isLoading ? 500 : "auto",
          }}
        >
          {isLoading ? (
            <div className={"tajah-question-progress"}>
              <CircularProgress />
            </div>
          ) : (
            <div
              className={"tajah-form-container"}
              style={{
                boxShadow: "none",
                borderRadius: 0,
              }}
            >
              <div className={"tajah-pre-table-header course-exams"}>
                <span>{t("trainer.quiz.exams")}</span>
                <div
                  className={
                    "tajah-pre-table-header-actions course-exams__actions"
                  }
                >
                  <button
                    className={"btn btn-success"}
                    style={{
                      backgroundColor: "#006d77",
                      borderColor: "#006d77",
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: 10,
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: document.body.dir == "rtl" ? 10 : 0,
                      marginRight: document.body.dir == "rtl" ? 0 : 10,
                    }}
                    onClick={() => {
                      localExportQuizes();
                    }}
                  >
                    <i
                      style={{ color: "white", fontSize: 15 }}
                      className="far fa-sign-out"
                    ></i>
                    <span
                      style={{
                        color: "white",
                        marginLeft: document.body.dir == "rtl" ? 0 : 5,
                        marginRight: document.body.dir == "rtl" ? 5 : 0,
                      }}
                    >
                      {t("trainer.quiz.export")} Excel
                    </span>
                  </button>
                  <button
                    className={"btn btn-success"}
                    style={{
                      backgroundColor: "#006d77",
                      borderColor: "#006d77",
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: 10,
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: document.body.dir == "rtl" ? 10 : 0,
                      marginRight: document.body.dir == "rtl" ? 0 : 10,
                    }}
                    onClick={() => {
                      setModalContent((prevState) => {
                        if (prevState == 2) {
                          modalRef.current.showModal();
                        }

                        return 2;
                      });
                    }}
                  >
                    <i
                      style={{ color: "white", fontSize: 15 }}
                      className="far fa-sign-in"
                    ></i>
                    <span
                      style={{
                        color: "white",
                        marginLeft: document.body.dir == "rtl" ? 0 : 5,
                        marginRight: document.body.dir == "rtl" ? 5 : 0,
                      }}
                    >
                    {t("trainer.quiz.import")} JSON
                    </span>
                  </button>
                  <button
                    className={"btn btn-success"}
                    style={{
                      backgroundColor: "#006d77",
                      borderColor: "#006d77",
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: 10,
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: document.body.dir == "rtl" ? 10 : 0,
                      marginRight: document.body.dir == "rtl" ? 0 : 10,
                    }}
                    onClick={() => {
                      setModalContent((prevState) => {
                        if (prevState == 3) {
                          modalRef.current.showModal();
                        }

                        return 3;
                      });
                    }}
                  >
                    <i
                      style={{ color: "white", fontSize: 15 }}
                      className="far fa-sign-in"
                    ></i>
                    <span
                      style={{
                        color: "white",
                        marginLeft: document.body.dir == "rtl" ? 0 : 5,
                        marginRight: document.body.dir == "rtl" ? 5 : 0,
                      }}
                    >
                      {t("trainer.quiz.import")} ZIP
                    </span>
                  </button>
                  <button
                    className={"btn btn-success"}
                    style={{
                      backgroundColor: "#006d77",
                      borderColor: "#006d77",
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: 10,
                      alignItems: "center",
                    }}
                    onClick={() => {
                      history.push(
                        "/trainer/course/" +
                          id +
                          "/" +
                          props.quizable +
                          "/" +
                          quizable_id +
                          "/assessment/add"
                      );
                    }}
                  >
                    <i
                      style={{ color: "white", fontSize: 15 }}
                      className="far fa-plus"
                    ></i>
                    <span
                      style={{
                        color: "white",
                        marginLeft: document.body.dir == "rtl" ? 0 : 5,
                        marginRight: document.body.dir == "rtl" ? 5 : 0,
                      }}
                    >
                      {t("trainer.quiz.add_exam")}
                    </span>
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">{t("trainer.quiz.crud.exam_title")}</th>
                      <th scope="col">{t("crud.list.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exams.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.title}</td>
                          <td className={"exam-table-action-item"}>
                            <IconsTooltip
                              title={t("trainer.quiz.tooltip.edit")}
                              content={
                                <i
                                  onClick={() => {
                                    history.push(
                                      `/trainer/course/${id}/${props.quizable}/${quizable_id}/exam/edit/${item.id}`
                                    );
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: item.active ? "black" : "red",
                                  }}
                                  className="fal fa-edit"
                                ></i>
                              }
                            />

                            <IconsTooltip
                              title={t("trainer.quiz.tooltip.delete")}
                              content={
                                <i
                                  onClick={() => {
                                    setModalContent((prevState) => {
                                      if (prevState == 1) {
                                        modalRef.current.showModal();
                                      }

                                      return 1;
                                    });
                                    setSelectedExam(item.id);
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: "black",
                                  }}
                                  className="fal fa-trash"
                                ></i>
                              }
                            />

                            <IconsTooltip
                              title={t("trainer.quiz.tooltip.sort_questions")}
                              content={
                                <>
                                  {item.random == 0 ? (
                                    <i
                                      onClick={() => {
                                        history.push(
                                          `/trainer/course/${id}/${props.quizable}/${quizable_id}/exam/${item.id}/sort`
                                        );
                                      }}
                                      style={{
                                        fontSize: 20,
                                        color: "black",
                                      }}
                                      className="fal fa-sort"
                                    ></i>
                                  ) : (
                                    <i
                                      style={{
                                        fontSize: 20,
                                        color: "black",
                                        opacity: 0.3,
                                      }}
                                      className="fal fa-sort"
                                    ></i>
                                  )}
                                </>
                              }
                            />

                            <IconsTooltip
                              title={t("trainer.quiz.tooltip.grades")}
                              content={
                                <i
                                  onClick={() => {
                                    history.push(
                                      `/trainer/course/${id}/${props.quizable}/${quizable_id}/exam/${item.id}/grades`
                                    );
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: !item.is_corrected ? "red" : "black",
                                  }}
                                  className="fal fa-list"
                                ></i>
                              }
                            />

                            {/* statistics */}

                            <IconsTooltip
                              title={t("analysis.statistics")}
                              content={
                                <i
                                  className="fal fa-eye"
                                  onClick={() => {
                                    history.push(
                                      `/trainer/course/${id}/${props.quizable}/${quizable_id}/exam/${item.id}/statistics/${item.id}`
                                    );
                                    // history.push({pathname:`/trainer/exam/statistics/${item.id}`})
                                  }}
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                    backgroundColor: "transparent",
                                    borderWidth: 0,
                                    fontSize: 20,
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              }
                            />
                            {/* statistics */}
                            <IconsTooltip
                              title={t("trainer.quiz.tooltip.send_marks")}
                              content={
                                <i
                                  onClick={async () => {
                                    setIsLoading(true);
                                    try {
                                      await sendMarks(item.id);
                                      toast.success(
                                        <span
                                          style={{
                                            fontSize: 13,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {t(
                                            "trainer.quiz.grades_sent_successfully"
                                          )}
                                        </span>
                                      );
                                    } catch (err) {
                                      
                                      toast.error(
                                        <span
                                          style={{
                                            fontSize: 13,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {t(
                                            "trainer.quiz.failed_to_send_grades"
                                          )}
                                        </span>
                                      );
                                    }
                                    setIsLoading(false);
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: "black",
                                  }}
                                  className="fa fa-paper-plane"
                                ></i>
                              }
                            />

                            <IconsTooltip
                              title={`${t("trainer.quiz.tooltip.exportFile")} JSON`}
                              content={
                                <i
                                  onClick={async () => {
                                    setIsLoading(true);
                                    try {
                                      await exportQuiz(item.id);
                                    } catch (err) {
                                      
                                    }
                                    setIsLoading(false);
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: "black",
                                  }}
                                  className="far fa-sign-out"
                                ></i>
                              }
                            />

                            <IconsTooltip
                              title={`${t("trainer.quiz.tooltip.exportFile")} ZIP`}
                              content={
                                <i
                                  onClick={async () => {
                                    setIsLoading(true);
                                    try {
                                      await exportQuizXML(item.id);
                                    } catch (err) {
                                      
                                    }
                                    setIsLoading(false);
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: "black",
                                  }}
                                  className="far fa-download"
                                ></i>
                              }
                            />

                            <IconsTooltip
                              title={`${t("trainer.quiz.import")} ZIP`}
                              content={
                                <i
                                  onClick={async () => {
                                    setSelectedExamForEdit(item);
                                    setModalContent((prevState) => {
                                      if (prevState == 4) {
                                        modalRef.current.showModal();
                                      }

                                      return 4;
                                    });
                                  }}
                                  style={{
                                    fontSize: 20,
                                    color: "black",
                                  }}
                                  className="far fa-upload"
                                ></i>
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <ReactPaginate
                  nextLabel={t("next")}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={meta.totalPages}
                  previousLabel={t("previous")}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          )}
        </Paper>
      </div>
    </>
  );
}

export default ListExams;

import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { couponsListConstants } from "../../Constants/coupons/couponsList.constants";

// GET COUPONS LIST
export const fetchCouponsList = ({ page = 1, perPage = 10, filter, type }) => {
  return async (dispatch) => {
    dispatch({
      type: couponsListConstants.GET_COUPONS_LIST_REQUEST,
    });

    await connector
      .get(
        type === "sendCoupon"
          ? env.list_gifts_coupons
          : type === "receivedCoupon"
          ? env.list_received_coupons
          : env.list_all_coupon,
        {
          params: {
            page,
            perPage,
            ...filter,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: couponsListConstants.GET_COUPONS_LIST_SUCCESS,
            payload: {
              coupons:
                type === "sendCoupon" || type === "receivedCoupon"
                  ? response?.data?.data.gifts.data
                  : response?.data?.coupons.data,
              meta:
                type === "sendCoupon" || type === "receivedCoupon"
                  ? response?.data?.data.gifts.meta
                  : response?.data?.coupons.meta,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: couponsListConstants.GET_COUPONS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: couponsListConstants.GET_COUPONS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

// DELETE COUPON
export const deleteCoupon = (id, closeModal) => {
  return (dispatch) => {
    dispatch({ type: couponsListConstants.DELETE_COUPON_REQUEST });
    connector
      .delete(URLBuilder(env.delete_coupon, { id: id }))
      .then((response) => {
        if (response.status && response.status === 200 && response.data.status) {
          dispatch({
            type: couponsListConstants.DELETE_COUPON_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: couponsListConstants.DELETE_COUPON_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: couponsListConstants.DELETE_COUPON_REJECTED,
          payload: err.response.data.msg,
        });
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      })
      .finally(() => {
        closeModal();
      });
  };
};

// UPDATE COUPON STATUS
export const updateCouponStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: couponsListConstants.UPDATE_COUPON_STATUS_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.coupon_status, {
          id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: couponsListConstants.UPDATE_COUPON_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: couponsListConstants.UPDATE_COUPON_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: couponsListConstants.UPDATE_COUPON_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};

import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { toServerTime } from "../../../../../Services/Timezone";
import CloseIcon from "../../../../../assets/icons/remove-red.svg";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import Checkbox from "../../../../Shared/Components/Checkbox/Checkbox";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../../Shared/Components/Input/Input";
import MainDatePicker from "../../../../Shared/Components/MainDatePicker/MainDatePicker";
import Select from "../../../../Shared/Components/Select/Select";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./editLessonModal.module.css";
import { getTeams } from "../../../../../Services/api/teams/TeamsProvider";

const EditLessonModal = ({
  onClose,
  onSave,
  lesson,
  courseId,
  fetchCourse,
  lessons,
}) => {
  const { t } = useTranslation();

  const [startDateHours, setStartDateHours] = useState(null);
  const [startDateMinutes, setStartDateMinutes] = useState(null);
  const [endDateHours, setEndDateHours] = useState(null);
  const [endDateMinutes, setEndDateMinutes] = useState(null);
  const [hasSubmittedLesson, setHasSubmittedLesson] = useState(false);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "startDate") {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endDate") {
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    }
  };

  useEffect(() => {
    currentDate();
    fetchTeams();
    setLoading(false);
  }, []);
  async function fetchTeams() {
    const res = await getTeams(courseId, { status: "active", type: "lesson", perPage: 1000 });
    if (res.status == 200) {
      setTeams(
        res.data.data?.map((team) => ({
          id: team.id,
          value: team.id,
          label: team.name,
        }))
      );
    }
  }
  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours === "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  const lessonOptions = [
    {
      id: 0,
      value: 0,
      label: t("do_not_depend_on_previous_lesson"),
    },
    ...lessons
      .filter((item) => !item.emptyField && item.id !== lesson.id)
      .map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.title,
        };
      }),
  ];

  return (
    <div className={classes.edit_lesson_modal}>
      <div className={classes.edit_lesson_modal_header}>
        <h2 className={classes.edit_lesson_modal_title}>
          {t("trainer.course.lesson.restriction.title")}
        </h2>
        <div className={classes.edit_lesson_modal_close} onClick={onClose}>
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      {loading ? (
        <SkeletonCardOverlay />
      ) : (
        <Formik
          initialValues={{
            title: lesson?.title || "",
            start_date: lesson?.start_date
              ? new Date(getLocaleTime(new Date(lesson?.start_date)))
              : setHours(
                  setMinutes(new Date(), startDateMinutes),
                  startDateHours
                ),
            end_date: lesson?.end_date
              ? new Date(getLocaleTime(new Date(lesson?.end_date)))
              : setHours(setMinutes(new Date(), endDateMinutes), endDateHours),
            previous_lesson_id: lesson?.previous_lesson_id || null,
            team_id: lesson?.team_id || null,
            have_deadline:
              lesson?.start_date && lesson?.end_date ? true : false,
            coworking_space: lesson?.team_id ? true : false,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setLoading(true);
              setSubmitting(true);
              let data = { ...values };
              data.previous_lesson_id =
                Number(data.previous_lesson_id) === 0
                  ? null
                  : data.previous_lesson_id;

              if (data.have_deadline) {
                data.start_date = toServerTime(
                  formatDate(data.start_date) +
                    " " +
                    formatTime(data.start_date)
                );
                data.end_date = toServerTime(
                  formatDate(data.end_date) + " " + formatTime(data.end_date)
                );
              } else {
                data.start_date = "deleted";
                data.end_date = "deleted";
              }

              if(!data.coworking_space) {
                data.team_id = null;
              }

              let response = await onSave(data);
              if (response.status === 201 && response.data.status) {
                setLoading(false);
                setSubmitting(false);
                await fetchCourse();
              }
            } catch (err) {
            } finally {
              setLoading(false);
              setSubmitting(false);
              onClose();
            }
          }}
          validateOnChange={hasSubmittedLesson}
          validate={(values) => {
            const errors = {};
            // if (!values.title) {
            //   errors.title = t("crud.errors.required");
            // }
            if (values.have_deadline && !values.start_date) {
              errors.start_date = t("crud.errors.required");
            }
            if (values.have_deadline && !values.end_date) {
              errors.end_date = t("crud.errors.required");
            }
            if (values.coworking_space && !values.team_id) {
              errors.team_id = t("crud.errors.required");
            }

            setHasSubmittedLesson(true);
            return errors;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div className="form-fields">
                  <div className={`col-12`}>
                    <Select
                      name="previous_lesson_id"
                      label={t("previous_lesson")}
                      placeholder={t("previous_lesson")}
                      value={values.previous_lesson_id}
                      onchange={handleChange}
                      error={errors.previous_lesson_id}
                      options={lessonOptions || []}
                      initialValue={t("do_not_depend_on_previous_lesson")}
                    />
                  </div>
                  <div className={`col-12`}>
                    <Checkbox
                      name="have_deadline"
                      label={t("have_deadline")}
                      onChange={(event) => {
                        setFieldValue("have_deadline", event.target.checked);
                      }}
                      checked={values.have_deadline}
                      error={errors.have_deadline}
                    />
                  </div>
                  {values.have_deadline ? (
                    <>
                      <div className={`col-12 col-md-6`}>
                        <MainDatePicker
                          label={t(`general.start_date`)}
                          name="start_date"
                          error={errors.start_date}
                          value={values.start_date}
                          onChange={(date) => {
                            setFieldValue("start_date", date);
                            values.end_date < date &&
                              setFieldValue("end_date", date);
                            let CurrentDate = new Date();
                            let CurrentDateFormate =
                              CurrentDate.toISOString().split("T")[0];
                            let DataPickerValue = date
                              .toISOString()
                              .split("T")[0];
                            if (
                              new Date(DataPickerValue).getTime() >
                              new Date(CurrentDateFormate).getTime()
                            ) {
                              setStartDateHours(0);
                              setStartDateMinutes(0);
                            } else {
                              currentDate("startDate");
                            }
                          }}
                          minDate={new Date()}
                          minTime={setHours(
                            setMinutes(new Date(), startDateMinutes),
                            startDateHours
                          )}
                          maxTime={setHours(setMinutes(new Date(), 30), 23)}
                          required
                        />
                      </div>
                      <div className={`col-12 col-md-6`}>
                        <MainDatePicker
                          label={t(`general.end_date`)}
                          name="end_date"
                          error={errors.end_date}
                          value={values.end_date}
                          onChange={(date) => {
                            setFieldValue("end_date", date);

                            let CurrentDate = new Date();
                            let CurrentDateFormate =
                              CurrentDate.toISOString().split("T")[0];
                            let DataPickerValue = date
                              .toISOString()
                              .split("T")[0];
                            if (
                              new Date(DataPickerValue).getTime() >
                              new Date(CurrentDateFormate).getTime()
                            ) {
                              setEndDateHours(0);
                              setEndDateMinutes(0);
                            } else {
                              currentDate("endDate");
                            }
                          }}
                          minDate={new Date(values.start_date)}
                          minTime={setHours(
                            setMinutes(new Date(), startDateMinutes),
                            startDateHours
                          )}
                          maxTime={setHours(setMinutes(new Date(), 30), 23)}
                          required
                        />
                      </div>
                    </>
                  ) : null}
                  <div className={`col-12`}>
                    <Checkbox
                      name="coworking_space"
                      label={t("coworking_space")}
                      onChange={(event) => {
                        setFieldValue("coworking_space", event.target.checked);
                      }}
                      checked={values.coworking_space}
                      error={errors.coworking_space}
                    />
                  </div>
                  {values.coworking_space ? (
                    <div className={`col-12`}>
                      <Select
                        name="team_id"
                        label={t("coworking_space")}
                        placeholder={t("coworking_space")}
                        value={values.team_id}
                        onchange={handleChange}
                        error={errors.team_id}
                        options={teams || []}
                        initialValue={t("coworking_space")}
                      />
                    </div>
                  ) : null}
                </div>
                <div
                  className={
                    classes["course-details-actions"] +
                    " course-details-actions"
                  }
                >
                  <CustomButton
                    value={t("submit")}
                    colors="#036c77"
                    type="submit"
                    disable={isSubmitting}
                    loading={isSubmitting}
                    classes={classes["course-details-actions-btn"]}
                  />
                  <CustomButton
                    value={t("crud.placeholders.cancel")}
                    colors="#036c77"
                    variant="outlined"
                    type="button"
                    disable={isSubmitting}
                    loading={isSubmitting}
                    action={onClose}
                    classes={classes["course-details-actions-btn"]}
                  />
                </div>
              </form>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditLessonModal;

import DocumentIcon from "../../../../assets/icons/document.svg";
import classes from "./filePreview.module.css";

const FilePreview = ({
  icon = DocumentIcon,
  name,
  backgroundColor = "rgb(223, 223, 223, 0.2)",
  file,
  ImgbackgroundColor,
}) => {
  const downloadFile = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = URL.createObjectURL(blob).split("/").slice(-1)[0];
        link.click();
      })
      .catch(console.error);
  };

  return (
    <div
      className={`${classes["file-preview"]} `}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={classes["file-preview-img"]}
        style={{ backgroundColor: ImgbackgroundColor }}
      >
        <img src={icon} alt="" />
      </div>
      <div className={classes["file-preview-name"]}>{name}</div>
      {file && (
        <button
          className={classes["file-preview-download"]}
          onClick={() => downloadFile(file)}
        >
          <i className="fa fa-download"></i>
        </button>
      )}
    </div>
  );
};

export default FilePreview;

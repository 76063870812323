import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import Select from "react-select";
import {getTraineeCourseAnalysis, getUserAnalysis,} from "../../../../Services/api/Analysis/AnalysisProvider";
import {useHistory} from "react-router";
import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";

const TraineeCourse = () => {
  require("../Analysis.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [usersId, setUsersId] = useState(null);
  const [error, setError] = useState(null);
  const [course, setCourses] = useState([]);
  const [search, setSearch] = useState(false);

  useEffect(() => {
    getUserAnalysis().then((res) => {
      if (res.status && res.status == 200 && res.data.status) {
        setUsers(res.data.data);
        setIsLoading(false);
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("failed_fetching")}
          </span>
        );
      }
    });
  }, []);

  const apiHandler = (e) => {
    setIsLoading(true);
    if (usersId === null) {
      setError(t("analysis.userError"));
      setIsLoading(false);
    } else {
      setSearch(true);
      setError(null);
      getTraineeCourseAnalysis(usersId).then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setIsLoading(false);
          setCourses(res.data.response.courses);
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("failed_fetching")}
            </span>
          );
        }
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="admin_label">
            <NavLink to="/admin">{t("Home")}</NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("sidebar.label.traineeAnalysis")}
          </div>
          <>
            <div className="counter_analysis">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  width: "100%",
                  gap: "50px",
                  alignItems: "flex-end",
                }}
              >
                <div className="select_analysis" style={{ width: "100%" }}>
                  <div className="select_label">{t("analysis.allUser")}</div>
                  <Select
                    name="users"
                    id="users"
                    options={users}
                    getOptionLabel={(option) =>
                      i18n.language === "ar" ? option.name_ar == null ? option.name_en  : option.name_ar : option.name_en
                    }
                    getOptionValue={(option) => option.id}
                    isClearable={true}
                    onChange={(item) => {
                      if (item) {
                        setUsersId(item.id);
                      } else {
                        setUsersId(null);
                      }
                    }}
                    placeholder={t("analysis.select")}
                  />
                  <p
                    className={"form-input-error-space"}
                    style={{ position: "absolute" }}
                  >
                    {error ? error : null}
                  </p>
                </div>

                <div
                  className="select_analysis"
                  style={{
                    width: "100%",
                  }}
                >
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#126e77",
                      color: "#fff",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                    }}
                    onClick={apiHandler}
                  >
                    {t("analysis.search")}
                  </button>
                </div>
              </div>

              {isLoading ? (
                <div
                  style={{
                    height: "25vh",
                    marginTop: "20px",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <>
                  {search == false ? (
                    <h5
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "70px 0",
                      }}
                    >
                      {t("analysis.userError")}
                    </h5>
                  ) : (
                    <table
                      className="table table-bordered"
                      style={{
                        margin: 0,
                        marginTop: "50px",
                        padding: 0,
                      }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{t("courses")}</th>
                          <th scope="col">{t("lessons")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {course.map((c, index) => (
                          <tr key={c.id}>
                            <td>{index + 1}</td>
                            <td>{c.name}</td>
                            <td>
                              <i
                                className="fal fa-eye"
                                onClick={(e) => {
                                  history.push(
                                    `/admin/trainee-analysis/${usersId}/${c.id}`
                                  );
                                }}
                                style={{
                                  marginLeft: 5,
                                  marginRight: 5,
                                  backgroundColor: "white",
                                  borderWidth: 0,
                                  fontSize: 20,
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default TraineeCourse;

import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { TrainerContext } from "../../../Services/api/toutorials/trainerContext";
import { getTrainer } from "../../../Services/api/toutorials/trainerProvider";
import { default as arrow, default as icon } from "../../../assets/image/Icon.png";
import { calcNumChunk, chunk } from "../../../utils";
import UserCard from "../../Shared/Components/UserCard/UserCard";
import TrainerSpinnerCard from "../../trainers/TrainerSpinnerCard";
import TrainerCards from "./trainerCards";

function Trainers() {
  require("./trainers.css");
  const { t } = useTranslation();
  const trainerContext = useContext(TrainerContext);
  let trainerData = [];
  const size = calcNumChunk(trainerContext.trainers.length, 4);
  trainerData = chunk(trainerContext.trainers, size);

  const trainerSpinner = [];

  for (let i = 0; i < trainerContext.initialSpinnersCarousel; i++) {
    trainerSpinner.push(<TrainerSpinnerCard key={i} />);
  }

  useEffect(() => {
    getTrainer()
      .then((res) => {
        trainerContext.setIsContentLoaded(true);
        if (res.status && res.status === 200 && res.data.status) {
          trainerContext.setTrainers(res.data.response.data);
        }
      })
      .catch((error) => {
        
      });
    return () => {
      trainerContext.setTrainers([]);
      trainerContext.setIsContentLoaded(false);
    };
  }, []);

  return (
    <section
      className="trainer_section"
      style={{
        paddingBottom: "70px",
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="trainers">
              <div className="head_box">
                <div className="head_lable">{t("ProfessionalTrainers")}</div>

                <NavLink to="/trainers" className="text-dark">
                  <div className="head_view">
                    <div className="">{t("ViewAll")}</div>
                    <div className="arrow_seeall">
                      <img src={icon} alt="" className="arrow_icon" />
                    </div>
                  </div>
                </NavLink>
              </div>

              <div id="myCarousel" className="carousel slide" data-ride="carousel" data-interval="false">
                <div className="carousel-inner" role="listbox">
                  {trainerData.map((trainer, index) => (
                    <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                      {trainerContext.isContentLoaded
                        ? trainer.map((item) => (
                            <div className="col-lg-3 col-md-6" key={`cards${item.name_en}`}>
                              <NavLink to={`/trainers/trainer-details/${item.id}`}>
                                <UserCard
                                  name={item.name_ar}
                                  rate={item.rating}
                                  postionTitle={item.job_title_en}
                                  description={item.description_ar}
                                  img={item.img}
                                  countCourses={item.countCourses}
                                  countUsers={item.countUsers}
                                />
                              </NavLink>
                            </div>
                          ))
                        : trainerSpinner}
                    </div>
                  ))}
                </div>
                <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                  <img src={arrow} alt="arrow icon left" className="i" />
                </a>
                <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                  <img src={arrow} alt="arrow icon right" className="i" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Trainers;

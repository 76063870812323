import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/icons/Btn.svg";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import LessonDiscussionItem from "../TrainingCourseContentLessons/LessonContentView/LessonDiscussionItem";
import LessonQuizItem from "../TrainingCourseContentLessons/LessonContentView/LessonQuizItem";
import UnitItem from "./UnitItem";

import { useState } from "react";
import CircularProgressBar from "./CircularProgressBar";

export default function ({
  index,
  courseId,
  chapter,
  isCourseClosed,
  accessableAfterClose,
  saveWatchingProgressAPI,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const buildProgress = (data, type) => {
    let progress = {
      user_watch_evidences_count: 0,
      user_watch_trackers_count: 0,
      user_progress_evidences_count: 0,
      user_progress_trackers_count: 0,
    };

    const calculateProgress = (progress, item) => {
      // console.log({item})
      progress.user_watch_evidences_count += Number(
        item.user_watch_evidences_count
      );
      progress.user_watch_trackers_count += Number(
        item.user_watch_trackers_count
      );
      progress.user_progress_evidences_count += Number(
        item.user_progress_evidences_count
      );
      progress.user_progress_trackers_count += Number(
        item.user_progress_trackers_count
      );

      // console.log({progress})
    };

    if (type === "chapter") {
      calculateProgress(progress, data);
      data.sub_chapters.forEach((subChapter) => {
        calculateProgress(progress, subChapter);
        subChapter.lessons.forEach((lesson) => {
          calculateProgress(progress, lesson);
        });
      });
    } else if (type === "subChapter") {
      calculateProgress(progress, data);
      data.lessons.forEach((lesson) => {
        calculateProgress(progress, lesson);
      });
    }

    return {
      watch_percentage:
        (Number(progress.user_watch_evidences_count) /
          Number(progress.user_watch_trackers_count)) *
          100 || 0,
      progress_percentage:
        (Number(progress.user_progress_evidences_count) /
          Number(progress.user_progress_trackers_count)) *
          100 || 0,
    };
  };
  const progress = buildProgress(chapter, "chapter");
  return (
    <div className="tw-rounded-lg tw-border tw-border-gray-300 tw-divide-y tw-divide-gray-300">
      <button
        type="button"
        onClick={() => setIsOpen((state) => !state)}
        className="tw-text-start tw-w-full tw-p-4 tw-flex tw-flex-col md:tw-flex-row tw-space-y-4 md:tw-space-y-0 tw-items-center tw-justify-between tw-space-s-4"
      >
        <div className="tw-flex tw-items-center tw-space-s-6">
          <div className="tw-bg-[#006e78] tw-rounded-lg tw-text-xl tw-text-white tw-h-10 tw-aspect-square tw-flex tw-items-center tw-justify-center tw-font-sans">
            {index + 1}
          </div>

          <div className="tw-space-y-2">
            <div className="tw-text-xl tw-font-bold">{chapter.name}</div>
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-space-s-6">
          {progress && Object.keys(progress).length ? (
            <div className="tw-flex tw-items-center tw-space-s-3">
              <CircularProgressBar
                title={t("progress")}
                percent={progress.progress_percentage}
                color="#24b3b9"
              />
              <CircularProgressBar
                title={t("watchingProgress")}
                percent={progress.watch_percentage}
                color="#f57a30"
              />
            </div>
          ) : null}
          <ArrowLeftIcon
            className={`tw-h-8 tw-w-8 tw-transition-all ${
              isOpen ? "tw-rotate-90" : ""
            }`}
          />
        </div>
      </button>
      {isOpen ? (
        <div className="tw-p-4 tw-space-y-4">
          {(!isCourseClosed || accessableAfterClose) &&
          chapter.quiz_list?.length ? (
            <div className="tw-space-y-4">
              {chapter.quiz_list.map((quiz, index) => (
                <LessonQuizItem
                  key={quiz.id}
                  quiz={quiz}
                  courseId={courseId}
                  chapterId={chapter.id}
                  isCourseClosed={isCourseClosed}
                  onOpen={() => {
                    saveWatchingProgressAPI({
                      watchable_type: "quiz",
                      watchable: quiz,
                      chapter_id: chapter.id,
                    });
                  }}
                />
              ))}
            </div>
          ) : null}
          {(!isCourseClosed || accessableAfterClose) &&
          chapter.interactive_contents?.length ? (
            <div className="tw-space-y-4">
              {chapter.interactive_contents.map((discussion, index) => (
                <LessonDiscussionItem
                  isCourseClosed={isCourseClosed}
                  key={discussion.id}
                  discussion={discussion}
                  courseId={courseId}
                  chapterId={chapter.id}
                  onOpen={() => {
                    !isCourseClosed &&
                      saveWatchingProgressAPI({
                        watchable_type: "discussion",
                        watchable: discussion,
                        chapter_id: chapter.id,
                      });
                  }}
                />
              ))}
            </div>
          ) : null}
          {chapter.sub_chapters?.length ? (
            <div className="tw-space-y-4">
              {chapter.sub_chapters.map((subChapter, index) => (
                <UnitItem
                  key={subChapter.id}
                  index={index}
                  courseId={courseId}
                  chapterId={chapter.id}
                  unit={subChapter}
                  progress={buildProgress(subChapter, "subChapter")}
                  isCourseClosed={isCourseClosed}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

import React from "react";
import classes from "./avatar.module.css";
const Avatar = ({ image, active }) => {
  return (
    <div className={classes["avatar"]}>
      <img src={image} alt="" />
      <div
        className={`${classes["avatar__status"]} ${
          active
            ? classes["avatar__status-active"]
            : classes["avatar__status-inactive"]
        }`}
      ></div>
    </div>
  );
};

export default Avatar;

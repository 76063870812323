import { Stack, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import EvaluationApi from "../../../../Services/api/evaluation";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import { InputWithLabel, MainBtn, PopUp } from "../../../Shared/Components";
import LoadingSpinner from "../../../Shared/Components/LoadingSpinner/LoadingSpinner";
import { PopUpClose } from "../../../Shared/icons";
const EvaluationPopUp = ({ addCriteria, handleCloseAddCriteria, setTableData, courseId, id }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const theme = useTheme();
  const [isVerified, setVerified] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getItem = async () => {
    setIsLoading(true);
    const data = await EvaluationApi.show(id);
    setData(data.data.data);
    setIsLoading(false);
  };
  const [totalWeights, setTotalWeights] = useState({});

  // const getTotals = async () => {
  //   // setIsLoading(true)
  //   const data = await EvaluationApi.totalWeights();
  //   setTotalWeights(data.data.data);
  //   // setIsLoading(false)
  // };

  useEffect(() => {
    // getTotals();
    if (id) {
      getItem();
    }
  }, []);
  return (
    <PopUp
      fullWidth
      width={"650px"}
      px={"0px"}
      py={"28px"}
      open={addCriteria}
      setOpen={handleCloseAddCriteria}
      ariaDescribedby={"add-criteria-answer-popup"}
      maxWidth={"650px"}
      minHeight={"300px"}
    >
      <Stack
        position={"relative"}
        sx={{
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
          pb: "28px",
          mb: "28px",
        }}
      >
        <Stack
          position={"absolute"}
          sx={{
            top: "8px",
            left: i18n.language == Lang.AR ? "25px" : "unset",
            right: i18n.language == Lang.EN ? "25px" : "unset",
          }}
        >
          <MainBtn disableRipple variant={"text"} onClick={handleCloseAddCriteria}>
            <PopUpClose />
          </MainBtn>
        </Stack>
        <Stack px={"25px"}>
          <Typography variant={"h6"} color={"primary.main"}>
            {id ? t("answerCriteria.editCriteria") : t("answerCriteria.addNewCriteria")}
          </Typography>
        </Stack>
      </Stack>
      <Stack px={"25px"}>
        <Typography variant={"subtitle1"} fontWeight={600} color={"primary.main"} mb={"25px"}>
          {t("answerCriteria.criteriaTitle")}
        </Typography>
        {isLoading && (
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              minHeight: "375px",
            }}
          >
            <LoadingSpinner />
          </Stack>
        )}
        {!isLoading && (
          <Formik
            initialValues={{
              title_en: data.title_en || "",
              title_ar: data.title_ar || "",
              weight: data.weight || "",
              course_id: courseId,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              if (isVerified) {
                try {
                  if (id) {
                    let response = await EvaluationApi.update(id, values);
                    if (response.status === 200 && response.data.status) {
                      setTableData((prev) => {
                        return {
                          ...prev,
                          data: prev.data.map((e) => {
                            if (id === e.id) {
                              return { ...e, ...response.data.data };
                            } else {
                              return e;
                            }
                          }),
                        };
                      });
                      toast.success(<span style={{ fontSize: 14 }}>{t("criterionSuccessfullyEdited")}</span>);
                      values.title_en = "";
                      values.title_ar = "";
                      values.weight = "";
                      handleCloseAddCriteria();
                    } else {
                      alert("Failure");
                    }
                  } else {
                    let response = await EvaluationApi.store(values);
                    if (response.status === 200 && response.data.status) {
                      setTableData((prev) => {
                        return {
                          ...prev,
                          data: [response.data.data, ...prev.data],
                        };
                      });
                      toast.success(<span style={{ fontSize: 14 }}>{t("criterionSuccessfully")}</span>);
                      values.title_en = "";
                      values.title_ar = "";
                      values.weight = "";
                      handleCloseAddCriteria();
                    } else {
                      alert("Failure");
                    }
                  }

                  setSubmitting(false);
                } catch (error) {
                  Object.keys(error.response.data.errors).forEach((key) => {
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>{error.response.data.errors[key][0]}</span>
                    );
                  });
                }
              } else {
                toast.error(<span style={{ fontSize: 14 }}>{t("isRobot")}</span>);
              }
            }}
            validateOnChange={hasSubmitted}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};

              if (!values.title_en) {
                errors.title_en = t("crud.errors.required");
              }

              if (!values.title_ar) {
                errors.title_ar = t("crud.errors.required");
              }

              if (!values.weight) {
                errors.weight = t("crud.errors.required");
              } else if (values.weight < 0 || values.weight > 100) {
                errors.weight = `${t("criteriaweight")} 100`;
              }
              // else if (
              //   (data.status == 1 || !data.status) &&
              //   (values.weight < 0 ||
              //     values.weight >
              //       100 -
              //         (totalWeights.total_active_weights -
              //           (data.status == 1 ? data.weight : 0)))
              // ) {
              //   errors.weight = `${t("criteriaweight")} ${
              //     101 -
              //     (totalWeights.total_active_weights -
              //       (data.status == 1 ? data.weight : 0))
              //   }`;
              // }
              if (Object.keys(errors).length === 0) {
                setVerified(true);
              }
              return errors;
            }}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <Stack component={"form"} onSubmit={handleSubmit} spacing={"25px"}>
                <InputWithLabel
                  id="titleCriteriaArabic"
                  name="title_ar"
                  label={"titleCriteriaArabic"}
                  placeholder={"titleCriteriaArabic"}
                  type="text"
                  handleChange={handleChange}
                  values={values}
                  error={errors.title_ar ? errors.title_ar : null}
                />
                <InputWithLabel
                  id="titleCriteriaEnglish"
                  name="title_en"
                  label={"titleCriteriaEnglish"}
                  placeholder={"titleCriteriaEnglish"}
                  type="text"
                  handleChange={handleChange}
                  values={values}
                  error={errors.title_en ? errors.title_en : null}
                />
                <Stack>
                  <InputWithLabel
                    id="criteriaWeight"
                    name="weight"
                    label={"criteriaWeight"}
                    placeholder={"criteriaWeight"}
                    type="number"
                    handleChange={handleChange}
                    values={values}
                    error={errors.weight ? errors.weight : null}
                  />
                  {/* <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    gap={"5px"}
                    alignItems={"center"}
                    mt={"30px"}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color={"grey.200"}
                    >
                      {t("total_active_weights")}:
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      color={"grey.200"}
                    >
                      {100 -
                        (data.status == 0
                          ? totalWeights.total_active_weights
                          : +values.weight +
                            (totalWeights.total_active_weights -
                              (id ? +data.weight : 0)))}
                    </Typography>
                  </Stack> */}
                </Stack>
                <Stack alignItems="flex-end">
                  <MainBtn
                    sx={{
                      width: "165px",
                      height: "60px",
                      borderRadius: "8px",
                    }}
                    width={182}
                    height={75}
                    borderRadius={"6px"}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("done")}
                  </MainBtn>
                </Stack>
              </Stack>
            )}
          </Formik>
        )}
      </Stack>
    </PopUp>
  );
};

export default EvaluationPopUp;

import { Pagination } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteSavedSearch } from "../../../../Redux/Actions/search.action";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import SearchView from "../../../../assets/icons/search-view.png";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "../search.module.css";


const SearchHistoryTable = ({getSavedSearchesHandler,setShowDeleteModal,setSelectedSearchId,setShowSaveSearchModal,selectedSearchDetailsHandler}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { savedSearches, savedSearchesLoading, savedSearchesMeta } = useSelector((state) => state.searchReducer)


 


  const searchHistoryTableActions = [
		{
			id: "view-details",
			icon: <img className={classes["search-view-icon"]} src={SearchView} alt="" />,
			action: (id) => {
        history.push(`/admin/search/history/${id}`)
			},
			tooltip: t("search.view"),
		},
		{
			id: "edit",
			icon: <img src={editIcon} alt="" />,
			action: (id) => {
        selectedSearchDetailsHandler(id)
				setShowSaveSearchModal(true);
			},
			tooltip: t("general.edit"),
		},

		{
			id: "delete",
			icon: <img src={DeleteIcon} alt="" />,
			action: (id) => {
        setShowDeleteModal(true)
        setSelectedSearchId(id)
      },
			tooltip: t("general.delete"),
		},
	];

  const columns = [
    {
      id: "serial",
      name: "#",
      key: "serial",
      cell: ({ rowData,rowIndex }) => <>{rowIndex + 1}</>
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "name",
      name: t("search.save_search_name"),
      key: "name",
      cell: ({ rowData }) => <>{rowData?.name || "-"}</>,
    },
    {
      id: "source",
      name: t("search.search_source"),
      key: "source",
      cell: ({ rowData }) => <>{rowData?.source || "-"}</>,
    },
    {
      id: "created_at",
      name: t("search.search_date"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
    },
    // {
    //   id: "actions",
    //   name: t("general.actions"),
    //   key: "actions",
    //   cell: ({ rowData }) => (
    //     <button className={classes["saved-search__button"]}
    //       onClick={() => {
    //         history.push(`/admin/search/history/${rowData.id}`)
    //       }}
    //     >{t("general.search")}</button>
    //   ),
    // },
    {
			id: "actions",
			name: t("general.actions"),
			key: "actions",
			cell: ({ rowData }) => (
				<TableActionsColumn
					actions={searchHistoryTableActions}
					id={rowData.id}
				/>
			),
			sortable: false,
		},

  ]


  return (
    <>
      <MainTable
        columns={columns}
        data={savedSearches || []}
        isLoading={savedSearchesLoading}
      />
      <Pagination
        count={savedSearchesMeta?.totalPages || 0}
        showFirstButton
        showLastButton
        variant="outlined"
        shape="rounded"
        className="main-pagination"
        onChange={(e, page) => {
          getSavedSearchesHandler(page)
        }}
      />
    </>
  )
}

export default SearchHistoryTable
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import { TrainerProfileContext } from "../../../Services/api/toutorials/trainerProfileContext";
import { editTrainerOverview, getTrainerProfile } from "../../../Services/api/toutorials/trainerProvider";
import HomeIcon from "../../../assets/icons/home.svg";
import facebook from "../../../assets/image/facebook.png";
import linkedin from "../../../assets/image/linkedin.png";
import twitter from "../../../assets/image/twitter.png";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import Input from "../../Shared/Components/Input/Input";
import InputFile from "../../Shared/Components/InputFile/InputFile";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import { Hint } from "./../../../Components";

function EditOverView({ editHandler, trainerInfo, getTrainerInfoHandler }) {
  require("./editOverview.css");
  const { t, i18n } = useTranslation();
  const [description_ar, setDescription_ar] = useState("");
  const [description_en, setDescription_en] = useState("");
  const trainerProfileContext = useContext(TrainerProfileContext);
  let info = trainerProfileContext?.trainersProfile?.info;

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setDescription_ar(info?.description_ar);
    setDescription_en(info?.description_en);
  }, []);

  const isValidURL = (url) => {
    var res = url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "private_profile",
      page: t("sidebar.label.private_profile"),
      pagePath: "/private-profile",
    },
    {
      id: `peyment_request`,
      page: t("private_profile.about_me"),
      pagePath: "#",
      active: true,
    },
  ];

  return (
    <div style={{ padding: "2rem 0" }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            <MainBox>
              <MainBoxHead title={t("edit_about_me")} />
              <Formik
                initialValues={{
                  description_ar: trainerInfo?.description_ar || "",
                  description_en: trainerInfo?.description_en || "",

                  job_title_en: trainerInfo?.job_title_en == null ? "" : trainerInfo?.job_title_en,
                  job_title_ar: trainerInfo?.job_title_ar == null ? "" : trainerInfo?.job_title_ar,
                  linkedin: trainerInfo?.linkedin == null ? "" : trainerInfo?.linkedin,
                  twitter: trainerInfo?.twitter == null ? "" : trainerInfo?.twitter,
                  facebook: trainerInfo?.facebook == null ? "" : trainerInfo?.facebook,
                  file: "",
                }}
                onSubmit={async (values) => {
                  let body = new FormData();
                  body.append("description_ar", values.description_ar);
                  body.append("description_en", values.description_en);
                  body.append("job_title_ar", values.job_title_ar);
                  body.append("job_title_en", values.job_title_en);
                  body.append("linkedin", values.linkedin);
                  body.append("facebook", values.facebook);
                  body.append("twitter", values.twitter);
                  body.append("file", values.file);
                  editTrainerOverview(body)
                    .then((res) => {
                      if (res.status && res.status == 200 && res.data.status) {
                        editHandler();
                        getTrainerInfoHandler();
                      }
                      // getTrainerProfile()
                      //   .then((res) => {
                      //   })
                      //   .catch((error) => {
                      //
                      //   });
                      toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("edited_over_view")}</span>);
                    })
                    .catch((err) => {
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {typeof err === "string" ? (
                            <span
                              style={{
                                fontSize: 12,
                                fontWeight: "500",
                                display: "block",
                              }}
                            >
                              {err}
                            </span>
                          ) : err.data ? (
                            <>
                              <span
                                style={{
                                  fontSize: 13,
                                  fontWeight: "bold",
                                  display: "block",
                                }}
                              >
                                {err.data.msg}
                              </span>
                              <ul>
                                {Object.keys(err.data.errors).map((key) => {
                                  return (
                                    <li>
                                      <span style={{ fontSize: 11 }}>{err.data.errors[key]}</span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          ) : (
                            t("Failure_in_service")
                          )}
                        </span>
                      );
                    });
                }}
                enableReinitialize
                validateOnChange={hasSubmitted}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!isValidURL(values.twitter) && values.twitter !== "") {
                    errors.twitter = t("valid_links");
                  }

                  if (!isValidURL(values.facebook) && values.facebook !== "") {
                    errors.facebook = t("valid_links");
                  }

                  if (!isValidURL(values.linkedin) && values.linkedin !== "") {
                    errors.linkedin = t("valid_links");
                  }

                  return errors;
                }}
              >
                {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                  <form onSubmit={handleSubmit} className="aboutme_edit_form">
                    <div className="trainer_edit">
                      <Input
                        label={t("job_title_ar")}
                        type="text"
                        name="job_title_ar"
                        placeholder={t("job_title_ar")}
                        onChange={handleChange}
                        value={values.job_title_ar}
                      />
                    </div>

                    <div className="trainer_edit">
                      <Input
                        label={t("job_title_en")}
                        type="text"
                        name="job_title_en"
                        placeholder={t("job_title_en")}
                        onChange={handleChange}
                        value={values.job_title_en}
                      />
                    </div>
                    <div className="trainer_edit">
                      <div className="overview_add">
                        <label>{t("add_ar_description")}</label>
                        <Hint hint={t("pleaseaddbr")} color="#6c757d" fontSize={12} />
                      </div>
                      <SunEditor
                        placeholder={t("add_ar_description")}
                        value={values.description_ar}
                        defaultValue={values.description_ar}
                        onChange={(e) => {
                          handleChange({
                            target: { name: "description_ar", value: e },
                          });
                        }}
                        setOptions={{
                          height: 150,
                          rtl: i18n.language === "ar" ? true : false,
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "align",
                              "horizontalRule",
                              "list",
                              "lineHeight",
                              "codeView",
                            ],
                          ],
                        }}
                        setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                      />
                    </div>
                    <div className="trainer_edit">
                      <div className="overview_add">
                        <label>{t("add_en_description")}</label>

                        <Hint hint={t("pleaseaddbr")} color="#6c757d" fontSize={12} />
                      </div>

                      <SunEditor
                        placeholder={t("add_en_description")}
                        value={values.description_en}
                        defaultValue={values.description_en}
                        onChange={(e) => {
                          handleChange({
                            target: { name: "description_en", value: e },
                          });
                        }}
                        setOptions={{
                          height: 150,
                          rtl: i18n.language === "ar" ? true : false,
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "align",
                              "horizontalRule",
                              "list",
                              "lineHeight",
                              "codeView",
                            ],
                          ],
                        }}
                        setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                      />
                    </div>

                    {/* <div style={{ width: "50%" }}>
                      <InputFile
                        name="file"
                        type="document"
                        documentWithoutTitle={true}
                        label={t("trainer.course.lesson.attachment")}
                        placeholder={t("trainer.course.lesson.attachment")}
                        setFieldValue={setFieldValue}
                        value={values.package}
                        error={errors.package}
                        handleChangeAboutMe={(event) => {
                          let file = event.target.files[0];
                          let reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onload = () => {
                            setFieldValue("file", event.target.files[0]);
                          };
                          reader.onerror = function (error) {
                            
                          };
                        }}
                      />
                      <Hint
                        hint={t("hint.overview.file")}
                        color="#6c757d"
                        fontSize={12}
                      />
                    </div> */}
                    <div style={{ width: "50%" }}>
                      <label htmlFor="" className="overview_add_top_aboutme">
                        {t("add_social_links")}
                      </label>
                      <div className="soical_edit  my-2">
                        <Input
                          label={
                            <div style={{ display: "flex" }}>
                              <img src={twitter} alt="" className="profile_soical_img mx-2" />
                              {t("add_twitter_links")}
                            </div>
                          }
                          type="text"
                          name="twitter"
                          placeholder={t("add_twitter_links")}
                          onChange={handleChange}
                          value={values.twitter}
                        />
                      </div>
                      <p className={"form-input-error-space mx-5"}>{errors.twitter ? errors.twitter : null}</p>
                      <div className="soical_edit  my-2">
                        <Input
                          label={
                            <div style={{ display: "flex" }}>
                              <img src={facebook} alt="" className="profile_soical_img mx-2" />
                              {t("add_facebook_links")}
                            </div>
                          }
                          type="text"
                          name="facebook"
                          placeholder={t("add_facebook_links")}
                          onChange={handleChange}
                          value={values.facebook}
                        />
                      </div>
                      <p className={"form-input-error-space mx-5"}>{errors.facebook ? errors.facebook : null}</p>

                      <div className="soical_edit my-2">
                        <Input
                          label={
                            <div style={{ display: "flex" }}>
                              {" "}
                              <img src={linkedin} alt="" className="profile_soical_img mx-2" />
                              {t("add_linkedin_links")}
                            </div>
                          }
                          type="text"
                          name="linkedin"
                          placeholder={t("add_linkedin_links")}
                          onChange={handleChange}
                          value={values.linkedin}
                        />
                      </div>
                      <p className={"form-input-error-space mx-5"}>{errors.linkedin ? errors.linkedin : null}</p>
                    </div>
                    <div className="container_buttons_edit_aboutme">
                      <div className="overview_button_div_edit_aboutme">
                        <button
                          className="overview_button_aboutme"
                          style={{ backgroundColor: "#006d77" }}
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {t("ok")}
                        </button>

                        <button
                          className="overview_button_aboutme"
                          style={{
                            backgroundColor: "#fff",
                            color: "#006d77",
                            border: "1px solid #006d77",
                          }}
                          onClick={() => editHandler()}
                        >
                          {t("cancel")}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </MainBox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditOverView;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../../Services/api/Roles/RolesProvider";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Profile from "../../../../trainees/privateProfile/editProfile/profile/Profile";

export default function SystemUserEdit() {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    getUser(id)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setUserData(res.data.response);
          setLoading(false);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-12">
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  height: "100vh",
                }}
              >
                <SkeletonCardOverlay />
              </div>
            </div>
          ) : (
            <Profile userData={userData} setUserData={setUserData} />
          )}
        </div>
      </div>
    </div>
  );
}

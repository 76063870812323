import React from "react";
import { CourseProvider } from "../../Services/api/courses/courseContext";
import { DepartmentProvider } from "../../Services/api/toutorials/categoryContext";
import { ClassificationProvider } from "../../Services/api/toutorials/classificationContext";
import { TrainerProvider } from "../../Services/api/toutorials/trainerContext";
import { Certification, CoursesList, MainSearch, Program, Trainers } from "./";
import MainSection from "./MainSection/MainSection";
import CarosalHomePage from "./CarosalHomePage/CarosalHomePage";

function Home() {
  return (
    <>
      <DepartmentProvider>
        <ClassificationProvider>
          <CourseProvider>
            <MainSection />
            <CarosalHomePage />
            <Certification />
            {/* <MainSearch /> */}
            <CoursesList />
          </CourseProvider>
        </ClassificationProvider>
      </DepartmentProvider>
      {/* <Certification /> */}
      {/* <Tracks /> */}
      <Program />
      <TrainerProvider>
        <Trainers />
      </TrainerProvider>
      {/* <Testimonial/>    */}
      {/* <Partners/>  */}
    </>
  );
}

export default Home;

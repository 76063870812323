import React from 'react'
import classes from "./StarsRatingDynamic.module.css"
import Rating from '@mui/material/Rating';


const StarsRatingDynamic = ({ rating }) => {

  const starsCount = 5; 
  const emptyStarStyles = [];
  for (let i = 1; i <= starsCount; i++) {
    emptyStarStyles.push(classes[`star-${i}`]);
  }

  return <Rating name="read-only" value={rating} style={{ color: '#fff', }} readOnly />

}

export default StarsRatingDynamic
import { Formik } from "formik";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import IconTooltips from "../../../../Components/tooltips/IconsTooltip";
import { decrypt } from "../../../../Helpers/Secret";
import {
  assignTrainees,
  getTraineesForSponsors,
  searchTrainee,
} from "../../../../Services/api/Financial/FinancialProvider";
import { Lang } from "../../../../utils";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";

function AddNewTrainee() {
  require("./Sponsor.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [errorSearch, setErrorSearch] = useState("");
  const [traineeArray, setTraineeArray] = useState([]);
  const [traineeTable, setTraineeTable] = useState([]);
  const [course, setCourses] = useState({});
  const [program, setProgram] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    getTraineesForSponsors(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCourses(res.data.data.course);
          setProgram(res.data.data.program);
          // setTrainee(res.data.data.map((d) => d.trainees.map((t) => t)));
          // setLoading(false);
        }
      })
      .catch((err) => {});
  }, [id]);

  const searchTypeSelect = [
    { key: "name", value: t("sponsor.name") },
    { key: "email", value: t("sponsor.email") },
  ];

  const searchHandle = async (searchType) => {
    const payload = {
      type: program ? "program" : "course",
      id: program ? program.id : course.id,
    };
    payload[`${searchType}`] = search;

    if (search == "") {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("sponsor.fillData")}</span>);
      return;
    }

    try {
      setLoadingTable(true);
      let response = await searchTrainee(payload);
      if (response.status === 200 && response.data.status) {
        if (response.data.response.trainee !== null) {
          setTraineeTable((prev) => [
            ...prev,
            response.data.response.trainee.id
              ? response.data.response.trainee
              : {
                  name_ar: response.data.response.trainee,
                  name_ar: response.data.response.trainee,
                },
          ]);
          setTraineeArray((prev) => [
            ...prev,
            response.data.response.trainee.id ? response.data.response.trainee.id : response.data.response.trainee,
          ]);
          setSearch("");
          setLoadingTable(false);
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("sponsor.userNotfound")}</span>);
          setLoadingTable(false);
        }
      }
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error.response && error.response.data.msg ? error.response.data.msg : "Failure in service"}
        </span>
      );
      setLoadingTable(false);
    }
  };

  const deleteHandler = (name) => {
    const arr = traineeTable.filter((t) => (i18n.language === Lang.AR ? t.name_ar !== name : t.name_en !== name));
    setTraineeTable((prev) => [...arr]);
    const newArrayTrainee = arr.map((a) => (a.id ? a.id : Lang.AR ? a.name_ar : a.name_en));

    setTraineeArray(newArrayTrainee);
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          marginBottom: "100px",
        }}
      >
        <div className="admin_flex">
          <div className="admin_label">
            <NavLink to={`${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}`}>
              {t("admin.label.admin_label")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            <NavLink
              to={`${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant/sponsor" : "/admin/sponsor"}`}
            >
              {t("sponsor.sponsor")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("sponsor.addSponsor")}
          </div>
        </div>
        {isLoading == true ? (
          <>
            <div className={`table_bank_tran`}>
              <h3
                className="table_header"
                style={{
                  paddingBottom: "20px",
                }}
              >
                <div>{t("sponsor.sponsor")}</div>
              </h3>
              <Formik
                initialValues={{
                  partner_id: id,
                  trainees: [],
                  searchType: "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const dataPayload = {
                    ...values,
                  };
                  delete dataPayload.searchType;

                  dataPayload.trainees = [...traineeArray];

                  if (dataPayload.trainees.length == 0) {
                    toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("sponsor.traineeMust")}</span>);
                    return;
                  }

                  assignTrainees(dataPayload)
                    .then((res) => {
                      setIsLoading(true);
                      history.push(
                        `${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/sponsor`
                      );
                      setIsLoading(false);
                    })
                    .catch((err) => {
                      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data.msg}</span>);
                      setIsLoading(false);
                      setSubmitting(false);
                    });
                }}
                validateOnChange={false}
                validate={(values) => {
                  const errors = {};
                  if (!values.searchType) {
                    errors.searchType = t("crud.errors.required");
                  }
                  return errors;
                }}
              >
                {({ values, handleSubmit, setFieldValue, errors }) => (
                  <form onSubmit={handleSubmit} style={{ width: "100%", padding: "0 50px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "100%",
                        marginTop: 30,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "100%",
                          marginTop: 30,
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        >
                          <label>{t("sponsor.searchType")}</label>
                          <Select
                            name="searchType"
                            id="searchType"
                            options={searchTypeSelect}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            onChange={(status) => {
                              if (status) {
                                setFieldValue("searchType", status.key);
                              } else {
                                setFieldValue("searchType", null);
                              }
                            }}
                            placeholder={t("sponsor.searchType")}
                          />
                          <p className={"form-input-error-space"}>{errors.searchType ? errors.searchType : null}</p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "100%",
                          marginTop: 30,
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        >
                          <label>{t("sponsor.searchTrainee")}</label>
                          <input
                            id="search"
                            type="search"
                            name="search"
                            className="admin_filter_input"
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            placeholder={t("sponsor.searchEmail")}
                            value={search}
                          />
                        </div>
                        <div
                          style={{
                            flex: 0.4,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        >
                          <button
                            style={{
                              marginTop: "33px",
                              padding: "6px 0",
                              borderRadius: "5px",
                            }}
                            className="admin_add_button"
                            onClick={(e) => {
                              e.stopPropagation();
                              searchHandle(values.searchType, values.type, values.course_id, values.program_id);
                            }}
                            type="button"
                          >
                            {t("sponsor.search")}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "100%",
                        marginTop: 30,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("sponsor.listTrainees")}</label>

                        <div
                          className="table-responsive"
                          style={{
                            width: "100%",
                          }}
                        >
                          <table className="table table-bordered">
                            {loadingTable ? (
                              <div
                                className="mt-5"
                                style={{
                                  width: "100%",
                                  height: "20vh",
                                }}
                              >
                                <SkeletonCard />
                              </div>
                            ) : (
                              <>
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{t("sponsor.user")}</th>
                                    <th scope="col">{t("sponsor.rels")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {traineeTable.length === 0 ? (
                                    <tr>
                                      <td colSpan={4}>{t("no_record")}</td>
                                    </tr>
                                  ) : (
                                    traineeTable.map((trainee, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{i18n.language === Lang.AR ? trainee.name_ar : trainee.name_ar}</td>
                                        <td>
                                          <div className="release_icon">
                                            <IconTooltips
                                              title={t("modal.coupon.buttons.delete")}
                                              content={
                                                <i
                                                  className="fal fa-trash-alt"
                                                  onClick={() =>
                                                    deleteHandler(
                                                      i18n.language === Lang.AR ? trainee.name_ar : trainee.name_ar
                                                    )
                                                  }
                                                  style={{
                                                    marginLeft: 5,
                                                    marginRight: 5,
                                                    backgroundColor: "white",
                                                    borderWidth: 0,
                                                    fontSize: 20,
                                                    color: "red",
                                                    cursor: "pointer",
                                                  }}
                                                ></i>
                                              }
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                        <p className={"form-input-error-space"}>{errors.trainees ? errors.trainees : null}</p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        width: "20%",
                        marginTop: 20,
                      }}
                    >
                      <button
                        style={{
                          width: "100%",
                        }}
                        type="submit"
                        className="admin_add_button"
                      >
                        {t("sponsor.save")}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "50vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default AddNewTrainee;

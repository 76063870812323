import CircularProgress from "@mui/material/CircularProgress";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { addFileToQuestionBank } from "../../../../../../../Services/api/exams/ExamsProvider";

function FileSection (props) {
    require("./FileSection.css")

    const {t} = useTranslation();
    const [file, setFile] = useState(props.mediaId);
    const [isLoading, setIsLoading] = useState(false);

    const inputFile = useRef(null);

    return (
        <>
            <div className={"tajah-question-file-uploader"} onClick={() => {
                if (inputFile) {
                    inputFile.current.click();
                }
            }}>
                {
                    isLoading?
                        <CircularProgress /> :
                    file ?
                        <button className={"admin_add_button"} style={{backgroundColor: 'red'}} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();

                            setFile(null);
                            props.setMediaId("");
                            inputFile.current.value = "";
                        }}>
                            Delete File
                        </button>
                        :
                        <>
                            <i className={"fas fa-file"} />
                            <span style={{marginTop: 10}}>Upload File</span>
                        </>
                }
                <input id="file" name="file" type="file" accept={".pptx, .ppt, .docx, .doc, .zip, .rar, .html, .pdf, image/* , video/*"} ref={inputFile}
                       style={{display: "none"}} onChange={(event) => {
                    let file = event.target.files[0]
                    if (file) {
                        props.setSubmitting(true);
                        setIsLoading(true);
                        let reader = new FileReader()
                        reader.readAsDataURL(file)
                        reader.onload = () => {
                            let formData = new FormData();
                            formData.append('file', event.target.files[0]);
                            formData.append('type_id', props.typeId);
                            addFileToQuestionBank(formData).then(async (res) => {
                                props.setMediaId(res.data.media_id);
                                props.setSubmitting(false);
                                setFile(event.target.files[0]);
                                setIsLoading(false);
                            }).catch((error) => {
                                props.setSubmitting(false);
                                setIsLoading(false);
                                if (error.response.data && error.response.data.errors) {
                                    Object.keys(error.response.data.errors).forEach(key => {
                                        toast.error(
                                            <span style={{fontSize: 13, fontWeight: "bold"}}>
                                                {error.response.data.errors[key][0]}
                                            </span>
                                        );
                                    })
                                }
                            });
                        };
                        reader.onerror = function (error) {
                        }
                    } else {
                        inputFile.current.value = "";
                    }
                }}/>
            </div>
            <p></p>
        </>
    )
}

export default FileSection;
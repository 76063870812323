import React from "react";
import classes from "./tableHeadActions.module.css";
const TableHeadActions = ({ actions }) => {
  return (
    <>
      {actions && !!actions.length && (
        <div className={classes["table-head-actions"]}>
          {actions.map((action, index) => (
            <div
              key={index}
              className={`${classes["table-head-actions__list"]} ${
                classes[`table-head-actions__list-${action.theme}`]
              }`}
              onClick={action.action}
            >
              <div className={classes["table-head-actions__list__icon"]}>
                {action.icon}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default TableHeadActions;

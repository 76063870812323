import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { usePusherContext } from "../../Contexts/PusherContext";
import {
  getlivechatSettings,
  livechatChangeMessage,
  postLivechatCloseSession,
  postLivechatSendMessage,
} from "../../Redux/Actions/livechat/livechat.action";
import toLocalTimezone from "../../Services/Timezone";
import chatPopup from "../../assets/icons/chat-popup.svg";
import logo from "../../assets/image/Logo Tebx.png";
import profile from "../../assets/image/profile-avatar.png";
import NotificationSound from "../../assets/sounds/notification.mp3";
import i18n from "../../i18n/i18n";
import { Lang } from "../../utils";
import WarningMessage from "../Shared/Components/WarningMessage/WarningMessage";
import ChatBubbleHeader from "./ChatBubbleHeader";
import ChatBubbleInputs from "./ChatBubbleInputs";
import ChatBubbleRegisteration from "./ChatBubbleRegisteration";
import classes from "./chatBubble.module.css";

function ChatBubble() {
  require("./chatBubble.css");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { livechat_setting, livechat_send_message, livechat_messages, livechat_message_change } = useSelector(
    (state) => state.livechatReducer
  );
  const [livechatSetting, setLivechatSetting] = useState({});
  const [livechatSendMessageRes, setLivechatSendMessageRes] = useState({});
  const [display, setDisplay] = useState("none");
  const inputFile = useRef(null);
  const [messages, setMessages] = useState([
    {
      img: logo,
      created_at: new Date(),
      message: t("chat.potmsg"),
      direction: i18n.language === Lang.AR ? "left" : "right",
      id: 0,
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");
  const [isStart, setIsStart] = useState(false);
  const [showChatAfterReg, setShowChatAfterReg] = useState(false);
  const currentUserid = localStorage.getItem("userId");
  const [pusherChannel, setPusherChannel] = useState(null);

  const { pusher } = usePusherContext();

  function colseChat() {
    localStorage.removeItem("chat_username");
    localStorage.removeItem("chat_email");
    setInputMessage("");
    setShowChatAfterReg(false);
    if (display == "none") {
      setDisplay("block");
    } else {
      setDisplay("none");
    }
    setIsStart(false);
    if (livechatSendMessageRes.data?.response?.chat_session_id) {
      setMessages([
        {
          img: logo,
          created_at: new Date(),
          message: t("chat.potmsg"),
          id: 0,
          direction: i18n.language === Lang.AR ? "left" : "right",
        },
      ]);
      dispatch(postLivechatCloseSession(livechatSendMessageRes.data?.response?.chat_session_id));

      setLivechatSendMessageRes({});
    }
  }

  function showHideChat() {
    if (display == "none") {
      setDisplay("block");
      const chatId = localStorage.getItem("chatId");
      if (chatId) {
        fetchMessages(livechatSendMessageRes.data?.response?.chat_session_id);
      }
    } else {
      setDisplay("none");
    }
  }

  const fetchPreferences = () => {
    if (livechat_setting?.status === 200) {
      setLivechatSetting(livechat_setting.data.setting);
    }
  };

  function fetchMessages(chatId) {
    if (!pusherChannel && chatId) setPusherChannel(pusher.subscribe("chat-" + chatId));
    // dispatch(getlivechatMessage(chatId));
  }

  function sendMessage() {
    if (inputMessage.trim() !== "") {
      let formData = new FormData();
      if (localStorage.getItem("chatId") != null) formData.append("chat_id", localStorage.getItem("chatId"));
      if (localStorage.getItem("userId") != null) formData.append("user_id", localStorage.getItem("userId"));
      if (localStorage.getItem("chat_username") != null) formData.append("name", localStorage.getItem("chat_username"));
      if (localStorage.getItem("chat_email") != null) formData.append("email", localStorage.getItem("chat_email"));

      if (livechatSendMessageRes.data?.response?.chat_session_id)
        formData.append("chat_session_id", livechatSendMessageRes.data?.response?.chat_session_id);

      formData.append("message", inputMessage);
      dispatch(postLivechatSendMessage(formData));

      messages.push({
        img: localStorage.getItem("img"),
        created_at: new Date(),
        message: inputMessage,
        user_id: localStorage.getItem("userId"),
        direction: i18n.language === Lang.AR ? "right" : "left",
      });
      setInputMessage("");
    }
  }

  const handleOnEnter = (event) => {
    if (event.key == "Enter") {
      sendMessage(inputMessage);
    }
  };

  const refreshChat = () => {
    fetchMessages(livechatSendMessageRes.data?.response?.chat_session_id);
  };

  // TRIGGERED ON MOUNT
  useEffect(() => {
    return () => {
      const chatId = localStorage.getItem("chatId");
      pusherChannel?.unbind("App\\Events\\MessageSent");
      if (chatId) pusher?.unsubscribe("chat-" + chatId);
    };
  }, []);

  useEffect(() => {
    fetchPreferences();
  }, [livechat_setting]);

  useEffect(() => {
    dispatch(getlivechatSettings());
    localStorage.removeItem("chat_email");
    localStorage.removeItem("chat_username");
    if (localStorage.getItem("chat_email") == null) {
      setIsStart(false);
    }
  }, []);

  useEffect(() => {
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind("App\\Events\\MessageSent");

      pusherChannel.bind("App\\Events\\MessageSent", function (data) {
        // if (!messages.find((message) => message.id === data.message.id)) {
        //   var audio = new Audio(NotificationSound);
        //   audio.play();
        //   toast.warning(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("chat.receivedMSG")}</span>);

        //   setMessages((oldArray) => [...oldArray, data.message]);
        // }
        setMessages((oldArray) => [...oldArray, data.message]);
      });
    }
  }, [pusherChannel]);

  useEffect(() => {
    if (livechat_messages.status === 200) {
      setMessages(livechat_messages.data.response.messages);
    }
  }, [livechat_messages]);

  useEffect(() => {
    if (livechat_message_change && livechatSendMessageRes.data?.response?.chat_session_id) {
      refreshChat();

      dispatch(livechatChangeMessage());
    }
  }, [livechat_message_change, dispatch, livechatSendMessageRes]);

  useEffect(() => {
    setLivechatSendMessageRes(livechat_send_message);
  }, [livechat_send_message]);

  return (
    <>
      {livechatSetting.settings?.chat_service && (
        <div>
          <div id="chat-circle" className={classes["chat-circle"]} onClick={showHideChat}>
            <span className={classes.chaticon}>
              <img src={chatPopup} alt="" style={{ width: "70px", height: "70px" }} />
            </span>
          </div>
          <div
            className={classes["form-container-chat"]}
            style={{
              display: display,
            }}
          >
            <ChatBubbleHeader showHideChat={colseChat} refreshChat={refreshChat}></ChatBubbleHeader>
            {localStorage.getItem("chat_email") == null &&
            !showChatAfterReg &&
            localStorage.getItem("email") == null ? (
              <div className={classes.chatBubbleRegisteration}>
                <ChatBubbleRegisteration
                  setShowChatAfterReg={setShowChatAfterReg}
                  showHideChat={showHideChat}
                  setIsStart={setIsStart}
                ></ChatBubbleRegisteration>
              </div>
            ) : livechatSetting.settings.chat_auto_reply ? (
              <div className={classes.warningMessage}>
                <WarningMessage
                  img={logo}
                  description={
                    livechatSetting.settings.chat_auto_reply_message ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: livechatSetting.settings.chat_auto_reply_message,
                        }}
                      />
                    ) : (
                      t("chat.pleasecommunicate")
                    )
                  }
                  label={livechatSetting.settings.chat_auto_reply_message ? "" : t("chat.outsideworkinghours")}
                />
              </div>
            ) : !isStart && localStorage.getItem("chat_email") == null ? (
              <div className={classes.warningMessage}>
                <WarningMessage
                  img={logo}
                  label={
                    livechatSetting.settings.chat_welcome_message ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: livechatSetting.settings.chat_welcome_message,
                        }}
                      />
                    ) : (
                      t("chat.chat_welcome_message")
                    )
                  }
                  btn={true}
                  isStart={() => setIsStart(true)}
                />
              </div>
            ) : (
              <div className={classes.message}>
                {messages &&
                  messages.reverse().map((item) => {
                    const dateTimeAgo = moment(new Date(toLocalTimezone(item.created_at))).format(
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    return (
                      <div className={classes.relative}>
                        <div
                          className={`${classes["live-chat-container"]} 
                       ${
                         item.user_id == currentUserid
                           ? i18n.language === Lang.AR
                             ? classes.right
                             : classes.left
                           : i18n.language === Lang.AR
                           ? classes.left
                           : classes.right
                       }
                      `}
                          style={{
                            float: item.direction && item.direction,
                          }}
                        >
                          <div className={classes["content-container"]} key={item.id}>
                            {item.img != null || item.user != null ? (
                              <img
                                className={classes["admin_img"]}
                                src={item.img || item.user.img || item.chat.user.img || item.user.image}
                                alt=""
                              ></img>
                            ) : (
                              <img className={classes["admin_img"]} src={profile} alt=""></img>
                            )}
                            <div className={item.user_id == currentUserid ? classes.right : classes.left}>
                              <div>
                                <span className={classes.date}>{dateTimeAgo}</span>
                              </div>
                              <div>
                                {item.message?.startsWith("message-") ? (
                                  <a href={process.env.REACT_APP_SERVER_PATH + "storage/chat-manager/" + item.message}>
                                    <img
                                      src={process.env.REACT_APP_SERVER_PATH + "storage/chat-manager/" + item.message}
                                      style={{
                                        width: "auto",
                                        maxHeight: 400,
                                      }}
                                      alt=""
                                    />
                                  </a>
                                ) : (
                                  item.message
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
            {(localStorage.getItem("email") == null || isStart) &&
              (localStorage.getItem("chat_email") == null || isStart) && (
                <ChatBubbleInputs
                  inputMessage={inputMessage}
                  disabled={
                    localStorage.getItem("chat_email") == null &&
                    !showChatAfterReg &&
                    localStorage.getItem("email") == null
                      ? true
                      : false || livechatSetting.settings.chat_auto_reply
                  }
                  inputFile={inputFile}
                  setInputMessage={setInputMessage}
                  handleOnEnter={handleOnEnter}
                  messages={messages.reverse()}
                  sendMessage={sendMessage}
                  // sendAttachment={sendAttachment}
                ></ChatBubbleInputs>
              )}
          </div>
        </div>
      )}
    </>
  );
}

export default ChatBubble;

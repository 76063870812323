import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  approveBooth,
  getExhibitionBoothsBookingDetails,
  getExhibitionPositions,
  rejectBooth,
} from "../../../Services/api/Exhibition/ExhibitionProvider";

import { NavLink, useLocation } from "react-router-dom";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  DOCUMENT_PDF,
  acceptAudios,
  acceptDocuments,
  acceptImages,
  validateAudio,
  validateDocument,
  validateImage,
} from "../../../Services/Validator";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import BoothDetailsAudio from "./BoothDetailsAudio";
import BoothDetailsFile from "./BoothDetailsFile";
import BoothDetailsSelect from "./BoothDetailsSelect";
import BoothsDetailsInput from "./BoothsDetailsInput";
import RejectBoothModal from "./RejectBoothModal";
import "./boothsBookingDetails.css";
const BoothsBookingDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [boothData, setBoothData] = useState(null);
  const [Banner, setBanner] = useState([0, 1, 2]);
  const [ExhibitionData, setExhibitionData] = useState(null);
  const [ExhibitionPositions, setExhibitionPositions] = useState([]);
  const [position, setposition] = useState(null);
  const [filesDoc, setFilesDoc] = useState([0]);
  const [rejectBoothReason, setRejectBoothReason] = useState("");
  const [rejectBoothReasonError, setRejectBoothReasonError] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);

  const getBoothsBookingDetails = async () => {
    try {
      const response = await getExhibitionBoothsBookingDetails(id);
      if (response.status == 200) {
        setIsLoading(false);
        setBoothData(response.data.booth);
        setposition(response.data.booth.position);
      }
    } catch (error) {
      
    }
  };

  const _position = (id) => {
    setposition(id);
  };
  const getProducts = (documents) => {
    var files = {};
    documents.forEach((i, k) => {
      files = {
        ...files,
        [`id_${k}`]: i.id,
        [`name_${k}`]: i.name,
        [`image_${k}`]: {
          name: i.name,
          src: i.image,
        },
        [`description_${k}`]: i.description,
        [`url_title_${k}`]: i.url_title,
        [`url_${k}`]: i.url,
        [`video_${k}`]: i.video,
      };
    });
    return files;
  };

  const getBanners = (documents) => {
    var files = {};
    documents.forEach((i, k) => {
      files = {
        ...files,
        [`banners_id_${k}`]: i.id,
        [`banners_position_${k}`]: i.position,
        [`banners_image_${k}`]: {
          src: i.image,
        },
      };
    });
    return files;
  };

  const _ExhibitionPositions = async (id) => {
    let res = await getExhibitionPositions(id);
    if (res.status == 200) {
      setExhibitionPositions(res.data.data.positions);
    }
  };

  useEffect(() => {
    getBoothsBookingDetails();
    if (location.state) {
      setExhibitionData(location.state.data);
      _ExhibitionPositions(location.state.data.id);
    }
  }, []);

  const approveBoothHandler = async () => {
    setIsLoading(true);

    if (boothData?.cart_item?.cart?.order?.id) {
      let formData = new FormData();
      formData.append("booth_id", boothData?.id);
      formData.append("order_id", boothData?.cart_item?.cart?.order?.id);

      try {
        const response = await approveBooth(formData);
        setIsLoading(false);
        if (response.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg ? response.data.msg : "success"}
            </span>,
            {
              onClose: () => {},

              autoClose: 1000,
            }
          );
          history.push(`/admin/exhibition/${boothData.exhibition_id}/booths-booking-list`);
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg ? response.data.msg : "Failure in service"}
            </span>
          );
        }
      } catch (err) {
        setIsLoading(false);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data.msg ? err.data.msg : "Failure in service"}</span>
        );
      }
    }
  };

  const rejectBoothHandler = async () => {
    setRejectBoothReasonError(!rejectBoothReason ? t("crud.errors.required") : "");
    if (boothData?.cart_item?.cart?.order?.id && rejectBoothReason) {
      setIsLoading(true);

      let formData = new FormData();
      formData.append("booth_id", boothData?.id);
      formData.append("order_id", boothData?.cart_item?.cart?.order?.id);
      formData.append("cancel_reason", rejectBoothReason);
      try {
        const response = await rejectBooth(formData);
        setIsLoading(false);
        if (response.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.msg ? response.msg : "success"}</span>,
            {
              onClose: () => {},
              autoClose: 1000,
            }
          );
          history.push(`/admin/exhibition/${boothData.exhibition_id}/booths-booking-list`);
          setShowRejectModal(false);
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.msg ? response.msg : "Failure in service"}
            </span>
          );
        }
      } catch (err) {
        setIsLoading(false);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data.msg ? err.data.msg : "Failure in service"}</span>
        );
      }
    }
  };

  return (
    <div className="exhibition-Bookings">
      <div className="container-fluid profile_wrapper">
        <div className="admin_label">
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to="/">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to="/admin/exhibitions-list">{t("Exhibition.Exhibition")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
        </div>
      </div>
      <div className="container-fluid profile_wrapper booth-details__wrapper">
        <div className={isLoading ? null : "table_bank_tran"}>
          <div className="row booth-details-title">
            <div className="col-12">
              <h3 className="table_header">{t("booth.booth_details")}</h3>
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            boothData && (
              <div className="booth-details">
                <Formik
                  initialValues={{
                    name: boothData.name,
                    description: boothData.description,
                    audio: {
                      src: boothData.audio,
                    },
                    logo: {
                      src: boothData.logo,
                    },
                    document: {
                      src: boothData.document,
                    },
                    participation_type: boothData.participation_type,
                    position: boothData.position,
                    size: boothData.size,
                    ...getProducts(boothData.products),
                    ...getBanners(boothData.banners),
                  }}
                  onSubmit={async (values, { setSubmitting }) => {}}
                  validateOnChange={true}
                  validate={(values) => {
                    const errors = {};
                    if (!values.name) {
                      errors.name = t("crud.errors.required");
                    }

                    if (!values.logo) {
                      errors.logo = t("crud.errors.required");
                    }
                    if (values.logo && !values.logo.src && !validateImage(values.logo)) {
                      errors.logo = t("booth.Note_image");
                    }

                    if (values.document && !values.document.src && !validateDocument(values.document)) {
                      errors.document = t("validation.document_type_or_size_incorrect");
                    }

                    if (!values.description) {
                      errors.description = t("crud.errors.required");
                    }

                    if (!values.participation_type) {
                      errors.participation_type = t("crud.errors.required");
                    }

                    if (!values.audio) {
                      errors.audio = t("crud.errors.required");
                    }

                    if (values.audio && !values.audio.src && !validateAudio(values.audio)) {
                      errors.audio = t("Exhibition.validation_MP3");
                    }

                    for (let i = 0; i < Banner.length; i++) {
                      if (!values[`banners_position_${i}`]) {
                        errors[`banners_position_${i}`] = t("crud.errors.required");
                      }
                      if (
                        values[`banners_image_${i}`] &&
                        !values[`banners_image_${i}`].src &&
                        !validateImage(values[`banners_image_${i}`])
                      ) {
                        errors[`banners_image_${i}`] = t("booth.Note_image");
                      }
                    }

                    if (position) {
                      values.position = position;
                    }

                    if (!values.position) {
                      errors.position = t("crud.errors.required");
                    }

                    for (let i = 0; i < filesDoc.length; i++) {
                      if (!values[`name_${filesDoc[i]}`]) {
                        errors[`name_${filesDoc[i]}`] = t("crud.errors.required");
                      }
                      if (!values[`image_${filesDoc[i]}`]) {
                        errors[`image_${filesDoc[i]}`] = t("crud.errors.required");
                      }
                      if (!values[`url_title_${filesDoc[i]}`]) {
                        errors[`url_title_${filesDoc[i]}`] = t("crud.errors.required");
                      }
                      if (!values[`url_${filesDoc[i]}`]) {
                        errors[`url_${filesDoc[i]}`] = t("crud.errors.required");
                      }
                      if (!values[`description_${filesDoc[i]}`]) {
                        errors[`description_${filesDoc[i]}`] = t("crud.errors.required");
                      }
                      if (
                        values[`image_${filesDoc[i]}`] &&
                        !values[`image_${filesDoc[i]}`].src &&
                        !validateImage(values[`image_${filesDoc[i]}`])
                      ) {
                        errors[`image_${filesDoc[i]}`] = t("booth.Note_image");
                      }
                    }

                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    handleBlur,
                    setFieldValue,
                    validateForm,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <BoothsDetailsInput
                            label={t("booth.name")}
                            type="text"
                            name="name"
                            defaultValue={boothData.name}
                            onChange={handleChange}
                            error={errors.name}
                          />
                        </div>
                        <div className="col-12 col-md-8">
                          <BoothDetailsFile
                            label={t("booth.Upload_Image")}
                            name="logo"
                            file={values.logo}
                            fileLabel={t("image_preview")}
                            icon={<i className="fa fa-image"></i>}
                            filePull={t("booth.Pull_file")}
                            fileDrag={t("booth.drag_Image")}
                            fileNote={t("booth.Note_image")}
                            accept={acceptImages()}
                            setFieldValue={setFieldValue}
                            error={errors.logo}
                          />
                        </div>
                        <div className="col-12 col-md-12">
                          <BoothDetailsFile
                            label={t("booth.download_PDF")}
                            name="document"
                            file={values.document}
                            fileLabel={t("file_preview")}
                            icon={<i className="fa fa-image"></i>}
                            filePull={t("booth.Pull_file")}
                            fileDrag={t("booth.drag_download_PDF")}
                            fileNote={t("booth.download_PDF_note")}
                            accept={acceptDocuments([DOCUMENT_PDF])}
                            setFieldValue={setFieldValue}
                            error={errors.document}
                          />
                        </div>
                        <div className="col-12 col-md-12">
                          <label htmlFor="description">{t("booth.Description")}</label>
                          <textarea
                            id="description"
                            name="description"
                            rows="7"
                            className="tajah-bordered-text-area"
                            onChange={handleChange}
                            placeholder={t("crud.placeholders.description")}
                            defaultValue={boothData.description}
                          />
                          <p className={"form-input-error-space"}>{errors.description ? errors.description : null}</p>
                        </div>
                        <div className="col-12 col-md-4">
                          <BoothsDetailsInput
                            label={t("booth.Adjective")}
                            type="text"
                            name="participation_type"
                            defaultValue={boothData.participation_type}
                            onChange={handleChange}
                            error={errors.participation_type}
                          />
                        </div>
                        <div className="col-12 col-md-8">
                          <BoothDetailsAudio
                            label={t("Exhibition.Upload_audio")}
                            name="audio"
                            value={values.audio}
                            filePull={t("booth.Pull_file")}
                            icon={<i className="fa fa-microphone"></i>}
                            fileDrag={t("Exhibition.drag_audio")}
                            fileNote={t("Exhibition.Note_mp3")}
                            accept={acceptAudios()}
                            setFieldValue={setFieldValue}
                            error={errors.audio}
                          />
                        </div>
                        <div className="col-12 col-md-12">
                          <BoothDetailsSelect
                            label={t("booth.size")}
                            name="size"
                            defaultValue={boothData.size}
                            onChange={(event) => {
                              setBoothData({
                                ...boothData,
                                size: event.target.value,
                              });
                              if (event.target.value == "small") {
                                setBanner([0, 0, 0]);
                              } else if (event.target.value == "medium") {
                                setBanner([0, 0, 0, 0]);
                              } else if (event.target.value == "larg") {
                                setBanner([0, 0, 0, 0, 0, 0]);
                              }
                            }}
                            options={[
                              {
                                value: "small",
                                name: t("booth.small"),
                              },
                              {
                                value: "medium",
                                name: t("booth.medium"),
                              },
                              {
                                value: "larg",
                                name: t("booth.larg"),
                              },
                            ]}
                            error={errors.size}
                          />
                        </div>
                        {Banner.map((data, key) => {
                          return (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-8">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <label htmlFor="logo" className="admin_add_label">
                                      {t("booth.Banner_Image") + (key + 1)}
                                    </label>
                                    {values[`banners_image_${key}`]?.src ? (
                                      <a
                                        href={values[`banners_image_${key}`].src}
                                        target={"_blank"}
                                        style={{ margin: "0px 8px 8px 8px" }}
                                      >
                                        {" "}
                                        {t("image_preview")}{" "}
                                      </a>
                                    ) : null}
                                  </div>

                                  <div style={{ position: "relative" }}>
                                    <div className="booth-details__file">
                                      <div className="add-audio">
                                        {values[`banners_image_${key}`] ? (
                                          values[`banners_image_${key}`].src ? (
                                            <img
                                              src={values[`banners_image_${key}`].src}
                                              style={{
                                                height: "80px",
                                              }}
                                            />
                                          ) : (
                                            values[`banners_image_${key}`].name
                                          )
                                        ) : (
                                          t("booth.Pull_file")
                                        )}
                                        <div className="add-audio-icon">
                                          <i className="fa fa-image"></i>
                                        </div>
                                      </div>
                                      <p style={{ display: "inline-block" }} className="msg-audio">
                                        {t("booth.drag_Image")}
                                        <br />
                                        {t("booth.Note_image")}
                                      </p>
                                    </div>

                                    <input
                                      id={`banners_image_${key}`}
                                      name={`banners_image_${key}`}
                                      type="file"
                                      accept={acceptImages()}
                                      className="input-upload-audio"
                                      onChange={(event) => {
                                        setFieldValue(`banners_image_${key}`, event.target.files[0]);
                                      }}
                                    />
                                  </div>
                                  <p className={"form-input-error-space"} style={{ paddingTop: "10px " }}>
                                    {errors[`banners_image_${key}`] ? errors[`banners_image_${key}`] : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        <div className="col-12 col-md-12">
                          <label htmlFor="name" className="admin_add_label">
                            {t("booth.count_booth")}
                          </label>
                          {ExhibitionData ? (
                            <div className="row position-box">
                              {ExhibitionData.booths_no > 12 ? (
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-2 position-ui position-opacity">
                                      <div className="position-content"></div>
                                    </div>
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(13) != -1 ? (
                                          <div className={`position-content Position-not`}>13</div>
                                        ) : (
                                          <div
                                            className={`position-content ${position == 13 ? " active-position" : ""}`}
                                            onClick={() => _position(13)}
                                          >
                                            13
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${position == 13 ? " active-position" : ""}`}
                                          onClick={() => _position(13)}
                                        >
                                          13
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(14) != -1 ? (
                                          <div className={`position-content Position-not`}>14</div>
                                        ) : (
                                          <div
                                            className={`position-content ${position == 14 ? " active-position" : ""}`}
                                            onClick={() => _position(14)}
                                          >
                                            14
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${position == 14 ? " active-position" : ""}`}
                                          onClick={() => _position(14)}
                                        >
                                          14
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(15) != -1 ? (
                                          <div className={`position-content Position-not`}>15</div>
                                        ) : (
                                          <div
                                            className={`position-content ${position == 15 ? " active-position" : ""}`}
                                            onClick={() => _position(15)}
                                          >
                                            15
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${position == 15 ? " active-position" : ""}`}
                                          onClick={() => _position(15)}
                                        >
                                          15
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-2 position-ui ">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(16) != -1 ? (
                                          <div className={`position-content Position-not`}>16</div>
                                        ) : (
                                          <div
                                            className={`position-content ${position == 16 ? " active-position" : ""}`}
                                            onClick={() => _position(16)}
                                          >
                                            16
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${position == 16 ? " active-position" : ""}`}
                                          onClick={() => _position(16)}
                                        >
                                          16
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-2 position-ui position-opacity">
                                      <div className="position-content"></div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-12">
                                <div className="row">
                                  {/* position-opacity */}
                                  {ExhibitionData.booths_no > 16 ? (
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(19) != -1 ? (
                                          <div className={`position-content Position-not`}>19</div>
                                        ) : (
                                          <div
                                            className={`position-content ${position == 19 ? " active-position" : ""}`}
                                            onClick={() => _position(19)}
                                          >
                                            19
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${position == 19 ? " active-position" : ""}`}
                                          onClick={() => _position(19)}
                                        >
                                          19
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="col-md-2 position-ui position-opacity"></div>
                                  )}

                                  {ExhibitionData.booths_no >= 12 ? (
                                    <>
                                      <div className="col-md-2 position-ui">
                                        {ExhibitionPositions.length > 0 ? (
                                          ExhibitionPositions.indexOf(9) != -1 ? (
                                            <div className={`position-content Position-not`}>9</div>
                                          ) : (
                                            <div
                                              className={`position-content ${position == 9 ? " active-position" : ""}`}
                                              onClick={() => _position(9)}
                                            >
                                              9
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className={`position-content ${position == 9 ? " active-position" : ""}`}
                                            onClick={() => _position(9)}
                                          >
                                            9
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-2 position-ui">
                                        {ExhibitionPositions.length > 0 ? (
                                          ExhibitionPositions.indexOf(10) != -1 ? (
                                            <div className={`position-content Position-not`}>10</div>
                                          ) : (
                                            <div
                                              className={`position-content ${position == 10 ? " active-position" : ""}`}
                                              onClick={() => _position(10)}
                                            >
                                              10
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className={`position-content ${position == 10 ? " active-position" : ""}`}
                                            onClick={() => _position(10)}
                                          >
                                            10
                                          </div>
                                        )}
                                      </div>

                                      <div className="col-md-2 position-ui">
                                        {ExhibitionPositions.length > 0 ? (
                                          ExhibitionPositions.indexOf(11) != -1 ? (
                                            <div className={`position-content Position-not`}>11</div>
                                          ) : (
                                            <div
                                              className={`position-content ${position == 11 ? " active-position" : ""}`}
                                              onClick={() => _position(11)}
                                            >
                                              11
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className={`position-content ${position == 11 ? " active-position" : ""}`}
                                            onClick={() => _position(11)}
                                          >
                                            11
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-2 position-ui ">
                                        {ExhibitionPositions.length > 0 ? (
                                          ExhibitionPositions.indexOf(12) != -1 ? (
                                            <div className={`position-content Position-not`}>12</div>
                                          ) : (
                                            <div
                                              className={`position-content ${position == 12 ? " active-position" : ""}`}
                                              onClick={() => _position(12)}
                                            >
                                              12
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className={`position-content ${position == 12 ? " active-position" : ""}`}
                                            onClick={() => _position(12)}
                                          >
                                            12
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : null}

                                  {ExhibitionData.booths_no > 16 ? (
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(20) != -1 ? (
                                          <div className={`position-content Position-not`}>20</div>
                                        ) : (
                                          <div
                                            className={`position-content ${position == 20 ? " active-position" : ""}`}
                                            onClick={() => _position(20)}
                                          >
                                            20
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${position == 20 ? " active-position" : ""}`}
                                          onClick={() => _position(20)}
                                        >
                                          20
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="col-md-2 position-ui position-opacity"></div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  {ExhibitionData.booths_no > 16 ? (
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(17) != -1 ? (
                                          <div className={`position-content Position-not`}>17</div>
                                        ) : (
                                          <div
                                            className={`position-content ${position == 17 ? " active-position" : ""}`}
                                            onClick={() => _position(17)}
                                          >
                                            17
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${position == 17 ? " active-position" : ""}`}
                                          onClick={() => _position(17)}
                                        >
                                          17
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="col-md-2 position-ui position-opacity"></div>
                                  )}

                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(5) != -1 ? (
                                        <div className={`position-content Position-not`}>5</div>
                                      ) : (
                                        <div
                                          className={`position-content ${position == 5 ? " active-position" : ""}`}
                                          onClick={() => _position(5)}
                                        >
                                          5
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${position == 5 ? " active-position" : ""}`}
                                        onClick={() => _position(5)}
                                      >
                                        5
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(6) != -1 ? (
                                        <div className={`position-content Position-not`}>6</div>
                                      ) : (
                                        <div
                                          className={`position-content ${position == 6 ? " active-position" : ""}`}
                                          onClick={() => _position(6)}
                                        >
                                          6
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${position == 6 ? " active-position" : ""}`}
                                        onClick={() => _position(6)}
                                      >
                                        6
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(7) != -1 ? (
                                        <div className={`position-content Position-not`}>7</div>
                                      ) : (
                                        <div
                                          className={`position-content ${position == 7 ? " active-position" : ""}`}
                                          onClick={() => _position(7)}
                                        >
                                          7
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${position == 7 ? " active-position" : ""}`}
                                        onClick={() => _position(7)}
                                      >
                                        7
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui ">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(8) != -1 ? (
                                        <div className={`position-content Position-not`}>8</div>
                                      ) : (
                                        <div
                                          className={`position-content ${position == 8 ? " active-position" : ""}`}
                                          onClick={() => _position(8)}
                                        >
                                          8
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${position == 8 ? " active-position" : ""}`}
                                        onClick={() => _position(8)}
                                      >
                                        8
                                      </div>
                                    )}
                                  </div>
                                  {ExhibitionData.booths_no > 16 ? (
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(18) != -1 ? (
                                          <div className={`position-content Position-not`}>18</div>
                                        ) : (
                                          <div
                                            className={`position-content ${position == 18 ? " active-position" : ""}`}
                                            onClick={() => _position(18)}
                                          >
                                            18
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${position == 18 ? " active-position" : ""}`}
                                          onClick={() => _position(18)}
                                        >
                                          18
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="col-md-2 position-ui position-opacity"></div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-2 position-ui position-opacity">
                                    <div className="position-content"></div>
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(1) != -1 ? (
                                        <div className={`position-content Position-not`}>1</div>
                                      ) : (
                                        <div
                                          className={`position-content ${position == 1 ? " active-position" : ""}`}
                                          onClick={() => _position(1)}
                                        >
                                          1
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${position == 1 ? " active-position" : ""}`}
                                        onClick={() => _position(1)}
                                      >
                                        1
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(2) != -1 ? (
                                        <div className={`position-content Position-not`}>2</div>
                                      ) : (
                                        <div
                                          className={`position-content ${position == 2 ? " active-position" : ""}`}
                                          onClick={() => _position(2)}
                                        >
                                          2
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${position == 2 ? " active-position" : ""}`}
                                        onClick={() => _position(2)}
                                      >
                                        2
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(3) != -1 ? (
                                        <div className={`position-content Position-not`}>3</div>
                                      ) : (
                                        <div
                                          className={`position-content ${position == 3 ? " active-position" : ""}`}
                                          onClick={() => _position(3)}
                                        >
                                          3
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${position == 3 ? " active-position" : ""}`}
                                        onClick={() => _position(3)}
                                      >
                                        3
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(4) != -1 ? (
                                        <div className={`position-content Position-not`}>4</div>
                                      ) : (
                                        <div
                                          className={`position-content ${position == 4 ? " active-position" : ""}`}
                                          onClick={() => _position(4)}
                                        >
                                          4
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${position == 4 ? " active-position" : ""}`}
                                        onClick={() => _position(4)}
                                      >
                                        4
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui position-opacity">
                                    <div className="position-content"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="position-nots">
                            <span className="available">{t("booth.Available")}</span>
                            <span className="unavailable">{t("booth.unAvailable")}</span>
                          </div>

                          <p className={"form-input-error-space"}>{errors.position ? errors.position : null}</p>
                        </div>

                        {filesDoc.length > 0 &&
                          filesDoc.map((doc) => {
                            return (
                              <div key={doc} style={{ padding: "20px 0 0 0" }} className="col-md-12">
                                <div>
                                  <div className="row">
                                    <div className="col-12 col-md-4">
                                      <label htmlFor="name" className="admin_add_label">
                                        {t("booth.product_name")}
                                      </label>
                                      <input
                                        id={`name_${doc}`}
                                        type="text"
                                        name={`name_${doc}`}
                                        className="admin_add_input"
                                        onChange={handleChange}
                                        defaultValue={boothData.products[doc] ? boothData.products[doc].name : null}
                                      />
                                      <p className={"form-input-error-space"}>
                                        {errors[`name_${doc}`] ? errors[`name_${doc}`] : null}
                                      </p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                      <div
                                        className="add-audio"
                                        style={{
                                          fontWeight: "500",
                                          width: "100%",
                                          padding: " 11px 15px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        {values[`image_${doc}`] ? (
                                          values[`image_${doc}`].src ? (
                                            <img
                                              src={values[`image_${doc}`].src}
                                              style={{
                                                height: "80px",
                                              }}
                                            />
                                          ) : (
                                            values[`image_${doc}`].name
                                          )
                                        ) : (
                                          t("booth.Product_Upload")
                                        )}
                                        {/* <img alt='Image_banner' style={{width: "85px",borderRadius: "10px",margin:" 0 10px"}} src={`${BoothData.products[data].image}`}/>*/}
                                      </div>

                                      <input
                                        id={`image_${doc}`}
                                        name={`image_${doc}`}
                                        type="file"
                                        accept={acceptImages()}
                                        className="input-upload-audio"
                                        onChange={(event) => {
                                          setFieldValue(`image_${doc}`, event.target.files[0]);
                                        }}
                                      />
                                      <p className={"form-input-error-space"}>
                                        {errors[`image_${doc}`] ? errors[`image_${doc}`] : null}
                                      </p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                      <button
                                        className="delete-upload-file"
                                        type="button"
                                        onClick={() => {
                                          setFilesDoc((old) => {
                                            const index = old.findIndex((i) => i == doc);
                                            if (index >= 0) old.splice(index, 1);
                                            return [...old];
                                          });
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                        {t("Exhibition.delete")}
                                      </button>

                                      <div>
                                        {values[`image_${doc}`]?.src ? (
                                          <a href={values[`image_${doc}`]?.src} target={"_blank"}>
                                            {t("image_preview")}
                                          </a>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-12">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label htmlFor="name" className="admin_add_label">
                                        {t("booth.title_name")}
                                      </label>
                                      <input
                                        id={`url_title_${doc}`}
                                        type="text"
                                        name={`url_title_${doc}`}
                                        className="admin_add_input"
                                        defaultValue={
                                          boothData.products[doc] ? boothData.products[doc].url_title : null
                                        }
                                        onChange={handleChange}
                                      />
                                      <p className={"form-input-error-space"}>
                                        {errors[`url_title_${doc}`] ? errors[`url_title_${doc}`] : null}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <label htmlFor="name" className="admin_add_label">
                                        {t("booth.url_page")}
                                      </label>
                                      <input
                                        id={`url_${doc}`}
                                        type="text"
                                        name={`url_${doc}`}
                                        className="admin_add_input"
                                        defaultValue={boothData.products[doc] ? boothData.products[doc].url : null}
                                        onChange={handleChange}
                                      />
                                      <p className={"form-input-error-space"}>
                                        {errors[`url_${doc}`] ? errors[`url_${doc}`] : null}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <label htmlFor="name" className="admin_add_label">
                                        {t("booth.url_page")}
                                      </label>
                                      <input
                                        id={`video_${doc}`}
                                        type="text"
                                        name={`video_${doc}`}
                                        defaultValue={boothData.products[doc] ? boothData.products[doc].video : null}
                                        className="admin_add_input"
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-12">
                                  <label htmlFor="description">{t("booth.desc_product")}</label>

                                  <textarea
                                    rows="3"
                                    className="tajah-bordered-text-area"
                                    onChange={(val) => setFieldValue(`description_${doc}`, val.target.value)}
                                    placeholder={t("crud.placeholders.description")}
                                    defaultValue={boothData.products[doc] ? boothData.products[doc].description : null}
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors[`description_${doc}`] ? errors[`description_${doc}`] : null}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        <div className="col-12 col-md-12">
                          <button
                            style={{
                              color: "#126e77",
                              background: "transparent",
                              border: "none",
                            }}
                            type="button"
                            onClick={() =>
                              setFilesDoc([...filesDoc, `${filesDoc.length}-${Math.floor(Math.random() * 10000000)}`])
                            }
                          >
                            {t("booth.add_pro")}
                          </button>
                        </div>
                        <div className="col-12 col-md-12 booth-booking__actions">
                          <button
                            type="button"
                            disabled={!boothData?.cart_item?.cart?.order?.id && isLoading}
                            style={{
                              padding: "4px",
                              borderRadius: "6px",
                              display: "inline-block",
                            }}
                            className="admin_add_button btn_zoom"
                            onClick={approveBoothHandler}
                          >
                            {t("booth.approve")}
                          </button>

                          <button
                            type="submit"
                            disabled={isSubmitting}
                            style={{
                              padding: "4px",
                              borderRadius: "6px",
                              display: "inline-block",
                            }}
                            className="admin_add_button btn_zoom"
                          >
                            {t("booth.edit-btn")}
                          </button>
                          <button
                            type="button"
                            disabled={!boothData?.cart_item?.cart?.order?.id && isLoading}
                            style={{
                              padding: "4px",
                              borderRadius: "6px",
                              display: "inline-block",
                            }}
                            className="admin_add_button btn_zoom  booth-booking__actions-reject"
                            onClick={() => {
                              setShowRejectModal(true);
                            }}
                          >
                            {t("booth.reject")}
                          </button>
                          <button
                            style={{
                              color: "#333",
                              background: "transparent",
                              border: "none",
                              padding: "10px 40px",
                              display: "inline-block",
                            }}
                            type="button"
                            onClick={() => {
                              history.go(-1);
                            }}
                          >
                            {t("booth.back")}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )
          )}
        </div>
        {showRejectModal && (
          <RejectBoothModal
            onChange={(event) => {
              setRejectBoothReason(event.target.value);
              setRejectBoothReasonError(!event.target.value ? t("crud.errors.required") : "");
            }}
            close={() => {
              setShowRejectModal(false);
            }}
            action={rejectBoothHandler}
            error={rejectBoothReasonError}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default BoothsBookingDetails;

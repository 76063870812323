import style from "./customButton.module.css";

export default function CustomButton({
  value,
  type,
  variant,
  action,
  icon,
  classes,
  colors,
  disable = false,
  iconClass,
  loading,
  readOnly,
  tooltip,
}) {
  return (
    <button
      onClick={disable ? null : action}
      type={type}
      className={`${classes} ${style["custom-btn"]}`}
      style={{
        background: loading
          ? "#8bdce5"
          : disable
          ? "#ccc"
          : variant === "outlined"
          ? "transparent"
          : colors,
        border: loading
          ? "#8bdce5"
          : disable
          ? "#ccc"
          : variant === "outlined"
          ? `2px solid ${colors}`
          : "transparent",
        color: loading
          ? "#036c77"
          : disable
          ? "#aaa"
          : variant === "outlined"
          ? colors
          : "white",
        cursor: disable ? "not-allowed" : readOnly ? "default" : "pointer",
      }}
      disabled={disable}
      title={tooltip}
    >
      {value && <span>{value}</span>}
      {icon && <img src={icon} alt={value} className={`${iconClass}`} />}
      {loading && <div className={style["lds-dual-ring"]}></div>}
    </button>
  );
}

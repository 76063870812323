import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import toLocalTimezone, { toServerTime } from "../../Services/Timezone";
import { ListTypes, updateMeeting } from "../../Services/api/Zoom/ZoomProvider";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";

export default function EditSession() {
  require("./zoom.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { courseId, id } = useParams();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [types, setTypes] = useState([]);
  const [oldData, setOldData] = useState({});
  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [changeTime, setChangeTime] = useState(false);
  const [changeEndTime, setChangeEndTime] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);

  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);

  useEffect(() => {
    setIsLoading(false);
    currentDate();
    async function executeOnLoad() {
      let response = await ListTypes();
      if (response.status && response.status == 200) {
        setTypes(response.data.data);
      }
    }

    if (location.state) {
      setOldData(location.state.data);
      if (location.state.data.reminder_time && location.state.data.reminder_timing_type) {
        setCheckbox(true);
      }
      let createDate = toLocalTimezone(location.state.data.start_time);
      setStartDate(new Date(createDate));
    }
    executeOnLoad();
  }, []);

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate);
  };
  const currentDateEnd = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
  };
  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const formatTime = (t) => {
    var now = new Date(t);
    var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    let hours = "" + utc.getHours(),
      minutes = "" + utc.getMinutes(),
      seconds = "" + utc.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return [hours, minutes, seconds].join(":");
  };

  return (
    <div>
      <div className="header_profile">
        <div className="container-fluid profile_wrapper">
          <div className="profile_grid">
            <div className="profile_dhead">
              <h3>{t("zoom.Add_Session")}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="admin_label">
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to="/">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {t("zoom.Add_Session")}
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className={isLoading ? null : "table_bank_tran"}>
          {isLoading ? (
            <div
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <>
              <h3 className="table_header">{t("zoom.Add_Session")}</h3>
              <div className="admin_input_sponsor">
                <Formik
                  initialValues={{
                    type_id: oldData.meeting_type,
                    topic: oldData.meeting_agenda,
                    restrict_number: oldData.max_registrants,
                    percentage: parseInt(oldData.percentage),
                    duration: oldData.duration,
                    start_time: oldData.start_time,
                    reminder_sent: oldData.reminder_sent,
                    time: oldData.reminder_time,
                    timing_type: oldData.reminder_timing_type,
                    title: oldData.meeting_agenda,
                    end: "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      setIsLoading(true);
                      let dataToBeUploaded = {
                        agenda: values.topic,
                        start: "",
                        ...values,
                      };
                      if (changeTime) {
                        dataToBeUploaded.start_time = toServerTime(dataToBeUploaded.start_time);
                      }

                      if (changeEndTime) {
                        dataToBeUploaded.end = toServerTime(dataToBeUploaded.end);
                      }
                      dataToBeUploaded.start = toServerTime(dataToBeUploaded.start_time);

                      if (checkbox == false) {
                        delete dataToBeUploaded.time;
                        delete dataToBeUploaded.timing_type;
                      }
                      if (dataToBeUploaded.type_id) {
                        dataToBeUploaded.type_id = parseInt(dataToBeUploaded.type_id);
                      }
                      if (dataToBeUploaded.type_id == 1) {
                        delete dataToBeUploaded.password;
                        delete dataToBeUploaded.start;
                        delete dataToBeUploaded.title;
                        delete dataToBeUploaded.end;
                      }
                      delete dataToBeUploaded.type_id;
                      delete dataToBeUploaded.reminder_sent;
                      // if(dataToBeUploaded.start.toString().includes("\.")) {
                      //     let indexOfDot_start_time = dataToBeUploaded.start.indexOf(".");
                      //     dataToBeUploaded.start = dataToBeUploaded.start.substring(0, indexOfDot_start_time)
                      // }
                      // if(dataToBeUploaded.end.toString().includes("\.")) {
                      //     let indexOfDot_end_time = dataToBeUploaded.end.indexOf(".");
                      //     dataToBeUploaded.end = dataToBeUploaded.end.substring(0, indexOfDot_end_time)
                      // }
                      const response = await updateMeeting(oldData.id, dataToBeUploaded);

                      if (response.status === 200 && response.data.status) {
                        history.push(`/trainer/course/${courseId}/lesson/${id}`);
                        //setIsLoading(false);
                      } else {
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {response.data.msg ? response.data.msg : "Failure in service"}
                          </span>
                        );
                        setSubmitting(false);
                      }
                    } catch (err) {
                      setSubmitting(false);
                      setIsLoading(false);
                      toast.error(
                        <>
                          <span style={{ fontSize: 13, fontWeight: "bold", display: "block", paddingBottom: "10px" }}>
                            {err.response.data.msg}
                          </span>
                          <ul>
                            {Object.keys(err.response.data.errors).map((key) => {
                              return (
                                <li>
                                  <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      );
                    }
                  }}
                  validateOnChange={hasSubmitted}
                  validate={(values) => {
                    setHasSubmitted(true);
                    const errors = {};
                    if (!values.type_id) {
                      errors.type_id = t("crud.errors.required");
                    }
                    if (!values.topic) {
                      errors.topic = t("crud.errors.required");
                    }

                    if (!values.restrict_number) {
                      errors.restrict_number = t("crud.errors.required");
                    }

                    if (!values.percentage) {
                      errors.percentage = t("crud.errors.required");
                    }

                    if (values.duration < 30) {
                      errors.duration = t("zoom.error_duration_session");
                    }
                    if (!values.duration) {
                      errors.duration = t("crud.errors.required");
                    }
                    if (!values.start_time) {
                      errors.start_time = t("crud.errors.required");
                    }

                    if (checkbox) {
                      if (!values.time) {
                        errors.time = t("crud.errors.required");
                      }
                      if (!values.timing_type) {
                        errors.timing_type = t("crud.errors.required");
                      }
                    }
                    if (values.type_id && values.type_id != 1) {
                      if (!values.title) {
                        errors.title = t("crud.errors.required");
                      }
                      if (!values.password) {
                        errors.password = t("crud.errors.required");
                      }
                      if (!values.end) {
                        errors.end = t("crud.errors.required");
                      }
                    }

                    return errors;
                  }}
                >
                  {({ values, errors, handleChange, handleSubmit, isSubmitting, handleBlur, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="admin_add_form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="name" className="admin_add_label">
                                {t("zoom.Name_training_course")}
                              </label>
                              <select
                                name="type_id"
                                id="type_id"
                                className="admin_add_input"
                                value={values.type_id}
                                onChange={handleChange}
                              >
                                {types.length > 0 &&
                                  types.map((type, key) => {
                                    return (
                                      <option key={key} value={type.id}>
                                        {" "}
                                        {i18n.language == "en" ? type.name.en : type.name.ar}{" "}
                                      </option>
                                    );
                                  })}
                              </select>
                              <p className={"form-input-error-space"}>{errors.type_id ? errors.type_id : null}</p>
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="name" className="admin_add_label">
                                {t("zoom.topic_session")}
                              </label>
                              <input
                                id="topic_session"
                                type="text"
                                name="topic"
                                className="admin_add_input"
                                onChange={handleChange}
                                defaultValue={oldData.meeting_agenda}
                                // value={values.name}
                              />
                              <p className={"form-input-error-space"}>{errors.topic ? errors.topic : null}</p>
                            </div>
                          </div>
                        </div>
                        {values.type_id && values.type_id != 1 ? (
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="name" className="admin_add_label">
                                  {t("title")}
                                </label>
                                <input
                                  type="text"
                                  name="title"
                                  className="admin_add_input"
                                  onChange={handleChange}
                                  defaultValue={oldData.meeting_agenda}
                                />
                                <p className={"form-input-error-space"}>{errors.title ? errors.title : null}</p>
                              </div>

                              <div className="col-md-4">
                                <label htmlFor="name" className="admin_add_label">
                                  {t("password")}
                                </label>
                                <input
                                  type="password"
                                  name="password"
                                  className="admin_add_input"
                                  onChange={handleChange}
                                />
                                <p className={"form-input-error-space"}>{errors.password ? errors.password : null}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="name" className="admin_add_label">
                                {t("zoom.number_registere")}
                              </label>
                              <input
                                type="number"
                                name="restrict_number"
                                className="admin_add_input"
                                onChange={handleChange}
                                defaultValue={oldData.max_registrants}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.restrict_number ? errors.restrict_number : null}
                              </p>
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="name" className="admin_add_label">
                                {t("zoom.Percentage_attendance")}
                              </label>
                              <input
                                type="number"
                                name="percentage"
                                className="admin_add_input"
                                onChange={handleChange}
                                defaultValue={oldData.percentage}
                              />
                              <span className="span-input">%</span>
                              <p className={"form-input-error-space"}>{errors.percentage ? errors.percentage : null}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="start_day" className="admin_add_label">
                                {t("zoom.start_date")}
                              </label>
                              <DatePicker
                                selected={values.start_time}
                                onChange={(date) => {
                                  setChangeTime(true);
                                  setFieldValue("start_time", date);
                                  setStartDate(date);

                                  let CurrentDate = new Date();
                                  let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                  let DataPickerValue = date.toISOString().split("T")[0];
                                  if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                    setHoursDatepicker(0);
                                    setMinutesDatepicker(0);
                                  } else {
                                    currentDate();
                                  }
                                }}
                                className="admin_add_input"
                                dateFormat={"yyyy-MM-dd h:mm aa"}
                                showTimeSelect
                                name="start_time"
                                minDate={new Date()}
                                selected={startDate}
                                // minTime={setHours(setMinutes(new Date(), new Date().getMinutes()), new Date().getHours())} // new Date().getHours()
                                // maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                                maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              />
                              <p className={"form-input-error-space"}>{errors.start_time ? errors.start_time : null}</p>
                            </div>
                            {values.type_id && values.type_id != 1 ? (
                              <div className="col-md-4">
                                <label htmlFor="start_day" className="admin_add_label">
                                  {t("end_date")}
                                </label>
                                <DatePicker
                                  selected={values.end}
                                  onChange={(date) => {
                                    setFieldValue("end", date);
                                    setChangeEndTime(true);
                                    setEndDate(date);

                                    let CurrentDate = new Date();
                                    let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                    let DataPickerValue = date.toISOString().split("T")[0];
                                    if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                      setHoursDatepickerEnd(0);
                                      setMinutesDatepickerEnd(0);
                                    } else {
                                      currentDateEnd();
                                    }
                                  }}
                                  className="admin_add_input"
                                  dateFormat={"yyyy-MM-dd h:mm aa"}
                                  showTimeSelect
                                  name="end"
                                  minDate={new Date()}
                                  minTime={setHours(setMinutes(new Date(), minutesDatepickerEnd), hoursDatepickerEnd)}
                                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                />
                                <p className={"form-input-error-space"}>{errors.end ? errors.end : null}</p>
                              </div>
                            ) : null}
                            <div className="col-md-4">
                              <label htmlFor="name" className="admin_add_label">
                                {t("zoom.duration_session")}
                              </label>
                              <input
                                id="name"
                                type="number"
                                name="duration"
                                className="admin_add_input"
                                onChange={handleChange}
                                defaultValue={oldData.duration}
                                // value={values.name}
                              />
                              <span className="span-input">{t("zoom.minute")} </span>
                              <p className={"form-input-error-space"}>{errors.duration ? errors.duration : null}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <input
                            type="checkbox"
                            className="checkbox_zoom"
                            name="reminder_sent"
                            onChange={() => setCheckbox(!checkbox)}
                            defaultChecked={checkbox ? 1 : 0}
                          />
                          <p className="parg_zoom">{t("zoom.minutes_before_appointment")}</p>
                        </div>
                        {checkbox ? (
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-1">
                                <input
                                  type="number"
                                  className="admin_add_input"
                                  name="time"
                                  defaultValue={oldData.reminder_time}
                                  onChange={handleChange}
                                  placeholder={i18n.language == "en" ? "2 hours" : "2 ساعه"}
                                />
                                <p className={"form-input-error-space"}>{errors.time ? errors.time : null}</p>
                              </div>
                              <div className="col-md-2">
                                <select
                                  name="timing_type"
                                  id="timing_type"
                                  className="admin_add_input"
                                  defaultValue={oldData.reminder_timing_type ? oldData.reminder_timing_type : ""}
                                  onChange={handleChange}
                                >
                                  <option value=""> {i18n.language == "en" ? "select value" : " اختار الموعد"} </option>
                                  <option value="days"> {i18n.language == "en" ? "days" : "يوم"} </option>
                                  <option value="hours"> {i18n.language == "en" ? "hours" : "ساعه"} </option>
                                  <option value="minutes"> {i18n.language == "en" ? "minutes" : "دقيقة"} </option>
                                </select>
                                <p className={"form-input-error-space"}>
                                  {errors.timing_type ? errors.timing_type : null}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-md-12">
                          <button type="submit" disabled={isSubmitting} className="admin_add_button btn_zoom">
                            {t("zoom.addition")}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getExhibitionVisitors } from "../../../../Redux/Actions/exhibition/exhibition.action";
import { exportExhibitionUsers } from "../../../../Services/api/Exhibition/ExhibitionProvider";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import PaymentDetailsModal from "../../../Shared/Components/PaymentDetailsModal/PaymentDetailsModal";
import TableHeadActions from "../../../Shared/Components/TableHeadActions/TableHeadActions";
import Tabs from "../../../Shared/Components/Tabs/Tabs";
import { filterActions } from "./ExhibitionDetailsTableAction";
import UsersTable from "./UsersTable";
import classes from "./exhibitionDetails.module.css";
const ExhibitionDetailsUsersTable = ({ id, mode, setIsExportLoading }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [activeTab, setActiveTab] = useState(mode === "Free" ? 2 : 1);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [payment, setPayment] = useState(null);
  const [dataToExport, setDataToExport] = useState([]);
  const { visitorsLoading } = useSelector((state) => state.exhibitionReducer);
  const [filterData, setFilterData] = useState({
    name: "",
    email: "",
    phone: "",
    orderDate: "",
    paymentMethod: "",
    rating: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = (e) => {
    setFilterData({
      name: "",
      email: "",
      phone: "",
      orderDate: "",
      paymentMethod: "",
      rating: "",
    });
    setIsResetApplied(true);
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getFilterData = () => {
    const filterVisitorsData = {};
    filterData.name && (filterVisitorsData.name = filterData.name);
    filterData.email && (filterVisitorsData.email = filterData.email);
    filterData.phone && (filterVisitorsData.phone = filterData.phone);
    filterData.orderDate && (filterVisitorsData.orderDate = filterData.orderDate);
    filterData.paymentMethod && (filterVisitorsData.paymentMethod = filterData.paymentMethod);
    filterData.rating && (filterVisitorsData.rating = filterData.rating);

    sortColumn && (filterVisitorsData.sort_column = sortColumn);
    sortOrder && (filterVisitorsData.sort_order = sortOrder);
    filterVisitorsData.status = activeTab;
    return filterVisitorsData;
  };

  const getVisitorsHandler = () => {
    const filterVisitorsData = getFilterData();
    setDataToExport(filterVisitorsData);

    dispatch(
      getExhibitionVisitors({
        id,
        perPage: 10,
        page: currentPage,
        filter: filterVisitorsData,
      })
    );
  };

  const tabs = [
    {
      id: "users",
      key: 1,
      label: t("Exhibition.request_users_list"),
      disable: mode === "Free",
    },
    {
      id: "usersApproved",
      key: 2,
      label: mode === "Free" ? t("Exhibition.users_list") : t("Exhibition.accessible_users"),
    },
    {
      id: "usersRejected",
      key: 3,
      label: t("Exhibition.rejected_users"),
      disable: mode === "Free",
    },
  ];
  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("booth.user_name"),
      type: "text",
      placeholder: t("booth.user_name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "email",
      component: "input",
      label: t("booth.email"),
      type: "email",
      placeholder: t("booth.email"),
      name: "email",
      value: filterData.email,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "phone",
      component: "input",
      label: t("booth.phone"),
      type: "text",
      placeholder: t("booth.phone"),
      name: "phone",
      value: filterData.phone,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    ...(mode !== "Free"
      ? [
          {
            id: "paymentMethod",
            label: t("Exhibition.payment_method"),
            component: "select",
            placeholder: t("Exhibition.payment_method"),
            initialValue: t("Exhibition.payment_method"),
            name: "paymentMethod",
            value: filterData.paymentMethod,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "wallet",
                value: "wallet",
                label: t("financial.label.wallet"),
              },
              {
                id: "bankTransfer",
                value: "BankTrasfer",
                label: t("financial.label.bankTrasfer"),
              },
            ],
          },
        ]
      : []),
    ...(activeTab === 2
      ? [
          {
            id: "rating",
            label: t("Exhibition.rating"),
            component: "select",
            placeholder: t("Exhibition.rating"),
            initialValue: t("Exhibition.rating"),
            name: "rating",
            value: filterData.rating,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "0",
                value: "0",
                label: 0,
              },
              {
                id: "1",
                value: "1",
                label: 1,
              },
              {
                id: "2",
                value: "2",
                label: 2,
              },
              {
                id: "3",
                value: "3",
                label: 3,
              },
              {
                id: "4",
                value: "4",
                label: 4,
              },
              {
                id: "5",
                value: "5",
                label: 5,
              },
            ],
          },
        ]
      : []),
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "reset",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-2   border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        getVisitorsHandler();
      },
    },
  ];

  const bankTransferActionHandler = (payment) => {
    setShowPaymentModal(true);
    setPayment(payment);
  };

  useEffect(() => {
    getVisitorsHandler();
    setIsResetApplied(false);
  }, [id, currentPage, sortColumn, sortOrder, isResetApplied, activeTab]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    await exportExhibitionUsers(id, type, dataToExport)
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        setIsExportLoading(false);
      });
  };

  return (
    <div className={classes["exhibition-details__tables"]}>
      <div className={classes["exhibition-details__tables__header"]}>
        <Tabs
          tabs={tabs}
          disabled={visitorsLoading}
          activeTab={activeTab}
          onSelectTab={(key) => {
            setActiveTab(key);
          }}
        />
        <TableHeadActions
          actions={filterActions({
            showFilter: () => {
              setShowFilter((prev) => !prev);
            },
            addExhibition: () => {},
            exportPdf: () => {
              exportHandler("pdf");
            },
            exportExcel: () => {
              exportHandler("xlsx");
            },
          })}
        />
      </div>
      {showFilter && <FilterBox fields={filterFields} />}
      <UsersTable
        setShowPaymentModal={setShowPaymentModal}
        setCurrentPage={setCurrentPage}
        sortAction={sortActionHandler}
        exhibitionId={id}
        bankTransferActionHandler={bankTransferActionHandler}
        activeTab={activeTab}
        mode={mode}
      />
      {showPaymentModal && (
        <PaymentDetailsModal
          closeModal={() => {
            setShowPaymentModal(false);
            setPayment(null);
          }}
          payment={payment}
        />
      )}
    </div>
  );
};

export default ExhibitionDetailsUsersTable;

import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { decrypt } from "../../../Helpers/Secret";
import CourseDetails from "./Profile/Details/Details";

function Profile(props) {
  const { id, programId } = useParams();
  const location = useLocation();
  const roles = JSON.parse(decrypt(localStorage.getItem("roles")) || "[]");
  const role = location?.state?.role
    ? location?.state?.role
    : roles.includes("admin")
    ? "admin"
    : roles.includes("manager")
    ? "manager"
    : roles.includes("trainer")
    ? "trainer"
    : "partner";

  const status = location?.state?.status || "Published";

  return (
    <>
      <CourseDetails
        id={id ?? props.match.params.id}
        role={role}
        status={status}
        programId={programId}
        inProgramCourses={props.inProgramCourses}
      />
    </>
  );
}

export default Profile;

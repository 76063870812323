import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CourseCard from "../../../Shared/Components/CourseCard/CourseCard";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import classes from "./latestCourses.module.css";
import { useEffect, useRef, useState } from "react";
import { latestCourses } from "../../../../Services/api/courses/courseProvider";
import LatestCoursesCard from "./LatestCoursesCard/LatestCoursesCard";
import SharedSlider from "../../../Shared/SharedSlider/SharedSlider";
import "../Categories/categories.css";
import SkeletonSpinnerOverlay from "../../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import i18n from "../../../../i18n/i18n";

const LatestCourses = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [courses, setcourses] = useState([]);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    const apiCall = latestCourses;
    apiCall({
      page: 1,
      perPage: 12,
      status: "Published",
      not_in_program: 1,
      is_program: 0,
    })
      .then((res) => {
        if (res.status && res.status === 200) {
          if (i18n.language === "ar") {
            setcourses(res.data.data.courses.toReversed());
          } else {
            setcourses(res.data.data.courses);
          }
        }
        setloading(true);
      })
      .catch((err) => {
        console.log(err);
        setloading(true);
      });
  }, []);

  return (
    <div>
      {" "}
      {loading ? (
        <>
          <div className={classes.trainersSectionCont}>
            <div className={classes.categories}>
              <h2 className={classes.sectionTitle}>
                {t("home.latest")}&nbsp;
                <span>{t("home.Courses")}</span>
              </h2>
              {courses.length <= 3 && width > 1200 ? (
                <div className={classes.staticCont}>
                  {courses.map((course, index) => (
                    <LatestCoursesCard
                      img={course.img}
                      name={course.name}
                      seats={course.available_seats}
                      description={course.brief_description}
                      start_date={course.start_date}
                      price={course.price}
                      id={course.id}
                      key={index}
                      loading={loading}
                      index={index}
                      totalCards={courses.length}
                    />
                  ))}
                </div>
              ) : courses.length === 2 && width >= 730 ? (
                <div className={classes.staticCont}>
                  {courses.map((course, index) => (
                    <LatestCoursesCard
                      img={course.img}
                      name={course.name}
                      seats={course.available_seats}
                      description={course.brief_description}
                      start_date={course.start_date}
                      price={course.price}
                      id={course.id}
                      key={index}
                      loading={loading}
                      index={index}
                      totalCards={courses.length}
                    />
                  ))}
                </div>
              ) : courses.length === 1 ? (
                <div className={classes.staticCont}>
                  {courses.map((course, index) => (
                    <LatestCoursesCard
                      img={course.img}
                      name={course.name}
                      seats={course.available_seats}
                      description={course.brief_description}
                      start_date={course.start_date}
                      price={course.price}
                      id={course.id}
                      key={index}
                      loading={loading}
                      index={index}
                      totalCards={courses.length}
                    />
                  ))}
                </div>
              ) : (
                <SharedSlider
                  slidesToShow={3}
                  slidesToScroll={3}
                  dots={true}
                  noArrows={true}
                  classes={`${classes.slider} categories-slider`}
                  responsive={[
                    {
                      breakpoint: 2000,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 1920,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 1600,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 1400,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      },
                    },
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                    {
                      breakpoint: 1000,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                    {
                      breakpoint: 730,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 660,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 380,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                >
                  {width <= 600
                    ? i18n.language === "ar"
                      ? courses
                          .toReversed()
                          .slice(0, 4)
                          .toReversed()
                          .map((course, index) => (
                            <LatestCoursesCard
                              img={course.img}
                              name={course.name}
                              seats={course.available_seats}
                              description={course.brief_description}
                              start_date={course.start_date}
                              price={course.price}
                              id={course.id}
                              key={index}
                              loading={loading}
                              index={index}
                              totalCards={courses.length}
                            />
                          ))
                      : courses
                          .slice(0, 4)
                          .map((course, index) => (
                            <LatestCoursesCard
                              img={course.img}
                              name={course.name}
                              seats={course.available_seats}
                              description={course.brief_description}
                              start_date={course.start_date}
                              price={course.price}
                              id={course.id}
                              key={index}
                              loading={loading}
                              index={index}
                              totalCards={courses.length}
                            />
                          ))
                    : courses.map((course, index) => (
                        <LatestCoursesCard
                          img={course.img}
                          name={course.name}
                          seats={course.available_seats}
                          description={course.brief_description}
                          start_date={course.start_date}
                          price={course.price}
                          id={course.id}
                          key={index}
                          loading={loading}
                          index={index}
                          totalCards={courses.length}
                        />
                      ))}
                </SharedSlider>
              )}

              <div className={classes.aboutButtonCont}>
                <CustomButton
                  value={t("more_courses")}
                  colors="#133535"
                  classes={classes.aboutButton}
                  action={() => history.push(`/training-courses`)}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <div
              style={{
                height: "400px",
                width: "90%",
              }}
            >
              <SkeletonSpinnerOverlay
                backdrop={true}
                skeletonWidth="100"
                skeletonHight="100"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LatestCourses;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { answerLessonActivity } from "../../../../../Services/api/courses/courseProvider";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/youtube.svg";

export default function ScormQuestionView({ question, setFieldValue }) {
  const [score, setScore] = useState(null);
  function saveRes(e) {
    if (
      e.data["cmi_element"] === "cmi.core.score.raw" ||
      e.data["cmi_element"] === "cmi.score.raw"
    ) {
      if (score !== e.data["value"]) {
        setScore(e.data["value"]);
      }
    }
  }

  useEffect(() => {
    if (question.media?.path) {
      window.addEventListener("message", saveRes);
      return () => window.removeEventListener("message", saveRes);
    }
  }, []);

  useEffect(() => {
    if (score !== null) setFieldValue("answer", score / 100);
  }, [score]);

  return (
    <>
      {question.media?.path ? (
        <iframe
          id={question.id}
          title={"Scorm"}
          src={`${process.env.REACT_APP_SERVER_PATH}scorm/player?path=${question.media?.path}`}
          display="flex"
          height={"644px"}
          width="100%"
          allow="fullscreen"
        ></iframe>
      ) : (
        <button
          type="button"
          disabled={!question.media?.path}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon
            className={`tw-w-16 tw-h-16 ${
              question.media?.path ? "tw-stroke-teal-600" : "tw-stroke-gray-400"
            }`}
          />
        </button>
      )}
    </>
  );
}

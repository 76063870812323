import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"

import { Formik } from "formik"
import { toast } from "react-toastify"
import CourseVideo from "../../Profile/CourseVideo/CourseVideo"
import Header from "../Header/Header"

import { CourseProfileProvider } from "../../../../../Services/api/courses/Trainer/CourseProfileContext"
import { addObjective } from "../../../../../Services/api/EduGoals/EduGoalsProvider"

const Add = () => {
    require("../Content/content.css");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { id } = useParams();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <CourseProfileProvider>
            <Header />
            <div className="container-fluid training-course-syllabus-container" style={{
                marginTop: 35
            }}>
                <div className="training-course-syllabus-section">
                    <div className="add-goals-Edu">
                        <h4>{t('edu.Add_edu_goals')} </h4>
                        <div>
                            <Formik
                                initialValues={{
                                    name: "",
                                    description: "",
                                }}
                                onSubmit={async (values, { setSubmitting }) => {
                                    try {
                                        
                                        let dataEduGoal = {
                                            course_id: id,
                                            ...values,
                                        };
                                        let response = await addObjective(dataEduGoal)

                                        if(response.status === 201){
                                            toast.success(
                                                <span style={{ fontSize: 13, fontWeight: "bold", display:'block', paddingBottom: '10px' }}>
                                                    {response.data.msg}
                                                </span>
                                            );
                                            history.push(`/trainer/course/educational-goals/${id}`);
                                        }
                                    } catch (err) {
                                        toast.error(
                                            <span style={{ fontSize: 13, fontWeight: "bold", display:'block', paddingBottom: '10px' }}>{err.response.data.msg}</span>
                                        );
                                    }
                                }}
                                // validateOnChange={}
                                validate={(values) => {
                                    const errors = {};
                                    if (!values.name) {
                                        errors.name = t("crud.errors.required");
                                    }
                                    if (!values.description) {
                                        errors.description = t("crud.errors.required");
                                    }
                                    return errors;
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    handleBlur,
                                    setFieldValue,
                                }) => (
                                    <form onSubmit={handleSubmit} className="admin_add_form">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <label htmlFor="name" className="admin_add_label">
                                                            {t("edu.Goal")}
                                                        </label>
                                                        <input
                                                            id="goalOne"
                                                            type="text"
                                                            name="name"
                                                            className="admin_add_input"
                                                            onChange={handleChange}
                                                        />
                                                        <p className={"form-input-error-space"}>
                                                            {errors.name ? errors.name : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        <label htmlFor="name" className="admin_add_label">
                                                        {t("edu.description")}
                                                        </label>
                                                        <input
                                                            id="description"
                                                            type="text"
                                                            name="description"
                                                            className="admin_add_input"
                                                            onChange={handleChange}
                                                        />
                                                        <p className={"form-input-error-space"}>
                                                            {errors.description ? errors.description : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <button
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    className="btn_submit"
                                                >
                                               {t('edu.save')}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
                <CourseVideo courseID={id} />
            </div>
        </CourseProfileProvider>
    )
}
export default Add

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import {
  getAllCourses,
  getAnalysis,
  getAnalysisById,
  getChapter,
  getChapterAnalysis,
  getLesson,
  getLessonAnalysis,
} from "../../../Services/api/Analysis/AnalysisProvider";
import numberOfContents from "../../../assets/image/analysis/number-of-contents.png";
import numberOfDiscussions from "../../../assets/image/analysis/number-of-discussions.png";
import numberOfExam from "../../../assets/image/analysis/number-of-exams.png";
import numberOfFiles from "../../../assets/image/analysis/number-of-files.png";
import numberOfLessons from "../../../assets/image/analysis/number-of-lessons.png";
import numberOfRegistered from "../../../assets/image/analysis/number-of-registered.png";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import AnalysisCard from "./AnalysisCard";

function Analysis() {
  require("./Analysis.css");
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [courseIdCheck, setCourseIdCheck] = useState(null);
  const [chapterId, setChapterId] = useState(null);
  const [chapterIdCheck, setChapterIdCheck] = useState(null);
  const [lessonId, setLessonId] = useState(null);
  const [count, setCount] = useState({});
  const [chapter, setChapter] = useState([]);
  const [lesson, setLesson] = useState([]);
  const [chapterAnalysis, setChapterAnalysis] = useState(null);
  const [lessonAnalysis, setLessonAnalysis] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setChapter([]);
    setChapterId(null);
    setLesson([]);
    setLessonId(null);
    setChapterAnalysis(null);
    setCourseIdCheck(null);
    setChapterIdCheck(null);
    if (courseId !== null) {
      setChapter([]);
      setChapterId(null);
      setLesson([]);
      setLessonId(null);
      setChapterAnalysis(null);
      setCourseIdCheck(null);
      setChapterIdCheck(null);
      getAnalysisById(courseId)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setCount(res.data.response);
            setIsLoading(false);
            setCourseIdCheck(courseId);
          }
        })
        .catch((err) => {
          
        });
      getChapter(courseId)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setChapter(res.data.chapters);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          
        });
    } else {
      setChapterAnalysis(null);
      getAnalysis()
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setCount(res.data.response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          
        });
    }
  }, [courseId]);

  useEffect(() => {
    setIsLoading(true);
    setLesson([]);
    setLessonId(null);
    setChapterIdCheck(null);
    if (chapterId !== null) {
      getLesson(chapterId)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setLesson(res.data.lessons);
            setIsLoading(false);
            setChapterIdCheck(chapterId);
          }
        })
        .catch((err) => {
          
        });
      getChapterAnalysis(chapterId)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setChapterAnalysis(res.data.response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          
        });
    } else {
      setLesson([]);
      setLessonId(null);
      setChapterAnalysis(null);
      getAnalysis()
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setCount(res.data.response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          
        });
    }
  }, [chapterId]);

  useEffect(() => {
    setIsLoading(true);
    if (lessonId !== null) {
      getLessonAnalysis(lessonId)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setLessonAnalysis(res.data.response);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          
        });
    } else {
      setLessonAnalysis(null);
    }
  }, [lessonId]);

  useEffect(() => {
    getAllCourses()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCourse(res.data.courses);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="admin_label">
            <NavLink to="/admin">{t("Home")}</NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("sidebar.label.dashBoardAnalysis")}
          </div>
          <>
            <div className="counter_analysis">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  width: "100%",
                  gap: "50px",
                }}
              >
                <div className="select_analysis" style={{ width: "100%" }}>
                  <div className="select_label">{t("analysis.allCourse")}</div>
                  <Select
                    name="couese"
                    id="couese"
                    options={course}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isClearable={true}
                    onChange={(item) => {
                      if (item) {
                        setCourseId(item.id);
                      } else {
                        setCourseId(null);
                        setChapterId(null);
                        setLessonId(null);
                      }
                    }}
                    placeholder={t("analysis.all")}
                  />
                </div>
                {courseIdCheck !== null ? (
                  <div className="select_analysis" style={{ width: "100%" }}>
                    <div className="select_label">{t("analysis.allChapter")}</div>
                    <Select
                      name="chapter"
                      id="chapter"
                      options={chapter}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      onChange={(item) => {
                        if (item) {
                          setChapterId(item.id);
                        } else {
                          setChapterId(null);
                          setLessonId(null);
                        }
                      }}
                      placeholder={t("analysis.all")}
                    />
                  </div>
                ) : null}

                {chapterIdCheck !== null ? (
                  <div className="select_analysis" style={{ width: "100%" }}>
                    <div className="select_label">{t("analysis.allLesson")}</div>
                    <Select
                      name="lesson"
                      id="lesson"
                      options={lesson}
                      getOptionLabel={(option) => option.title}
                      getOptionValue={(option) => option.id}
                      isClearable={true}
                      onChange={(item) => {
                        if (item) {
                          setLessonId(item.id);
                        } else {
                          setLessonId(null);
                        }
                      }}
                      placeholder={t("analysis.all")}
                    />
                  </div>
                ) : null}
              </div>

              {isLoading ? (
                <div
                  style={{
                    height: "25vh",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <div className="counter_analysis_grid_top"></div>
              )}
              {isLoading ? (
                <div
                  style={{
                    height: "25vh",
                    marginTop: "20px",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <>
                  <>
                    <h5
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "30%",
                        margin: "auto",
                        marginBottom: "20px",
                        borderBottom: "2px solid #ccc",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {t("analysis.courses")}
                    </h5>
                    <div className="counter_analysis_grid">
                      <AnalysisCard
                        img={numberOfRegistered}
                        label={t("analysis.users_count")}
                        counter={count.users_count}
                      />
                      <AnalysisCard
                        img={numberOfContents}
                        label={t("analysis.content_count")}
                        counter={count.content_count}
                      />
                      <AnalysisCard img={numberOfFiles} label={t("analysis.files_count")} counter={count.files_count} />
                      <AnalysisCard
                        img={numberOfLessons}
                        label={t("analysis.lessons_count")}
                        counter={count.lessons_count}
                      />
                      <AnalysisCard
                        img={numberOfDiscussions}
                        label={t("analysis.discussions_count")}
                        counter={count.discussions_count}
                      />
                      <AnalysisCard img={numberOfExam} label={t("analysis.quiz_count")} counter={count.quiz_count} />
                    </div>
                  </>
                  {chapterAnalysis && (
                    <>
                      <h5
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30%",
                          margin: "auto",
                          marginBottom: "20px",
                          borderBottom: "2px solid #ccc",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {t("analysis.chapters")}
                      </h5>
                      <div className="counter_analysis_grid">
                        <AnalysisCard
                          img={numberOfRegistered}
                          label={t("analysis.users_count")}
                          counter={chapterAnalysis.users_count}
                        />
                        <AnalysisCard
                          img={numberOfContents}
                          label={t("analysis.content_count")}
                          counter={chapterAnalysis.content_count}
                        />
                        <AnalysisCard
                          img={numberOfFiles}
                          label={t("analysis.sub_chapters")}
                          counter={chapterAnalysis.sub_chapters}
                        />
                        <AnalysisCard
                          img={numberOfLessons}
                          label={t("analysis.lessons_count")}
                          counter={chapterAnalysis.lessons}
                        />
                        <AnalysisCard
                          img={numberOfExam}
                          label={t("analysis.quiz_count")}
                          counter={chapterAnalysis.quiz_list}
                        />
                      </div>
                    </>
                  )}

                  {lessonAnalysis && (
                    <>
                      <h5
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30%",
                          margin: "auto",
                          marginBottom: "20px",
                          borderBottom: "2px solid #ccc",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {t("analysis.lessons")}
                      </h5>
                      <div className="counter_analysis_grid">
                        <AnalysisCard
                          img={numberOfRegistered}
                          label={t("analysis.objectives")}
                          counter={lessonAnalysis.objectives}
                        />
                        <AnalysisCard
                          img={numberOfContents}
                          label={t("analysis.content_count")}
                          counter={lessonAnalysis.contents}
                        />
                        <AnalysisCard
                          img={numberOfFiles}
                          label={t("analysis.videos")}
                          counter={lessonAnalysis.videos}
                        />
                        <AnalysisCard
                          img={numberOfLessons}
                          label={t("analysis.lessons_count_view")}
                          counter={lessonAnalysis.lesson_views}
                        />
                        <AnalysisCard
                          img={numberOfDiscussions}
                          label={t("analysis.discussions_count")}
                          counter={lessonAnalysis.assignments}
                        />
                        <AnalysisCard
                          img={numberOfExam}
                          label={t("analysis.quiz_count")}
                          counter={lessonAnalysis.quiz_list}
                        />
                      </div>
                      <div
                        className="table-responsive tabel-analysis"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            marginBottom: "10px",
                          }}
                        >
                          {t("analysis.contantView")}
                        </div>
                        <table
                          className="table table-bordered"
                          style={{
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">{t("analysis.title")}</th>
                              <th scope="col">{t("analysis.view")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lessonAnalysis.contents_view.map((lesson, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{lesson.title}</td>
                                <td>{lesson.views}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default Analysis;

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { CourseContext } from "../../../../../Services/api/courses/courseContext";
import i18n from "../../../../../i18n/i18n";
import StarsRating from "../../../../Shared/Components/StarsRating/StarsRating";
import CourseVideos from "./CourseVideos";
import classes from "./trainingCourseContent.module.css";

export default function TrainingCourseContentInfo({
  isProgram,
  inProgram,
  backPath,
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const courseDetailsContext = useContext(CourseContext);
  const course = courseDetailsContext.courseDetails;
  const [trainers, setTrainers] = useState([]);

  useEffect(() => {
    if (course.trainer && course.sub_trainers) {
      const manager = course.trainer;
      const subTrainers = [...course.sub_trainers];
      setTrainers([
        manager,
        ...subTrainers.filter(
          (trainer) => Number(trainer.id) !== Number(course.trainer.id)
        ),
      ]);
    }
  }, [course]);

  const itemsList = [
    {
      id: "description",
      title: t("description"),
      data: ({ description }) => (
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      ),
    },
    {
      id: "category_department",
      title: t("category_department"),
      data: ({ category, department_name_en, department_name_ar }) => (
        <div>
          {i18n.language === "ar" ? category.name_ar : category.name_en} -{" "}
          {i18n.language === "ar" ? department_name_ar : department_name_en}
        </div>
      ),
    },
    ...(!course.prerequisited_course
      ? []
      : [
          {
            id: "prerequisite",
            title: t("prerequisite"),
            data: ({ prerequisited_course }) => (
              <div>{prerequisited_course.name}</div>
            ),
          },
        ]),

    ...(!inProgram
      ? [
          {
            id: "is_scfhsv",
            title: t("memoryNumbers"),
            data: ({ is_scfhsv }) => (
              <div>
                {is_scfhsv ? t("general.required") : t("general.not_required")}
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <div
      className={"container-fluid" + " " + classes.training_course_content_info}
    >
      <div className={classes.training_top}>
        <div className={classes.training_container}>
          <div className={classes.training_over_view}>
            <h2 className={classes.training_over_view_title}>{t("details")}</h2>
            <div className={classes.training_over_view_content}>
              {itemsList.map((item) => (
                <div className={classes.training_item} key={item.id}>
                  <h3 className={classes.training_over_view_subtitle}>
                    {item.title}
                  </h3>
                  <div className={classes.training_over_view_data}>
                    {item.data(course)}
                  </div>
                </div>
              ))}
            </div>
            <div className={classes.training_video}>
              <CourseVideos />
            </div>
          </div>
        </div>
        {!isProgram ? (
          <>
            <div className={classes.training_separator} />
            <div className={classes.trainers_container}>
              <h2 className={classes.training_over_view_subtitle}>
                {t("courses_list.trainers")}
              </h2>
              <div className={classes.training_over_view_content}>
                {trainers.length > 0
                  ? trainers.map((trainer, index) => (
                      <div
                        className={classes.trainer_container}
                        key={trainer.id}
                      >
                        <div
                          className={classes.trainer_image}
                          onClick={() =>
                            history.push(
                              `/trainers/trainer-details/${trainer.id}`
                            )
                          }
                        >
                          <img src={trainer.img} alt="trainer" />
                        </div>
                        <div className={classes.trainer_info}>
                          <div
                            className={classes.trainer_name}
                            onClick={() =>
                              history.push(
                                `/trainers/trainer-details/${trainer.id}`
                              )
                            }
                          >
                            {trainer.name} -{" "}
                            {index == 0
                              ? t("selection.role.manager")
                              : t("selection.role.trainer")}
                          </div>
                          <div className={classes.trainer_rating}>
                            <StarsRating
                              rating={Number(trainer.ratings_avg_stars)}
                              cutStyle={classes.star_font_size}
                            />
                          </div>
                          <div
                            className={classes.trainer_description}
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language == "ar"
                                  ? trainer.description_ar
                                  : trainer.description_en,
                            }}
                          ></div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className={classes.training_bottom}>
        {!course.is_program && course.chapaters.length > 0 ? (
          <div className={classes.training_plan}>
            <h2 className={classes.training_plan_title}>
              {t("training_plan")}
            </h2>
            <div className={classes.training_plan_content}>
              {course.chapaters?.map((chapter, index) => (
                <div
                  key={chapter.id}
                  className={classes.training_plan_content_item}
                >
                  <div className={classes.training_plan_content_item_dot} />
                  <div className={classes.training_plan_content_item_title}>
                    {chapter.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : course.is_program &&
          course.courses.sort(
            (a, b) => Number(a.program_order) - Number(b.program_order)
          ).length > 0 ? (
          <div className={classes.training_plan}>
            <h2 className={classes.training_plan_title}>
              {t("courses_title")}
            </h2>
            <div className={classes.training_plan_program_content}>
              {course.courses?.map((course, index) => (
                <div
                  key={course.id}
                  className={classes.training_plan_program_content_item}
                  onClick={() => {
                    if (backPath === "received-coupons") {
                      history.push(
                        `/private-profile/received-coupons/program/${id}/course/${course.id}/details`
                      );
                    } else {
                      history.push(
                        `/training-programs/${id}/details/course/${course.id}/details`
                      );
                    }
                  }}
                >
                  <div
                    className={classes.training_plan_program_content_item_image}
                  >
                    <img src={course.img} alt="course" />
                  </div>
                  <div
                    className={classes.training_plan_program_content_item_title}
                  >
                    {course.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { decrypt } from "../../../../Helpers/Secret";
import { deleteRefund, updateRefundStatus } from "../../../../Redux/Actions/refundSetting/refundSetting.action";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "./refundList.module.css";

const RefundTable = ({ type }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [refundId, setRefundId] = useState(null);
  const history = useHistory();

  const { refundsList, refundsListLoading } = useSelector((state) => state.refundsListReducer);

  const updateRefundStatusHandler = (id) => {
    dispatch(
      updateRefundStatus({
        id,
      })
    );
  };

  const deleteHandler = (id) => {
    setShowDeleteModal(true);
    setRefundId(id);
  };

  const confirmDeleteRefundHandler = () => {
    if (!refundId) return;
    dispatch(deleteRefund(refundId));
    setShowDeleteModal(false);
  };

  const displayMode = (parent_type) => {
    if (parent_type === "course") {
      return t("rate.label.course");
    } else if (parent_type === "program_payment") {
      return t("general.program");
    } else if (parent_type === "exhibition_visitor") {
      return t("general.exhibition_visitor");
    } else if (parent_type === "exhibition_booth") {
      return t("general.exhibition_booth");
    }
  };

  const RefundActionsList = [
    {
      id: "edit",
      icon: <img src={editIcon} alt="" />,
      action: (id) => {
        history.push(
          `${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/refund-setting${
            type !== "trainee" ? `/${type}` : ""
          }/edit/${id}`
        );
      },
      tooltip: t("general.edit"),
    },

    {
      id: "delete",
      icon: <img src={DeleteIcon} alt="" />,
      action: (id) => {
        deleteHandler(id);
      },
      tooltip: t("general.delete"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "title",
      name: t("financial.refund.refund_requset_title"),
      key: "title",
      cell: ({ rowData }) => <div className={classes["refund-list__table-name"]}>{rowData?.title || "-"}</div>,
    },
    {
      id: "refund_mechanism",
      name: t("refund_mechanisem"),
      key: "refund_mechanism",
      cell: ({ rowData }) => <>{rowData?.mechanism == 1 ? t("coupon.label.co_value") : t("coupon.label.per_value")}</>,
    },

    {
      id: "refund_value",
      name: t("financial.refund.price"),
      key: "rrefund_value",
      cell: ({ rowData }) => (
        <>{rowData?.mechanism == 2 ? rowData?.amount + "%" : rowData?.amount + t("general.sar")}</>
      ),
    },
    {
      id: "parent_type",
      name: t("zoom.type"),
      key: "parent_type",
      cell: ({ rowData }) => (
        <>
          <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.refundable_type == "course" ? "#8599B6" : "#12C689",
              backgroundColor: rowData?.refundable_type == "course" ? "#C1CEE222" : "#C1E2C822",
            }}
          >
            {displayMode(rowData?.refundable_type) || "-"}
          </span>
        </>
      ),
    },
    {
      id: "refunadable_name",
      name: t("financial.refund.refundable_name"),
      key: "refundable_name",
      cell: ({ rowData }) => (
        <span
          style={{
            color: "#046c77",
          }}
        >
          {rowData?.refunadable?.name || "-"}
        </span>
      ),
    },
    ...(type === "trainee"
      ? [
          {
            id: "payment_refund",
            name: t("payment_setting"),
            key: "payment_refund",
            cell: ({ rowData }) => (
              <>
                {rowData?.refunadable?.payments ? (
                  <>
                    {rowData?.refunadable?.payments?.map((item, indx) =>
                      item.id == rowData?.refundable_id ? (
                        <>
                          {t("payment_setting")} {indx + 1}
                        </>
                      ) : (
                        <></>
                      )
                    )}
                  </>
                ) : (
                  "-"
                )}
              </>
            ),
          },
        ]
      : []),
    {
      id: "note_for_user",
      name: t("note_for_user"),
      key: "note_for_user",
      cell: ({ rowData }) => <span>{rowData?.notes || "-"}</span>,
    },
    ...(type === "trainee"
      ? [
          {
            id: "created_by",
            name: t("general.created_by"),
            key: "created_by",
            cell: ({ rowData }) => (
              <>
                <span>
                  {(i18n.language === "en" ? rowData?.created_by?.name_en : rowData?.created_by?.name_ar) || "-"}
                </span>
              </>
            ),
          },
        ]
      : []),
    {
      id: "start_date",
      name: t("general.start_date"),
      key: "start_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.start_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.start_date)}
        </>
      ),
    },
    {
      id: "end_date",
      name: t("general.end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.end_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.end_date)}
        </>
      ),
    },

    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => <TableActionsColumn actions={RefundActionsList} id={rowData.id} />,
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status}
          id={rowData.id}
          onStatusChange={() => {
            updateRefundStatusHandler(rowData.id); ///////////////
          }}
        />
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <MainTable columns={columns} data={refundsList || []} isLoading={refundsListLoading} />
      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon}
          title={t("financial.refund.label_popup_refund")}
          confirmMsg={t("financial.refund.msg_popup_refund")}
          confirmText={t("general.confirm")}
          cancelText={t("general.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            confirmDeleteRefundHandler();
          }}
          isLoading={false}
        />
      )}
    </>
  );
};

export default RefundTable;

import { Field, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { assignTrainees, getTrainees } from "../../../../Services/api/toutorials/assignToCourseProvider";
import CloseIcon from "../../../../assets/icons/remove-red.svg";
import SearchIcon from "../../../../assets/icons/search.svg";
import DataTable from "./DataTable";
import classes from "./addCourseToTraineeModal.module.css";

const AddCourseToTraineeModal = ({ closeModal, setLoading, getTraineesReg, trainees }) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [traineesOptions, setTraineesOptions] = useState([]);

  useEffect(() => {
    getTrainees(id).then((res) => {
      if (res.status && res.status === 200 && res.data.status) {
        const traineesSet = new Set(trainees.map((i) => i.id));
        setTraineesOptions(res.data.response.filter((item) => !traineesSet.has(item.id)));
      }
    });
  }, []);

  const traineesFields = [
    {
      id: "order",
      column: ({ rowData, rowIndex }) => {
        return <>{rowIndex + 1}</>;
      },
    },
    // {
    //   id: "id",
    //   column: ({ rowData }) => <>{rowData.id}</>,
    // },
    {
      id: "name",
      column: ({ rowData }) => <>{rowData.name || "-"}</>,
    },
    {
      id: "email",
      column: ({ rowData }) => <>{rowData.email || "-"}</>,
    },
  ];

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className={classes["add-course-modal"]}>
      <div className={classes["add-course-modal__container"]} ref={modalRef}>
        <div
          className={classes["add-course-modal__close"]}
          onClick={() => {
            closeModal();
          }}
        >
          <img src={CloseIcon} alt="" />
        </div>
        <div className={classes["add-course-modal__head"]}>
          <h3 className={classes["add-course-modal__head-title"]}>{t("department_trainees.add_course_to_trainee")}</h3>
        </div>
        <Formik
          initialValues={{
            trainees: [],
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setLoading(true);
              const data = {
                ...values,
              };
              let response = await assignTrainees(id, data);
              if (response.status && response.status === 200 && response.data.status) {
                closeModal();
                toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
                getTraineesReg();
              }
              setSubmitting(false);
              setLoading(false);
            } catch (err) {
              toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data.msg}</span>);
              setSubmitting(false);
              setLoading(false);
            }
          }}
          validate={(values) => {
            const errors = {};
            if (!values.trainees || values.trainees.length === 0) {
              errors.trainees = t("general.required");
            }
            return errors;
          }}
          validateOnChange={true}
        >
          {({ setFieldValue, setValues, values, handleSubmit, errors, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes["add-course-modal__content"]}>
                <DataTable
                  isAddCourseToTrainee={true}
                  label={t("trainee_tobe_assign")}
                  selectedItems={[...values.trainees]}
                  isLoading={false}
                  data={traineesOptions}
                  fields={traineesFields}
                  error={errors.trainees}
                  searchableFields={["name", "email"]}
                  selectedHandler={(value) => {
                    if (values.trainees.includes(value.id)) {
                      setFieldValue("trainees", [...values.trainees.filter((item) => item !== value.id)]);
                    } else {
                      setFieldValue("trainees", [...values.trainees, value.id]);
                    }
                  }}
                  isMultiple
                  required
                  canSelectAll
                  toggleSelectAllHandler={() => {
                    setFieldValue(
                      "trainees",
                      values.trainees.length === traineesOptions.length ? [] : traineesOptions.map((item) => item.id)
                    );
                  }}
                />
                <div className={` ${classes["add-course-modal__content__box__actions"]}`}>
                  <button
                    className={` ${classes["add-course-modal__content__box__actions-btn"]}`}
                    disabled={isSubmitting}
                  >
                    {t("general.submit")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddCourseToTraineeModal;

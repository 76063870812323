import { useTheme } from "@mui/material";

const ContactArrow = ({ width, height, fill }) => {
	const theme = useTheme();
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || "22"}
			height={height || "22"}
			viewBox="0 0 22 22"
		>
			<g
				id="arrow-left-circle_7_"
				data-name="arrow-left-circle (7)"
				transform="translate(1 1.342)"
			>
				<circle
					id="Ellipse_3952"
					data-name="Ellipse 3952"
					cx="10"
					cy="10"
					r="10"
					transform="translate(0 -0.342)"
					fill="none"
					stroke={fill || theme.palette.primary.main}
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				/>
				<path
					id="Path_12201"
					data-name="Path 12201"
					d="M12,8,8,12l4,4"
					transform="translate(-2 -2)"
					fill="none"
					stroke={fill || theme.palette.primary.main}
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				/>
				<line
					id="Line_127"
					data-name="Line 127"
					x1="8"
					transform="translate(6 9.658)"
					fill="none"
					stroke={fill || theme.palette.primary.main}
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				/>
			</g>
		</svg>
	);
};

export default ContactArrow;

import { Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { exportCoursePartnerList } from "../../../../Services/api/CoursePartnersList";
import { getAllUsers } from "../../../../Services/api/Roles/RolesProvider";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import { getCourseInfo } from "../../../../Services/api/courses/courseProvider";
import { assignPartners, getPartners } from "../../../../Services/api/toutorials/assignToCourseProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import exit from "../../../../assets/icons/remove-red.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CoursePartnersTable from "./CoursePartnersTable";
import { filterActions } from "./CoursePartnersTableActions";
import classes from "./coursePartners.module.css";

const CoursePartners = ({ isProgram, programCourses }) => {
  const { t, i18n } = useTranslation();
  const { id, courseId } = useParams();
  const addPartnersModal = useRef(null);
  const authContext = useContext(AuthContext);

  const [showAddPartnersModal, setShowAddPartnersModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);
  const [otherPartners, setOtherPartners] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);

  const getPartnersApi = () => {
    getPartners(programCourses ? courseId : id)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setPartners(res.data.partners);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      });
  };

  useEffect(() => {
    getPartnersApi();
    getAllUsers({ roles: ["partner"] })
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setOtherPartners(res.data.users);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      });
    getCourseInfo(programCourses ? courseId : id)
      .then((res) => {
        setCourseName(res.data.course.name);
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      });
  }, []);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("sidebar.label.content_development"),
      pagePath: "#",
    },
    {
      id: `${isProgram || programCourses ? "programs" : "courses"}`,
      page: t(`sidebar.label.${isProgram || programCourses ? "program" : "courses"}`),
      pagePath: `/admin/${isProgram || programCourses ? "program/list" : "courses"}`,
    },
    ...(programCourses
      ? [
          {
            id: "courses",
            page: t("sidebar.label.courses"),
            pagePath: `/admin/program/${id}/courses`,
          },
        ]
      : []),
    {
      id: `${isProgram ? "program-name" : "course-name"}`,
      page: courseName,
      pagePath: "#",
    },
    {
      id: "course-partners",
      page: t(`course-partners.${isProgram ? "program_partners" : "course_partners"}`),
      active: true,
    },
  ];

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      //  const filter = getFilterData();
      await exportCoursePartnerList(type, programCourses ? courseId : id, isProgram);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const handleOpenAddTrainersModal = () => {
    setShowAddPartnersModal(true);
    addPartnersModal.current.showModal();
  };

  const handleCloseAddTrainersModal = () => {
    setShowAddPartnersModal(false);
    addPartnersModal.current.dismissModal();
  };

  return (
    <>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <BasicModal ref={addPartnersModal} />
      {showAddPartnersModal && (
        <MainBox className={`p-0 rounded ${classes["add-trainer-modal-container"]}`}>
          <div className={classes["add-trainer-modal"]}>
            <div className={classes["add-trainer-modal-header"]}>
              <h2 className={classes["add-trainer-modal-title"]}>
                {t(`course-partners.${isProgram ? "program_partners" : "course_partners"}`)}
              </h2>
              <img
                src={exit}
                alt="exit"
                className={classes["add-trainer-modal-exit"]}
                onClick={handleCloseAddTrainersModal}
              />
            </div>
            <div className={classes["add-trainer-modal-body"]}>
              <Formik
                initialValues={{
                  partner_ids: partners.map((partner) => {
                    return {
                      id: partner.id,
                      name: i18n.language === "ar" ? partner.name_ar : partner.name_en,
                      email: partner.email,
                    };
                  }),
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    setSubmitting(true);
                    setLoading(true);
                    let data = {
                      course_id: programCourses ? courseId : id,
                      partner_ids: values.partner_ids.map((e) => e.id),
                    };

                    let response = await assignPartners(isProgram ? "program" : "course", data);
                    if (response.status === 200 && response.data.status) {
                      toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
                      getPartnersApi();
                      setLoading(false);
                      setSubmitting(false);
                    } else {
                      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
                    }
                  } catch (err) {
                    toast.error(
                      <>
                        <span style={{ fontSize: 13, fontWeight: "bold", display: "block" }}>{err.data.msg}</span>
                        {err.data.errors && (
                          <ul>
                            {Object.keys(err.data.errors).map((key) => {
                              return (
                                <li>
                                  <span style={{ fontSize: 11 }}>{err.data.errors[key]}</span>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </>
                    );
                    setLoading(false);
                    setSubmitting(false);
                  }
                  handleCloseAddTrainersModal();
                }}
                validateOnChange={true}
                validate={(values) => {
                  const errors = {};

                  if (!isProgram && values.partner_ids.length === 0) {
                    errors.partner_ids = t("crud.errors.required");
                  }

                  return errors;
                }}
              >
                {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit} className={classes["add-trainer-modal-form"]}>
                    <div className={classes["width-100"]}>
                      <div className={classes["add-trainer-modal-form-label"]}>{t("assginCourse.label.partners")}</div>
                      <Select
                        isMulti
                        name="user"
                        defaultValue={values.partner_ids}
                        options={otherPartners}
                        getOptionLabel={(partner) => `${partner.name} (${partner.email})`}
                        getOptionValue={(partner) => partner.id}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(newPartners) => {
                          setFieldValue("partner_ids", newPartners);
                        }}
                        placeholder={t("assginCourse.placeholders.partner_placeholders")}
                      />

                      <p className={classes["form-input-error-space"]}>
                        {errors.partner_ids ? errors.partner_ids : null}
                      </p>
                    </div>
                    <div className={classes["add-trainer-modal__form-actions"]}>
                      <CustomButton
                        value={t("assginCourse.label.release")}
                        colors="#036c77"
                        type="submit"
                        loading={loading}
                        disable={loading}
                        classes={classes["add-trainer-modal__form-actions__btn"]}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </MainBox>
      )}
      <div className={classes["course-partners"]}>
        <div className="container-fluid">
          <div className={classes["course-partners__container"]}>
            <div className="row">
              <div className="col-12">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <MainBoxHead
                    title={t(`course-partners.${isProgram ? "program_partners" : "course_partners"}`)}
                    actions={filterActions({
                      addAction: () => {
                        handleOpenAddTrainersModal();
                      },
                      exportPdf: () => {
                        exportHandler("pdf");
                      },
                      exportExcel: () => {
                        exportHandler("xlsx");
                      },
                      isAdmin: authContext.inAdminPanel,
                    })}
                  />
                  <CoursePartnersTable partners={partners} loading={loading} />
                  {/* <Pagination
                    count={1}
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    className="main-pagination"
                    page={currentPage}
                    onChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                  /> */}
                </MainBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursePartners;

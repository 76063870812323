import React from "react";

const BoothDetailsAudio = ({
  label,
  name,
  value,
  filePull,
  icon,
  fileDrag,
  fileNote,
  accept,
  setFieldValue,
  error,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <label htmlFor={name} className="admin_add_label">
          {label}
        </label>

        {value?.src ? (
          <audio
            controls
            style={{
              margin: "8px 0",
              padding: "8px",
            }}
          >
            <source src={value.src} type="audio/mpeg"></source>
          </audio>
        ) : null}
      </div>

      <div style={{ position: "relative" }}>
        <div className="booth-details__file">
          <div className="add-audio">
            {value?.name ? value.name : filePull}
            <div className="add-audio-icon">{icon}</div>
          </div>
          <p style={{ display: "inline-block" }} className="msg-audio">
            {fileDrag}
            <br />
            {fileNote}
          </p>
        </div>

        <input
          id={name}
          name={name}
          type="file"
          accept={accept}
          className="input-upload-audio"
          onChange={(event) => {
            setFieldValue(name, event.target.files[0]);
          }}
        />
      </div>
      <p className={"form-input-error-space"} style={{ paddingTop: "10px " }}>
        {error ? error : null}
      </p>
    </>
  );
};

export default BoothDetailsAudio;

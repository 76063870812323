import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";
// import { getNeededDataCreateAdvertisement } from "../../../Services/api/Advertisement/AdProvider";
// import { getAllSpecialties } from "../../../Services/api/HomeCoursesProvider";
// import { fetchSystemUsersList } from "../../../Redux/Actions/systemUsers/systemUsersList";
// import { getAllCourses } from "../../../Services/api/CoursesList";

const StatisticsFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // const [types, setTypes] = useState([]);
  // const [audience_types, setAudience_types] = useState([]);
  // const [trainersList, setTrainersList] = useState([]);
  // const [courses, setCourses] = useState([]);
  // const [specialtisOptions, setSpecialtisOptions] = useState([]);
  // const { systemUsersList } = useSelector(
  //   (state) => state.systemUsersListReducer
  // );
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   getAllSpecialties()
  //     .then((res) => {
  //       if (res.status && res.status === 200 && res.data.status) {
  //         const optionsSpacialtis = res.data.data.specialties.data.map(
  //           (item) => ({
  //             label: i18n.language === "ar" ? item.name_ar : item.name_en,

  //             id: item.id,
  //             value: item.id,
  //           })
  //         );
  //         setSpecialtisOptions(optionsSpacialtis);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });

  //   dispatch(
  //     fetchSystemUsersList({
  //       page: currentPage,
  //       perPage: 10000,
  //     })
  //   );

  //   getAllCourses({
  //     paginated: 0,
  //     not_in_program: 1,

  //     perPage: 1000,
  //   }).then((res) => {
  //     if (res.status && res.status == 200 && res.data.status) {
  //       setCourses(res?.data?.data?.courses?.data || []);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   if (systemUsersList?.length) {
  //     const trainers = systemUsersList.filter((user) =>
  //       user.roles.map((role) => role.name).includes("trainer")
  //     );
  //     setTrainersList(trainers);
  //   }
  // }, [systemUsersList]);

  const filterFields = [
    // {
    //   id: "trainer",
    //   label: t("courses_list.trainer_name"),
    //   component: "selectSearch",
    //   placeholder: t("courses_list.trainer_name"),
    //   initialValue: t("courses_list.trainer_name"),
    //   name: "trainer",
    //   value: filterData.trainer,
    //   classes: "col-12 col-md-6 col-xl-4",
    //   onchange: changeHandler,
    //   options: trainersList?.length
    //     ? trainersList?.map((trainer) => ({
    //         id: trainer.id,
    //         value: trainer.id,
    //         label: trainer.name,
    //       }))
    //     : [],
    // },
    // {
    //   id: "specialty",
    //   label: t("courses_list.specialty"),
    //   component: "selectSearch",
    //   placeholder: t("courses_list.specialty"),
    //   initialValue: t("courses_list.specialty"),
    //   name: "specialty",
    //   value: filterData.specialty,
    //   classes: "col-12 col-md-6 col-xl-4",
    //   onchange: changeHandler,
    //   options: specialtisOptions || [],
    // },
    // {
    //   id: "course",
    //   label: t("courses_list.course_name"),
    //   component: "selectSearch",
    //   placeholder: t("courses_list.course_name"),
    //   initialValue: t("courses_list.course_name"),
    //   name: "course",
    //   value: filterData.course,
    //   classes: "col-12 col-md-6 col-xl-4",
    //   onchange: changeHandler,
    //   options: courses?.length
    //     ? courses?.map((course) => ({
    //         id: course.id,
    //         value: course.id,
    //         label: course.name,
    //       }))
    //     : [],
    // },

    {
      id: "start_year",
      label: t("general.search_start_date"),
      component: "datePicker",
      placeholder: t("general.search_start_date"),
      name: "start_year",
      value: filterData.start_year,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
    },
    {
      id: "end_year",
      label: t("general.search_end_date"),
      component: "datePicker",
      placeholder: t("general.search_end_date"),
      name: "end_year",
      value: filterData.end_year,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
    },

    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        filterHandler();
      },
    },
  ];
  return <FilterBox fields={filterFields} />;
};

export default StatisticsFilter;

import React, { useEffect, useRef } from "react";
import classes from "./NewHomeExploreCard.module.css";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";
import i18n from "../../../../../i18n/i18n";

export default function NewHomeExploreCard({
  cardContant,
  isThree,
  index,
  totalCards,
}) {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    // console.log(isInView);
    if (isInView) {
      mainControls.start("visible");
      // setisvisible(true);
    }
  }, [isInView]);

  return (
    <div
      ref={ref}
      className={`${classes.mainCard} ${
        !isThree ? classes.mainCardMargin : ""
      }`}
    >
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{
          duration: 0.6,
          delay:
            i18n.language === "ar"
              ? 0.4 * ((totalCards - index - 1) % 3) +
                0.4 * ((totalCards - index - 1) % 3)
              : 0.4 * (index % 3) + 0.4 * (index % 3),
          ease: "easeIn",
        }}
        className={classes.subCard}
      >
        <img src={cardContant.icon_one} alt="" />
        <h2>{cardContant.text_one}</h2>
      </motion.div>
      {cardContant.icon_two && (
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{
            duration: 0.6,
            delay:
              i18n.language === "ar"
                ? 0.4 * ((totalCards - index - 1) % 3) +
                  0.4 * ((totalCards - index - 1) % 3) +
                  0.4
                : 0.4 * (index % 3) + 0.4 * (index % 3) + 0.4,
            ease: "easeIn",
          }}
          className={classes.subCard}
        >
          <img src={cardContant.icon_two} alt="" />
          <h2>{cardContant.text_two}</h2>
        </motion.div>
      )}
    </div>
  );
}

import React, {Component} from "react";
import banana from "../../../../assets/image/banana-.png";
import cabbag from "../../../../assets/image/cabbag.png";
import strawberry from "../../../../assets/image/strawberry.png";
import orange from "../../../../assets/image/orange.png";
import papper from "../../../../assets/image/papper.png";
import peas from "../../../../assets/image/peas.png";

export default class Drag extends Component {
  state = {
    tasks: [
      { id: "1", category: "wip", img: peas },
      { id: "2", category: "wip", img: banana },
      { id: "3", category: "wip", img: cabbag },
      { id: "4", category: "wip", img: strawberry },
      { id: "5", category: "wip", img: orange },
      { id: "6", category: "wip", img: papper },
    ],
  };

  onDragStart = (ev, id) => {
    ev.dataTransfer.setData("id", id);
  };

  onDragOver = (ev) => {
    ev.preventDefault();
  };

  onDrop = (ev, cat) => {
    let id = ev.dataTransfer.getData("id");

    let tasks = this.state.tasks.filter((task) => {
      if (task.id == id) {
        task.category = cat;
      }
      return task;
    });

    this.setState({
      ...this.state,
      tasks,
    });
  };

  render() {
    var tasks = {
      wip: [],
      complete: [],
      complete1: [],
    };

    this.state.tasks.forEach((t) => {
      tasks[t.category].push(
        <div
          key={t.id}
          onDragStart={(e) => this.onDragStart(e, t.id)}
          draggable
          className="move_image_question"
        >
          <div className={`move_img_question_${t.id}`}>
            <img src={t.img} alt="" />
          </div>
        </div>
      );
    });

    return (
      <div className="">
        <label htmlFor="" className="questios_input_label">
          انقل العناصر التالية الي المجموعة الخاصة بها؟
        </label>
        <div className="move_question">
          <div className="questios_input_label_small">
            قم بسحب العنصر إلي المجموعة الخاصة بها
          </div>
          <div
            className="move_img_question"
            onDragOver={(e) => this.onDragOver(e)}
            onDrop={(e) => {
              this.onDrop(e, "wip");
            }}
          >
            {tasks.wip}
          </div>
        </div>

        <div className="answers_move_question">
          <div
            className="answer_move_question"
            onDragOver={(e) => this.onDragOver(e)}
            onDrop={(e) => this.onDrop(e, "complete1")}
          >
            <div className="">الفاكهة</div>
            <div className="box_answer">{tasks.complete1}</div>
          </div>
          <div
            className="answer_move_question"
            onDragOver={(e) => this.onDragOver(e)}
            onDrop={(e) => this.onDrop(e, "complete")}
          >
            <div className="">الخضروات</div>
            <div className="box_answer">{tasks.complete}</div>
          </div>
        </div>
      </div>
    );
  }
}

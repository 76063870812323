import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import SearchInput from "./SearchInput";
import classes from "./search.module.css";
const SearchTabs = ({ activeTab, tabs, size, hasInput, onChange, onInputChange, inputType, disabled, inputVale }) => {
  const checkChecked = (tab) => {
    if (tab?.is_array && Array.isArray(activeTab)) return activeTab?.join() === tab?.value;
    return activeTab === tab?.value;
  };

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        {tabs?.map((tab) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              padding: "0 !important",
            }}
          >
            <FormControlLabel
              key={tab?.value}
              value={tab?.text}
              control={
                <Radio
                  size={size || "medium"}
                  checked={checkChecked(tab)}
                  onClick={() => {
                    if (tab?.differenceFilter) return onChange(tab?.value, tab?.filter, tab?.is_array);
                    onChange(tab?.value);
                  }}
                />
              }
              label={tab?.text}
              sx={{
                margin: "0",
                color: "#777777",
                "& .MuiFormControlLabel-label": {
                  fontFamily: "inherit",
                },
              }}
              disabled={disabled}
            />
            {hasInput && tab.value != "not-specified" && (
              <Box
                className={classes["search__box__item__tabs-box"]}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  position: "relative",
                  padding: "0 !important",
                }}
              >
                <SearchInput
                  width={"100px"}
                  onChange={(value) => {
                    onInputChange(value, tab?.value);
                  }}
                  type={inputType}
                  disabled={disabled}
                  value={tab?.value === activeTab ? inputVale : ""}
                />
                {tab?.is_percentage && <span className={classes["search__box__item__tabs-percent"]}>%</span>}
              </Box>
            )}
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default SearchTabs;

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { TrainerAddExamContext } from "../../../../../Providers/TrainerAddExamProvider";

import toLocalTimezone, {
  toServerTime,
} from "../../../../../Services/Timezone";

function ExamBasicInfo(props) {
  require("./BasicInfo.css");
  const { t } = useTranslation();
  const trainerAddExamContext = useContext(TrainerAddExamContext);
  const [showMarkConditions, setShowMarkConditions] = useState([
    { id: 1, name_en: "After Specific Date", name_ar: "بعد تاريخ محدد" },
    { id: 2, name_en: "After Course End", name_ar: "بعد إنتهاء المساق" },
  ]);

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    var now = new Date(t);
    var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      seconds = "" + t.getSeconds();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return [hours, minutes, seconds].join(":");
  };

  const { id, quizable_id, exam_id } = useParams();

  return (
    <>
      <Formik
        initialValues={{
          start_date: trainerAddExamContext.examPayload.start_date
            ? trainerAddExamContext.examPayload.start_date.split(" ")[0]
            : "",
          start_time: trainerAddExamContext.examPayload.start_date
            ? trainerAddExamContext.examPayload.start_date.split(" ")[1]
            : "",
          end_date: trainerAddExamContext.examPayload.end_date
            ? trainerAddExamContext.examPayload.end_date.split(" ")[0]
            : "",
          end_time: trainerAddExamContext.examPayload.end_date
            ? trainerAddExamContext.examPayload.end_date.split(" ")[1]
            : "",
          period: trainerAddExamContext.examPayload.period
            ? trainerAddExamContext.examPayload.period
            : "",
          answer_attempts: trainerAddExamContext.examPayload.answer_attempts
            ? trainerAddExamContext.examPayload.answer_attempts
            : "",
          title: trainerAddExamContext.examPayload.title
            ? trainerAddExamContext.examPayload.title
            : "",
          success_rate: trainerAddExamContext.examPayload.title
            ? trainerAddExamContext.examPayload.success_rate
            : "",
          show_mark:
            trainerAddExamContext.examPayload.show_mark &&
            trainerAddExamContext.examPayload.show_mark == 1
              ? true
              : false,
          show_mark_condition: trainerAddExamContext.examPayload
            .show_mark_condition
            ? trainerAddExamContext.examPayload.show_mark_condition
            : "",
          show_mark_condition_date: trainerAddExamContext.examPayload
            .show_mark_condition_date
            ? trainerAddExamContext.examPayload.show_mark_condition_date
            : "",
          description: trainerAddExamContext.examPayload.description
            ? trainerAddExamContext.examPayload.description
            : "",
          random: trainerAddExamContext.examPayload.random == 1 ? true : false,
          can_back: trainerAddExamContext.examPayload.can_back == 1 ? true : false,
          course_objectives: trainerAddExamContext.examPayload.course_objectives
            ? trainerAddExamContext.examPayload.course_objectives
            : "",
          trainees: trainerAddExamContext.examPayload.trainees
            ? trainerAddExamContext.examPayload.trainees
            : "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          // if(values.start_time.toString().includes("\.")) {
          //     let indexOfDot_start_time = values.start_time.indexOf(".");
          //     values.start_time = values.start_time.substring(0, indexOfDot_start_time)
          // }
          // if(values.end_time.toString().includes("\.")) {
          //     let indexOfDot_end_time = values.end_time.indexOf(".");
          //     values.end_time = values.end_time.substring(0, indexOfDot_end_time)
          // }
          const examPayload = trainerAddExamContext.examPayload;
          examPayload.start_date = toServerTime(
            values.start_date + " " + values.start_time
          );
          examPayload.end_date = toServerTime(
            values.end_date + " " + values.end_time
          );
          examPayload.period = values.period;
          examPayload.title = values.title;
          examPayload.description = values.description;
          examPayload.random = values.random ? 1 : 0;
          examPayload.can_back = values.can_back ? 1 : 0;
          examPayload.course_objectives = values.course_objectives;
          examPayload.trainees = values.trainees;
          examPayload.quizable_type = trainerAddExamContext.quizable;
          examPayload.quizable_id = quizable_id;
          examPayload.show_mark = values.show_mark ? 1 : 0;
          examPayload.show_mark_condition = values.show_mark_condition;
          examPayload.show_mark_condition_date =
            values.show_mark_condition_date;
          examPayload.answer_attempts = values.answer_attempts;
          examPayload.success_rate = values.success_rate;
          trainerAddExamContext.setExamPayload(examPayload);
          trainerAddExamContext.setSelectedTrainees(values.trainees);
          trainerAddExamContext.setActivePanel(1);
        }}
        validateOnChange={false}
        validate={(values) => {
          const errors = {};
          let checkDates = true;

          if (!values.start_date) {
            checkDates = false;
            errors.start_date = t("crud.errors.required");
          }

          if (!values.start_time) {
            checkDates = false;
            errors.start_time = t("crud.errors.required");
          }

          if (!values.end_date) {
            checkDates = false;
            errors.end_date = t("crud.errors.required");
          }

          if (!values.end_time) {
            checkDates = false;
            errors.end_time = t("crud.errors.required");
          }

          if (!values.period) {
            errors.period = t("crud.errors.required");
          }

          if (!values.success_rate) {
            errors.success_rate = t("crud.errors.required");
          } else if (
            parseFloat(values.success_rate) < 0 ||
            parseFloat(values.success_rate) > 100
          ) {
            errors.success_rate = t("crud.errors.percentage");
          }

          if (!values.answer_attempts) {
            errors.answer_attempts = t("crud.errors.required");
          }

          if (!values.title) {
            errors.title = t("crud.errors.required");
          }

          if (!values.description) {
            errors.description = t("crud.errors.required");
          }

          if (
            values.show_mark_condition &&
            values.show_mark_condition == 1 &&
            !values.show_mark_condition_date
          ) {
            errors.show_mark_condition_date = t("crud.errors.required");
          }

          if (
            !Array.isArray(values.course_objectives) ||
            values.course_objectives.length == 0
          ) {
            errors.course_objectives = t("crud.errors.required");
          }

          if (
            checkDates &&
            new Date(values.start_date + " " + values.start_time) >=
              new Date(values.end_date + " " + values.end_time)
          ) {
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("crud.errors.start_date_is_smaller_than_or_equal_end_date")}
              </span>
            );

            errors.end_date = t(
              "crud.errors.start_date_is_smaller_than_or_equal_end_date"
            );
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setErrors,
          setSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="admin_add_form">
            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2 "} style={{ flex: 2 }}>
                <div className={"tajah-form-row-element-holder"}>
                  <label htmlFor="name_en" className="admin_add_label">
                    {t("trainer.quiz.crud.title")}
                  </label>
                  <input
                    value={values.title}
                    onChange={(elem) =>
                      setFieldValue("title", elem.target.value)
                    }
                    placeholder={t("trainer.quiz.crud.input.title")}
                    className="admin_filter_input"
                  />
                  <p className={"form-input-error-space"}>
                    {errors.title ? errors.title : null}
                  </p>
                </div>
              </div>

              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.random}
                          onChange={(elem) => {
                            setFieldValue("random", elem.target.checked);
                          }}
                        />
                      }
                      label={t("trainer.quiz.crud.random")}
                    />
                  </FormGroup>
                  <p className={"form-input-error-space"}>
                    {errors.random ? errors.random : null}
                  </p>
                </div>
              </div>

              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.can_back}
                          onChange={(elem) => {
                            setFieldValue("can_back", elem.target.checked);
                          }}
                        />
                      }
                      label={t("trainer.quiz.crud.can_back")}
                    />
                  </FormGroup>
                  <p className={"form-input-error-space"}>
                    {errors.can_back ? errors.can_back : null}
                  </p>
                </div>
              </div>
              
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.show_mark}
                          onChange={(elem) => {
                            let selectedErrors = errors;
                            delete selectedErrors.show_mark_condition_date;
                            setErrors(selectedErrors);
                            if (!elem.target.checked) {
                              setFieldValue("show_mark_condition", "");
                              setFieldValue("show_mark_condition_date", "");
                            }
                            setFieldValue("show_mark", elem.target.checked);
                          }}
                        />
                      }
                      label={t("trainer.quiz.crud.show_mark")}
                    />
                  </FormGroup>
                  <p className={"form-input-error-space"}>
                    {errors.show_mark ? errors.show_mark : null}
                  </p>
                </div>
              </div>
            </div>
            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <label htmlFor="name_en" className="admin_add_label">
                    {t("trainer.quiz.crud.show_mark_condition")}
                  </label>
                  <Select
                    name="show_mark_condition"
                    id="show_mark_condition"
                    isClearable={true}
                    value={showMarkConditions.filter((item) => {
                      if (values.show_mark_condition == item.id) {
                        return true;
                      } else {
                        return false;
                      }
                    })}
                    isDisabled={!values.show_mark}
                    options={showMarkConditions}
                    getOptionLabel={(option) =>
                      document.body.dir == "rtl"
                        ? option.name_ar
                        : option.name_en
                    }
                    getOptionValue={(option) => option.id}
                    onChange={(item) => {
                      if (item == null) {
                        setFieldValue("show_mark_condition", null);
                        setFieldValue("show_mark_condition_date", "");
                      } else {
                        setFieldValue("show_mark_condition", item.id);
                        if (item.id != 1) {
                          setFieldValue("show_mark_condition_date", "");
                        }
                      }
                    }}
                    placeholder={t(
                      "trainer.quiz.crud.select.show_mark_condition"
                    )}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.show_mark_condition
                      ? errors.show_mark_condition
                      : null}
                  </p>
                </div>
              </div>

              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <div className={"tajah_filter_item_label_actionable"}>
                    <div>
                      <label>
                        {t("trainer.quiz.crud.show_mark_condition_date")}
                      </label>
                    </div>
                    <div className={"tajah_filter_item_label_action"}>
                      {values.show_mark_condition_date ? (
                        <div
                          className={"tajah_filter_item_label_action_clear"}
                          onClick={() => {
                            setFieldValue("show_mark_condition_date", "");
                          }}
                        >
                          <i
                            className="fas fa-times"
                            style={{ color: "white", fontSize: 10 }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <DatePicker
                    disabled={values.show_mark_condition != 1}
                    value={values.show_mark_condition_date}
                    onChange={(date) => {
                      setFieldValue(
                        "show_mark_condition_date",
                        formatDate(new Date(date))
                      );
                    }}
                    placeholderText={t(
                      "trainer.quiz.crud.select.show_mark_condition_date"
                    )}
                    className="admin_filter_input"
                    dateFormat={"yyyy-MM-dd"}
                    minDate={new Date()}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.show_mark_condition_date
                      ? errors.show_mark_condition_date
                      : null}
                  </p>
                </div>
              </div>
            </div>
            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label htmlFor="name_en" className="admin_add_label">
                    {t("trainer.quiz.crud.description")}
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    rows="7"
                    className="tajah-bordered-text-area"
                    onChange={(elem) => {
                      setFieldValue("description", elem.target.value);
                    }}
                    placeholder={t("trainer.quiz.crud.input.description")}
                    value={values.description}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.description ? errors.description : null}
                  </p>
                </div>
              </div>
            </div>
            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label htmlFor="name_en" className="admin_add_label">
                    {t("trainer.quiz.crud.objectives")}
                  </label>
                  <Select
                    name="course_objectives"
                    id="course_objectives"
                    isClearable={true}
                    isMulti={true}
                    defaultValue={trainerAddExamContext.objectives.filter(
                      (item) => {
                        if (values.course_objectives.includes(item.id)) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    )}
                    options={trainerAddExamContext.objectives}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(items) => {
                      const courseObjectives = [];
                      items.forEach((item) => {
                        courseObjectives.push(item.id);
                      });
                      setFieldValue("course_objectives", courseObjectives);
                    }}
                    placeholder={t("trainer.quiz.crud.select.objectives")}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.course_objectives ? errors.course_objectives : null}
                  </p>
                </div>
              </div>
            </div>
            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label htmlFor="name_en" className="admin_add_label">
                    {t("trainer.quiz.crud.trainees")}
                  </label>
                  <Select
                    name="trainees"
                    id="trainees"
                    isClearable={true}
                    isMulti={true}
                    defaultValue={trainerAddExamContext.trainees.filter(
                      (item) => {
                        if (values.trainees.includes(item.id)) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                    )}
                    options={trainerAddExamContext.trainees}
                    getOptionLabel={(option) =>
                      document.body.dir == "rtl"
                        ? option.name_ar
                        : option.name_en
                    }
                    getOptionValue={(option) => option.id}
                    onChange={(items) => {
                      const trainees = [];
                      items.forEach((item) => {
                        trainees.push(item.id);
                      });
                      setFieldValue("trainees", trainees);
                    }}
                    placeholder={t("trainer.quiz.crud.select.trainees")}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.trainees ? errors.trainees : null}
                  </p>
                </div>
              </div>
            </div>
            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label htmlFor="name_en" className="admin_add_label">
                    {t("trainer.quiz.crud.success_rate")}
                  </label>
                  <input
                    value={values.success_rate}
                    type="number"
                    min={0}
                    max={100}
                    onChange={(elem) =>
                      setFieldValue("success_rate", elem.target.value)
                    }
                    placeholder={t("trainer.quiz.crud.input.success_rate")}
                    className="admin_filter_input"
                  />
                  <p className={"form-input-error-space"}>
                    {errors.success_rate ? errors.success_rate : null}
                  </p>
                </div>
              </div>
            </div>
            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <label className="admin_add_label">
                    {t("trainer.quiz.crud.period")}
                  </label>
                  <input
                    value={values.period}
                    type="number"
                    min={0}
                    onChange={(elem) =>
                      setFieldValue("period", elem.target.value)
                    }
                    placeholder={t("trainer.quiz.crud.input.period")}
                    className="admin_filter_input"
                  />
                  <p className={"form-input-error-space"}>
                    {errors.period ? errors.period : null}
                  </p>
                </div>
              </div>

              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <label className="admin_add_label">
                    {t("trainer.quiz.crud.answer_attempts")}
                  </label>
                  <input
                    value={values.answer_attempts}
                    type="number"
                    min={0}
                    onChange={(elem) =>
                      setFieldValue("answer_attempts", elem.target.value)
                    }
                    placeholder={t("trainer.quiz.crud.input.answer_attempts")}
                    className="admin_filter_input"
                  />
                  <p className={"form-input-error-space"}>
                    {errors.answer_attempts ? errors.answer_attempts : null}
                  </p>
                </div>
              </div>
            </div>
            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <div className={"tajah_filter_item_label_actionable"}>
                    <div>
                      <label>{t("crud.placeholders.select.start_date")}</label>
                    </div>
                    <div className={"tajah_filter_item_label_action"}>
                      {values.start_date ? (
                        <div
                          className={"tajah_filter_item_label_action_clear"}
                          onClick={() => {
                            setFieldValue("start_date", "");
                          }}
                        >
                          <i
                            className="fas fa-times"
                            style={{ color: "white", fontSize: 10 }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <DatePicker
                    value={values.start_date}
                    onChange={(date) => {
                      setFieldValue("start_date", formatDate(new Date(date)));
                    }}
                    placeholderText={t("crud.placeholders.select.start_date")}
                    className="admin_filter_input"
                    dateFormat={"yyyy-MM-dd"}
                    minDate={new Date()}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.start_date ? errors.start_date : null}
                  </p>
                </div>
              </div>

              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <div className={"tajah_filter_item_label_actionable"}>
                    <div>
                      <label>{t("crud.placeholders.select.start_time")}</label>
                    </div>
                    <div className={"tajah_filter_item_label_action"}>
                      {values.start_time ? (
                        <div
                          className={"tajah_filter_item_label_action_clear"}
                          onClick={(date) => {
                            setFieldValue("start_time", "");
                          }}
                        >
                          <i
                            className="fas fa-times"
                            style={{ color: "white", fontSize: 10 }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <DatePicker
                    value={values.start_time}
                    onChange={(date) => {
                      setFieldValue("start_time", formatTime(new Date(date)));
                    }}
                    placeholderText={t("crud.placeholders.select.start_time")}
                    className="admin_filter_input"
                    showTimeSelect
                    showTimeSelectOnly
                    showTime={{ use12Hours: true }}
                    dateFormat={"HH:mm:ss"}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.start_time ? errors.start_time : null}
                  </p>
                </div>
              </div>
            </div>

            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <div className={"tajah_filter_item_label_actionable"}>
                    <div>
                      <label>{t("crud.placeholders.select.end_date")}</label>
                    </div>
                    <div className={"tajah_filter_item_label_action"}>
                      {values.end_date ? (
                        <div
                          className={"tajah_filter_item_label_action_clear"}
                          onClick={() => {
                            setFieldValue("end_date", "");
                          }}
                        >
                          <i
                            className="fas fa-times"
                            style={{ color: "white", fontSize: 10 }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <DatePicker
                    value={values.end_date}
                    onChange={(date) => {
                      setFieldValue("end_date", formatDate(new Date(date)));
                    }}
                    placeholderText={t("crud.placeholders.select.end_date")}
                    className="admin_filter_input"
                    dateFormat={"yyyy-MM-dd"}
                    minDate={new Date(values.start_date)}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.end_date ? errors.end_date : null}
                  </p>
                </div>
              </div>

              <div className={"tajah-form-row-element-v2"}>
                <div className={"tajah-form-row-element-holder"}>
                  <div className={"tajah_filter_item_label_actionable"}>
                    <div>
                      <label>{t("crud.placeholders.select.end_time")}</label>
                    </div>
                    <div className={"tajah_filter_item_label_action"}>
                      {values.end_time ? (
                        <div
                          className={"tajah_filter_item_label_action_clear"}
                          onClick={(date) => {
                            setFieldValue("end_time", "");
                          }}
                        >
                          <i
                            className="fas fa-times"
                            style={{ color: "white", fontSize: 10 }}
                          ></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <DatePicker
                    value={values.end_time}
                    onChange={(date) =>
                      setFieldValue("end_time", formatTime(new Date(date)))
                    }
                    placeholderText={t("crud.placeholders.select.end_date")}
                    className="admin_filter_input"
                    showTimeSelect
                    showTimeSelectOnly
                    showTime={{ use12Hours: true }}
                    dateFormat={"HH:mm:ss"}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.end_time ? errors.end_time : null}
                  </p>
                </div>
              </div>
            </div>

            <div className={"tajah-form-row add-exams__row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{
                    width: "95%",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <button
                    type="submit"
                    className="admin_add_button"
                    disabled={isSubmitting}
                  >
                    {t("crud.placeholders.next")}
                  </button>
                  {/*{*/}
                  {/*    trainerAddExamContext.editable ?*/}
                  {/*        <button*/}
                  {/*            type="button"*/}
                  {/*            className="admin_add_button"*/}
                  {/*            disabled={isSubmitting}*/}
                  {/*            style={{*/}
                  {/*                marginRight: document.body.dir == 'rtl' ? 10 : 0,*/}
                  {/*                marginLeft: document.body.dir == 'rtl' ? 0 : 10,*/}
                  {/*            }}*/}
                  {/*            onClick={() => {*/}
                  {/*                setSubmitting(true);*/}
                  {/*                trainerAddExamContext.submitExamEdits(null, exam_id).then(res => {*/}
                  {/*                    toast.success(*/}
                  {/*                        <span style={{ fontSize: 13, fontWeight: "bold" }}>*/}
                  {/*                            {t('trainer.quiz.edited_successfully')}*/}
                  {/*                        </span>*/}
                  {/*                    )*/}
                  {/*                    setSubmitting(false);*/}
                  {/*                }).catch(err => {*/}
                  {/*                    toast.error(*/}
                  {/*                        <span style={{ fontSize: 13, fontWeight: "bold" }}>*/}
                  {/*                            {t('trainer.quiz.failed_editing')}*/}
                  {/*                        </span>*/}
                  {/*                    )*/}
                  {/*                    setSubmitting(false);*/}
                  {/*                })*/}
                  {/*            }}*/}
                  {/*        >*/}
                  {/*            {t("crud.placeholders.save")}*/}
                  {/*        </button> : null*/}
                  {/*}*/}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ExamBasicInfo;

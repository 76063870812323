import { ReactComponent as ShareContentIcon } from "../../../../../assets/icons/ShareContent.svg";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import toLocalTimezone from "../../../../../Services/Timezone";
import LessonItem from "./LessonItem";

export default function LessonDiscussionItem({
  courseId,
  chapterId,
  unitId,
  lessonId,
  discussion,
  isCourseClosed,
  onOpen,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const params = {
    icon: <ShareContentIcon className="tw-h-5" />,
    name: t("trainer.course.lesson.discussions"),
    title: discussion.name,
    ...(discussion.time_status === "Not Started" || discussion.is_on_team === false
      ? {}
      : {
          buttons: [
            {
              label:
                discussion.time_status === "Finished" ||
                (discussion.is_disabled && discussion.time_status === "Started")
                  ? t("see")
                  : t("participate"),
              onClick: () => {
                onOpen();
                history.push(`/course/${courseId}/activity/${discussion.id}`);
              },
            },
          ],
        }),

    footer: {
      flags: {
        include_view: discussion.is_on_team !== false && discussion.include_view_per,
        include_achivement: discussion.is_on_team !== false && discussion.include_achivement_per,
        is_watched: discussion.is_watched,
        is_achieved: discussion.is_achieved,
        time_status: discussion.time_status,
      },
      end: () => (
        <p>
          {discussion.is_disabled && discussion.is_open
            ? t("offline")
            : discussion.is_on_team === false
            ? `${t("space_to")} ${discussion?.team?.name}`
            : discussion.time_status === "Not Started"
            ? t("Not_started")
            : discussion.time_status === "Finished"
            ? t("finished")
            : ""}
        </p>
      ),
      columns: [
        {
          label: t("avialible_from"),
          value: moment(toLocalTimezone(discussion.start_time)).format("YYYY-MM-DD hh:mm A"),
          rows: [
            {
              label: t("To"),
              value: moment(toLocalTimezone(discussion.end_time)).format("YYYY-MM-DD hh:mm A"),
            },
          ],
        },
      ],
    },
  };
  return <LessonItem {...params} />;
}

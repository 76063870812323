import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { decrypt } from "../../../../Helpers/Secret";
import {
  getCertificateTemplate,
  updateCertificateTemplate,
} from "../../../../Services/api/certificate/certificateTemplateProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import CertificateTemplatesForm from "../CertificateTemplatesForm";

export default function EditCertificateTemplates() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const roles = JSON.parse(decrypt(localStorage.getItem("roles")) || "[]");
  const role = location?.state?.role
    ? location?.state?.role
    : roles.includes("admin")
    ? "admin"
    : roles.includes("manager")
    ? "manager"
    : "trainer";

  const [certificateDetails, setCertificateDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCertificateTemplateHandler = async () => {
    setLoading(true);
    getCertificateTemplate(id)
      .then((response) => {
        if (response.status && response.status == 200 && response.data.status) {
          setCertificateDetails(response.data.template);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return history.push(`${role === "admin" ? "/admin" : ""}/page-not-found`);
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {error?.response?.data?.msg ? error?.response?.data?.msg : "Failure in service"}
          </span>
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!id) return;
    getCertificateTemplateHandler();
  }, [id]);

  const submitHandler = async (values) => {
    let response = await updateCertificateTemplate(values, id);
    if (response?.status === 200 && response?.data.status) {
      history.push({
        pathname: `${role === "admin" ? "/admin" : ""}/certificateTemplates`,
        state: { role: role },
      });
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg ? response.data.msg : t("certificate.messages.update_successfully")}
        </span>,
        {
          onClose: () => {},
          autoClose: 1000,
        }
      );
    } else {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("certificate.messages.failed_update")}</span>);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <CertificateTemplatesForm type="edit" submitHandler={submitHandler} certificateData={certificateDetails} />
      )}
    </>
  );
}

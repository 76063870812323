import { Formik } from "formik";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { paymentAction } from "../../Services/api/toutorials/PaymentsProvaider";
import exit from "../../assets/icons/remove-red.svg";
import ApproveIcon from "../../assets/image/user-check.svg";
import RejectIcon from "../../assets/image/user-x.svg";
import { utcToLocal } from "../../utils/utcToLocal";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";
import MainBox from "../Shared/Components/MainBox/MainBox";
import MainTable from "../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../Shared/Components/Modal/BasicModal";
import SharedModal from "../Shared/Components/SharedModal/SharedModal";
import TableActionsColumn from "../Shared/Components/TableActionsColumn/TableActionsColumn";
import Tabs from "../Shared/Components/Tabs/Tabs";
import BankModal from "./BankModal";
import classes from "./payments.module.css";
import { downloadInvoice } from "../../Services/api/program/programContext";

const PaymentsTable = ({
  resetFilterHandler,
  payments,
  getPayments,
  role,
  type,
  loading,
  activeTab,
  setActiveTab,
  setIsExportLoading,
}) => {
  const { t, i18n } = useTranslation();
  const approvePaymentModalRef = useRef(null);
  const rejectPaymentModalRef = useRef(null);
  const bankModalRef = useRef(null);
  const [showApprovePaymentModal, setShowApprovePaymentModal] = useState(false);
  const [showRejectPaymentModal, setShowRejectPaymentModal] = useState(false);
  const [paymentActionData, setPaymentActionData] = useState(null);
  const [showBankModal, setShowBankModal] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  const paymentsActionsList = [
    {
      id: "approve",
      icon: <img src={ApproveIcon} alt="" />,
      action: (id) => {
        openApprovePaymentModal(id);
      },
      tooltip: t("general.approve"),
    },
    {
      id: "reject",
      icon: <img src={RejectIcon} alt="" />,
      action: (id) => {
        openRejectPaymentModal(id);
      },
      tooltip: t("general.reject"),
    },
  ];
  const downloadFile = async (id) => {
    setIsExportLoading(true);
    await downloadInvoice(id);
    setIsExportLoading(false);
  };

  const openApprovePaymentModal = (id) => {
    setShowApprovePaymentModal(true);
    setPaymentActionData({ id: id, status: "Approved" });
    approvePaymentModalRef.current.showModal();
  };

  const closeApprovePaymentModal = () => {
    approvePaymentModalRef.current.dismissModal();
    setShowApprovePaymentModal(false);
    setPaymentActionData(null);
  };

  const openRejectPaymentModal = (id) => {
    setShowRejectPaymentModal(true);
    setPaymentActionData({ id: id, status: "Rejected" });
    rejectPaymentModalRef.current.showModal();
  };

  const closeRejectPaymentModal = () => {
    rejectPaymentModalRef.current.dismissModal();
    setShowRejectPaymentModal(false);
    setPaymentActionData(null);
  };

  const approvePaymentHandler = () => {
    paymentAction({
      id: paymentActionData.id,
      status: paymentActionData.status,
    })
      .then((res) => {
        toast.success(res.data.msg);
        getPayments();
      })
      .catch((err) => {
        toast.error(t("general.error"));
      })
      .finally(() => {
        closeApprovePaymentModal();
      });
  };

  const paymentDisplay = {
    Bank: t("general.bank"),
    Wallet: t("general.wallet"),
    Tebx: t("tebX"),
    cash: t("wallet.transaction.cash"),
    iban: t("wallet.transaction.iban"),
  };

  const displayStatus = {
    Waiting: <span className={classes["waiting"]}>{t("general.waiting")}</span>,
    Approved: (
      <span className={classes["approved"]}>{t("general.approved")}</span>
    ),
    Rejected: (
      <span className={classes["rejected"]}>{t("general.rejected")}</span>
    ),
    Canceled: (
      <span className={classes["canceled"]}>{t("general.canceled")}</span>
    ),
  };

  const openBankModalHandler = (payment) => {
    setShowBankModal(true);
    bankModalRef.current.showModal();
    setPaymentData(payment);
  };

  const closeBankModalHandler = () => {
    setShowBankModal(false);
    bankModalRef.current.dismissModal();
    setPaymentData(null);
  };
  const displayGift = (is_gift) => {
    if (is_gift) {
      return t("yes");
    }
    return t("no");
  };

  const tabs = [
    {
      id: "waiting_requests",
      key: 1,
      label: t("financial.payments_request.waiting_req"),
    },

    {
      id: "requests_approved",
      key: 2,
      label: t("financial.payments_request.approved_req"),
    },
    {
      id: "requests_rejected",
      key: 3,
      label: t("financial.payments_request.rejected_req"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "photo",
      name: t("general.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["departments-trainees__table-photo"]}>
          {rowData?.user?.img ? (
            <img src={rowData?.user?.img} alt="" />
          ) : (
            <i className="fa fa-user" aria-hidden="true"></i>
          )}
        </div>
      ),
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "name",
      name: t("user_name"),
      key: "name",
      cell: ({ rowData }) => (
        <>
          {i18n.language === "ar"
            ? rowData?.user?.name_ar
            : rowData?.user?.name_en || "-"}
        </>
      ),
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => (
        <a
          className={classes["departments-trainees__table-email"]}
          href={`mailto: ${rowData?.user?.email}`}
        >
          {rowData?.user?.email}
        </a>
      ),
    },
    {
      id: "id_number",
      name: t("system_users.id_number"),
      key: "id_number",
      cell: ({ rowData }) => <>{rowData?.user?.id_number || "-"}</>,
    },
    {
      id: "roles",
      name: t("roles"),
      key: "roles",
      cell: ({ rowData }) => (
        <>
          {rowData?.user?.roles?.map((role) => role.label).join(" | ") || "-"}
        </>
      ),
    },
    ...(type === "financial"
      ? [
          {
            id: "transaction_type",
            name: t("wallet.label.WDtransactiontype"),
            key: "transaction_type",
            cell: ({ rowData }) => (
              <div>{rowData?.transaction_type || "-"}</div>
            ),
            sortable: false,
          },
          {
            id: "payment_type",
            name: t("payments.payable_type"),
            key: "payment_type",
            cell: ({ rowData }) => <>{rowData?.payable_target || "-"}</>,
          },
          {
            id: "processed_by",
            name: t("payments.processed_by"),
            key: "processed_by",
            cell: ({ rowData }) => (
              <>
                {Object.keys(rowData?.wallet_transaction).length > 0
                  ? rowData?.wallet_transaction?.actor[`name_${i18n.language}`]
                  : "-"}
              </>
            ),
          },
        ]
      : []),
    {
      id: "payment_method",
      name: t("payments.payment_method"),
      key: "payment_method",
      cell: ({ rowData }) => (
        <div className={classes["payment-method"]}>
          {rowData.payment_method === "Bank" &&
          Object.keys(rowData.wallet_transaction).length === 0 ? (
            <button
              className={classes["payment-method--bank"]}
              onClick={() => {
                openBankModalHandler(rowData);
              }}
            >
              {t("general.bank")}
            </button>
          ) : (
            <p
              style={{
                margin: 0,
              }}
            >
              {Object.keys(rowData.wallet_transaction).length > 0
                ? paymentDisplay[rowData.wallet_transaction.transfer_type] ||
                  "-"
                : paymentDisplay[rowData.payment_method] || "-"}
            </p>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => <>{displayStatus[rowData?.status] || "-"}</>,
    },
    {
      id: "payable_name",
      name: t("payments.payable_name"),
      key: "payable_name",
      cell: ({ rowData }) => (
        <>
          {rowData?.coupon_courses ? (
            Array.isArray(rowData?.coupon_courses) ? (
              <p>
                {rowData?.coupon_courses
                  ?.map((course) => course.name)
                  .join(" | ")}
              </p>
            ) : (
              <p>{rowData?.coupon_courses?.name || "-"}</p>
            )
          ) : null}
          <p>
            {rowData?.payable_name?.includes("-")
              ? rowData?.payable_name.split("-").map((item, indx) =>
                  indx === 0 ? (
                    <>
                      {item} <br />
                    </>
                  ) : (
                    item
                  )
                )
              : rowData?.payable_name || "-"}
          </p>
        </>
      ),
    },
    {
      id: "payment_courses",
      name: t("payments.payment_courses"),
      key: "payment_courses",
      cell: ({ rowData }) =>
        rowData?.covered_courses?.length > 0 ? (
          <p>
            {rowData?.covered_courses?.map((course) => course.name).join(" | ")}
          </p>
        ) : rowData?.coupon_courses ? (
          Array.isArray(rowData?.coupon_courses) ? (
            <p>
              {rowData?.coupon_courses
                ?.map((course) => course.name)
                .join(" | ")}
            </p>
          ) : (
            rowData?.coupon_courses?.name || "-"
          )
        ) : (
          "-"
        ),
    },
    {
      id: "gift",
      name: t("gift"),
      key: "gift",
      cell: ({ rowData }) => <>{displayGift(rowData?.is_gift) || "-"}</>,
    },
    {
      id: "gift_to",
      name: t("trainee_list_label"),
      key: "gift_to",
      cell: ({ rowData }) => (
        <>
          {rowData.payable_target === "Gift Coupon" ||
          rowData.payable_target === "كوبون إهداء" ? (
            <>
              {rowData?.coupon_users?.map((user) => (
                <>
                  {user.email} <br />
                </>
              ))}
              {rowData?.coupon_guests?.map((user) => (
                <>
                  {user.email} <br />
                </>
              ))}
            </>
          ) : rowData.giftTo ? (
            rowData?.giftTo.email
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      id: "origianl_price",
      name: t("payments.original_price"),
      key: "origianl_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>
          {`${Number(rowData?.origianl_price)} ${t("rs")}` || "-"}
        </div>
      ),
    },
    {
      id: "discount_price",
      name: t("payments.discount_price"),
      key: "discount_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>
          {`${Number(rowData?.offer_amount)} ${t("rs")}` || "-"}
        </div>
      ),
    },
    {
      id: "coupon_price",
      name: t("payments.coupon_price"),
      key: "coupon_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>
          {`${Number(rowData?.coupon_amount)} ${t("rs")}` || "-"}
        </div>
      ),
    },
    {
      id: "coupon_include_tax",
      name: t("payments.coupon_include_tax"),
      key: "coupon_include_tax",
      cell: ({ rowData }) => (
        <>
          {rowData?.payable_include_tax && rowData?.payable_include_tax !== "0"
            ? t("yes")
            : t("no")}
        </>
      ),
    },
    {
      id: "net_price",
      name: t("payments.net_price"),
      key: "net_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>
          {`${Number(rowData?.net_ammount)} ${t("rs")}` || "-"}
        </div>
      ),
    },
    {
      id: "tax_price",
      name: t("payments.tax_price"),
      key: "tax_price",
      cell: ({ rowData }) => (
        <div className={classes["price-minus"]}>
          {`${Number(rowData?.tax_amount)} ${t("rs")}` || "-"}
        </div>
      ),
    },
    {
      id: "payment_price",
      name: t("payments.payment_price"),
      key: "payment_price",
      cell: ({ rowData }) => (
        <div className={classes["price-plus"]}>
          {`${Number(rowData?.paid_amount)} ${t("rs")}` || "-"}
        </div>
      ),
    },
    {
      id: "processed_by",
      name: t("general.processed_by"),
      key: "processed_by",
      cell: ({ rowData }) => (
        <>
          {rowData?.processor
            ? i18n.language === "ar"
              ? rowData.processor.name_ar
              : rowData.processor.name_en
            : "-"}
        </>
      ),
    },
    // {
    //   id: "payable_serial_number",
    //   name: t("payments.payable_serial_number"),
    //   key: "payable_serial_number",
    //   cell: ({ rowData }) => <>{rowData?.payable_serial_number || "-"}</>,
    // },
    // {
    //   id: "payable_code",
    //   name: t("payments.payable_code"),
    //   key: "payable_code",
    //   cell: ({ rowData }) => <>{rowData?.payable_code || "-"}</>,
    // },
    {
      id: "rejection_reason",
      name: t("financial.payments_request.rejected_reason"),
      key: "rejection_reason",
      cell: ({ rowData }) => (
        <>
          <div style={{ width: 300 }}>
            <p className={classes["note_table"]}>
              {rowData?.rejection_reason || "-"}
            </p>
          </div>
        </>
      ),
    },
    {
      id: "accept_note",
      name: t("financial.payments_request.accept_note"),
      key: "accept_note",
      cell: ({ rowData }) => (
        <>
          <div style={{ width: 300 }}>
            <p className={classes["note_table"]}>
              {rowData?.accept_reason ||
                rowData?.wallet_transaction?.notes ||
                "-"}
            </p>
            {Object.keys(rowData?.wallet_transaction).length > 0 && (
              <p>
                {t("general.attachment")}:{" "}
                <a
                  href={rowData?.wallet_transaction?.attachment?.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#036c77",
                    textDecoration: "underline",
                    cursor: "pointer",
                    whiteSpace: "wrap",
                  }}
                >
                  {rowData?.wallet_transaction?.attachment?.name}
                </a>
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      id: "invoice_tb",
      name: t("sponsor.invoice.invoice_tb"),
      key: "invoice_tb",
      cell: ({ rowData }) => (
        <p
          style={{ color: "#24b3b9", cursor: "pointer" }}
          onClick={() =>
            rowData?.status === "Approved" ? downloadFile(rowData.id) : null
          }
        >
          {rowData?.status === "Approved" ? t("invoice_display") : "-"}
        </p>
      ),
    },
    {
      id: "transfer_date",
      name: t("payments.transfer_date"),
      key: "transfer_date",
      cell: ({ rowData }) =>
        rowData?.transfer_date ? (
          <>{moment(rowData?.transfer_date).format("DD/MM/YYYY")}</>
        ) : (
          "-"
        ),
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) =>
        rowData?.created_at ? (
          <>
            {moment(rowData?.created_at).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.created_at)}
          </>
        ) : (
          "-"
        ),
    },
    {
      id: "processed_at",
      name: t("general.processed_at"),
      key: "processed_at",
      cell: ({ rowData }) =>
        rowData?.processed_at ? (
          <>
            {moment(rowData?.processed_at).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.processed_at)}
          </>
        ) : (
          "-"
        ),
    },
    ...(type === "financial" || activeTab !== 1
      ? []
      : [
          {
            id: "actions",
            name: t("general.actions"),
            key: "actions",
            cell: ({ rowData }) => (
              <TableActionsColumn
                actions={paymentsActionsList}
                id={rowData.id}
              />
            ),
          },
        ]),
  ];

  return (
    <div className={classes["departments-trainees__tables"]}>
      {type === "course" || type === "program" ? (
        <Tabs
          tabs={tabs}
          disabled={loading}
          activeTab={activeTab}
          onSelectTab={(key) => {
            resetFilterHandler();
            setActiveTab(key);
          }}
        />
      ) : null}

      <MainTable columns={columns} data={payments || []} isLoading={loading} />
      <BasicModal ref={approvePaymentModalRef}>
        {showApprovePaymentModal && (
          <SharedModal
            title={t("payments.approve")}
            cancelText={t("general.cancel")}
            confirmMsg={t("payments.approve_msg")}
            confirmText={t("general.approve")}
            onConfirm={approvePaymentHandler}
            onCancel={closeApprovePaymentModal}
          />
        )}
      </BasicModal>
      <BasicModal ref={rejectPaymentModalRef}>
        {showRejectPaymentModal && (
          <MainBox className="p-0 rounded">
            <div className={classes["reject-payment-modal"]}>
              <div className={classes["reject-payment-modal__header"]}>
                <h3>{t("general.reject")}</h3>
                <img src={exit} alt="" onClick={closeRejectPaymentModal} />
              </div>
              <div className={classes["reject-payment-modal__body"]}>
                <Formik
                  initialValues={{
                    rejection_reason: "",
                  }}
                  onSubmit={(values) => {
                    const data = {
                      id: paymentActionData.id,
                      status: paymentActionData.status,
                      rejectionReason: values.rejection_reason,
                    };
                    paymentAction(data)
                      .then((res) => {
                        toast.success(res.data.msg);
                        getPayments();
                      })
                      .catch((err) => {
                        toast.error(t("general.error"));
                      })
                      .finally(() => {
                        closeRejectPaymentModal();
                      });
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.rejection_reason) {
                      errors.rejection_reason = t("crud.errors.required");
                    }
                    return errors;
                  }}
                  validateOnChange={true}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    errors,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="rejection_reason">
                          {t("financial.payments_request.rejected_reason")}
                        </label>
                        <textarea
                          id="rejection_reason"
                          name="rejection_reason"
                          style={{
                            border: "1px solid rgb(201, 200, 200)",
                            display: "block",
                            width: "500px",
                            borderRadius: "6px",
                            padding: "15px",
                          }}
                          maxLength={3000}
                          value={values.rejection_reason}
                          onChange={handleChange}
                          placeholder={t(
                            "financial.payments_request.rejected_reason"
                          )}
                          defaultValue={values.rejection_reason}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.rejection_reason
                            ? errors.rejection_reason
                            : ""}
                        </p>
                      </div>
                      <div className={classes["reject-payment-modal__submit"]}>
                        <CustomButton
                          value={t("submit")}
                          type="submit"
                          action={handleSubmit}
                          colors="#036c77"
                          disabled={false}
                          classes={classes["reject-payment-modal__submit-btn"]}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </MainBox>
        )}
      </BasicModal>
      <BasicModal ref={bankModalRef}>
        {showBankModal && (
          <BankModal
            closeBankModalHandler={closeBankModalHandler}
            paymentData={paymentData}
          />
        )}
      </BasicModal>
    </div>
  );
};

export default PaymentsTable;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { getCourseInfo } from "../../../../Services/api/courses/courseProvider";
import CourseForm from "../CourseForm/CourseForm";

export default function AddCourse() {
  const history = useHistory();
  const { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCourseInfo(id)
      .then((res) => {
        setCourseData(res.data.course);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return <CourseForm type="preview" courseData={courseData} loading={loading} />;
}

import { useTranslation } from "react-i18next";
import FilterBox from "../Shared/Components/FilterBox/FilterBox";
import classes from "./payments.module.css";

const PaymentsFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  resetFilterHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  role,
  type,
}) => {
  const { t, i18n } = useTranslation();

  // payer_name: "",
  // payer_email: "",
  // payment_method: "",
  // payable_type: "",
  // role: "",
  // payable_name: "",
  // payable_serial_number: "",
  // payable_code: "",

  const filterFields = [
    {
      id: "payer_name",
      component: "input",
      label: t("department_trainees.trainee_name"),
      type: "text",
      placeholder: t("department_trainees.trainee_name"),
      name: "payer_name",
      value: filterData.payer_name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "payer_email",
      component: "input",
      label: t("general.email"),
      type: "text",
      placeholder: t("general.email"),
      name: "payer_email",
      value: filterData.payer_email,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "id_number",
      component: "input",
      label: t("system_users.id_number"),
      type: "text",
      placeholder: t("system_users.id_number"),
      name: "id_number",
      value: filterData.id_number,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    ...(type === "financial"
      ? [
          {
            id: "transaction_type",
            label: t("wallet.label.WDtransactiontype"),
            component: "select",
            placeholder: t("wallet.label.WDtransactiontype"),
            initialValue: t("wallet.label.WDtransactiontype"),
            name: "transaction_type",
            value: filterData.transaction_type,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "Payment",
                value: "Payment",
                label: t("general.payment"),
              },
              {
                id: "Refund",
                value: "Refund",
                label: t("general.refund"),
              },
              {
                id: "Deposit",
                value: "Deposit",
                label: t("general.deposit"),
              },
              {
                id: "Withdraw",
                value: "Withdraw",
                label: t("general.withdraw"),
              },
            ],
          },
        ]
      : []),
    {
      id: "payment_method",
      label: t("general.payment_method"),
      component: "select",
      placeholder: t("general.payment_method"),
      initialValue: t("general.payment_method"),
      name: "payment_method",
      value: filterData.payment_method,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: [
        {
          id: "bank",
          value: "Bank",
          label: t("bank"),
        },
        {
          id: "wallet",
          value: "Wallet",
          label: t("wallet.label.wallet"),
        },
        {
          id: "tebx",
          value: "Tebx",
          label: t("tebx"),
        },
        {
          id: "cash",
          value: "cash",
          label: t("wallet.transaction.cash"),
        },
        {
          id: "iban",
          value: "iban",
          label: t("wallet.transaction.iban"),
        },
      ],
    },
    ...(type === "financial"
      ? [
          {
            id: "payable_type",
            label: t("payments.payable_type"),
            component: "select",
            placeholder: t("payments.payable_type"),
            initialValue: t("payments.payable_type"),
            name: "payable_type",
            value: filterData.payable_type,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "course",
                value: "course",
                label: t("trainer.course.label"),
              },
              {
                id: "program_payment",
                value: "program_payment",
                label: t("trainer.program.label"),
              },
              {
                id: "booth",
                value: "booth",
                label: t("general.exhibition_booth"),
              },
              {
                id: "visit",
                value: "exhibition_visit",
                label: t("general.exhibition_visitor"),
              },
              {
                id: "wallet",
                value: "wallet",
                label: t("general.wallet"),
              },
              {
                id: "wallet_transaction",
                value: "wallet_transaction",
                label: t("general.user"),
              },
              {
                id: "coupon",
                value: "coupon",
                label: t("general.coupon"),
              },
            ],
          },
          {
            id: "payable_name",
            component: "input",
            label: t("payments.payable_name"),
            type: "text",
            placeholder: t("payments.payable_name"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),
    // {
    //   id: "role",
    //   label: t("role"),
    //   component: "select",
    //   placeholder: t("role"),
    //   initialValue: t("role"),
    //   name: "role",
    //   value: filterData.role,
    //   classes: "col-12 col-md-6 col-xl-2",
    //   onchange: changeHandler,
    //   options: [
    //     {
    //       id: "trainee",
    //       value: "trainee",
    //       label: t("trainee"),
    //     },
    //     {
    //       id: "partner",
    //       value: "partner",
    //       label: t("partner"),
    //     },
    //   ],
    // },
    ...(filterData.payable_type === "course" ||
    filterData.payable_type === "program_payment"
      ? [
          {
            id: "payable_serial_number",
            component: "input",
            label: t("payments.payable_serial_number"),
            type: "text",
            placeholder: t("payments.payable_serial_number"),
            name: "payable_serial_number",
            value: filterData.payable_serial_number,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "payable_code",
            component: "input",
            label: t("payments.payable_code"),
            type: "text",
            placeholder: t("payments.payable_code"),
            name: "payable_code",
            value: filterData.payable_code,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),
    ...(type === "financial"
      ? [
          {
            id: "status",
            label: t("general.status"),
            component: "select",
            placeholder: t("general.status"),
            initialValue: t("general.status"),
            name: "status",
            value: filterData.status,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "Waiting",
                value: "Waiting",
                label: t("general.waiting"),
              },
              {
                id: "Approved",
                value: "Approved",
                label: t("general.approved"),
              },
              {
                id: "Rejected",
                value: "Rejected",
                label: t("general.rejected"),
              },
            ],
          },
        ]
      : []),
    ...(type === "financial"
      ? [
          {
            id: "is_gift",
            label: t("gift"),
            component: "select",
            placeholder: t("gift"),
            initialValue: t("gift"),
            name: "is_gift",
            value: filterData.is_gift,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "yes",
                value: "1",
                label: t("yes"),
              },
              {
                id: "no",
                value: "0",
                label: t("no"),
              },
            ],
          },
        ]
      : []),
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default PaymentsFilter;

import connector from "../Handler";
import env from "../Environment";

export function aboutUS() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.about_us)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function aboutUSCount() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.about_us_count)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editAboutUSAdmin(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.edit_about_us_admin, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import EditIcon from "../../../assets/icons/pen.svg";
import MainTable from "../../Shared/Components/MainTable/MainTable";

import DeleteIcon2 from "../../../assets/icons/delete.svg";
import classes from "./advertisement.module.css";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../Shared/Components/SwitchToggle/SwitchToggle";
import {
  ActiveAdvertisement,
  DeactiveAdvertisement,
  deleteAdvertisement,
} from "../../../Redux/Actions/Advertisements/advertisements.action";
// import { cancelEvent } from "../../../Redux/Actions/events/events.action";
// import { toast } from "react-toastify";

const AdvertisementsTable = ({ sortAction, currentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [adId, setAdId] = useState(null);

  //   const confirmCancelEventHandler = () => {
  //     if (!eventId) return;
  //     dispatch(cancelEvent(eventId, sendEmails));
  //     setShowCancelModal(false);
  //   };
  //   const cancelHandler = (id) => {
  //     setShowCancelModal(true);
  //     setEventId(id);
  //   };

  const { advertisementsList, advertisementsListLoading } = useSelector(
    (state) => state.advertisementsListReducer
  );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };
  //   useEffect(() => {}, [systemUsersList]);

  const activeAdStatusHandler = (id) => {
    dispatch(ActiveAdvertisement(id));
  };
  const deactiveAdStatusHandler = (id) => {
    dispatch(DeactiveAdvertisement(id));
  };

  const deleteHandler = (id) => {
    setShowDeleteModal(true);
    setAdId(id);
  };

  const confirmDeleteAdHandler = () => {
    if (!adId) return;
    dispatch(deleteAdvertisement(adId));
    setShowDeleteModal(false);
  };

  const eventsActionsList = [
    {
      id: "edit",
      icon: <img src={EditIcon} alt="" />,
      action: (id, name) => {
        // if (name) {
        //   toast.error(
        //     <span style={{ fontSize: 13, fontWeight: "bold" }}>
        //       {t("cant_edit_cancel")}
        //     </span>
        //   );
        // } else {
        // }
        history.push(`/admin/advertisements-list/${id}/edit`);
      },
      tooltip: t("general.edit"),
    },

    {
      id: "cancel",
      icon: <img src={DeleteIcon2} alt="" />,
      action: (id) => {
        deleteHandler(id);
      },
      tooltip: t("general.delete"),
    },
  ];
  const displayMode = (has_button) => {
    if (has_button) {
      return t("no");
    } else {
      return t("yes");
    }
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (currentPage * 10 - 10)}</>
      ),
    },
    {
      id: "title",
      name: t("title"),
      key: "title",
      cell: ({ rowData }) => (
        <div style={{ width: 300 }}>
          <p className={classes["note_table"]}>{rowData?.title || "-"}</p>
        </div>
      ),
      sortable: false,
    },

    {
      id: "from_date",
      name: t("general.start_date"),
      key: "from_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.from_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.from_date)}
        </>
      ),
      sortable: true,
    },
    {
      id: "to_date",
      name: t("general.end_date"),
      key: "to_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.to_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.to_date)}
        </>
      ),
      sortable: true,
    },
    {
      id: "has_button",
      name: t("has_button_table"),
      key: "has_button",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: "#fff",
            backgroundColor: rowData?.has_button
              ? "rgba(165, 8, 8, 0.685)"
              : "#12C689",
          }}
        >
          {displayMode(rowData?.has_button) || "-"}
        </span>
      ),
      sortable: false,
    },
    // {
    //   id: "button_text",
    //   name: t("button_text"),
    //   key: "button_text",
    //   cell: ({ rowData }) => <div>{rowData?.button_text || "-"}</div>,
    //   sortable: false,
    // },
    {
      id: "url",
      name: t("button_url"),
      key: "url",
      cell: ({ rowData }) => (
        <div>
          {rowData?.url ? (
            <span
              onClick={() => {
                window.open(rowData?.url, "_blank");
              }}
              style={{ color: "blue", cursor: "pointer", fontWeight: 600 }}
            >
              {i18n.language === "ar" ? "رابط" : "Link"}
            </span>
          ) : (
            "-"
          )}
        </div>
      ),
      sortable: false,
    },

    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: true,
    },
    // {
    //   id: "status",
    //   name: t("status"),
    //   key: "status",
    //   cell: ({ rowData }) => (
    //     <span
    //       className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
    //       style={{
    //         color: "#fff",
    //         backgroundColor: rowData?.canceled
    //           ? "rgba(165, 8, 8, 0.685)"
    //           : "#12C689",
    //       }}
    //     >
    //       {displayMode(rowData?.canceled) || "-"}
    //     </span>
    //   ),
    //   sortable: false,
    // },

    {
      id: "actions",
      name: t("system_users.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn
          actions={eventsActionsList}
          id={rowData.id}
          //   name={rowData.canceled}
        />
      ),
      sortable: false,
    },
    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status === "active" ? true : false}
          id={rowData.id}
          onStatusChange={() => {
            rowData.status === "active"
              ? deactiveAdStatusHandler(rowData.id)
              : activeAdStatusHandler(rowData.id);
          }}
        />
      ),
      sortable: false,
    },
  ];
  return (
    <>
      <MainTable
        columns={columns}
        data={advertisementsList || []}
        isLoading={advertisementsListLoading}
        sortAction={sortAction}
      />
      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon2}
          title={t("ad_delete_title")}
          confirmMsg={t("ad_delete_msg")}
          confirmText={t("general.confirm")}
          cancelText={t("general.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            confirmDeleteAdHandler();
          }}
          isLoading={false}
        />
      )}
    </>
  );
};

export default AdvertisementsTable;

import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function listAllCoupon(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.list_all_coupon, {
          params: params,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportCoupons(export_type, params, type) {
  let extension = "";
  export_type == "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          type === "sendCoupon"
            ? env.list_gifts_coupons
            : type === "receivedCoupon"
            ? env.list_received_coupons
            : env.list_all_coupon,
          {
            responseType: "blob",
            params: {
              ...params,
              export: extension,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "coupons" + "." + extension); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportChartsData(type, params) {
  let extension = "";
  type == "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.statistics, {
          responseType: "blob",
          params: {
            ...params,
            export: extension,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Charts Data" + "." + extension); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportCouponUsers(type, params, id) {
  let extension = "";
  type == "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_coupon_users, { id: id }), {
          responseType: "blob",
          params: {
            ...params,
            export: extension,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "coupons" + "." + extension); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addCoupon(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_coupon, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCouponInfo(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_coupon_info, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getCouponUsers(id, filter) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_coupon_users, { id: id }), {
          params: { ...filter },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteCoupon(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_coupon, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateCoupon(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(
          URLBuilder(env.update_coupon, { id: id }),
          { ...payload, _method: "put" },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

// send coupon gift
export function sendCouponGift(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.send_coupon_gift, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCourseCoupon() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_course_coupon)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function listTraineesCoupon() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.list_trainees_coupon)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCoursesCoupon(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_courses_coupon, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

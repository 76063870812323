import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import i18n from "../../../i18n/i18n";
import { systemUsersListConstant } from "../../Constants/systemUsers/systemUsersList";

// GET SYSTEM USERS LIST
export const fetchSystemUsersList = ({ page = 1, perPage = 10, filter, isSponser }) => {
  return async (dispatch) => {
    dispatch({
      type: systemUsersListConstant.GET_SYSTEM_USERS_LIST_REQUEST,
    });

    const url = isSponser ? env.get_sponsors_list : env.get_roles;
    await connector
      .get(url, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: systemUsersListConstant.GET_SYSTEM_USERS_LIST_SUCCESS,
            payload: {
              users: response.data.response.Users,
              roles: response.data.response.AllRoles,
              genders: response.data.response.genders,
              // medicalFields: response.data.response.medical_fields,
              specialties: response.data.response.specialties,
              countries: response.data.response.countries,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: systemUsersListConstant.GET_SYSTEM_USERS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: systemUsersListConstant.GET_SYSTEM_USERS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

export const updateSystemUserStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REQUEST,
    });

    await connector
      .post(env.active_users, { user_id: id })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};

// addUsersByExcelApi
export const addUsersByExcelApi = (data) => {
  return async (dispatch) => {
    dispatch({
      type: systemUsersListConstant.ADD_USERS_BY_EXCEL_REQUEST,
    });

    await connector
      .post(env.add_users_by_excel, data)
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: systemUsersListConstant.ADD_USERS_BY_EXCEL_SUCCESS,
            payload: response?.data?.response,
          });
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
        } else {
          toast.error(
            <>
              {response?.data?.errors ? (
                Object.keys(response?.data?.errors)?.map((key) => {
                  return <span style={{ fontSize: 12 }}>{response?.data?.errors[key]}</span>;
                })
              ) : (
                <span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>
              )}
            </>
          );
          dispatch({
            type: systemUsersListConstant.ADD_USERS_BY_EXCEL_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <>
            {err?.response?.data?.errors ? (
              Object.keys(err?.response?.data?.errors)?.map((key) => {
                return (
                  <span style={{ fontSize: 12 }}>
                    {`${err?.response?.data?.errors[key]} (${i18n.language == "ar" ? "حقل رقم" : "filed number"} ${
                      Number(key.slice(0, 1)) + 2
                    })`}
                    <br />
                  </span>
                );
              })
            ) : (
              <span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.msg}</span>
            )}
          </>
        );
        dispatch({
          type: systemUsersListConstant.ADD_USERS_BY_EXCEL_REJECTED,
          payload: err,
        });
      });
  };
};

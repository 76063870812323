import React from "react";
import classes from "./Calender.module.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { utcToLocal } from "../../utils/utcToLocal";

export default function EventCard({
  type,
  img,
  name,
  tag,
  startDate,
  endDate,
  clickedDate,
  startMsg,
  endMsg,
  course_name,
  startEndMsg,
  duration,
}) {
  const { t, i18n } = useTranslation();
  return (
    <div className={classes.cardCont}>
      <div className={classes.img_title}>
        <div className={classes.imgCont}>
          <img
            src={
              img ||
              "https://tebx-migrate-be.tajahdev.dev/imagesSeed/courses/course2.jpeg"
            }
            alt=""
            className={classes.img}
          />
        </div>
        <div className={classes.contentCont}>
          <h2 style={{ lineHeight: "2rem" }}>
            {moment(startDate).format("DD-MM-YYYY") ===
              moment(endDate).format("DD-MM-YYYY") || endDate === "" ? (
              <>
                {`${startEndMsg}`}
                <br />

                {`${name}`}
              </>
            ) : moment(clickedDate).format("DD-MM-YYYY") ===
              moment(endDate).format("DD-MM-YYYY") ? (
              <>
                {`${endMsg}`}
                <br />

                {`${name}`}
              </>
            ) : moment(clickedDate).format("DD-MM-YYYY") ===
              moment(startDate).format("DD-MM-YYYY") ? (
              <>
                {`${startMsg}`}
                <br />
                {`${name}`}
              </>
            ) : (
              name
            )}
          </h2>
          {course_name && (
            <h4 style={{ lineHeight: "1rem" }}>
              {t("course_name")}
              <br />
              {course_name}
            </h4>
          )}
          <div className={classes["booth-status-container"]}>
            <div
              className={`${classes["booth-status-box"]} ${
                classes[`booth-status-box-color`]
              }`}
            >
              {tag}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.date_timeCont}>
        <div className={classes.date_time}>
          <p>{t("start_date")}</p>
          <span>{moment(startDate).format("YYYY-MM-DD")}</span>
          <p>{t("start_time")}</p>
          <span>{utcToLocal(startDate)}</span>
        </div>
        <div className={classes.date_time}>
          <p>{t("general.end_date")}</p>
          <span>
            {endDate === ""
              ? moment(startDate).format("YYYY-MM-DD")
              : moment(endDate).format("YYYY-MM-DD")}
          </span>
          <p>{duration ? t("sesion_duration") : t("end_time")}</p>
          <span>
            {duration ? `${duration} ${t("minutes")}` : utcToLocal(endDate)}
          </span>
        </div>
      </div>
    </div>
  );
}

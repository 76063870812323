import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/icons/Btn.svg";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import CircularProgressBar from "./CircularProgressBar";

export default function UnitItem({ index, courseId, chapterId, unit, isCourseClosed, progress }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <button
      type="button"
      onClick={() => history.push(`/training-courses/${courseId}/chapter/${chapterId}/unit/${unit.id}`, { index })}
      className="tw-rounded-lg tw-text-start tw-w-full tw-border tw-border-gray-300 tw-p-2 tw-flex tw-items-center tw-justify-between tw-space-s-4"
    >
      <div className="tw-flex tw-items-center tw-space-s-6">
        <div className="tw-bg-[#006e78] tw-rounded-lg tw-text-lg tw-text-white tw-h-8 tw-aspect-square tw-flex tw-items-center tw-justify-center tw-font-sans">
          {index + 1}
        </div>

        <div className="tw-space-y-2">
          <div className="tw-text-lg tw-font-bold">{unit.name}</div>
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-space-s-6">
        {progress && Object.keys(progress).length ? (
          <div className="tw-flex tw-items-center tw-space-s-3">
            <CircularProgressBar title={t("progress")} percent={progress.progress_percentage} color="#24b3b9" />
            <CircularProgressBar title={t("watchingProgress")} percent={progress.watch_percentage} color="#f57a30" />
          </div>
        ) : null}
        <ArrowLeftIcon className="tw-h-8 tw-w-8" />
      </div>
    </button>
  );
}

import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import QuestionTypeEnum, {
  QuestionTypeWithFile,
  QuestionTypeWithOptions,
} from "../../Enums/QuestionTypeEnum";
import QuestionValidator, {
  getQuestionFileTypes,
} from "../../Modules/Admin/Assessments/Form/Part/QuestionValidator";
import { HotSpotOptions } from "../../Modules/Admin/Assessments/QuestionTypes";
import Checkbox from "../../Modules/Shared/Components/Checkbox/Checkbox";
import CustomButton from "../../Modules/Shared/Components/CustomButton/CustomButton";
import FilesDragAndDropInput from "../../Modules/Shared/Components/FilesDragAndDropInput/FilesDragAndDropInput";
import Input from "../../Modules/Shared/Components/Input/Input";
import InputFile from "../../Modules/Shared/Components/InputFile/InputFile";
import { LessonContentContext } from "../../Providers/LessonContentProvider";
import { downloadFileFromLesson } from "../../Services/api/courses/courseProvider";
import { ReactComponent as CloseIcon } from "../../assets/icons/crose.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import { ReactComponent as DocumentIcon } from "../../assets/icons/document.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg";

function AddFileToLessonModal(props) {
  require("./AddFileToLessonModal.css");
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const attachmentPanel = useRef(null);
  const lessonContentContext = useContext(LessonContentContext);

  const [showFileInput, setShowFileInput] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);
  const [packageData, setPackageData] = useState("");
  const fileTypes = JSON.parse(localStorage.getItem("system_files_types"));

  const onAttachmentPanelHoverStart = (event) => {
    attachmentPanel.current.classList.add(
      "attachment-panel-dragging-activation"
    );
  };

  const onAttachmentPanelHoverEnd = (event) => {
    attachmentPanel.current.classList.remove(
      "attachment-panel-dragging-activation"
    );
  };

  const handleDownload = () => {
    const type = props.isActivities ? "activity" : "contnet";
    downloadFileFromLesson(
      props?.data?.title,
      props?.data?.extension,
      props?.data?.id,
      type
    );
  };

  const onAttachmentPanelHoverDropped = (event) => {
    event.preventDefault();
    let file = event.dataTransfer.files.item(0);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPackageData(file);
      attachmentPanel.current.classList.remove(
        "attachment-panel-dragging-activation"
      );
    };
    reader.onerror = function (error) {
      attachmentPanel.current.classList.remove(
        "attachment-panel-dragging-activation"
      );
    };
  };

  const onAttachmentPanelHoverOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  useEffect(() => {
    if (attachmentPanel.current != null) {
      attachmentPanel.current.addEventListener(
        "dragenter",
        onAttachmentPanelHoverStart
      );
      attachmentPanel.current.addEventListener(
        "dragleave",
        onAttachmentPanelHoverEnd
      );
      attachmentPanel.current.addEventListener(
        "drop",
        onAttachmentPanelHoverDropped
      );
      attachmentPanel.current.addEventListener(
        "dragover",
        onAttachmentPanelHoverOver
      );
    }

    return () => {
      if (attachmentPanel.current != null) {
        attachmentPanel.current.removeEventListener(
          "dragenter",
          onAttachmentPanelHoverStart
        );
        attachmentPanel.current.removeEventListener(
          "dragleave",
          onAttachmentPanelHoverEnd
        );
        attachmentPanel.current.removeEventListener(
          "drop",
          onAttachmentPanelHoverDropped
        );
        attachmentPanel.current.removeEventListener(
          "dragover",
          onAttachmentPanelHoverOver
        );
      }
    };
  }, []);

  return (
    <>
      <div
        className="add_lesson_file_modal"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="add_lesson_file_modal_header">
          <h2 className="add_lesson_file_modal_title">{props.title}</h2>
          <div
            className="add_lesson_file_modal_close"
            onClick={props.dismissModal}
          >
            <img src={DeleteIcon} alt="close" />
          </div>
        </div>
        <Formik
          initialValues={{
            title: props?.data?.title || "",
            include_achivement_per: props?.data?.include_achivement_per
              ? props?.data?.include_achivement_per
              : false,
            include_view_per: props?.data?.include_view_per
              ? props?.data?.include_view_per
              : false,
            content: props?.data?.content || "",
            media: props?.data?.media || packageData, ////////
            width: props?.data?.width || "",
            options: props?.data?.data ? JSON.parse(props.data.data) : [],
            type: props?.data?.type || props?.type || "",
            activity_mark: props?.data?.activity_mark || "",
            answer_attempts: props?.data?.answer_attempts || "",
            success_mark: props?.data?.success_mark || "",
            lesson_id: id,
            ...(props.data ? { _method: "put" } : {}),
          }}
          onSubmit={async (values, { setSubmitting }) => {
            lessonContentContext.setInitialLoading(true);
            setSubmitting(true);
            setIsLocalSubmitting(true);
            let formData = new FormData();
            Object.keys(values).forEach((field) => {
              if (field === "media" && !values[field]?.id) {
                values[field] && formData.append("file", values[field]);
              } else if (field === "options") {
                formData.append("data", JSON.stringify(values[field]));
              } else if (values[field] !== "") {
                formData.append(field, values[field]);
              }
            });

            formData.delete("include_achivement_per");
            formData.delete("include_view_per");

            formData.append(
              "include_achivement_per",
              values.include_achivement_per ? 1 : 0
            );
            formData.append(
              "include_view_per",
              values.include_view_per ? 1 : 0
            );

            formData.append("parent_id", null);
            props.isActivities
              ? formData.append("content_type", "activity")
              : formData.append("content_type", "content");

            try {
              props.data
                ? await lessonContentContext.editContentActivity(
                    formData,
                    props?.data?.id
                  )
                : await lessonContentContext.addContent(formData);
              await lessonContentContext.fetchLessonContent(id);
              props.dismissModal();
              props.type === "audio" && props.setPreview(false);
              setIsLocalSubmitting(false);
              setSubmitting(false);
              lessonContentContext.setInitialLoading(false);
            } catch (error) {
              if (error.response.data && error.response.data.errors) {
                // Object.keys(error.response.data.errors).forEach((key) => {
                //   toast.error(
                //     <span style={{ fontSize: 13, fontWeight: "bold" }}>
                //       {error.response.data.errors[key][0]}
                //     </span>
                //   );
                // });
                lessonContentContext.setInitialLoading(false);
              }
              setIsLocalSubmitting(false);
              setSubmitting(false);
              return;
            }
          }}
          validate={(values) => {
            setHasSubmitted(true);
            const errors = {};
            if (!values.title) {
              errors.title = t("crud.errors.required");
            }
            if (!props.data) {
              if (!values.media) {
                errors.media = t("crud.errors.required");
              }
            }
            if (
              props.type === "html" ||
              props.type === "scorm" ||
              props.type === "h5p" ||
              props.type === "hotspot" ||
              props.type === "xapi"
            ) {
              if (props.type !== "hotspot") {
                if (!values.success_mark && values.success_mark != "0") {
                  errors.success_mark = t("crud.errors.required");
                } else if (
                  parseFloat(values.success_mark) < 0 ||
                  parseFloat(values.success_mark) > 100
                ) {
                  errors.success_mark = `${t("crud.errors.percentage")}`;
                }
              }
              if (!values.activity_mark && values.activity_mark != "0") {
                errors.activity_mark = t("crud.errors.required");
              } else if (parseFloat(values.activity_mark) < 0) {
                errors.activity_mark = `${t("crud.errors.min")} 0`;
              }
              if (!values.answer_attempts && values.answer_attempts != "0") {
                errors.answer_attempts = t("crud.errors.required");
              }
            }

            if (props.type === "hotspot" && !values.options.length) {
              errors.options = t("crud.errors.required");
            }
            return errors;
          }}
          validateOnChange={hasSubmitted}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="add_lesson_file_modal_form"
            >
              <div className="add_lesson_file_modal_form_fields">
                <Input
                  type="text"
                  placeholder={t("trainer.course.lesson.crud.attachmentLabel")}
                  label={t("trainer.course.lesson.attachmentLabel")}
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  error={errors.title}
                  required
                />
                <div style={{ display: "flex", gap: "20px" }}>
                  {props.isActivities ? (
                    <Checkbox
                      name="include_achivement_per"
                      label={t("include_achivement_per")}
                      onChange={(event) => {
                        setFieldValue(
                          "include_achivement_per",
                          event.target.checked ? 1 : 0
                        );
                      }}
                      checked={
                        values.include_achivement_per === true ||
                        values.include_achivement_per === 1
                      }
                      value={
                        values.include_achivement_per === true ||
                        values.include_achivement_per === 1
                      }
                      error={errors.include_achivement_per}
                    />
                  ) : (
                    <></>
                  )}
                  <Checkbox
                    name="include_view_per"
                    label={t("include_view_per")}
                    onChange={(event) => {
                      setFieldValue(
                        "include_view_per",
                        event.target.checked ? 1 : 0
                      );
                    }}
                    checked={
                      values.include_view_per === true ||
                      values.include_view_per === 1
                    }
                    error={errors.include_view_per}
                    value={
                      values.include_view_per === true ||
                      values.include_view_per === 1
                    }
                  />
                </div>

                <label htmlFor="content">
                  {t("trainer.course.lesson.attachmentDescription")}
                </label>
                <SunEditor
                  value={values.content}
                  defaultValue={values.content}
                  setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                  name="content"
                  onChange={(content) => {
                    setFieldValue("content", content);
                  }}
                  setOptions={{
                    height: 200,
                    rtl: i18n.language === "ar" ? true : false,
                    buttonList: [
                      ["undo", "redo"],

                      ["showBlocks", "codeView"],
                      ["align", "horizontalRule"],
                      ["link", "image", "video", "audio"],
                    ],
                  }}
                />
                {(props.type === "html" ||
                  props.type === "scorm" ||
                  props.type === "h5p" ||
                  props.type === "hotspot" ||
                  props.type === "xapi") && (
                  <>
                    <Input
                      type="number"
                      min={0}
                      disabled={props.data}
                      placeholder={t("activity_mark")}
                      label={t("activity_mark")}
                      name="activity_mark"
                      onChange={handleChange}
                      value={values.activity_mark}
                      error={errors.activity_mark}
                    />
                    {props.type !== "hotspot" && (
                      <Input
                        type="number"
                        min={0}
                        max={100}
                        placeholder={t("success_mark")}
                        label={t("success_mark")}
                        name="success_mark"
                        onChange={handleChange}
                        value={values.success_mark}
                        error={errors.success_mark}
                      />
                    )}

                    <Input
                      type="number"
                      min={1}
                      placeholder={
                        props.isActivities
                          ? t("number_activity_try")
                          : t("number_exam_try")
                      }
                      label={
                        props.isActivities
                          ? t("number_activity_try")
                          : t("number_exam_try")
                      }
                      name="answer_attempts"
                      onChange={handleChange}
                      value={values.answer_attempts}
                      error={errors.answer_attempts}
                    />
                  </>
                )}

                <>
                  <InputFile
                    name="media"
                    type="document"
                    documentWithoutTitle={true}
                    label={t("trainer.course.lesson.attachment")}
                    placeholder={t("trainer.course.lesson.attachment")}
                    setFieldValue={(key, value) =>
                      setFieldValue(key, value?.file)
                    }
                    value={values.media}
                    error={errors.media}
                    accept={
                      props.type === "pdf"
                        ? ".pdf"
                        : props.type === "audio"
                        ? ".mp3,.mp4,.wav,.m4a,.wma"
                        : props.type === "hotspot"
                        ? ".png,.jpg,.jpeg,.svg"
                        : props.type === "html"
                        ? ".html"
                        : props.type === "h5p"
                        ? ".h5p"
                        : props.type === "file"
                        ? fileTypes.map((file) => `.${file.type}`).join(",")
                        : ".zip"
                    }
                    handleDownload={handleDownload}
                  />
                </>
                {props.type === "hotspot" ? (
                  <HotSpotOptions
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="add_lesson_file_actions">
                <CustomButton
                  type="submit"
                  value={t("sendNow")}
                  loading={isLocalSubmitting}
                  disable={isSubmitting}
                  colors="#036c77"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AddFileToLessonModal;

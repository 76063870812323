import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export async function getTrainer() {
  try {
    const response = connector.get(env.get_trainer);

    return response;
  } catch (error) {}
}

export function getTrainerInfo(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_trainer_info, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTrainerByCategory(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_trainer_category, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTrainerProfile() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_trainer_profile)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getTrainerHome() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.all_trainer)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editTrainerOverview(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.edit_trainer_overview, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

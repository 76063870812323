import React from "react";
import classes from "./select.module.css";
const Select = ({
  label,
  labelColor,
  name,
  onchange,
  options,
  defaultValue,
  initialValue,
  error,
  required,
  value,
  disabled = false,
  subLabel,
}) => {
  const randomId = Math.random().toString(36).substring(7);
  return (
    <div className={classes["select-field"]}>
      <label htmlFor={name}>
        <span style={{ color: labelColor ? labelColor : "black" }}>{label}</span>
        {subLabel && <span style={{ color: "#777" }}> {subLabel}</span>}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>

      <select name={name} onChange={onchange} value={value} disabled={disabled} defaultValue={defaultValue}>
        <option value={initialValue} hidden>
          {initialValue}
        </option>
        {options.map((option, index) => (
          <option value={option.value} key={option.id + randomId + index}>
            {option.label}
          </option>
        ))}
      </select>
      {<p className={`form-input-error-space ${classes["select-field-error"]}`}>{error && error}</p>}
    </div>
  );
};

export default Select;

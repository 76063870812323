import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./ChatBubbleHeader.module.css";
import { useHistory } from "react-router";
import help from "../../assets/icons/help-circle.svg";
import deleteicon from "../../assets/icons/delete-white.svg";

function ChatBubbleHeader(props) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes["chat-bubble-header"]}>
      <span>{t("chat.title")}</span>
      <div className={classes["chat-bubble-buttons"]}>
        <button onClick={() => history.push(`/faq/faq-list`)}>
          <span style={{ fontSize: 20, fontWeight: "bold" }}>
            <img src={help} alt="" />
          </span>
        </button>
        <button onClick={props.showHideChat}>
          <span style={{ fontSize: 20, fontWeight: "bold" }}>
            <img src={deleteicon} alt="" />
          </span>
        </button>
      </div>
    </div>
  );
}

export default ChatBubbleHeader;

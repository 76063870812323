import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { addCourseDepartment } from "../../../../Services/api/courses/courseProvider";
import ManageDepartments from "./ManageDepartments";

const CreateDepartment = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const { state } = useLocation();
	const specialtyId = state?.specialtyId;

	const submitHandler = async (values) => {
		let response = await addCourseDepartment(values);
		if (response.status === 201 && response.data.status) {
			if (specialtyId) {
				history.push(`/admin/category/${specialtyId}`);
			} else {
				history.push("/admin/department");
			}
			toast.success(
				<span style={{ fontSize: 13, fontWeight: "bold" }}>
					{response.data.msg ? response.data.msg : t("department_manage.department_created_successfully")}
				</span>,
				{
					onClose: () => {},
					autoClose: 1000,
				}
			);
		} else {
			toast.error(
				<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("department_manage.failed_creating_department")}</span>
			);
		}
	};

	return <ManageDepartments type={"create"} submitHandler={submitHandler} />;
};

export default CreateDepartment;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getExhibitionBoothsList } from "../../../../Redux/Actions/exhibition/exhibition.action";
import { cancelBoothForAdmin, exportExhibitionBooths } from "../../../../Services/api/Exhibition/ExhibitionProvider";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import ModelWithInput from "../../../Shared/Components/ModelWithInput/ModelWithInput";
import PaymentDetailsModal from "../../../Shared/Components/PaymentDetailsModal/PaymentDetailsModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import TableHeadActions from "../../../Shared/Components/TableHeadActions/TableHeadActions";
import Tabs from "../../../Shared/Components/Tabs/Tabs";
import BoothTable from "./BoothTable";
import { filterActions } from "./ExhibitionDetailsTableAction";
import classes from "./exhibitionDetails.module.css";

const ExhibitionDetailsBoothsTable = ({ id, mode, setIsExportLoading }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [payment, setPayment] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [dataToExport, setDataToExport] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const { boothsLoading } = useSelector((state) => state.exhibitionReducer);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [boothId, setBoothId] = useState(null);

  const [filterData, setFilterData] = useState({
    name: "",
    visitCount: "",
    numberOfProducts: "",
    status: "",
    rating: "",
    paymentMethod: "",
    orderDate: "",
    size: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = (e) => {
    setFilterData({
      name: "",
      visitCount: "",
      numberOfProducts: "",
      status: "",
      rating: "",
      paymentMethod: "",
      orderDate: "",
      size: "",
    });
    setIsResetApplied(true);
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn === columnKey) {
      setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getFilterData = () => {
    const filterBoothsData = {};
    filterData.name && (filterBoothsData.name = filterData.name);
    filterData.visitCount && (filterBoothsData.visit_count = filterData.visitCount);
    filterData.numberOfProducts && (filterBoothsData.products_count = filterData.numberOfProducts);
    filterData.status && (filterBoothsData.booth_status = filterData.status);
    filterData.rating && (filterBoothsData.rating = filterData.rating);
    filterData.paymentMethod && (filterBoothsData.payment_method = filterData.paymentMethod);
    filterData.orderDate && (filterBoothsData.created_at = filterData.orderDate);
    filterData.size && (filterBoothsData.size = filterData.size);

    sortColumn && (filterBoothsData.sort_column = sortColumn);
    sortOrder && (filterBoothsData.sort_order = sortOrder);
    filterBoothsData.status = activeTab;

    return filterBoothsData;
  };

  const getBoothHandler = () => {
    const filterBoothsData = getFilterData();
    setDataToExport(filterBoothsData);

    dispatch(
      getExhibitionBoothsList({
        id,
        perPage: 10,
        page: currentPage,
        filter: filterBoothsData,
      })
    );
  };

  const tabs = [
    {
      id: "booths",
      key: 1,
      label: t("Exhibition.request_booths_list"),
    },
    {
      id: "boothsApproved",
      key: 2,
      label: t("Exhibition.approved_booths"),
    },
    {
      id: "boothsRejected",
      key: 3,
      label: t("Exhibition.rejected_booths"),
    },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("booth.name"),
      type: "text",
      placeholder: t("booth.name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "paymentMethod",
      label: t("Exhibition.payment_method"),
      component: "select",
      placeholder: t("Exhibition.payment_method"),
      initialValue: t("Exhibition.payment_method"),
      name: "paymentMethod",
      value: filterData.paymentMethod,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: [
        {
          id: "wallet",
          value: "Wallet",
          label: t("financial.label.wallet"),
        },
        {
          id: "bankTransfer",
          value: "BankTrasfer",
          label: t("financial.label.bankTrasfer"),
        },
      ],
    },
    ...(activeTab === 3
      ? [
          {
            id: "status",
            label: t("Exhibition.status_exh"),
            component: "select",
            placeholder: t("Exhibition.status_exh"),
            initialValue: t("Exhibition.status_exh"),
            name: "status",
            value: filterData.status,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "active",
                value: "1",
                label: t("Exhibition.active"),
              },
              {
                id: "inactive",
                value: "0",
                label: t("Exhibition.inactive"),
              },
            ],
          },
        ]
      : []),
    ...(activeTab === 3
      ? [
          {
            id: "rating",
            label: t("Exhibition.rating"),
            component: "select",
            placeholder: t("Exhibition.rating"),
            initialValue: t("Exhibition.rating"),
            name: "rating",
            value: filterData.rating,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "1",
                value: "1",
                label: 1,
              },
              {
                id: "2",
                value: "2",
                label: 2,
              },
              {
                id: "3",
                value: "3",
                label: 3,
              },
              {
                id: "4",
                value: "4",
                label: 4,
              },
              {
                id: "5",
                value: "5",
                label: 5,
              },
            ],
          },
        ]
      : []),

    {
      id: "size",
      label: t("booth.size"),
      component: "select",
      placeholder: t("booth.size"),
      initialValue: t("booth.size"),
      name: "size",
      value: filterData.size,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: [
        {
          id: "small",
          value: "small",
          label: t("booth.small"),
        },
        {
          id: "medium",
          value: "medium",
          label: t("booth.medium"),
        },
        {
          id: "larg",
          value: "larg",
          label: t("booth.large"),
        },
      ],
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "reset",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-2  border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        getBoothHandler();
      },
    },
  ];

  const bankTransferActionHandler = (payment) => {
    setShowPaymentModal(true);
    setPayment(payment);
  };

  const openCancelModal = (boothId) => {
    setShowCancelModal(true);
    setBoothId(boothId);
  };

  const cancelBooth = async (reason) => {
    setSubmitting(true);
    try {
      const payload = {
        booth_id: boothId,
        cancel_reason: reason,
      };
      const res = await cancelBoothForAdmin(payload);
      if (res.status === 201 && res.data.status) {
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data.msg}</span>);
        getBoothHandler();
      } else {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data.msg}</span>);
      }
    } catch (error) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.data.msg}</span>);
    } finally {
      setShowCancelModal(false);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getBoothHandler();
    setIsResetApplied(false);
  }, [id, currentPage, isResetApplied, sortColumn, sortOrder, activeTab]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    await exportExhibitionBooths(id, type, dataToExport)
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        setIsExportLoading(false);
      });
  };
  return (
    <div className={classes["exhibition-details__tables"]}>
      {submitting && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className={classes["exhibition-details__tables__header"]}>
        <Tabs
          tabs={tabs}
          disabled={boothsLoading}
          activeTab={activeTab}
          onSelectTab={(key) => {
            setActiveTab(key);
          }}
        />
        <TableHeadActions
          actions={filterActions({
            showFilter: () => {
              setShowFilter((prev) => !prev);
            },
            addExhibition: () => {},
            exportPdf: () => {
              exportHandler("pdf");
            },
            exportExcel: () => {
              exportHandler("xlsx");
            },
          })}
        />
      </div>

      {showFilter && <FilterBox fields={filterFields} />}
      <BoothTable
        bankTransferActionHandler={bankTransferActionHandler}
        openCancelModal={openCancelModal}
        setCurrentPage={setCurrentPage}
        sortAction={sortActionHandler}
        activeTab={activeTab}
      />
      {showPaymentModal && (
        <PaymentDetailsModal
          closeModal={() => {
            setShowPaymentModal(false);
            setPayment(null);
          }}
          payment={payment}
        />
      )}
      {showCancelModal && (
        <ModelWithInput
          openModal={showCancelModal}
          setOpenModal={setShowCancelModal}
          modalTitle={t("booth.cancel_booth")}
          buttonText={t("general.submit")}
          type={"textarea"}
          inputLabel={t("booth.cancel_reason")}
          handleSubmit={cancelBooth}
        />
      )}
    </div>
  );
};

export default ExhibitionDetailsBoothsTable;

import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { getAllSpecialties } from "../../../../Services/api/HomeCoursesProvider";
import { getRoles, getUsers, sendBulkEmails } from "../../../../Services/api/Roles/RolesProvider";
import { listCourseDepartments } from "../../../../Services/api/courses/courseProvider";
import { getCountries } from "../../../../Services/api/profile/profileProvider";
import { getAllUsers } from "../../../../Services/api/survey/SurveyProvider";
import { SunEditorText } from "../../../Shared/Components";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

export default function SendMessage({}) {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [coursesOptions, setCoursesOptions] = useState([{}]);
  const [loading, setLoading] = useState(false);

  const [userFetching, setUserFetching] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const { t } = useTranslation();

  const [num, setNum] = useState(1);

  const types = [
    {
      value: "1",
      label: t("custom"),
    },
    {
      value: "2",
      label: t("by_country"),
    },
    {
      value: "3",
      label: t("by_department"),
    },
    {
      value: "4",
      label: t("by_role"),
    },
    {
      value: "5",
      label: t("by_course"),
    },
  ];

  // const roles = [
  //   {
  //     value: "trainee",
  //     label: t("selection.role.trainee"),
  //   },
  //   {
  //     value: "trainer",
  //     label: t("selection.role.trainer"),
  //   },
  // ];

  useEffect(() => {
    fetchCountries();
    fetchRoles();
    fetchUsers({});
  }, []);

  function fetchCountries() {
    getCountries().then((res) => {
      setCountries(
        res.data.countries.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    });
  }

  function fetchRoles() {
    getRoles().then((res) => {
      setRoles(
        res.data.response.AllRoles.map((item) => ({
          value: item.id,
          label: item.label,
        }))
      );
    });
  }

  const fetchUsers = async (values) => {
    try {
      setUserFetching(true);
      setUsers([]);
      let response = await getAllUsers({
        search: values.search ? values.search : null,
        includes: values.users,
      });
      if (response.status == 200) {
        setUsers(response.data.users);
        setUserFetching(false);
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      setUserFetching(false);
    }
  };

  const promiseSpecialtiesOptions = (inputValue) =>
    new Promise((resolve) => {
      getAllSpecialties({
        search: inputValue,
      }).then((res) => {
        resolve(
          res.data.data.specialties.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      });
    });

  const promiseDepartmentsOptions = (specialty, inputValue) =>
    new Promise((resolve) => {
      listCourseDepartments({
        search: inputValue,
        specialty_id: specialty,
      }).then((res) => {
        resolve(
          res.data.data.departments.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      });
    });

  const courseTypes = [
    {
      value: "course",
      label: t("general.course"),
    },
    {
      value: "program",
      label: t("program"),
    },
  ];

  const fetchCoursesOptions = (courseType) => {
    try {
      setLoading(true);
      getAllCourses({
        paginated: 0,
        is_program: courseType === "program" ? 1 : 0,
        not_in_program: 1,
        perPage: 10000,
      }).then((res) => {
        setCoursesOptions(res.data.data.courses.data.map((item) => ({ value: item.id, label: item.name })));
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
    }
  };
  // new Promise((resolve) => {
  //   setLoading(true);
  //   getAllCourses({
  //     paginated: 0,
  //     is_program: courseType === "program" ? 1 : 0,
  //     not_in_program: 1,
  //     perPage: 10000,
  //   }).then((res) => {
  //     setCoursesOptions(res.data.data.courses.data.map((item) => ({ value: item.id, label: item.name })));
  //     setLoading(false);
  //     resolve(
  //       res.data.data.courses.data.map((item) => ({
  //         value: item.id,
  //         label: item.name,
  //       }))
  //     );
  //   });
  // });

  function breadcrumbList() {
    let breadcrumbList = [];
    breadcrumbList = [
      {
        id: t("admin.label.admin_label"),
        page: t("admin.label.admin_label"),
        pagePath: "/admin",
      },
      {
        id: t("send_message"),
        page: t("send_message"),
        active: true,
      },
    ];
    return breadcrumbList;
  }

  return (
    <>
      <div className="container-fluid">
        {isUpdating || loading ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        <>
          <div className="row">
            <div className="col-12 sm:tw-px-2 tw-py-8">
              {/*TODO*/}
              <Breadcrumb list={breadcrumbList()} />
            </div>
          </div>
          <div className={`tw-mb-4`}>
            <Formik
              key={num}
              initialValues={{
                subject: "",
                body: "",
                search: "",
                type: "1",
                all_users: false,
                specialty: "",
                departments: [],
                countries: [],
                roles: [],
                users: [],
                courseType: "",
                course: null,
              }}
              onSubmit={async (values, { setErrors, resetForm }) => {
                // payload preperation section
                setIsUpdating(true);
                let data = {
                  subject: values.subject,
                  body: values.body,
                };
                console.log(values);
                if (values.type == 1) {
                  data.user_ids = values.users.map((item) => item.id);
                  data.all_users = values.all_users ? 1 : 0;
                } else if (values.type == 2) {
                  data.country_ids = values.countries.map((item) => item.value);
                } else if (values.type == 3) {
                  data.department_ids = values.departments.map((item) => item.value);
                } else if (values.type == 4) {
                  data.roles_ids = values.roles.map((item) => item.value);
                } else if (values.type == 5) {
                  // data["trainee-for-course"] = values.course.value;
                  if (values.courseType.value === "program") {
                    data["trainee-for-program"] = values.course.value;
                  } else {
                    data["trainee-for-course"] = values.course.value;
                  }
                }

                try {
                  let response = await sendBulkEmails(data);
                  setIsUpdating(false);
                  setNum(num + 1);
                  toast.success(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("block.label.success_send_emails")}</span>
                  );
                } catch (err) {
                  setIsUpdating(false);
                  if (err.data?.errors) {
                    function mapError(err) {
                      if (err[0] && typeof err[0] === "string") return err[0];
                      else {
                        Object.keys(err).forEach(function (key, index) {
                          err[key] = mapError(err[key]);
                        });
                        return err;
                      }
                    }
                    const errors = mapError(err.data.errors);
                    setErrors(errors);
                  }
                  toast.error(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data?.msg ?? "Failure in service"}</span>
                  );
                }
              }}
              validate={(values) => {
                setHasSubmitted(true);
                const errors = {};

                console.log(values);

                if (!values.subject) {
                  errors.subject = t("crud.errors.required");
                } else if (values.subject.length > 180) {
                  errors.subject = t("The field text is too long");
                }

                if (!values.body) {
                  errors.body = t("crud.errors.required");
                }
                if (values.type == 1) {
                  if (!values.users.length && !values.all_users) {
                    errors.users = t("crud.errors.required");
                  }
                } else if (values.type == 2) {
                  if (!values.countries.length) {
                    errors.countries = t("crud.errors.required");
                  }
                } else if (values.type == 3) {
                  if (!values.departments.length) {
                    errors.departments = t("crud.errors.required");
                  }
                } else if (values.type == 4) {
                  if (!values.roles.length) {
                    errors.roles = t("crud.errors.required");
                  }
                } else if (values.type == 5) {
                  if (!values.course) {
                    errors.course = t("crud.errors.required");
                  }
                }
                return errors;
              }}
              validateOnChange={hasSubmitted}
            >
              {({
                setFieldValue,
                setValues,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <>
                    <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
                      <div className="tw-text-teal-500 tw-text-lg sm:tw-text-2xl">{t("send_message")}</div>
                      <div className="tw-space-y-4">
                        <div className="sm:tw-flex tw-items-center sm:tw-space-s-4">
                          {/* send type field */}
                          {types.map((type) => (
                            <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                              <Field
                                name="type"
                                type="radio"
                                value={type.value}
                                className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                              />
                              <div className="tw-text-gray-600 tw-font-medium">{type.label}</div>
                            </label>
                          ))}
                        </div>
                        {/* users field */}
                        <div className={`${values.type == 1 ? "" : "tw-hidden"}`}>
                          <div className="tw-flex tw-items-center tw-space-s-4 tw-mb-2">
                            <Field
                              type="checkbox"
                              name="all_users"
                              onChange={() => {
                                setValues({
                                  ...values,
                                  users: [],
                                  all_users: !values.all_users,
                                });
                              }}
                              className={`tw-h-4 tw-w-4 tw-shrink-0 tw-appearance-none tw-border-[1px] tw-rounded tw-border-gray-300 checked:tw-bg-teal-600 checked:tw-border-white checked:tw-border-x-[3px] ${
                                values.users.length ? "checked:tw-border-y-[6px]" : "checked:tw-border-y-[3px]"
                              }`}
                            />
                            <div className="tw-text-gray-600 tw-font-medium">{t("crud.placeholders.message.to")}</div>
                            {/* <div className="tw-text-teal-400 tw-text-xs">
                              {t("noselect_note")}
                            </div> */}
                          </div>
                          <FieldArray name="users">
                            {({ insert, remove, push }) => (
                              <div className="tw-border tw-rounded tw-divide-y">
                                <div className="tw-bg-gray-100 tw-p-4 md:tw-flex tw-items-center tw-justify-between">
                                  <div className="tw-flex tw-items-center tw-space-s-4">
                                    <div className="tw-font-semibold tw-text-gray-500">
                                      {t("crud.placeholders.select.user")}
                                    </div>
                                  </div>
                                  <div className="tw-flex tw-items-center tw-space-s-8">
                                    <Field
                                      name="search"
                                      className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent"
                                      placeholder={t("search_by_name_or_email")}
                                      onChange={({ target: { value } }) => {
                                        setFieldValue("search", value);
                                        fetchUsers({
                                          ...values,
                                          search: value,
                                        });
                                      }}
                                    />
                                    <li className="fal fa-search tw-text-teal-500 tw-w-6"></li>
                                  </div>
                                </div>
                                <div className="tw-py-4 tw-pe-4">
                                  <div className="tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-max-h-[40vh] tw-pe-4">
                                    {userFetching ? (
                                      [...Array(5).keys()].map((i) => (
                                        <div
                                          key={i}
                                          className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
                                        >
                                          <div className="tw-flex tw-items-center tw-space-s-4">
                                            <div
                                              className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded tw-border-gray-300`}
                                            ></div>
                                            <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
                                          </div>
                                          <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
                                        </div>
                                      ))
                                    ) : users.length ? (
                                      users.map((user) => (
                                        <button
                                          key={user.id}
                                          disabled={values.all_users}
                                          type="button"
                                          onClick={() => {
                                            const userIndex = values.users?.findIndex((i) => i.id == user.id);
                                            if (userIndex != undefined && userIndex >= 0) remove(userIndex);
                                            else push(user);
                                          }}
                                          className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full`}
                                        >
                                          <div className="tw-flex tw-items-center tw-space-s-4">
                                            <div
                                              className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded  ${
                                                values.users.find((item) => item.id == user.id) || values.all_users
                                                  ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                                                  : "tw-border-gray-300"
                                              }`}
                                            ></div>
                                            <div>{user.name}</div>
                                          </div>
                                          <div>{user.email}</div>
                                        </button>
                                      ))
                                    ) : (
                                      <div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">
                                        {t("there_no_matching_users")}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </FieldArray>
                          <ErrorMessage name={`users`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
                        </div>

                        <div className={`${values.type == 2 ? "" : "tw-hidden"}`}>
                          <label className="tw-block">
                            <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("countries")}</div>

                            <Select
                              id="countries"
                              options={countries}
                              value={values.countries}
                              isMulti
                              onChange={(items) => {
                                if (items) {
                                  setFieldValue("countries", items);
                                }
                              }}
                              placeholder={t("countries")}
                            />
                          </label>

                          <ErrorMessage
                            name={`countries`}
                            component="div"
                            className="tw-text-xs tw-text-red-700 tw-h-4"
                          />
                        </div>

                        {/* departments */}
                        <div
                          className={`tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4 ${
                            values.type == 3 ? "" : "tw-hidden"
                          }`}
                        >
                          <div>
                            <label className="tw-block">
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("general.specialty")}</div>

                              <AsyncSelect
                                id="specialty"
                                cacheOptions
                                defaultOptions
                                loadOptions={promiseSpecialtiesOptions}
                                onChange={(item) => {
                                  if (item) {
                                    setValues({
                                      ...values,
                                      departments: [],
                                      specialty: item.value,
                                    });
                                  }
                                }}
                                placeholder={t("general.specialty")}
                              />
                            </label>

                            <ErrorMessage
                              name={`specialty`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>
                          {values.specialty && (
                            <div>
                              <label className="tw-block">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("departments")}</div>

                                <AsyncSelect
                                  id="departments"
                                  key={values.specialty}
                                  defaultOptions
                                  value={values.departments}
                                  loadOptions={(inputValue) => promiseDepartmentsOptions(values.specialty, inputValue)}
                                  isMulti
                                  cacheOptions={false}
                                  onChange={(items) => {
                                    if (items) {
                                      setFieldValue(
                                        "departments",
                                        items.map((item) => item)
                                      );
                                    }
                                  }}
                                  placeholder={t("departments")}
                                />
                              </label>

                              <ErrorMessage
                                name={`departments`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>
                          )}
                        </div>

                        {/* roles */}
                        <div className={`${values.type == 4 ? "" : "tw-hidden"}`}>
                          <label className="tw-block">
                            <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("roles")}</div>

                            <Select
                              id="roles"
                              options={roles}
                              value={values.roles}
                              isMulti
                              onChange={(items) => {
                                if (items) {
                                  setFieldValue("roles", items);
                                }
                              }}
                              placeholder={t("roles")}
                            />
                          </label>

                          <ErrorMessage name={`roles`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
                        </div>

                        {/* courses */}
                        <div
                          className={`tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4 ${
                            values.type == 5 ? "" : "tw-hidden"
                          }`}
                        >
                          <div>
                            <label className="tw-block">
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                {t("select_course_or_program")}
                              </div>
                              <Select
                                id="courseType"
                                options={courseTypes}
                                value={values.courseType}
                                onChange={(item) => {
                                  if (item) {
                                    setValues({
                                      ...values,
                                      course: null,
                                      courseType: item,
                                    });
                                    fetchCoursesOptions(item.value);
                                  }
                                }}
                                placeholder={t("select_course_or_program")}
                              />
                            </label>

                            <ErrorMessage
                              name={`courseType`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>
                          {values.courseType && (
                            <div>
                              <label className="tw-block">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                  {t(`general.${values.courseType.value}s`)}
                                </div>

                                <Select
                                  id="course"
                                  key={values.courseType}
                                  defaultOptions
                                  value={values.course}
                                  options={coursesOptions}
                                  onChange={(item) => {
                                    if (item) {
                                      setFieldValue("course", item);
                                    }
                                  }}
                                  placeholder={t(`general.${values.courseType.value}s`)}
                                />
                              </label>
                              <ErrorMessage
                                name={`course`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>
                          )}
                        </div>

                        {/* subject field */}
                        <div>
                          <label className="tw-block mb-0">
                            <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                              {t("crud.placeholders.message.title")}
                            </div>

                            <Field
                              name="subject"
                              className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                              placeholder={t("crud.placeholders.message.title_placeholder")}
                              maxLength={180}
                            />
                          </label>
                          <ErrorMessage
                            name={`subject`}
                            component="div"
                            className="tw-text-xs tw-text-red-700 tw-h-4"
                          />
                        </div>

                        {/* body field */}
                        <div>
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                            {t("crud.placeholders.message.message")}
                          </div>

                          <SunEditorText
                            value={values.body}
                            setValue={(value) => {
                              setFieldValue("body", value);
                            }}
                            dirEditor={"rtl"}
                          />

                          <ErrorMessage name={`body`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
                        </div>

                        <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white disabled:tw-bg-teal-700/50"
                          >
                            {t("crud.placeholders.message.send")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                </form>
              )}
            </Formik>
          </div>
        </>
      </div>
    </>
  );
}

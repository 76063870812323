import moment from "moment";
import { useTranslation } from "react-i18next";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import classes from "./coursePartners.module.css";

const CoursePartnersTable = ({ partners, loading }) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      id: "num",
      name: "#",
      key: "num",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "photo",
      name: t("general.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["course-partners__table-photo"]}>
          {rowData.img ? (
            <img src={rowData.img} alt="" />
          ) : (
            <i className="fa fa-user" aria-hidden="true"></i>
          )}
        </div>
      ),
    },
    {
      id: "name",
      name: t("partner_name"),
      key: "name",
      cell: ({ rowData }) => (
        <>{i18n.language === "ar" ? rowData?.name_ar : rowData?.name_en}</>
      ),
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => (
        <a
          className={classes["course-partners__table-email"]}
          href={`mailto: ${rowData?.email}`}
        >
          {rowData?.email}
        </a>
      ),
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        // <>
        //   <div>{moment(rowData.created_at).format("DD/MM/YYYY")}</div>
        //   <div>{moment(rowData.created_at).format("hh:mm A")}</div>
        // </>
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
    },
  ];

  return (
    <MainTable columns={columns} data={partners || []} loading={loading} />
  );
};

export default CoursePartnersTable;

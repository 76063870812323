import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { downloadInvoice, fetchProgram, programsPayments } from "../../../Services/api/program/programContext";
import infoProgram from "../../../assets/image/info-program.png";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import ProgramHeader from "../ProgramHeader/ProgramHeader";

function TablePayment(props) {
    require("./TablePayment.css");
    const {t} = useTranslation();
    const history = useHistory();
    const {id} = useParams();

    const [programsPayment, setProgramsPayment] = useState([])
    const [program, setProgram] = useState(null);
    const [isContentLoaded, setIsContentLoaded] = useState(false);

    const fetchProgramDetails = () => {
        fetchProgram(id)
            .then((res) => {
                if (res.status && res.status == 200 && res.data.status) {
                    let lockUpcoming = false;
                    res.data.program.courses.forEach((item) => {
                        if (lockUpcoming) {
                            item.lock = true;
                        } else {
                            if (item.progress.progress == 0 || item.progress.progress != item.progress.total) {
                                lockUpcoming = true;
                                item.progressValue = item.progress.total == 0 ? '0%' : (item.progress.progress / item.progress.total * 100) + '%';
                            } else if (item.progress.progress == item.progress.total) {
                                item.done = true;
                            }
                        }
                    })
                    setProgram(res.data.program);
                    setIsContentLoaded(true);
                }
            })
            .catch((err) => {
                
                
            });
    }

    useEffect(() => {
        if(props.history.location.state && props.history.location.state.program) {
            setProgram(props.history.location.state.program)
        }else {
            fetchProgramDetails();
        }
        programsPayments(id, true)
            .then((res) => {
                if (res.status && res.status == 200 && res.data.status) {
                    setProgramsPayment(res.data.payments);
                    setIsContentLoaded(true);
                }
            })
            .catch((err) => {
                
                
            });
    }, []);


    return (
        <>
            <ProgramHeader label={program?.title} />
            <div className="container-fluid">
                <div className="table_payment_label">
                    <NavLink to="/">{t("Home")}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    <NavLink to={`/program/${id}`}>{program?.title}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    <NavLink to={`/register-program/${id}`}>{t("register_program")}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    {t("table_payment")}
                </div>

                <div
                    className="warning_msg"
                    style={{
                        width: "unset",
                        justifyContent: "start",
                    }}
                >
                    <img src={infoProgram} alt=""/>
                    <p>{t("programs.msg.table_payment")}</p>
                </div>

                {!isContentLoaded ? (
                    <div className="skeleton">
                        <SkeletonCard/>
                    </div>
                ) : (
                    <div className="table_payment">
                        <div className="table-responsive">
                            <table
                                className="table table-bordered"
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{t("programs.label.due_date")}</th>
                                    <th scope="col">{t("programs.label.amount")}</th>
                                    <th scope="col">{t("programs.label.status")}</th>
                                    <th scope="col">{t("programs.label.action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {programsPayment.map((payment, index) => (
                                    <tr key={payment.id}>
                                        <td>{index + 1}</td>
                                        <td>{payment.payment_date}</td>
                                        <td>
                                            {payment.amount} {t("Rial")}
                                        </td>
                                        <td>
                                            {!payment.is_paid
                                                ? t("programs.values.not_pay")
                                                : t("programs.values.paid")}
                                        </td>
                                        <td>
                                            {payment.is_paid == false && payment.order_id ==null ? (
                                                <button
                                                    className="table_payment_btn paymentNow"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        history.push(`/program/${id}/payment/${payment.id}`, {
                                                            program: program,
                                                            payment: payment
                                                        });
                                                    }}
                                                >
                                                    {t("programs.values.pay_now")}
                                                </button>
                                            ) : payment.is_paid == false && payment.order_id !=null ? (
                                                <button
                                                    className="table_payment_btn paymentNow"
                                                >
                                                    {t("programs.values.pending_payment")}
                                                </button>
                                            ) : (
                                                <button className="table_payment_btn receipt"
                                                        onClick={(e) => {
                                                            const link = document.createElement("a");
                                                            link.target = "_blank";
                                                            link.download = "program_payment.pdf"
                                                            downloadInvoice(payment.order_id).then((res)=> {
                                                                    link.href = URL.createObjectURL(
                                                                        new Blob([res.data], {type: "file/pdf"})
                                                                    );
                                                                    link.click();
                                                                }
                                                            ).catch((err)=>{
                                                                
                                                            })
                                                        }}
                                                >
                                                    {t("programs.values.show_details")}
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default TablePayment;

import {CustomButton, ButtonText, Spinner} from "./Button.Styles";


const Button = ({
                  text,
                  onClick = () => {
                  },
                  isGray,
                  width,
                  height,
                  borderRadius,
                  style = {},
                  isLoading,
                  link = ""
                }) => {
  return (
    <CustomButton
      to={link}
      as={link ? "" : "button"}
      disabled={isLoading}
      onClick={onClick}
      isGray={isGray}
      width={width}
      height={height}
      borderRadius={borderRadius}
      style={style}
    >
      <ButtonText loading={isLoading}>{text}</ButtonText>
      {isLoading && <Spinner />}
    </CustomButton>

  );
}

export default Button;
import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { refundssListConstants } from "../../Constants/refundSetting/refundSetting.constants";

// GET REFUNDS LIST
export const fetchRefundsList = ({ page = 1, perPage = 10, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: refundssListConstants.GET_REFUNDS_LIST_REQUEST,
    });

    await connector
      .get(env.refund_setting_list, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: refundssListConstants.GET_REFUNDS_LIST_SUCCESS,
            payload: {
              refunds: response?.data?.data,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: refundssListConstants.GET_REFUNDS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: refundssListConstants.GET_REFUNDS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

export const addRefunds = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: refundssListConstants.ADD_REFUND_REQUEST,
    });

    await connector
      .post(env.refund_setting_list, payload)
      .then((response) => {
        if (response?.status === 201 && response?.data?.status) {
          dispatch({
            type: refundssListConstants.ADD_REFUND_SUCCESS,
            payload: response,
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data.msg}
            </span>
          );
          dispatch({
            type: refundssListConstants.ADD_REFUND_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <>
            <span
              style={{
                fontSize: 13,
                fontWeight: "bold",
                display: "block",
                paddingBottom: "10px",
              }}
            >
              {err.response.data.msg}
            </span>
            <ul>
              {Object.keys(err.response.data.errors).map((key) => {
                return (
                  <li>
                    <span style={{ fontSize: 11 }}>
                      {err.response.data.errors[key]}
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
        dispatch({
          type: refundssListConstants.ADD_REFUND_REJECTED,
          payload: err,
        });
      });
  };
};

export const editRefunds = (id, payload) => {
  return async (dispatch) => {
    dispatch({
      type: refundssListConstants.EDIT_REFUND_REQUEST,
    });

    await connector
      .put(URLBuilder(env.refund_setting_list_id, { id: id }), payload, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: refundssListConstants.EDIT_REFUND_SUCCESS,
            payload: response,
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data.msg}
            </span>
          );
          dispatch({
            type: refundssListConstants.EDIT_REFUND_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        toast.error(
          <>
            <span
              style={{
                fontSize: 13,
                fontWeight: "bold",
                display: "block",
                paddingBottom: "10px",
              }}
            >
              {err.response.data.msg}
            </span>
            <ul>
              {Object.keys(err.response.data.errors).map((key) => {
                return (
                  <li>
                    <span style={{ fontSize: 11 }}>
                      {err.response.data.errors[key]}
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
        dispatch({
          type: refundssListConstants.EDIT_REFUND_REJECTED,
          payload: err,
        });
      });
  };
};

// DELETE REFUND
export const deleteRefund = (id) => {
  return async (dispatch) => {
    dispatch({ type: refundssListConstants.DELETE_REFUND_REQUEST });
    await connector
      .delete(URLBuilder(env.delete_refund_setting, { id: id }))
      .then((response) => {
        if (
          response.status &&
          response.status === 200 &&
          response.data.status
        ) {
          dispatch({
            type: refundssListConstants.DELETE_REFUND_SUCCESS,
            payload: { id },
          });
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: refundssListConstants.DELETE_REFUND_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: refundssListConstants.DELETE_REFUND_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};

// UPDATE REFUND STATUS
export const updateRefundStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: refundssListConstants.UPDATE_REFUND_STATUS_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.status_refund_setting, {
          id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: refundssListConstants.UPDATE_REFUND_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: refundssListConstants.UPDATE_REFUND_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: refundssListConstants.UPDATE_REFUND_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};

export function getRefundById(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.refund_setting_list_id, { id: id }))
      .then((res) => {
        dispatch({
          type: refundssListConstants.GET_REFUND_BY_ID,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
      });
  };
}

export function getReset() {
  return (dispatch) => {
    dispatch({
      type: refundssListConstants.GET_REFUNDS_RESET,
    });
  };
}

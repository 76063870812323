import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import IconTooltips from "../../../../Components/tooltips/IconsTooltip";
import DeleteModal from "../../../../Modals/DeleteModal/DeleteModal";
import { BlockedContext } from "../../../../Services/api/ipBlocked/ipBlockedContext";
import { blockCountries } from "../../../../Services/api/ipBlocked/ipBlockedProvider";
import deleteIcon from "../../../../assets/icons/delete.svg";
import ViewIcon from "../../../../assets/icons/eye.svg";
import FilterIcon from "../../../../assets/icons/filter.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";

import { useHistory } from "react-router";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";

import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteBlockById, getCountries } from "../../../../Redux/Actions/block/block.actions";
import DeleteIcon from "../../../../assets/image/alert-octagon.png";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import { actionsList } from "./actionsList";

function IpBlockedList({ setCurrentPage, filterData, setFilterData, applyFilterHandler, resetFilterHandler }) {
  require("./IpBlockedList.css");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const blockContext = useContext(BlockedContext);
  const [blockedId, setBlockedId] = useState("");
  const [titleName, setTitleName] = useState("");

  const [showFilter, setShowFilter] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const modalHandler = () => deleteModalRef.current.dismissModal();
  const deleteModalRef = useRef();
  const history = useHistory();

  const [isUpdating, setIsUpdating] = useState(false);
  const {
    allBlocksLoading,
    allBlocks,
    allBlocksMeta,
    allBlocksError,
    deleteBlockByIdLoading,
    deleteBlockByIdSuccess,
    deleteBlockByIdError,
    deleteBlockByIdMessage,
    deleteBlockByIdErrorMessage,
    countriesLoading,
    countries,
    countriesError,
    countriesSuccess,
    countriesErrorMessage,
  } = useSelector((state) => state.blockReducer);

  const deleteHandler = (id, titleName) => {
    // deleteModalRef.current.showModal();
    setTitleName(titleName);
    setBlockedId(id);
    setShowDeleteModal(true);
  };
  const deleteDate = (id) => {
    if (deleteBlockByIdLoading) return;
    dispatch(deleteBlockById(id));
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (!deleteBlockByIdSuccess) return;
  //   toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{deleteBlockByIdMessage}</span>, {
  //     autoClose: 1000,
  //   });
  //   setShowDeleteModal(false);
  //   dispatch(resetAllBlocksError());
  // }, [deleteBlockByIdSuccess]);

  // useEffect(() => {
  //   if (!deleteBlockByIdError) return;
  //   toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{deleteBlockByIdErrorMessage}</span>);
  //   setShowDeleteModal(false);
  //   dispatch(resetAllBlocksError());
  // }, [deleteBlockByIdError]);

  const getCountriesHandler = () => {
    dispatch(getCountries());
  };
  useEffect(() => {
    getCountriesHandler();
  }, []);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "range") {
      setFilterData({ ...filterData, [name]: value, ip: "", start_ip: "", end_ip: "", country: "" });
    } else {
      setFilterData({ ...filterData, [name]: value });
    }
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const filterFields = [
    {
      id: "name",
      component: "input",
      type: "text",
      placeholder: t("block.label.block_name"),
      label: t("block.label.block_name"),
      name: "name",
      value: filterData.name ?? "",
      onReset: resetHandler,
      classes: "col-12 col-md-6",
      onchange: changeHandler,
    },
    {
      id: "customizeBan",
      component: "select",
      placeholder: t("block.label.Customize__ban"),
      label: t("block.label.Customize__ban"),
      initialValue: t("block.label.Customize__ban"),
      name: "customizeBan",
      value: filterData.customizeBan ?? "",
      classes: "col-12 col-md-3",
      onReset: resetHandler,
      onchange: changeHandler,
      options: [
        {
          id: "site",
          value: "site",
          label: t("block.label.Restrict_platform"),
        },
        {
          id: "course",
          value: "course",
          label: t("block.label.Restricting_course_program"),
        },
      ],
    },
    {
      id: "range",
      component: "select",
      placeholder: t("block.label.Ban_scope"),
      label: t("block.label.Ban_scope"),
      initialValue: t("block.label.Ban_scope"),
      name: "range",
      value: filterData.range ?? "",
      classes: "col-12 col-md-3",
      onReset: resetHandler,
      onchange: changeHandler,
      options: [
        {
          id: "country",
          value: "country",
          label: t("block.label.country"),
        },
        {
          id: "IP",
          value: "ip",
          label: "IP",
        },
        {
          id: "IP-range",
          value: "iprange",
          label: "IP-range",
        },
      ],
    },
    filterData?.range === "ip" && {
      id: "ip",
      component: "input",
      type: "text",
      placeholder: "IP Address",
      label: "IP Address",
      name: "ip",
      value: filterData.ip ?? "",
      onReset: resetHandler,
      classes: "col-12 col-md-4",
      onchange: changeHandler,
    },
    filterData?.range === "country" && {
      id: "country",
      component: "select",
      placeholder: t("block.label.country"),
      label: t("block.label.country"),
      initialValue: t("block.label.country"),
      name: "country",
      value: filterData.country ?? "",
      classes: "col-12 col-md-4",
      onReset: resetHandler,
      onchange: changeHandler,
      options: [...countries],
    },
    filterData?.range === "iprange" && {
      id: "start_ip",
      component: "input",
      type: "text",
      placeholder: t("block.label.start_ip"),
      label: t("block.label.start_ip"),
      name: "start_ip",
      value: filterData.start_ip ?? "",
      onReset: resetHandler,
      classes: "col-12 col-md-4",
      onchange: changeHandler,
    },
    filterData?.range === "iprange" && {
      id: "end_ip",
      component: "input",
      type: "text",
      placeholder: t("block.label.end_ip"),
      label: t("block.label.end_ip"),
      name: "end_ip",
      value: filterData.end_ip ?? "",
      onReset: resetHandler,
      classes: "col-12 col-md-4",
      onchange: changeHandler,
    },
    {
      id: "desired_action",
      component: "select",
      placeholder: t("block.label.desired_action"),
      label: t("block.label.desired_action"),
      initialValue: t("block.label.desired_action"),
      name: "desired_action",
      value: filterData.desired_action ?? "",
      classes: "col-12 col-md-4",
      onReset: resetHandler,
      onchange: changeHandler,
      options: [
        {
          id: "allow",
          value: "allow",
          label: t("block.label.allow"),
        },
        {
          id: "block",
          value: "block",
          label: t("block.label.block"),
        },
      ],
    },
    {
      id: "allow_unblock",
      component: "select",
      placeholder: t("block.label.allow_unblock"),
      label: t("block.label.allow_unblock"),
      initialValue: t("block.label.allow_unblock"),
      name: "allow_unblock",
      value: filterData.allow_unblock ?? "",
      classes: "col-12 col-md-4",
      onReset: resetHandler,
      onchange: changeHandler,
      options: [
        {
          id: "0",
          value: "0",
          label: t("block.label.not_allowed"),
        },
        {
          id: "1",
          value: "1",
          label: t("block.label.allowed"),
        },
      ],
    },
    {
      id: "date",
      component: "input",
      type: "date",
      placeholder: t("block.label.date"),
      label: t("block.label.date"),
      name: "date",
      value: filterData.date ?? "",
      onReset: resetHandler,
      classes: "col-12 col-md-4",
      onchange: changeHandler,
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-3",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        resetFilterHandler();
      },
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-3",
      action: () => {
        applyFilterHandler();
      },
    },
  ];

  const displayBlock = {
    block: t("block.label.block"),
    allow: t("block.label.allow"),
  };

  const displayMethod = {
    site: t("block.label.site"),
    course: t("block.label.course_program"),
  };
  const displayRange = {
    country: t("block.label.country"),
    ip: "IP",
    iprange: "IP-Range",
  };
  const displayAllowUnblock = {
    0: t("block.label.not_allowed"),
    1: t("block.label.allowed"),
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData.id}</>,
    },
    {
      id: "name",
      name: t("block.table.name"),
      key: "name",
      cell: ({ rowData }) => <>{rowData.name}</>,
      sortable: false,
    },
    {
      id: "block_name",
      name: t("block.table.block_name"),
      key: "block_name",
      cell: ({ rowData }) => <>{displayBlock[rowData.action] || "-"}</>,
      sortable: false,
    },
    {
      id: "type",
      name: t("block.table.type"),
      key: "type",
      cell: ({ rowData }) => <>{displayMethod[rowData.method] || "-"}</>,
      sortable: false,
      tooltip: ({ rowData }) =>
        rowData.method === "course" ? `${rowData.course_name} - ${rowData.lesson_name}` : null,
    },
    {
      id: "range",
      name: t("block.table.range"),
      key: "range",
      cell: ({ rowData }) => <>{displayRange[rowData.range] || "-"}</>,
      sortable: false,
      tooltip: ({ rowData }) =>
        rowData.range === "country"
          ? rowData.country
          : rowData.range === "ip"
          ? rowData.ip
          : rowData.range === "iprange"
          ? `${rowData.start_ip} - ${rowData.end_ip}`
          : null,
    },
    {
      id: "created_at",
      name: t("block.table.date"),
      key: "created_at",
      cell: ({ rowData }) => <>{moment(rowData.created_at).format("YYYY-MM-DD")}</>,
      sortable: false,
    },
    {
      id: "allow_unblock",
      name: t("block.table.allow_unblock"),
      key: "allow_unblock",
      cell: ({ rowData }) => <>{displayAllowUnblock[rowData.allow_unblock] || "-"}</>,
      sortable: false,
    },
    // {
    //   id: "ranges",
    //   name: t("block.table.ranges"),
    //   key: "ranges",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.range === "ip"
    //         ? rowData?.ip
    //         : rowData.range === "iprange"
    //         ? `${rowData?.start_ip} - ${rowData?.end_ip}`
    //         : rowData?.range === "country"
    //         ? rowData?.country
    //         : null}
    //     </>
    //   ),
    //   sortable: false,
    // },
    {
      id: "actions",
      name: t("block.table.edit"),
      key: "actions",
      cell: ({ rowData }) => (
        <div
          className="release_icon"
          style={{
            alignItems: "unset",
          }}
        >
          <NavLink to={`/admin/ip-blocked/preview/${rowData.id}`}>
            <IconTooltips title={t("modal.coupon.buttons.preview")} content={<img src={ViewIcon} alt="" />} />
          </NavLink>
          <NavLink to={`/admin/ip-blocked/edit/${rowData.id}`}>
            <IconTooltips title={t("modal.coupon.buttons.edit")} content={<img src={editIcon} alt="" />} />
          </NavLink>
          <IconTooltips
            title={t("modal.coupon.buttons.delete")}
            content={
              <img
                style={{ cursor: "pointer" }}
                onClick={() => deleteHandler(rowData.id, rowData.name)}
                src={deleteIcon}
                alt=""
              />
            }
          />
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <BasicModal ref={deleteModalRef}>
        <DeleteModal
          label={t("coupon.label.delete_aprove")}
          title={t("modal.block.delete.title")}
          deleteMsg={t("modal.block.delete.body")}
          refuse={modalHandler}
          accept={() => deleteDate(blockedId)}
          deleteModalRef={deleteModalRef}
        />
      </BasicModal>
      {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
      <div className="admin_flex"></div>
      <MainBox>
        <div className="label_container" style={{ width: "100% !important" }}>
          <div className="titleAddFile">{t("block.label.block_list")}</div>
          <MainBoxHead
            actions={actionsList({
              addExhibition: () => {
                history.push(`/admin/ip-blocked/add`);
              },
              showFilter: () => {
                setShowFilter((prev) => !prev);
              },
            })}
          />
        </div>
        {showFilter && <FilterBox fields={filterFields} />}
        <MainTable columns={columns} data={allBlocks} isLoading={allBlocksLoading} />
        <Pagination
          count={allBlocksMeta?.totalPages || 0}
          showFirstButton
          showLastButton
          variant="outlined"
          shape="rounded"
          className="main-pagination"
          onChange={(e, page) => {
            setCurrentPage(page);
          }}
        />
        {showDeleteModal && (
          <SharedModal
            icon={DeleteIcon}
            title={t("modal.block.delete.body")}
            name={titleName}
            confirmMsg={t("block.massages.delete_confirmMsg")}
            confirmText={t("modal.common.confirm")}
            cancelText={t("modal.common.cancel")}
            onCancel={() => {
              setShowDeleteModal(false);
            }}
            onConfirm={() => {
              deleteDate(blockedId);
            }}
            isLoading={deleteBlockByIdLoading}
          />
        )}
      </MainBox>
    </>
  );
}

export default IpBlockedList;

import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { taxsListConstants } from "../../Constants/Tax/taxList.constants";

// GET TAXS LIST
export const fetchTaxsList = ({ page = 1, perPage = 10, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: taxsListConstants.GET_TAXS_LIST_REQUEST,
    });

    await connector
      .get(env.tax, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: taxsListConstants.GET_TAXS_LIST_SUCCESS,
            payload: {
              taxs: response?.data?.data,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: taxsListConstants.GET_TAXS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: taxsListConstants.GET_TAXS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

// fetchVatTax
export const fetchVatTax = () => {
  return async (dispatch) => {
    dispatch({
      type: taxsListConstants.GET_VAT_TAX_REQUEST,
    });

    await connector
      .get(env.vat_tax)
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: taxsListConstants.GET_VAT_TAX_SUCCESS,
            payload: {
              vatTax: response?.data?.vat,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: taxsListConstants.GET_VAT_TAX_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: taxsListConstants.GET_VAT_TAX_REJECTED,
          payload: err,
        });
      });
  };
};

// DELETE TAX
export const deleteTax = (id) => {
  return async (dispatch) => {
    dispatch({ type: taxsListConstants.DELETE_TAX_REQUEST });
    await connector
      .delete(URLBuilder(env.delete_tax, { id: id }))
      .then((response) => {
        if (response.status && response.status === 200 && response.data.status) {
          dispatch({
            type: taxsListConstants.DELETE_TAX_SUCCESS,
            payload: { id },
          });
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: taxsListConstants.DELETE_TAX_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: taxsListConstants.DELETE_TAX_REJECTED,
          payload: err.response.data.msg,
        });
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      });
  };
};

// UPDATE TAX STATUS
export const updateTaxStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: taxsListConstants.UPDATE_TAX_STATUS_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.tax_status_update, {
          id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: taxsListConstants.UPDATE_TAX_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: taxsListConstants.UPDATE_TAX_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: taxsListConstants.UPDATE_TAX_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};

import { useTranslation } from "react-i18next";
import AddIcon from "../../../assets/icons/plus.svg";
import DeleteIcon from "../../../assets/icons/remove-gray.svg";
import SearchInput from "./SearchInput";
import classes from "./saveSearchModal.module.css";
import searchClasses from "./search.module.css";

const SearchSaveEmails = ({ onEmailChange, emailValue, emailError, emails, addEmailHandler, deleteEmailHandler }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classes["search__box__item__email"]}>
      <div className={classes["search__box__item__email-container"]}>
        <div className={searchClasses["search__box__item-label"]}>{t("search.email")}</div>
        <div className={classes["search__box__item__email-field"]}>
          <SearchInput
            name={"email"}
            placeholder={t("search.email")}
            type={"text"}
            value={emailValue}
            onChange={(value) => {
              onEmailChange(value);
            }}
          />
          <button className={classes["search__box__item__email-field-btn"]} onClick={addEmailHandler} type="button">
            <img src={AddIcon} alt="" />
          </button>
        </div>
        <p className={`form-input-error-space`}>{emailError && emailError}</p>
      </div>
      <div className={classes["search__box__item__emails"]}>
        <ul className={classes["search__box__item__emails-list"]}>
          {emails.map((email, index) => (
            <li key={email} className={classes["search__box__item__emails-list-item"]}>
              <div className={classes["search__box__item__emails-list-item-text"]}>{email}</div>
              <div
                className={classes["search__box__item__emails-list-item-icon"]}
                onClick={() => deleteEmailHandler(email)}
              >
                <img src={DeleteIcon} alt="" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchSaveEmails;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LoadingSpinner from "../../Shared/Components/LoadingSpinner/LoadingSpinner";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import classes from "./Ads.module.css";
import { getAdHome } from "../../../Services/api/HomeCoursesProvider";
import AdCard from "./AdCard";
import { Pagination } from "@mui/material";

export default function AdvertismentsHome() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [adList, setAdList] = useState([]);
  const [meta, setMeta] = useState({});
  const [adListLoading, setAdListLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // setTrainersLoading(true);
    const apiCall = getAdHome;

    apiCall(10, currentPage)
      .then((res) => {
        if (res.status && res.status === 200) {
          setAdList(res.data.data);
          setMeta(res.data.meta);

          setAdListLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setAdListLoading(true);
      });
  }, [currentPage]);
  return (
    <div className="container-fluid">
      <div className="row" style={{ margin: "40px 0" }}>
        <div
          style={{
            display: "flex",
            gap: "15px",
            flex: "2 1 auto",
            alignItems: "center",
          }}
        >
          <p className={classes["title"]}>{t("Ads")}</p>
        </div>
      </div>
      <MainBox>
        {!adListLoading ? (
          <div className={classes["loading__container"]}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className={classes.cardsCont}>
              {adList.map((adv) => (
                <AdCard adv={adv} />
              ))}
            </div>
          </>
        )}
        <Pagination
          count={meta?.last_page || 0}
          showFirstButton
          showLastButton
          variant="outlined"
          shape="rounded"
          className="main-pagination"
          onChange={(e, page) => {
            setCurrentPage(page);
          }}
          page={currentPage}
        />
      </MainBox>{" "}
    </div>
  );
}

import React from 'react'
import classes from "./bootDetails.module.css"
const BootDetailsPref = ({ title, description }) => {
  return (
    <div className={classes["boot-details__pref"]}>
      <h2 className={classes["boot-details__pref-title"]}>{title}</h2>
      <div
        className={classes["boot-details__pref-desc"]}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  )
}

export default BootDetailsPref

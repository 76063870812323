import Box from "@mui/material/Box";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Select from "react-select";
import { toast } from "react-toastify";
import QuestionTypeEnum from "../../../../../../../Enums/QuestionTypeEnum";
import { AddQuestionToQuestionBankContext } from "../../../../../../../Providers/AddQuestionToQuestionBankProvider";
import { TrainerAddExamContext } from "../../../../../../../Providers/TrainerAddExamProvider";
import { addAttachementFileToQuestion } from "../../../../../../../Services/api/exams/ExamsProvider";
import FileSection from "../FileSection/FileSection";

function HotSpotQuestion (props) {
    require("./HotSpotQuestion.css");

    const {t} = useTranslation();
    const wrapperContext = useContext(props.renderedAs === 'questionBank' ? AddQuestionToQuestionBankContext : TrainerAddExamContext);

    const [toBeShownImage, setToBeShownImage] = useState(null);
    const inputFile = useRef(null);

    useEffect(() => {
        if (wrapperContext.isEdit) {
            setToBeShownImage(JSON.parse(wrapperContext.question.data)['spot']['media']);
        }
    }, []);

    const levels = [
      {
        id: 1,
        name: t("trainer.quiz.crud.easy"),
      },
      {
        id: 2,
        name: t("trainer.quiz.crud.medium"),
      },
      {
        id: 3,
        name: t("trainer.quiz.crud.hard"),
      },
    ];

    return (
        <>
            <Formik
                initialValues={{
                    title: wrapperContext.isEdit ? wrapperContext.question.title : "",
                    degree: wrapperContext.isEdit ? wrapperContext.question.degree : "",
                    level: wrapperContext.isEdit ? wrapperContext.question.level : "",
                    media_id: wrapperContext.isEdit && wrapperContext.question.media && wrapperContext.question.media ? wrapperContext.question.media.id : "",
                    spot: wrapperContext.isEdit ? JSON.parse(wrapperContext.question.data)['spot'] : {
                        noSelectionDone: true,
                        unit: '%', // Can be 'px' or '%'
                        x: 0,
                        y: 0,
                        width: 0,
                        height: 0
                    },
                    feedback: wrapperContext.isEdit ? wrapperContext.question.feedback : "",
                    course_objectives: wrapperContext.isEdit ? wrapperContext.question.course_objectives : [],
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    let payload = {
                        title: values.title,
                        degree: values.degree,
                        level: values.level,
                        options:{
                            spot: values.spot
                        },
                        media_id: values.media_id,
                        feedback: values.feedback,
                        course_objectives: values.course_objectives,
                    }

                    if (values.image) {
                        let formData = new FormData();
                        formData.append('file', values.image);
                        addAttachementFileToQuestion(formData).then(async (res) => {
                            
                            payload.options.spot.media = res.data.response.link;
                            payload.options.height = res.data.response.height;
                            payload.options.width = res.data.response.width;
                            try{
                                await props.onSubmit(payload);
                                setSubmitting(false);
                            }catch (err) {
                                setSubmitting(false);
                            }
                        }).catch((err) => {
                            
                            setSubmitting(false);
                            toast.error(
                                <span style={{fontSize: 13, fontWeight: "bold"}}>
                                    {t("trainer.quiz.alerts.cannot_add_image")}
                                </span>
                            );
                        });
                    } else {
                        await props.onSubmit(payload);
                        setSubmitting(false);
                    }
                }}
                validateOnChange={false}
                validate={(values) => {
                    const errors = {};

                    if (!values.title) {
                        errors.title = t("crud.errors.required");
                    }


                    if (!values.degree) {
                        errors.degree = t("crud.errors.required");
                    }

                    if (!values.level) {
                      errors.level = t("crud.errors.required");
                    }

                    if (!Array.isArray(values.course_objectives) || values.course_objectives.length == 0) {
                        errors.course_objectives = t("crud.errors.required");
                    }

                    if (toBeShownImage == null) {
                        errors.image = t("crud.errors.required");
                        toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                {t("trainer.quiz.crud.validation.question_bank.add.no_image_selected")}
                            </span>
                        );
                    } else if (values.spot.noSelectionDone) {
                        errors.image = t("crud.errors.required");
                        toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                {t("trainer.quiz.crud.validation.question_bank.add.no_spot_selected")}
                            </span>
                        );
                    }

                    return errors;
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      setErrors,
                      setSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} className="admin_add_form">
                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <label htmlFor="title" className="admin_add_label">
                                        {t("trainer.quiz.crud.question_title")}
                                    </label>
                                    <input
                                        value={values.title}
                                        onChange={(elem) =>
                                            setFieldValue('title',elem.target.value)
                                        }
                                        placeholder={t(
                                            "trainer.quiz.crud.input.question_title"
                                        )}
                                        className="admin_filter_input"
                                    />
                                    <p className={"form-input-error-space"}>
                                        {errors.title ? errors.title : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <FileSection
                                        mediaId={values.media_id}
                                        setMediaId={(mediaId) => {
                                            setFieldValue('media_id', mediaId);
                                        }}
                                        setSubmitting={setSubmitting}
                                        typeId={QuestionTypeEnum.hotspot}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <label className="admin_add_label">
                                        {t("trainer.quiz.crud.grade")}
                                    </label>
                                    <input
                                        value={values.degree}
                                        onChange={(elem) =>
                                            setFieldValue('degree',elem.target.value)
                                        }
                                        placeholder={t(
                                            "trainer.quiz.crud.input.grade"
                                        )}
                                        className="admin_filter_input"
                                    />
                                    <p className={"form-input-error-space"}>
                                        {errors.degree ? errors.degree : null}
                                    </p>
                                </div>
                            </div>
                        </div>
            
            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label htmlFor="level" className="admin_add_label">
                    {t("trainer.quiz.crud.level")}
                  </label>
                  <Select
                    name="level"
                    id="level"
                    // isClearable={true}
                    // isMulti={true}
                    value={levels.filter((item) => values.level == item.id ? true :  false)}
                    options={levels}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(item) => {
                      setFieldValue("level", item.id);
                    }}
                    placeholder={t("trainer.quiz.crud.select.level")}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.level ? errors.level : null}
                  </p>
                </div>
              </div>
            </div>

                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <label htmlFor="name_en" className="admin_add_label">
                                        {t("trainer.quiz.crud.objectives")}
                                    </label>
                                    <Select
                                        name="course_objectives"
                                        id="course_objectives"
                                        isClearable={true}
                                        isMulti={true}
                                        defaultValue={values.course_objectives}
                                        options={wrapperContext.objectives}
                                        getOptionLabel ={(option)=>option.name}
                                        getOptionValue ={(option)=>option.id}
                                        onChange={(items) => {
                                            const courseObjectives = [];
                                            items.forEach(item => {
                                                courseObjectives.push(item.id);
                                            })
                                            setFieldValue('course_objectives', courseObjectives);
                                        }}
                                        placeholder={t("trainer.quiz.crud.select.objectives")}/>
                                    <p className={"form-input-error-space"}>
                                        {errors.course_objectives ? errors.course_objectives : null}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <label className="admin_add_label">
                                        {t("trainer.quiz.crud.negative_feedback")}
                                    </label>
                                    <input
                                        value={values.feedback}
                                        onChange={(elem) =>
                                            setFieldValue('feedback',elem.target.value)
                                        }
                                        placeholder={t(
                                            "trainer.quiz.crud.input.negative_feedback"
                                        )}
                                        className="admin_filter_input"
                                    />
                                    <p className={"form-input-error-space"}>
                                        {errors.feedback ? errors.feedback : null}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: 20,
                                        flexDirection:'column',
                                        border: 1,
                                        width:"100%"
                                    }}>
                                        {
                                            !toBeShownImage?
                                                <>
                                                    <button
                                                        type={"button"}
                                                        className="admin_add_button"
                                                        onClick={() => {
                                                            if (inputFile) {
                                                                inputFile.current.click();
                                                            }
                                                        }}>
                                                        {t("crud.placeholders.add_image")}
                                                    </button>
                                                    <input
                                                        id="file"
                                                        name="file"
                                                        type="file"
                                                        ref={inputFile}
                                                        style={{ display: "none" }}
                                                        onChange={(event) => {
                                                            let file = event.target.files[0];
                                                            let reader = new FileReader();
                                                            reader.readAsDataURL(file);
                                                            reader.onload = () => {
                                                                setFieldValue("image", event.target.files[0]);
                                                                setToBeShownImage(reader.result);
                                                            };
                                                            reader.onerror = function (error) {
                                                                
                                                            };
                                                        }}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <ReactCrop crop={values.spot} onChange={(pixelSelection, percentageSelection) => {
                                                        setFieldValue('spot', percentageSelection)
                                                    }}>
                                                        <img src={toBeShownImage} />
                                                    </ReactCrop>
                                                </>
                                        }
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{
                                    width:"95%",
                                    display:"flex",
                                    flexDirection:"row"
                                }}>
                                    <button
                                        type="submit"
                                        className="admin_add_button"
                                        disabled={isSubmitting}
                                    >
                                        {t("crud.placeholders.save")}
                                    </button>

                                    {
                                        props.renderedAs !== 'questionBank' ?
                                            <button
                                                type="button"
                                                className="admin_add_button"
                                                disabled={isSubmitting}
                                                style={{
                                                    backgroundColor: '#CDCDCD',
                                                    color: '#000000',
                                                    marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                                    marginLeft: document.body.dir == 'ltr' ? 10 : 0
                                                }}
                                                onClick={() => {
                                                    wrapperContext.setActivePanel(0);
                                                }}
                                            >
                                                {t("crud.placeholders.previous")}
                                            </button> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default HotSpotQuestion;

import React, {createContext, useState} from "react";

const BlockedContext = createContext();

function BlockedProvider(props) {
  const [blocked, setBlocked] = useState([]);

  return (
    <BlockedContext.Provider value={{ blocked, setBlocked }}>
      {props.children}
    </BlockedContext.Provider>
  );
}

export { BlockedContext, BlockedProvider };

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MacImage from "../../../../assets/image/Mac2.png";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import classes from "./newHomeExplore.module.css";
import SkeletonSpinnerOverlay from "../../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import SharedSlider from "../../../Shared/SharedSlider/SharedSlider";
import Icon_1_1 from "../../../../assets/icons/explore1.svg";
import Icon_1_2 from "../../../../assets/icons/explore2.svg";
import Icon_2_1 from "../../../../assets/icons/explore3.svg";
import Icon_2_2 from "../../../../assets/icons/explore4.svg";
import Icon_3_1 from "../../../../assets/icons/explore5.svg";
import Icon_3_2 from "../../../../assets/icons/explore6.svg";
import NewHomeExploreCard from "./NewHomeExploreCard/NewHomeExploreCard";
import { useEffect, useState } from "react";
import "../Categories/categories.css";

const NewHomeExplore = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  const list = [
    {
      id: 1,
      text_one: t("Certified_medical_programs"),
      icon_one: Icon_1_1,
      text_two: t("Gifts_and_coupons_for_partners"),
      icon_two: Icon_1_2,
    },
    {
      id: 2,
      text_one: t("Attendance_or_accredited_certificates"),
      icon_one: Icon_2_1,
      text_two: t("Virtual_exhibitions_for_sponsors"),
      icon_two: Icon_2_2,
    },
    {
      id: 3,
      text_one: t("Interactive_and_live_training_sessions"),
      icon_one: Icon_3_1,
      text_two: t("Specialized_support_throughout_the_week"),
      icon_two: Icon_3_2,
    },
    // {
    //   id: 3,
    //   text_one: "Mutual Fund Schemes and Types",
    //   icon_one: Icon_3_1,
    //   text_two: "Transactions in Mutual Funds",
    //   icon_two: Icon_3_2,
    // },
  ];

  return (
    <>
      {" "}
      {
        <>
          <div className={classes.trainersSectionCont}>
            <div className={classes.categories}>
              <h2 className={classes.sectionTitle}>
                {t("learn_with")}&nbsp;
                <span>TEBx</span>
              </h2>
              {list.length <= 3 && width > 1200 ? (
                <div className={classes.staticCont}>
                  {list.map((cardContant, index) => (
                    <NewHomeExploreCard
                      cardContant={cardContant}
                      key={index}
                      isThree={true}
                      index={index}
                      totalCards={list.length}
                    />
                  ))}
                </div>
              ) : (
                <>
                  <SharedSlider
                    slidesToShow={7}
                    slidesToScroll={7}
                    dots={true}
                    noArrows={true}
                    classes={`${classes.slider} categories-slider`}
                    responsive={[
                      {
                        breakpoint: 2000,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                      {
                        breakpoint: 1920,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                      {
                        breakpoint: 1600,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                      {
                        breakpoint: 1400,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3,
                        },
                      },
                      {
                        breakpoint: 1200,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                        },
                      },
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2,
                        },
                      },
                      {
                        breakpoint: 650,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                        },
                      },
                      {
                        breakpoint: 380,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                        },
                      },
                    ]}
                  >
                    {list.map((cardContant, index) => (
                      <NewHomeExploreCard
                        cardContant={cardContant}
                        key={index}
                        index={index}
                        totalCards={list.length}
                      />
                    ))}
                  </SharedSlider>
                </>
              )}
            </div>
          </div>
        </>
      }
    </>
  );
};

export default NewHomeExplore;

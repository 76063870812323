import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./sharedSlider.css";
const SharedSlider = (props) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current && props.initialSlide) {
      sliderRef.current.slickGoTo(props.initialSlide);
    }
  }, [props.initialSlide]);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} slick-custom-btn next`}
        onClick={onClick}
        type="button"
        style={style}
      >
        <ArrowForwardIcon sx={{ color: "#24B3B9" }} />
      </button>
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button
        className={`${className} slick-custom-btn prev`}
        onClick={onClick}
        type="button"
        style={style}
      >
        <ArrowBackIcon sx={{ color: "#24B3B9" }} />
      </button>
    );
  };

  const settings = {
    initialSlide: props.initialSlide || 0,
    dots: props.dots || false,
    infinite: false,
    speed: 500,
    slidesToShow: props.slidesToShow || 5,
    slidesToScroll: props.slidesToScroll || 5,
    arrows: props.noArrows ? false : true,
    nextArrow: props.isCustomButtons ? <NextArrow /> : null,
    prevArrow: props.isCustomButtons ? <PrevArrow /> : null,
    appendDots: props.dots ? (dots) => <ul>{dots}</ul> : null,
    customPaging: props.dots ? () => <div className="custom-dot"></div> : null,
    ref: sliderRef,
    rtl: props.direction === "rtl" ? true : false,
    responsive: props.responsive || [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: props.slidesToShow || 4,
          slidesToScroll: props.slidesToScroll || 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: props.slidesToShow || 3,
          slidesToScroll: props.slidesToScroll || 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: props.slidesToShow || 2,
          slidesToScroll: props.slidesToScroll || 2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: props.slidesToShow || 1,
          slidesToScroll: props.slidesToScroll || 1,
        },
      },
    ],
  };
  return (
    <Slider
      {...settings}
      className={`shared-slider ${props.classes}`}
      style={props.style}
    >
      {props.children}
    </Slider>
  );
};

export default SharedSlider;

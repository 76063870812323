import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import toLocalTimezone, { toServerTime } from "../Services/Timezone";
import {
  editQuiz,
  getCourseObjectives,
  getCourseTrainees,
  getExamData,
  getQuestion,
  getQuestionTypes
} from "../Services/api/exams/ExamsProvider";

export const TrainerAddExamContext = React.createContext();

export function TrainerAddExamProvider(props) {
    const [activePanel, setActivePanel] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [objectives, setObjectives] = useState([]);
    const [trainees, setTrainees] = useState([]);
    const [selectedTrainees, setSelectedTrainees] = useState([]);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [selectedQuestionPanel, setSelectedQuestionPanel] = useState(0);
    const [examPayload, setExamPayload] = useState({});
    const [quizable, setQuizable] = useState("");
    const [editable, setEditable] = useState(false);
    const [addedQuestions, setAddedQuestions] = useState([]);
    const [selectedQuestionBankQuestions, setSelectedQuestionBankQuestions] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [question, setQuestion] = useState({});

    useEffect(() => {
        setSelectedQuestionPanel(0);
    }, [activePanel])

    const submitExamEdits = (payload = null, id) => {
        const myPromise = new Promise((resolve, reject) => {
            const questionBankItems = [];
            let order = 1;
            selectedQuestionBankQuestions.forEach(item => {
                questionBankItems.push({
                    question_id: item.id,
                    degree: item.degree,
                    order: order
                })
                order++;
            })

            const examPayloadInstance = examPayload;
            examPayloadInstance.questions = addedQuestions.filter(i => i.id).map(i => i.id);

            if (payload != null) {
                if(isEdit) {
                    examPayloadInstance.updated_questions = [{id: question.id, data: payload}];
                } else {
                    examPayloadInstance.new_questions = [payload];
                }
            }
            examPayloadInstance.questions_bank = questionBankItems;
            examPayloadInstance.start_date = toServerTime(examPayloadInstance.start_date)
            examPayloadInstance.end_date = toServerTime(examPayloadInstance.end_date)
            editQuiz(examPayloadInstance, id).then(res => {
                setIsEdit(false);
                setQuestion({})
                resolve(res);
            }).catch(err => {
                toast.error(
                    <span style={{fontSize: 13, fontWeight: "bold"}}>
                        {err.data.msg}
                    </span>
                )
                reject(err.response);
            })
        });

        return myPromise;
    }

    const fetchCourseObjectives = (id) => {
        const myPromise = new Promise((resolve, reject) => {
            getCourseObjectives(id).then(res => {
                setObjectives(res.data.data.objectives.data);
                resolve();
            }).catch(error => {
                
                reject();
            })
        });

        return myPromise;
    }

    const fetchCourseTrainees = (id) => {
        const myPromise = new Promise((resolve, reject) => {
            getCourseTrainees(id).then(res => {
                setTrainees(res.data.response);
                resolve();
            }).catch(error => {
                
                reject();
            })
        });

        return myPromise;
    }

    const fetchExamData = (id) => {

        const myPromise = new Promise((resolve, reject) => {
            getExamData(id).then(res => {
                const quizData = res.data.quiz;
                setExamPayload({
                    title: quizData.title,
                    show_mark: quizData.show_mark,
                    show_mark_condition: quizData.show_mark_condition,
                    show_mark_condition_date: quizData.show_mark_condition_date,
                    description: quizData.description,
                    start_date: toLocalTimezone(quizData.start_date),
                    end_date: toLocalTimezone(quizData.end_date),
                    period: quizData.period,
                    answer_attempts: quizData.answer_attempts,
                    random: quizData.random,
                    can_back: quizData.can_back,
                    course_objectives: quizData.course_objectives.map((item) => item.id),
                    trainees: quizData.trainees.map((item) => item.id),
                    success_rate: quizData.success_rate,
                });
                setAddedQuestions(quizData.added_questions);
                setSelectedTrainees(quizData.trainees.map((item) => item.id))
                resolve(quizData);
            }).catch(error => {
                
                reject();
            })
        });

        return myPromise;
    }

    const fetchQuestionTypes = () => {
        const myPromise = new Promise((resolve, reject) => {
            getQuestionTypes().then(res => {
                setQuestionTypes(res.data.types);
                resolve();
            }).catch(error => {
                
                reject();
            })
        });

        return myPromise;
    }

    const fetchQuestionData = (id) => {
        const myPromise = new Promise((resolve, reject) => {
            getQuestion(id).then(res => {
                
                setQuestion(res.data.Question);
                resolve();
            }).catch(error => {
                
                reject();
            })
        });

        return myPromise;
    }

    return (
        <TrainerAddExamContext.Provider
            value={{
                activePanel,
                setActivePanel,
                isLoading,
                setIsLoading,
                objectives,
                setObjectives,
                questionTypes,
                setQuestionTypes,
                selectedQuestionPanel,
                setSelectedQuestionPanel,
                examPayload,
                setExamPayload,
                quizable,
                setQuizable,
                selectedQuestionBankQuestions,
                setSelectedQuestionBankQuestions,
                editable,
                setEditable,
                addedQuestions,
                setAddedQuestions,
                isEdit,
                setIsEdit,
                question,
                setQuestion,
                trainees,
                setTrainees,
                selectedTrainees,
                setSelectedTrainees,
                fetchCourseObjectives,
                fetchQuestionTypes,
                fetchCourseTrainees,
                fetchExamData,
                submitExamEdits,
                fetchQuestionData
            }}
        >
            {props.children}
        </TrainerAddExamContext.Provider>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";

import Header from "./Header/Header";
import CourseVideo from "../Profile/CourseVideo/CourseVideo";
import Content from "./Content/Content";

import { useParams } from "react-router";
import { CourseProfileProvider } from "../../../../Services/api/courses/Trainer/CourseProfileContext";
import NewContent from "./NewContent/NewContent";

const EduGoals = ({ role }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <CourseProfileProvider>
        {/* <Header /> */}

        <>
          <div className="training-course-syllabus-section">
            {/*<Content/>*/}
            <NewContent role={role} />
          </div>
          {/*<CourseVideo courseID={id} />*/}
        </>
      </CourseProfileProvider>
    </>
  );
};

export default EduGoals;

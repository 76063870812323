import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { boothActions } from "../../../../Redux/Actions/Booth/booth.actions";
import { exportVisitsBooth } from "../../../../Services/api/Exhibition/ExhibitionProvider";
import { getCountries } from "../../../../Services/api/profile/profileProvider";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import TableHeadActions from "../../../Shared/Components/TableHeadActions/TableHeadActions";
import { filterActions } from "../ExhibitionDetails/ExhibitionDetailsTableAction";
import VisitsTable from "./VisitsTable";
import classes from "./bootDetails.module.css";

const BootDetailsVisitsTable = ({ id, tableData, loading, setIsExportLoading }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [dataToExport, setDataToExport] = useState([]);
  const [countries, setCountries] = useState([]);
  const language = localStorage.getItem("i18nextLng");

  const [filterData, setFilterData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
  });

  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountries(res.data.countries);
      })
      .catch((err) => {});
  }, []);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };
  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = (e) => {
    setFilterData({
      name: "",
      email: "",
      phone: "",
      country: "",
    });
    setDataToExport([]);
    setIsResetApplied(true);
  };

  useEffect(() => {
    if (isResetApplied) {
      getVisitsList();
      setIsResetApplied(false);
    }
  }, [isResetApplied]);

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getBodyData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.email && (filter.email = filterData.email);
    filterData.mobile && (filter.mobile = filterData.mobile);
    filterData.country && (filter.country = filterData.country);
    sortColumn && (filter.sort_column = sortColumn);
    sortOrder && (filter.sort_order = sortOrder);

    return filter;
  };

  const getVisitsList = async () => {
    const bodyData = getBodyData();
    setDataToExport(bodyData);

    dispatch(boothActions.getBoothVisitors({ id: id, perPage: 10, page: 1, filter: bodyData }));
  };

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("booth.user_name"),
      type: "text",
      placeholder: t("booth.user_name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-lg-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "email",
      component: "input",
      label: t("booth.email"),
      type: "email",
      placeholder: t("booth.email"),
      name: "email",
      value: filterData.email,
      classes: "col-12 col-md-6 col-lg-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "mobile",
      component: "input",
      label: t("booth.phone"),
      type: "text",
      placeholder: t("booth.phone"),
      name: "mobile",
      value: filterData.mobile,
      classes: "col-12 col-md-6 col-lg-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "country",
      component: "select",
      label: t("country"),
      placeholder: t("country"),
      initialValue: t("block.label.country"),
      name: "country",
      value: filterData.country ?? "",
      classes: "col-12 col-md-6 col-lg-4",
      onchange: changeHandler,
      onReset: resetHandler,
      options: countries.map((country) => ({
        id: country.id,
        value: language === "ar" ? country.name_ar : country.name_en,
        label: language === "ar" ? country.name_ar : country.name_en,
      })),
    },

    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-2 col-lg-4  offset-xs-0 offset-xl-4 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6 col-lg-4   col-xl-2",
      action: () => {
        setCurrentPage(1);
        getVisitsList();
      },
    },
  ];

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    await exportVisitsBooth({ id: id, filter: dataToExport, type: type })
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        setIsExportLoading(false);
      });
  };

  return (
    <div className={classes["boot-details__tables"]}>
      <div className={classes["boot-details__tables__header"]}>
        <h3>{t("booth.visitors_list")}</h3>

        <TableHeadActions
          actions={filterActions({
            showFilter: () => {
              setShowFilter((prev) => !prev);
            },
            addExhibition: () => {},
            exportPdf: () => {
              exportHandler("pdf");
            },
            exportExcel: () => {
              exportHandler("XLSX");
            },
          })}
        />
      </div>
      {showFilter && <FilterBox fields={filterFields} />}
      {!tableData.data || loading ? (
        <div
          style={{
            height: "50vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <>
          <VisitsTable tableData={tableData.data} sortActionHandler={sortActionHandler} />
          <Pagination
            count={tableData.meta?.totalPages || 0}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
            className="main-pagination"
            onChange={(e, page) => {
              setCurrentPage(page);
              dispatch(
                boothActions.getBoothVisitors({
                  id: id,
                  perPage: 10,
                  page: page,
                  filter: getBodyData(),
                })
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export default BootDetailsVisitsTable;

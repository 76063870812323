import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import IconTooltips from "../../Components/tooltips/IconsTooltip";
import SponsorModal from "../../Modals/Sponsor/Sponsor";
import { BasicModal } from "../Shared/Components/Modal/BasicModal";
import { getMySponsors } from "./../../Services/api/sponsor/SponsorProvider";
import "./sponsor.css";

export default function Sponsor() {
  const { t } = useTranslation();
  const history = useHistory();
  const [traineeModal, setTraineeModal] = useState(false);
  const [traineeId, setTraineeId] = useState("");
  const [sponsors, setSponsors] = useState([]);
  const modalRef = useRef();
  const [id, setIds] = useState(null);
  useEffect(() => {
    getMySponsors()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setSponsors(res.data.data);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  const openTraineesModal = (sponsor) => {
    setTraineeId(sponsor.id);
    setTraineeModal(true);
  };

  const modalHandler = () => {
    modalRef.current.dismissModal();
  };

  const sponsorTrainee = (payload) => {
    setIds(payload);
    setTimeout(() => {
      modalRef.current.showModal();
    }, 200);
  };
  return (
    <div>
      <BasicModal ref={modalRef}>
        <SponsorModal
          title={t("sponsor.traineeName")}
          id={id}
          refuse={modalHandler}
          modalRef={modalRef}
          justShow={true}
        />
      </BasicModal>
      <div className="container-fluid profile_wrapper">
        <div className="row">
          <div className="col-12">
            <div className="table_bank_tran">
              <h3 className="table_header"> {t("sponsor.Title")}</h3>
              <div
                className="table-responsive"
                style={{
                  width: "90%",
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">{t("sponsor.Type")}</th>
                      <th scope="col">{t("sponsor.Date")}</th>
                      <th scope="col">{t("sponsor.Course_Program")}</th>
                      <th scope="col">{t("sponsor.Number")}</th>
                      <th scope="col">{t("sponsor.trainees.title")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sponsors.length == 0 ? (
                      <tr>
                        <td
                          colSpan={6}
                          style={{ fontSize: "20px", textAlign: "center" }}
                        >
                          {t("no_record")}
                        </td>
                      </tr>
                    ) : (
                      sponsors.map((sponsor, index) => (
                        <tr key={index}>
                          <td>
                            {sponsor.program == null ? "Course" : "Program"}
                          </td>
                          <td>{sponsor.accreditation_at.split(" ")[0]}</td>
                          <td>
                            {sponsor.program == null
                              ? sponsor.course.name
                              : sponsor.program.title}
                          </td>
                          <td>{sponsor.actual_trainee_count}</td>
                          <td scope="col">
                            <div className="release_icon">
                              <IconTooltips
                                title={t("modal.coupon.buttons.data")}
                                content={
                                  <i
                                    className="fal fa-eye"
                                    onClick={() => sponsorTrainee(sponsor.id)}
                                    style={{
                                      marginLeft: 5,
                                      marginRight: 5,
                                      backgroundColor: "white",
                                      borderWidth: 0,
                                      fontSize: 20,
                                      color: "black",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../../assets/icons/home.svg";
import PdfIcon from "../../../assets/icons/pdf.svg";
import XlsIcon from "../../../assets/icons/xls.svg";
import { toast } from "react-toastify";
import {
  exportCourseTrainerRatting,
  getCourseTrainerRatting,
} from "../../../Services/api/courses/courseProvider";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import RattingBox from "./RattingBox";
import { Pagination } from "@mui/material";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
export default function TrainersRatting({
  role,
  programCourses,
  backPath,
  isProgram,
}) {
  const { courseId, id, programId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [rattings, setRattings] = useState([]);
  const [user, setUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const getRatting = () => {
    let params = {
      page: currentPage,
      perPage: 10,
    };
    setIsLoading(true);
    getCourseTrainerRatting(id, courseId, params).then((res) => {
      //   console.log(res);

      setRattings(res.data.data.rates);
      setUser(res.data.data.user);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getRatting();
  }, [currentPage]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `${role === "admin" ? "content_development" : "private_profile"}`,
      page: t(
        `sidebar.label.${
          role === "admin" ? "content_development" : "private_profile"
        }`
      ),
      pagePath: role === "admin" ? "#" : "/private-profile",
    },
    {
      id: `${programCourses || isProgram ? "programs" : "courses"}`,
      page: t(
        `sidebar.label.${programCourses || isProgram ? "program" : "courses"}`
      ),
      pagePath:
        role === "trainer" && programCourses
          ? "#"
          : `/${
              role === "admin"
                ? "admin"
                : role === "manager"
                ? "manager"
                : role === "trainer"
                ? "trainer"
                : ""
            }/${
              programCourses || isProgram
                ? backPath === "programs-history"
                  ? "programs-history"
                  : role === "manager" || role === "trainer"
                  ? "programs"
                  : "program/list"
                : backPath === "courses-history"
                ? "courses-history"
                : "courses"
            }`,
    },
    ...(programCourses
      ? [
          {
            id: "courses",
            page: t("sidebar.label.courses"),
            pagePath:
              role === "manager"
                ? backPath === "programs-history"
                  ? `/manager/programs-history/program/${programId}/courses`
                  : `/manager/program/${programId}/courses`
                : role === "trainer"
                ? backPath === "programs-history"
                  ? `/trainer/programs-history`
                  : "/trainer/programs"
                : `/admin/program/${programId}/courses`,
          },
        ]
      : []),

    {
      id: "departments-trainers",
      page: t(
        `department_trainers.${
          isProgram ? "program_trainers" : "course_trainers"
        }`
      ),
      pagePath:
        role === "admin"
          ? programCourses
            ? `/admin/program/${programId}/course/${courseId}/trainers`
            : isProgram
            ? `/admin/program/${courseId}/trainers`
            : `/${role}/course/${courseId}/trainers`
          : isProgram && role === "manager"
          ? backPath === "programs-history"
            ? `/programs-history/program/${courseId}/trainers`
            : `/program/${courseId}/trainers`
          : role === "manager" && programCourses
          ? backPath === "programs-history"
            ? `/programs-history/program/${programId}/course/${courseId}/trainers`
            : `/program/${programId}/course/${courseId}/trainers`
          : `${role === "admin" ? "/admin" : ""}/course/${courseId}/trainers`,
    },
    {
      id: t("trainer"),
      page: user?.name,
      active: true,
    },
  ];

  const exportHandler = async (type) => {
    try {
      setIsLoading(true);
      await exportCourseTrainerRatting(type, id, courseId);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const actionsList = [
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      title: t(`${t("trainer.quiz.export")} PDF`),
      action: () => exportHandler("pdf"),
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      title: t(`${t("trainer.quiz.export")} Excel`),
      action: () => exportHandler("xlsx"),
    },
  ];
  return (
    <div className="container-fluid" id={"content-section"}>
      <div className="row">
        <div className="col-12 sm:tw-px-2 tw-py-8">
          {/*TODO*/}
          <Breadcrumb list={breadcrumbList} />
        </div>
      </div>
      {isLoading ? (
        <div
          className="mt-5"
          style={{
            height: "65vh",
          }}
        >
          <SkeletonCardOverlay />
        </div>
      ) : (
        <div className="tw-space-y-4 tw-mb-8">
          <MainBox>
            <MainBoxHead title={user.name} actions={actionsList} />
            {rattings.map((rate) => (
              <RattingBox item={rate} />
            ))}

            <Pagination
              count={1}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              className="main-pagination"
              page={currentPage}
              onChange={(e, page) => {
                setCurrentPage(page);
              }}
            />
          </MainBox>
        </div>
      )}
    </div>
  );
}

import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Field, FieldArray, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { toServerTime } from "../../../../Services/Timezone";
import { getAllCourses } from "../../../../Services/api/CoursesList";
import { listTraineesCoupon } from "../../../../Services/api/coupon/couponProvider";
import { getAllUsers } from "../../../../Services/api/survey/SurveyProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import { Lang } from "../../../../utils";
import { formatDate, formatTime } from "../../../../utils/formatDate";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../Shared/Components/DataTable/DataTable";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import ManageCouponAction from "./ManageCouponAction";
import classes from "./manageCoupon.module.css";
import { decrypt } from "../../../../Helpers/Secret";
const ManageCoupon = ({ type, submitHandler, couponDetails }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);
  const [hoursDatepickerFir, setHoursDatepickerFir] = useState(null);
  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [trainees, setTrainees] = useState([]);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);

  const [isTraineesLoading, setIsTraineesLoading] = useState(true);
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [userFetching, setUserFetching] = useState(false);
  // const [changeSurveyWarning, setChangeSurveyWarning] = useState(null);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "coupon-list",
      page: t("coupon.label.coupon"),
      pagePath: `${decrypt(localStorage.getItem("type")) == "accountant" ? "/accountant" : "/admin"}/coupon`,
    },
    {
      id: "create_exhibition",
      page: type === "create" ? t("coupon.label.create_coupon") : couponDetails?.name,
      active: true,
    },
  ];
  const mechanismOptions = [
    {
      value: 2,
      label: t("coupon.label.per_value"),
    },
    // {
    //   value: 1,
    //   label: t("coupon.label.co_value"),
    // },
  ];

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
    },
  ];

  function getRoles(role) {
    return role == "" ? [] : role == "trainer_trainee" ? ["trainer", "trainee"] : [role];
  }

  const fetchUsers = async (values) => {
    try {
      setUserFetching(true);
      setUsers([]);
      let response = await getAllUsers({
        search: values.search,
        includes: values.except_users ? [] : values.users,
        roles: ["trainee"],
        course: null,
      });
      if (response.status == 200) {
        setUsers(response.data.users);
        setUserFetching(false);
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      setUserFetching(false);
    }
  };

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { not_in_program: 1, is_program: 0 } : {}),
      mode: "Paid",
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      })
      .finally(() => {
        setIsCoursesLoading(false);
        setIsProgramsLoading(false);
      });
  };
  const getAllTraineesHandler = async () => {
    setIsTraineesLoading(true);
    listTraineesCoupon()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTrainees(res?.data?.response);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      })
      .finally(() => {
        setIsTraineesLoading(false);
      });
  };

  useEffect(() => {
    currentDate();
    fetchUsers({
      users: [],
      search: "",
    });
    getAllTraineesHandler();
    getAllCoursesProgramsHandler({
      is_program: false,
    });
    getAllCoursesProgramsHandler({
      is_program: true,
    });
    setIsLoading(false);
  }, []);

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };
  const currentDateEnd = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
  };

  const traineesFields = [
    {
      id: "name",
      column: ({ rowData }) => <div>{i18n.language === Lang.AR ? rowData?.name_ar : rowData?.name_en}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.email}</div>,
    },
  ];

  const coursesFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
    {
      id: "code",
      column: ({ rowData }) => <>{rowData.code || "-"}</>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <>{rowData.run_serial_number || "-"}</>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => <>{rowData.start_date || "-"}</>,
    },
  ];

  const programsFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
    {
      id: "code",
      column: ({ rowData }) => <>{rowData.code || "-"}</>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <>{rowData.run_serial_number || "-"}</>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => <>{rowData.start_date || "-"}</>,
    },
  ];

  return (
    <div className={classes["create_coupon"]}>
      <div className="container-fluid">
        <div className={classes["create_coupon__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {!isLoading && (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      name: couponDetails?.name || "",
                      notes: couponDetails?.notes || "",
                      value: couponDetails?.value || "",
                      mechanism: couponDetails?.mechanism || "",
                      parentType: couponDetails?.parent_type || 1,
                      parent_id: couponDetails?.parent?.id || "",
                      users: couponDetails?.trainees?.map((item) => item?.id) || [],
                      times: couponDetails?.times || 0,
                      start_date: couponDetails?.start_date
                        ? new Date(getLocaleTime(new Date(couponDetails?.start_date)))
                        : setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
                      end_date: couponDetails?.end_date
                        ? new Date(getLocaleTime(new Date(couponDetails?.end_date)))
                        : setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        //
                        let dataToBeUploaded = {
                          ...values,
                          times_trainee: values.users.length,
                        };
                        if (values.times === 0) {
                          delete dataToBeUploaded.times;
                        }
                        dataToBeUploaded.start_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.start_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.start_date))
                        );

                        dataToBeUploaded.end_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.end_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.end_date))
                        );

                        if (type === "limitedEdit") {
                          // delete dataToBeUploaded.notes;
                          // delete dataToBeUploaded.users;
                          // delete dataToBeUploaded.parentType;
                          // delete dataToBeUploaded.parent_id;
                        }

                        await submitHandler(dataToBeUploaded);
                        setSubmitting(false);
                      } catch (err) {
                        toast.error(
                          <>
                            {err?.data?.errors ? (
                              Object.keys(err?.data?.errors)?.map((key) => {
                                return <span style={{ fontSize: 12 }}>{err?.data?.errors[key]}</span>;
                              })
                            ) : (
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.data?.msg}</span>
                            )}
                          </>
                        );
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }
                      if (!values.start_date) {
                        errors.start_date = t("crud.errors.required");
                      }
                      if (!values.end_date) {
                        errors.end_date = t("crud.errors.required");
                      }
                      if (!values.value) {
                        errors.value = t("crud.errors.required");
                      }

                      // if (type !== "limitedEdit") {
                      if (!values.notes) {
                        errors.notes = t("crud.errors.required");
                      }
                      // if (values.users.length == 0) {
                      //   errors.users = t("crud.errors.required");
                      // }
                      if (!values.parentType) {
                        errors.parentType = t("crud.errors.required");
                      }
                      if (!values.parent_id) {
                        errors.parent_id = t("crud.errors.required");
                      }

                      if (values.mechanism == 2 && values.value > 100) {
                        errors.value = t("crud.errors.percentage");
                      }
                      // }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                      setValues,
                    }) => (
                      <form onSubmit={handleSubmit} className={classes["create_coupon__form"]}>
                        {type === "cannotEdit" && (
                          <div className={classes["coupon__warning__note"]}>{t("coupon.label.cannot_edit_note")}</div>
                        )}
                        {type === "limitedEdit" && (
                          <div className={classes["coupon__warning__note"]}>{t("coupon.label.limited_edit_note")}</div>
                        )}
                        <MainBoxHead
                          title={type === "create" ? t("coupon.label.create_coupon") : t("coupon.label.edit_coupon")}
                        />
                        <div className={`row ${classes["create_coupon__form__fields"]}`}>
                          <div className={`${classes["create_coupon__form__field"]} col-12`}>
                            <Input
                              label={t("coupon.placeholders.coupon_name")}
                              type="text"
                              name="name"
                              placeholder={t("coupon.placeholders.coupon_name")}
                              onChange={handleChange}
                              error={errors.name}
                              value={values.name}
                              disabled={type === "cannotEdit"}
                            />
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12`}>
                            <Input
                              label={t("note_for_user")}
                              type="text"
                              name="notes"
                              placeholder={t("note_for_user")}
                              onChange={handleChange}
                              error={errors.notes}
                              value={values.notes}
                              disabled={type === "cannotEdit" || type === "limitedEdit"}
                            />
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12`}>
                            <label htmlFor="mechanism">{t("coupon.label.machinsim")}</label>
                            <Select
                              name="mechanism"
                              id="mechanism"
                              options={mechanismOptions}
                              value={mechanismOptions.find((option) => option.value == values.mechanism)}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setFieldValue("mechanism", item.value);
                              }}
                              placeholder={t("coupon.label.machinsim")}
                              isDisabled={type === "cannotEdit" || type === "limitedEdit"}
                            />
                            <p className={"form-input-error-space"}>{errors.mechanism ? errors.mechanism : null}</p>
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}>
                            <Input
                              label={t("coupon.placeholders.value_coupon")}
                              type="number"
                              name="value"
                              placeholder={t("coupon.placeholders.value_coupon")}
                              onChange={handleChange}
                              error={errors.value}
                              min={0.01}
                              max={values.mechanism == 2 ? 100 : null}
                              value={values.value}
                              disabled={type === "cannotEdit" || type === "limitedEdit"}
                            />
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}>
                            <Input
                              label={t("coupon.placeholders.times")}
                              type="number"
                              name="times"
                              placeholder={t("coupon.placeholders.times")}
                              onChange={handleChange}
                              error={errors.times}
                              min={0}
                              value={values.times}
                              disabled={type === "cannotEdit" || type === "limitedEdit"}
                              note={t("coupon.label.times_note")}
                            />
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}>
                            <MainDatePicker
                              label={t("general.start_date_time")}
                              name="start_date"
                              error={errors.start_date}
                              value={values.start_date}
                              onChange={(date) => {
                                setFieldValue("start_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date.toISOString().split("T")[0];
                                if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                  setHoursDatepicker(0);
                                  setMinutesDatepicker(0);
                                } else {
                                  currentDate();
                                }
                              }}
                              minDate={
                                values.parent_id
                                  ? new Date(
                                      moment(
                                        values.parentType == 1
                                          ? courses.find((course) => course.id == values.parent_id)?.start_date
                                          : programs.find((program) => program.id == values.parent_id)?.start_date
                                      ) > new Date()
                                        ? moment(
                                            values.parentType == 1
                                              ? courses.find((course) => course.id == values.parent_id)?.start_date
                                              : programs.find((program) => program.id == values.parent_id)?.start_date
                                          )
                                        : new Date()
                                    )
                                  : new Date()
                              }
                              minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              maxDate={
                                values.parent_id
                                  ? new Date(
                                      moment(
                                        values.parentType == 1
                                          ? courses.find((course) => course.id == values.parent_id)?.end_date
                                          : programs.find((program) => program.id == values.parent_id)?.end_date
                                      ) > new Date()
                                        ? moment(
                                            values.parentType == 1
                                              ? courses.find((course) => course.id == values.parent_id)?.end_date
                                              : programs.find((program) => program.id == values.parent_id)?.end_date
                                          )
                                        : null
                                    )
                                  : null
                              }
                              readOnly={type === "cannotEdit" || type === "limitedEdit"}
                            />
                            <p className={"form-input-error-space"}>{errors.start_date ? errors.start_date : null}</p>
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12 col-md-6`}>
                            <MainDatePicker
                              label={t("general.end_date_time")}
                              name="end_date"
                              error={errors.end_date}
                              value={values.end_date}
                              onChange={(date) => {
                                setFieldValue("end_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date.toISOString().split("T")[0];
                                if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                  setHoursDatepickerEnd(0);
                                  setMinutesDatepickerEnd(0);
                                } else {
                                  currentDateEnd();
                                }
                              }}
                              minDate={
                                values.parent_id
                                  ? new Date(
                                      moment(
                                        values.parentType == 1
                                          ? courses.find((course) => course.id == values.parent_id)?.start_date
                                          : programs.find((program) => program.id == values.parent_id)?.start_date
                                      ) > new Date()
                                        ? moment(
                                            values.parentType == 1
                                              ? courses.find((course) => course.id == values.parent_id)?.start_date
                                              : programs.find((program) => program.id == values.parent_id)?.start_date
                                          )
                                        : new Date()
                                    )
                                  : new Date()
                              }
                              minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              maxDate={
                                values.parent_id
                                  ? new Date(
                                      moment(
                                        values.parentType == 1
                                          ? courses.find((course) => course.id == values.parent_id)?.end_date
                                          : programs.find((program) => program.id == values.parent_id)?.end_date
                                      ) > new Date()
                                        ? moment(
                                            values.parentType == 1
                                              ? courses.find((course) => course.id == values.parent_id)?.end_date
                                              : programs.find((program) => program.id == values.parent_id)?.end_date
                                          )
                                        : null
                                    )
                                  : null
                              }
                              readOnly={type === "cannotEdit"}
                            />
                            <p className={"form-input-error-space"}>{errors.end_date ? errors.end_date : null}</p>
                          </div>
                          <>
                            {/* //////////////////////////////////////////////////////////// */}
                            <div className={`${classes["create_coupon__form__field"]} col-12`}>
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("survey.to")}</div>

                              <FieldArray name="users">
                                {({ insert, remove, push }) => (
                                  <div className="tw-border tw-rounded tw-divide-y">
                                    <div className="tw-bg-gray-100 tw-px-4 md:tw-flex tw-items-center tw-justify-between">
                                      <div className="tw-flex tw-items-center tw-space-s-4">
                                        <Field
                                          type="checkbox"
                                          name="except_users"
                                          disabled={type === "cannotEdit" || type === "limitedEdit"}
                                          onChange={() => {
                                            setValues({
                                              ...values,
                                              users:
                                                values.users.length === users.length
                                                  ? []
                                                  : users.map((user) => {
                                                      return user.id;
                                                    }),
                                              except_users: !values.except_users,
                                            });
                                          }}
                                          className={`tw-h-4 tw-w-4 tw-shrink-0 tw-appearance-none tw-border-[1px] tw-rounded tw-border-gray-300 checked:tw-bg-teal-600 checked:tw-border-white checked:tw-border-x-[3px] ${
                                            values.users.length
                                              ? "checked:tw-border-y-[6px]"
                                              : "checked:tw-border-y-[3px]"
                                          }`}
                                        />
                                        <div className="tw-font-semibold tw-text-gray-500">{t("select_names")}</div>
                                      </div>
                                      <div className="tw-flex tw-items-center tw-space-s-8" style={{ width: 310 }}>
                                        <Field
                                          disabled={type === "cannotEdit" || type === "limitedEdit"}
                                          name="search"
                                          className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent"
                                          placeholder={t("search_by_name_or_email")}
                                          onChange={({ target: { value } }) => {
                                            setFieldValue("search", value);
                                            fetchUsers({
                                              ...values,
                                              search: value,
                                            });
                                          }}
                                        />
                                        <li className="fal fa-search tw-text-teal-500 tw-w-6"></li>
                                      </div>
                                    </div>
                                    <div className="tw-py-4 tw-pe-4">
                                      <div className="tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-max-h-[40vh] tw-pe-4">
                                        {userFetching ? (
                                          [...Array(5).keys()].map((i) => (
                                            <div
                                              key={i}
                                              className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
                                            >
                                              <div className="tw-flex tw-items-center tw-space-s-4">
                                                <div
                                                  className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded tw-border-gray-300`}
                                                ></div>
                                                <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
                                              </div>
                                              <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
                                            </div>
                                          ))
                                        ) : users.length ? (
                                          users.map((user) => (
                                            <button
                                              disabled={type === "cannotEdit" || type === "limitedEdit"}
                                              key={user.id}
                                              type="button"
                                              onClick={() => {
                                                const userIndex = values.users?.findIndex((i) => i == user.id);
                                                if (userIndex != undefined && userIndex >= 0) remove(userIndex);
                                                else push(user.id);
                                              }}
                                              className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-w-full tw-text-center`}
                                            >
                                              <div className="tw-flex tw-items-center tw-space-s-4 ">
                                                <div
                                                  className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded  ${
                                                    values.users.includes(user.id)
                                                      ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                                                      : "tw-border-gray-300"
                                                  }`}
                                                ></div>
                                                <div className="tw-w-32 tw-px-2">{user.name}</div>
                                              </div>
                                              <div className="tw-w-64 tw-px-2">{user.email}</div>
                                            </button>
                                          ))
                                        ) : (
                                          <div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">
                                            {t("there_no_matching_users")}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </FieldArray>
                              <p className="tw-text-red-500 tw-text-xs" style={{ height: "10px" }}>
                                {errors.users && errors.users}
                              </p>
                            </div>
                          </>
                          {/* <div
                            className={`${classes["create_coupon__form__field"]} col-12`}
                          >
                            <DataTable
                              label={t("coupon.label.direct_to")}
                              title={t("select_names")}
                              selectedItems={[...values.users]}
                              isLoading={isTraineesLoading}
                              data={trainees}
                              fields={traineesFields}
                              isMultiple={true}
                              searchableFields={["name_ar", "name_en", "id"]}
                              selectedHandler={(item) => {
                                if (values?.users?.includes(item?.id)) {
                                  setFieldValue(
                                    `users`,
                                    values.users.filter((id) => id !== item?.id)
                                  );
                                } else {
                                  setFieldValue(`users`, [
                                    ...values.users,
                                    item?.id,
                                  ]);
                                }
                              }}
                              error={errors.users}
                              required
                              canSelectAll={true}
                              toggleSelectAllHandler={() => {
                                setFieldValue(
                                  `users`,
                                  values.users.length === trainees.length
                                    ? []
                                    : trainees.map((item) => item.id)
                                );
                              }}
                            />
                          </div> */}
                          <div className={`${classes["create_coupon__form__field"]} col-12`}>
                            <label htmlFor="course_program">{t("coupon.label.select_course_or_program")}</label>
                            <Select
                              name="course_program"
                              id="course_program"
                              options={parentTypeOptions}
                              value={parentTypeOptions.find((option) => option.id == values.parentType)}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.id}
                              onChange={(item) => {
                                setFieldValue("parentType", item.id);
                                setFieldValue("parent_id", "");
                              }}
                              placeholder={t("coupon.label.select_course_or_program")}
                              isDisabled={type === "cannotEdit" || type === "limitedEdit"}
                            />
                            <p className={"form-input-error-space"}>{errors.parentType ? errors.parentType : null}</p>
                          </div>
                          <div className={`${classes["create_coupon__form__field"]} col-12`}>
                            <DataTable
                              label={
                                values.parentType == 1
                                  ? t("coupon.label.select_course")
                                  : t("coupon.label.select_program")
                              }
                              selectedItems={[values.parent_id]}
                              isLoading={values.parentType == 1 ? isCoursesLoading : isProgramsLoading}
                              data={values.parentType == 1 ? courses : programs}
                              fields={values.parentType == 1 ? coursesFields : programsFields}
                              isMultiple={false}
                              searchableFields={
                                values.parentType == 1
                                  ? ["id", "name", "code", "run_serial_number"]
                                  : ["id", "name", "code", "run_serial_number"]
                              }
                              selectedHandler={(item) => {
                                setFieldValue("parent_id", item?.id);
                              }}
                              error={errors.parent_id}
                              required
                              disabled={type === "cannotEdit" || type === "limitedEdit"}
                            />
                          </div>
                          <ManageCouponAction type={type} submitAction={handleSubmit} isSubmitting={isSubmitting} />
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageCoupon;

import React, { useEffect, useState, useRef } from "react";
import classes from "./AboutUsSection.module.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import {
  aboutUS,
  aboutUSCount,
} from "../../../Services/api/toutorials/aboutUsProvider";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import CountUp from "react-countup";
import Counter1Icon from "../../../assets/icons/counter1.svg";
import Counter2Icon from "../../../assets/icons/counter2.svg";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

function CounterHome(props) {
  const refcount = useRef(null);
  const isInViewcount = useInView(refcount, { once: true });
  const mainControlscount = useAnimation();

  useEffect(() => {
    // console.log(isInViewcount);

    if (isInViewcount && props.loading) {
      mainControlscount.start("visible");
    }
  }, [props.loading, isInViewcount]);
  return (
    <div ref={refcount} className={classes.countdiv}>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            y: 120,
          },
          visible: {
            opacity: 1,
            y: 0,
          },
        }}
        initial="hidden"
        animate={mainControlscount}
        transition={{
          duration: 0.75,
          delay: 1,
        }}
        className={classes.count}
      >
        <img
          src={Counter1Icon}
          alt=""
          style={{
            width: 60,
            height: 60,
          }}
        />
        <h1>{props.t("num_courses")}</h1>
        {/* <h1>{aboutUsCount.courses}</h1> */}
        {isInViewcount && (
          <>
            <CountUp
              prefix="+"
              duration={5}
              end={props.aboutUsCount.courses}
              className={classes.counterFont}
            />
          </>
        )}
      </motion.div>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            y: 120,
          },
          visible: {
            opacity: 1,
            y: 0,
          },
        }}
        initial="hidden"
        animate={mainControlscount}
        transition={{
          duration: 0.75,
          delay: 1,
        }}
        className={classes.count}
      >
        <img
          src={Counter2Icon}
          alt=""
          style={{
            width: 60,
            height: 60,
          }}
        />
        <h1>{props.t("num_trainer")}</h1>
        {/* <h1>{aboutUsCount.trainers}</h1> */}
        {isInViewcount && (
          <CountUp
            prefix="+"
            duration={5}
            end={props.aboutUsCount.users}
            className={classes.counterFont}
          />
        )}
      </motion.div>
    </div>
  );
}

export default function AboutUsSection() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [aboutUsCount, setAboutUsCount] = useState("");
  const [loading, setloading] = useState(false);
  // const [isvisible, setisvisible] = useState(false);

  const ref = useRef(null);

  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    // console.log(isInViewcount);
    if (isInView && loading) {
      mainControls.start("visible");
      // setisvisible(true);
    }
  }, [isInView, loading]);

  useEffect(() => {
    const apiCall = aboutUSCount;
    apiCall()
      .then((res) => {
        // console.log(res);
        if (res.status && res.status === 200) {
          setAboutUsCount(res.data);
        }
        setloading(true);
      })
      .catch((err) => {
        console.log(err);
        setloading(true);
      });
  }, []);
  return (
    <div ref={ref}>
      {loading ? (
        <div className={classes.aboutUsMainCont}>
          <div className={classes.aboutUsMain}>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 120 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{ duration: 0.75, delay: 0.5 }}
              className={classes.aboutContent}
            >
              <h1>
                {t("about_")} <span className={classes.tebx}>TEBx</span>
              </h1>
              <h4>{t("about_tebx_breif")}</h4>
              <p>{t("about_us_paragraph")}</p>
              <CustomButton
                value={t("see_more")}
                colors="#fff"
                classes={classes.aboutButton}
                action={() => history.push("/about-us")}
              />
            </motion.div>
            <div className={classes.sapretordiv}></div>
            <CounterHome
              t={t}
              aboutUsCount={aboutUsCount}
              loading={loading}
            ></CounterHome>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <div
              style={{
                height: "400px",
                width: "90%",
              }}
            >
              <SkeletonSpinnerOverlay
                backdrop={true}
                skeletonWidth="100"
                skeletonHight="100"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

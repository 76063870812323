import { Formik } from "formik";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { default as ReactSelect } from "react-select";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import toLocalTimezone from "../../../../Services/Timezone";
import {
  downloadTicketActivityFile,
  downloadTicketFile,
  exportTicketDetailsList,
  ticketAction,
  ticketShow,
} from "../../../../Services/api/Tickets/TicketsProvider";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import { getAllUsers } from "../../../../Services/api/survey/SurveyProvider";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar-icon.svg";
import { ReactComponent as DocumentIcon } from "../../../../assets/icons/document.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { ReactComponent as FileIcon } from "../../../../assets/icons/file.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import ArrowIcon from "../../../../assets/icons/right-arrow-green.svg";
import { ReactComponent as StarIcon } from "../../../../assets/icons/star.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import { Lang } from "../../../../utils";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DialogModal from "../../../Shared/Components/DialogModal/DialogModal";
import FilesDragAndDropInput from "../../../Shared/Components/FilesDragAndDropInput/FilesDragAndDropInput";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import TicketsTransfersModal from "./TicketsTransfersModal";

function TicketView({ isTrainer = false }) {
  const dispatch = useDispatch();

  require("./View.css");
  const { t, i18n } = useTranslation();
  const [ticket, setTicket] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [priorities, setPriorities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [types, setTypes] = useState([]);
  // const [subjects, setSubjects] = useState([]);

  const [abilities, setAbilities] = useState([]);
  const [users, setUsers] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [retransfer, setRetransfer] = useState(false);
  const [close, setClose] = useState(false);
  const [priority, setPriority] = useState("");
  const [showTicketTransfersModal, setShowTicketTransfersModal] = useState(false);
  const [replies, setReplies] = useState([]);

  const { livechat_reply } = useSelector((state) => state.livechatReducer);

  const { id } = useParams();
  const history = useHistory();

  const authContext = useContext(AuthContext);

  useEffect(() => {
    fetchTicket();
  }, []);

  function fetchTicket() {
    ticketShow(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTicket({
            ...res.data.data.ticket,
            transfers: res.data.data.ticket.transfers.slice(1),
          });
          setPriorities(res.data.data.priorities);
          setDepartments(res.data.data.departments);
          setAbilities(res.data.data.abilities);
          setReplies(res?.data?.data?.replies);
          setTypes(res.data.data.types);
          setUsers(res.data.data.users);
          setIsLoading(false);
          setPriority(res.data.data.ticket?.priority?.value);
        }
      })
      .catch((err) => {});
  }

  const breadcrumbList = [
    ...(authContext.inAdminPanel
      ? [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
          {
            id: t("admin.label.admin_label"),
            page: t("admin.label.admin_label"),
            pagePath: "/admin",
          },
        ]
      : [
          {
            id: "home",
            page: <img src={HomeIcon} alt="" />,
            pagePath: "/",
          },
        ]),
    {
      id: t("admin.label.list_tickets"),
      page: t("admin.label.list_tickets"),
      pagePath: authContext.inAdminPanel ? "/admin/tickets" : "/tickets",
    },
    {
      id: id,
      page: i18n.language == Lang.AR ? ticket?.subject?.name_ar : ticket?.subject?.name_en,
      active: true,
    },
  ];
  const downloadFile = (id, filename) => {
    downloadTicketFile(id, filename)
      .then((res) => {})
      .catch((err) => {
        toast.error(<span style={{ fontSize: 15 }}>{err.data.msg}</span>);
      });
  };
  const downloadActivityFile = (id, filename) => {
    downloadTicketActivityFile(id, filename)
      .then((res) => {})
      .catch((err) => {
        toast.error(<span style={{ fontSize: 15 }}>{err.data.msg}</span>);
      });
  };

  const exportFile = async (type) => {
    setIsUpdating(true);
    try {
      await exportTicketDetailsList(id, type);
      setIsUpdating(false);
    } catch (e) {
      setIsUpdating(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  function TransferModal({}) {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [fetchCallback, setFetchCallback] = useState({});
    const userSelection = useRef();

    useEffect(() => {
      fetchUsers(ticket.type_id);
    }, []);

    const fetchUsers = async (support_ticket_type_id, search = "", callback) => {
      if (callback) {
        setFetchCallback({ callback });
      }
      try {
        setFilteredUsers([]);
        let response = await getAllUsers({
          search: search ? search : null,
          "users-for-tickets": true,
          support_ticket_type_id: support_ticket_type_id,
        });

        if (response.status == 200) {
          setFilteredUsers(response.data.users);
          if (fetchCallback.callback) {
            fetchCallback.callback(response.data.users);
          } else if (callback) {
            callback(response.data.users);
          }
        } else {
          throw response;
        }
      } catch (err) {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      }
    };

    return (
      <DialogModal
        onCancel={() => setRetransfer(false)}
        className="tw-bg-white sm:tw-rounded-lg tw-shadow-xl tw-p-4 tw-space-y-4 tw-w-full sm:tw-w-2/3 lg:tw-w-1/2"
      >
        <div>
          <div className="tw-text-lg sm:tw-text-xl tw-text-teal-600">{t("modal.common.reassign")}</div>
        </div>

        <div>
          <Formik
            initialValues={{
              support_ticket_type_id: ticket.type_id ?? "",
              // employee_id: ticket.user_id ?? "",
              private_comment: "",
              subject_id: ticket.subject_id ?? "",
              subjects: types?.find((type) => type.id == ticket.type_id)?.subjects,
            }}
            onSubmit={async (values) => {
              const { subjects, ...payload } = values;

              try {
                payload.type = "ticket type transfer";
                let response = await ticketAction(payload, id);
                if (response.status == 200 && response.data.status) {
                  toast.success(<span style={{ fontSize: 15 }}>{t("addTransferSuccess")}</span>);
                  // fetchTicket();
                  setRetransfer(false);

                  if (authContext.inAdminPanel) {
                    history.push("/admin/tickets");
                  } else {
                    history.push("/tickets");
                  }
                } else {
                  alert("Failure");
                }
              } catch (err) {
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.errors != null &&
                  typeof err.response.data.errors === "object" &&
                  !Array.isArray(err.response.data.errors)
                ) {
                  const issues = [];
                  Object.keys(err.response.data.errors).forEach((key) => {
                    issues.push(Array.isArray(err.response.data.errors[key]) ? err.response.data.errors[key][0] : "");
                  });
                  toast.error(
                    <>
                      <span style={{ fontSize: 14 }}>{err.response.data.msg}:</span>
                      <ul>
                        {issues.map((item, index) => {
                          return (
                            <li key={index}>
                              <span style={{ fontSize: 12 }}>{item}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  );
                } else if (typeof err === "string") {
                  toast.error(<span style={{ fontSize: 14 }}>{err}</span>);
                }
              }
            }}
            validateOnChange={hasSubmitted}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};
              if (!values.private_comment) {
                errors.private_comment = t("crud.errors.required");
              }
              if (!values.subject_id) {
                errors.subject_id = t("crud.errors.required");
              }

              return errors;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setValues,
              setErrors,
            }) => (
              <form onSubmit={handleSubmit} className="tw-space-y-4">
                <div>
                  <label className="tw-block mb-0">
                    <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("crud.placeholders.select.type")}</div>
                    <ReactSelect
                      name="support_ticket_type_id"
                      id="support_ticket_type_id"
                      isClearable={true}
                      value={types.find((item) => item.id == values.support_ticket_type_id)}
                      options={types}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      onChange={(item) => {
                        if (!item) {
                          setValues({
                            ...values,
                            support_ticket_type_id: "",
                            // employee_id: "",
                            subject_id: "",
                            subjects: [],
                          });
                        } else {
                          setValues({
                            ...values,
                            support_ticket_type_id: item.id,
                            // employee_id: "",
                            subject_id: "",
                            subjects: item.subjects,
                          });
                          fetchUsers(item.id, "");
                        }
                      }}
                      placeholder={t("crud.placeholders.select.type")}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </label>
                  <div className={"form-input-error-space"}>
                    {errors.support_ticket_type_id ? errors.support_ticket_type_id : null}
                  </div>
                </div>

                <div>
                  <label className="tw-block mb-0">
                    <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("ticket.subjects")}</div>
                    <ReactSelect
                      name="support_ticket_subjects"
                      id="support_ticket_subjects"
                      isClearable={true}
                      value={values.subjects.find((item) => item.id == values.subject_id) ?? null}
                      options={values.subjects}
                      getOptionLabel={(option) => (i18n.language === Lang.AR ? option.name_ar : option.name_en)}
                      getOptionValue={(option) => option.id}
                      onChange={(item) => {
                        if (!item) {
                          setValues({
                            ...values,
                            subject_id: "",
                          });
                        } else {
                          setValues({
                            ...values,
                            subject_id: item.id,
                          });
                        }
                      }}
                      placeholder={t("ticket.subjects")}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </label>
                  <div className={"form-input-error-space"}>{errors.subject_id ? errors.subject_id : null}</div>
                </div>

                {/* reply field */}
                <div>
                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("support.ticket_view.comment")}</div>

                  {/* <SunEditor
										placeholder={t("support.ticket_view.comment")}
										value={values.private_comment}
										defaultValue={values.private_comment}
										onChange={(e) => {
											setFieldValue("private_comment", e);
										}}
										setOptions={{
											height: 150,
											buttonList: [
												["bold", "underline", "italic", "strike", "align", "horizontalRule", "list", "lineHeight"],
											],
										}}
										setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px; text-align: right; direction: rtl"
									/> */}
                  <textarea
                    placeholder={t("support.ticket_view.comment")}
                    value={values.private_comment}
                    onChange={(e) => {
                      setFieldValue("private_comment", e.target.value);
                    }}
                    rows={5}
                    className="tw-block tw-w-full tw-py-2 tw-px-3 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500 sm:text-sm"
                  />
                  <div className={"form-input-error-space"}>
                    {errors.private_comment ? errors.private_comment : null}
                  </div>
                </div>

                <div className="tw-flex tw-items-center tw-justify-end tw-space-s-4 tw-pt-8">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white tw-w-full"
                  >
                    {t("save")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div></div>
      </DialogModal>
    );
  }

  const closeTicket = async () => {
    try {
      const payload = {
        type: "close ticket",
      };
      ticketAction(payload, id).then((res) => {
        toast.success(<span style={{ fontSize: 15 }}>{t("closeTicketSuccess")}</span>);
        if (authContext.inAdminPanel) {
          history.push(`/admin/tickets`);
        } else {
          history.push(`/tickets`);
        }
      });
    } catch (error) {}
  };

  const checkValueIncludesInAbilities = (value) => {
    return abilities?.find((item) => {
      return item.value === value;
    });
  };

  const checkDisplayReplyButton = () => {
    const includesInAbilities = checkValueIncludesInAbilities("reply on ticket");
    if (authContext.inAdminPanel || ticket?.owner || includesInAbilities) return true;
    return false;
  };

  const checkDisplayCloseTicketButton = () => {
    const includesInAbilities = checkValueIncludesInAbilities("close ticket");
    if (authContext.inAdminPanel || (!ticket?.owner && includesInAbilities)) return true;
    return false;
  };

  const checkDisplayTransferButton = () => {
    const includesInAbilities = checkValueIncludesInAbilities("ticket type transfer");
    if (authContext.inAdminPanel || (!ticket?.owner && includesInAbilities)) return true;
    return false;
  };

  const checkEnableChangePriority = () => {
    const includesInAbilities = checkValueIncludesInAbilities("change priority");
    if (authContext.inAdminPanel || (!ticket?.owner && includesInAbilities)) return false;
    return true;
  };

  const savePriorityHandler = async () => {
    try {
      const payload = {
        type: "change priority",
        priority: priority,
      };
      let response = await ticketAction(payload, id);
      if (response.status == 200 && response.data.status) {
        toast.success(<span style={{ fontSize: 15 }}>{t("support.ticket_view.priority_changed_successfully")}</span>);
        const activePriority = priorities.find((item) => {
          return item.value === priority;
        });
        setTicket((prev) => {
          return {
            ...prev,
            priority: activePriority,
          };
        });
      } else {
        toast.error(<span style={{ fontSize: 14 }}>{t("support.ticket_view.priority_failed")}</span>);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.errors != null &&
        typeof err.response.data.errors === "object" &&
        !Array.isArray(err.response.data.errors)
      ) {
        const issues = [];
        Object.keys(err.response.data.errors).forEach((key) => {
          issues.push(Array.isArray(err.response.data.errors[key]) ? err.response.data.errors[key][0] : "");
        });
        toast.error(
          <>
            <span style={{ fontSize: 14 }}>{err.response.data.msg}:</span>
            <ul>
              {issues.map((item, index) => {
                return (
                  <li key={index}>
                    <span style={{ fontSize: 12 }}>{item}</span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      } else if (typeof err === "string") {
        toast.error(<span style={{ fontSize: 14 }}>{err}</span>);
      }
    }
  };

  const checkDisplayRelatedValue = () => {
    switch (ticket?.type?.type) {
      case "1":
        return ticket?.course?.name;
      case "2":
        return ticket?.program?.title;
      case "3":
        return ticket?.exhibition?.name;
      default:
        return "";
    }
  };

  return (
    <>
      {retransfer ? <TransferModal /> : <></>}
      {showTicketTransfersModal && (
        <TicketsTransfersModal
          transferData={ticket?.transfers}
          setShowTicketTransfersModal={setShowTicketTransfersModal}
        />
      )}
      {close ? (
        <SharedModal
          // iconCom={
          //   <ReopenIcon className={`tw-h-16 tw-w-16 tw-fill-[#8599B6]`} />
          // }
          title={t("modal.support.tickets.close.title")}
          // name={ticket.title}
          confirmMsg={t("modal.support.tickets.close.body")}
          confirmText={t("modal.common.closeTicket")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setClose(false);
          }}
          onConfirm={() => {
            setClose(false);
            closeTicket();
          }}
        />
      ) : (
        <></>
      )}
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-mx-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {/* <Bar data={{ labels: ['f', 'e'], datasets: [{data: [5, 6]}] }} /> */}
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {isLoading ? (
          <div className="mt-5" style={{ height: "65vh" }}>
            <SkeletonCard />
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-10 tw-mb-8">
                <div
                  className="training-course-back"
                  onClick={() => {
                    authContext.inAdminPanel ? history.push("/admin/tickets") : history.push("/tickets");
                  }}
                >
                  <div className="training-course-back-icon">
                    <img src={ArrowIcon} alt="back" />
                  </div>
                  <div className="training-course-back-text">{t("back")}</div>
                </div>
                <div className="md:tw-flex tw-items-center tw-justify-between tw-flex tw-gap-4">
                  <div className="tw-text-lg sm:tw-text-3xl tw-text-teal-600">{t("ticket.details")}</div>
                  <div className="tw-flex tw-items-center tw-space-s-6">
                    <button
                      type="button"
                      onClick={() => exportFile("pdf")}
                      className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded tw-bg-[#b91414] tw-border-none"
                    >
                      <img src={PdfIcon} alt="" />
                    </button>
                    <button
                      type="button"
                      onClick={() => exportFile("xlsx")}
                      className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded tw-bg-[#138d5f] tw-border-none"
                    >
                      <img src={XlsIcon} alt="" />
                    </button>
                  </div>
                </div>

                <div className="tw-space-y-8">
                  <div className="tw-space-y-4 border tw-p-4">
                    <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                      <div>
                        <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("support.ticket_view.ticket_username")}
                        </div>
                        <input
                          disabled={true}
                          type="text"
                          value={ticket?.user?.name}
                          className="type-ticket  tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        />
                      </div>
                      <div>
                        <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("support.ticket_view.supporter_name")}
                        </div>
                        <input
                          disabled={true}
                          type="text"
                          value={users?.map((user) => user.name).join(", ")}
                          className="type-ticket  tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        />
                      </div>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                      <div>
                        <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("crud.list.ticket_type")}</div>
                        <input
                          disabled={true}
                          type="text"
                          value={ticket.type.name}
                          className="type-ticket  tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        />
                      </div>
                      <div>
                        <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("crud.list.subject")}</div>
                        <input
                          disabled={true}
                          type="text"
                          value={i18n.language == Lang.AR ? ticket.subject?.name_ar : ticket.subject?.name_en}
                          className="type-ticket  tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                        />
                      </div>
                    </div>

                    <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                      {(ticket?.type?.type == "1" || ticket?.type?.type == "2" || ticket?.type?.type == "3") && (
                        <div>
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("crud.list.related_with")}</div>
                          <input
                            disabled={true}
                            type="text"
                            value={checkDisplayRelatedValue()}
                            className="type-ticket  tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                          />
                        </div>
                      )}
                      {checkEnableChangePriority() && (
                        <div>
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("priority")}</div>
                          <ReactSelect
                            isDisabled={true}
                            id="priority"
                            ReactSelect
                            options={priorities}
                            value={priorities.find((item) => item.value == priority)}
                            getOptionLabel={(option) => option.text}
                            getOptionValue={(option) => option.value}
                            onChange={(item) => {
                              setPriority(item.value);
                            }}
                            placeholder={t("crud.placeholders.select.priority")}
                          />
                        </div>
                      )}
                    </div>
                    <div className=" type-ticket-priority tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                      {!checkEnableChangePriority() && (
                        <>
                          <div className="">
                            <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("priority")}</div>
                            <ReactSelect
                              isDisabled={checkEnableChangePriority()}
                              id="priority"
                              ReactSelect
                              options={priorities}
                              value={priorities.find((item) => item.value == priority)}
                              getOptionLabel={(option) => option.text}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setPriority(item.value);
                              }}
                              placeholder={t("crud.placeholders.select.priority")}
                            />
                          </div>
                          <div>
                            <button
                              type="button"
                              className="tw-bg-teal-700 tw-py-2 tw-px-8 sm:tw-px-16 tw-rounded tw-text-white priority-btn"
                              onClick={savePriorityHandler}
                              disabled={ticket.priority.value === priority}
                            >
                              {t("general.save")}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    {/* {ticket?.course?.name && (
											<div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
												<div>
													<div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("crud.list.course_name")}</div>
													<div className="ticket-course">{`${ticket?.course?.name} - ${t("general.id_number")}: (${
														ticket?.course?.id
													})`}</div>
												</div>
											</div>
										)} */}
                  </div>
                  <div className="tw-space-y-2">
                    <div
                      className="d-flex align-items-center"
                      style={{
                        gap: "30px",
                      }}
                    >
                      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                        {t("support.ticket_view.ticket_details")}
                      </div>
                      <div className="d-flex align-items-center tw-gap-4">
                        <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("crud.list.serial")}</div>
                        <div className="tw-text-gray-400 tw-text-sm">{ticket.serial}</div>
                      </div>
                    </div>
                    <div className="tw-p-4 border">
                      <div className="tw-flex tw-items-center tw-space-s-2 tw-text-teal-500">
                        <CalendarIcon className="tw-h-4" />
                        <div>
                          {`${moment(toLocalTimezone(ticket.created_at)).local(true).format("DD/MM/YYYY")} ${t(
                            "the_hour"
                          )}
                    ${moment(toLocalTimezone(ticket.created_at)).local(true).format("hh:mm A")}`}
                        </div>
                      </div>
                      <div
                        className="tw-text-gray-400 tw-text-sm"
                        dangerouslySetInnerHTML={{ __html: ticket.description }}
                      />
                    </div>
                  </div>

                  <div id="replies-container">
                    <div className="tw-text-teal-600 tw-font-bold tw-mb-2">{t("ticket.replies_records")}</div>
                    <div className="tw-overflow-y-auto inner-scrollbar tw-max-h-[50vh] tw-pe-4 tw-space-y-4">
                      {ticket.comments.map((comment) => (
                        <div className="tw-border tw-border-gray-200 tw-rounded tw-p-4 tw-space-y-4 border">
                          <div className="tw-inline-flex tw-items-center tw-justify-center tw-space-s-4">
                            <div
                              className="tw-bg-white-200 tw-mx-auto tw-rounded-full tw-h-12 tw-aspect-square tw-bg-cover tw-bg-no-repeat tw-bg-center tw-shadow-lg"
                              style={{
                                backgroundImage: `url(${comment.user.img})`,
                              }}
                            ></div>
                            <div className="tw-py-2">
                              <div className="tw-text-teal-600 tw-text-center sm:tw-text-start">
                                {comment.user.name}
                              </div>
                              <div className="tw-text-sm tw-text-gray-400 tw-text-center sm:tw-text-start">{`${`${moment(
                                toLocalTimezone(comment.date)
                              )
                                .local(true)
                                .format("DD/MM/YYYY")} ${t("the_hour")}
                    ${moment(toLocalTimezone(comment.date)).local(true).format("hh:mm A")}`}`}</div>
                            </div>
                          </div>
                          <div
                            className="tw-text-gray-400 tw-text-sm  ticket_view-replies-body"
                            dangerouslySetInnerHTML={{
                              __html: comment.comment,
                            }}
                            style={{
                              wordBreak: "break-all",
                            }}
                          />

                          {Object.keys(comment.attachment ?? {}).length ? (
                            <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2">
                              <div className="tw-flex tw-items-center tw-space-s-2">
                                <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                                  <DocumentIcon className="tw-h-4 tw-w-4" />
                                </div>

                                <div>{comment.attachment.name}</div>
                              </div>
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    downloadActivityFile(comment.id, comment.attachment.name);
                                  }}
                                >
                                  <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                                </button>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("general.rating")}</div>
                    <div className="border tw-p-4">
                      <div className="tw-flex tw-items-center tw-space-s-3 ">
                        {[...Array(5).keys()].map((item, index) => (
                          <div key={index}>
                            <StarIcon
                              className={`tw-h-6 tw-w-6 ${
                                ticket.rate.count > index
                                  ? "tw-fill-teal-700 tw-stroke-teal-700"
                                  : "tw-fill-white tw-stroke-gray-500"
                              }`}
                            />
                          </div>
                        ))}
                      </div>
                      {ticket?.rate?.rate_desc ? (
                        <div>
                          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("وصف التقييم")}</div>

                          <div
                            className="tw-text-gray-400 tw-text-sm"
                            dangerouslySetInnerHTML={{
                              __html: ticket?.rate?.rate_desc,
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {ticket.attachment?.name ? (
                      <div>
                        <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("general.attachment")}</div>
                        <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2">
                          <div className="tw-flex tw-items-center tw-space-s-2">
                            <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                              <DocumentIcon className="tw-h-4 tw-w-4" />
                            </div>

                            <div
                              style={{
                                wordBreak: "break-all",
                              }}
                            >
                              {ticket.attachment.name}
                            </div>
                          </div>
                          <div>
                            <button
                              type="button"
                              onClick={() => {
                                downloadFile(ticket.id, ticket.attachment.name);
                              }}
                            >
                              <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-gray-500" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="ticket-view__transfers">
                    <button
                      type="button"
                      onClick={() => setShowTicketTransfersModal(true)}
                      className="tw-bg-teal-700 tw-py-2 tw-px-8 sm:tw-px-8 tw-rounded tw-text-white"
                    >
                      {t("support.ticket_view.ticket_transfers")}
                    </button>
                  </div>

                  <div className="">
                    <Formik
                      initialValues={{
                        reply: "",
                        file: "",
                      }}
                      onSubmit={async (values, { resetForm }) => {
                        try {
                          const payload = {
                            type: "reply on ticket",
                            comment: values.reply,
                            file: values.file,
                          };
                          let response = await ticketAction(payload, id);
                          if (response.status === 200 && response.data.status) {
                            toast.success(<span style={{ fontSize: 15 }}>{t("addCommentSuccess")}</span>);
                            resetForm();
                            fetchTicket();
                            const element = document.getElementById("replies-container");
                            if (element) {
                              element.scrollIntoView(
                                {
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "center",
                                },
                                1000
                              );
                            }
                          } else {
                            alert("Failure");
                          }
                        } catch (err) {
                          if (
                            err &&
                            err.data &&
                            err.data.errors != null &&
                            typeof err.data.errors === "object" &&
                            !Array.isArray(err.data.errors)
                          ) {
                            const issues = [];
                            Object.keys(err.data.errors).forEach((key) => {
                              issues.push(Array.isArray(err.data.errors[key]) ? err.data.errors[key][0] : "");
                            });
                            toast.error(
                              <>
                                <span style={{ fontSize: 14 }}>{err.data.msg}</span>
                                <ul>
                                  {issues.map((item, index) => {
                                    return (
                                      <li key={index}>
                                        <span style={{ fontSize: 12 }}>{item}</span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </>
                            );
                          } else if (typeof err === "string") {
                            toast.error(<span style={{ fontSize: 14 }}>{err}</span>);
                          }
                        }
                      }}
                      validateOnChange={true}
                      validate={(values) => {
                        const errors = {};
                        const activeType = types.find((item) => Number(item.id) === Number(ticket.type_id));
                        if (!values.reply) {
                          errors.reply = t("crud.errors.required");
                        }

                        if (ticket?.type?.attachment?.value === "required" && !values.file) {
                          errors.file = t("crud.errors.required");
                        } else if (values.file) {
                          const fileExtension = values.file.name.split(".").pop().toLowerCase();
                          const fileSize = values.file.size / 1024;
                          const allowedFileType = activeType?.allowed_files.find(
                            (type) => type.extension === fileExtension
                          );
                          if (!allowedFileType) {
                            errors.file = t("crud.errors.file_extension_not_allowed");
                          }
                          if (Number.parseFloat(fileSize).toFixed(2) >= allowedFileType?.size) {
                            errors.file = `${t("crud.errors.file_size_too_large")} (${allowedFileType?.size} KB)`;
                          }
                        }
                        return errors;
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setErrors,
                      }) => (
                        <form onSubmit={handleSubmit} className="tw-space-y-4">
                          {/* reply field */}
                          {ticket.status.value === "closed" ? null : (
                            <>
                              <div className="tw-flex tw-flex-col tw-gap-4">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("ticket.reply")}</div>

                                {/* <CKEditor
                                  editor={ClassicEditor}
                                  className="tw-block tw-w-full tw-border tw-border-gray-300 tw-rounded"
                                  data={values.reply}
                                  onReady={(editor) => {
                                    setFieldValue("reply", editor?.getData());
                                    if (editor)
                                      editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                                        return new UploadAdapter(loader);
                                      };
                                  }}
                                  config={{
                                    simpleUpload: {
                                      uploadUrl: "http://tajah-tebx.test/api/ckeditor/image-upload",
                                      withCredentials: true,

                                      // Headers sent along with the XMLHttpRequest to the upload server.
                                      headers: {
                                        // 'X-CSRF-TOKEN': 'CSRF-Token',
                                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                                      },
                                    },
                                  }}
                                  onChange={(event, editor) => {
                                    setFieldValue("reply", editor?.getData());
                                  }}
                                /> */}
                                <SunEditor
                                  value={values.reply}
                                  onChange={(e) => {
                                    setFieldValue("reply", e);
                                  }}
                                  setOptions={{
                                    height: 200,
                                    rtl: i18n.language === "ar" ? true : false,
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize", "formatBlock"],
                                      ["paragraphStyle", "blockquote"],
                                      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                      ["fontColor", "hiliteColor", "textStyle"],
                                      ["removeFormat"],
                                      ["outdent", "indent"],
                                      ["showBlocks", "codeView"],
                                      ["align", "horizontalRule", "list", "lineHeight"],
                                      ["table", "link", "image", "video", "audio"],
                                    ],
                                  }}
                                  setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                                  name="reply"
                                  id="reply"
                                  setContents={values.reply}
                                />
                                <div className={"form-input-error-space"}>{errors.reply ? errors.reply : null}</div>
                                <div className="">
                                  {ticket.owner === true && ticket.user.is_admin === false ? null : (
                                    <div style={{ width: "100%" }}>
                                      <ReactSelect
                                        name="require_approval"
                                        id="require_approval"
                                        options={replies || []}
                                        getOptionLabel={(option) =>
                                          i18n.language === Lang.AR ? option.message_ar : option.message_en
                                        }
                                        getOptionValue={(option) => option?.id}
                                        onChange={(item) => {
                                          if (!item) return;
                                          setFieldValue(
                                            "reply",
                                            i18n.language === Lang.AR
                                              ? item.message_ar.replace(/\r\n/g, "<br>").replace(/ /g, "&nbsp;")
                                              : item.message_en.replace(/\r\n/g, "<br>").replace(/ /g, "&nbsp;")
                                          );
                                        }}
                                        placeholder={t("chat.answer_reply")}
                                        styles={{
                                          control: (base, state) => ({
                                            ...base,
                                            maxHeight: 50,
                                            boxShadow: "none",
                                            backgroundColor: "transparent",
                                            cursor: "pointer",
                                            minWidth: "250px",
                                            height: 50,
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: 10,
                                          }),
                                        }}
                                        isClearable
                                        isSearchable
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              {ticket?.type?.attachment?.value !== "not allowed" && (
                                <FilesDragAndDropInput
                                  id={"file"}
                                  label={t("general.add_attachment")}
                                  placeholder={t("general.add_attachment")}
                                  buttonTitle={
                                    <FileIcon className="tw-w-4 tw-h-4 tw-mx-2 tw-fill-white tw-stroke-white" />
                                  }
                                  name={"file"}
                                  accept={`${
                                    ticket?.type?.allowed_files?.map((item) => {
                                      return `.${item.extension}`;
                                    }) ?? ""
                                  }`}
                                  error={errors.file}
                                  value={values.file}
                                  onChange={(files) => files[0] && setFieldValue("file", files[0], true)}
                                  canRemove={true}
                                  removeAction={() => {
                                    setFieldValue("file", "");
                                  }}
                                />
                              )}
                            </>
                          )}

                          <div className="tw-flex tw-items-center tw-justify-end tw-space-s-4 tw-pt-8 ticket__view-actions">
                            {ticket.status.value != "closed" && (
                              <>
                                {checkDisplayReplyButton() && (
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="tw-bg-[#8599B6] tw-py-2 tw-px-8 sm:tw-px-16 tw-rounded tw-text-white"
                                  >
                                    {t("general.add_reply")}
                                  </button>
                                )}

                                {checkDisplayCloseTicketButton() && (
                                  <button
                                    type="button"
                                    onClick={() => setClose(true)}
                                    className="tw-bg-red-500 tw-py-2 tw-px-8 sm:tw-px-16 tw-rounded tw-text-white"
                                  >
                                    {t("modal.common.closeTicket")}
                                  </button>
                                )}
                                {checkDisplayTransferButton() && (
                                  <button
                                    type="button"
                                    onClick={() => setRetransfer(true)}
                                    className="tw-bg-teal-700 tw-py-2 tw-px-8 sm:tw-px-16 tw-rounded tw-text-white"
                                  >
                                    {t("modal.common.reassign")}
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TicketView;

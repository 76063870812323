import { specialtiesListConstants } from "../../Constants/specialties/specialtiesList.constants";

const initialState = {
  specialtiesList: [],
  specialtiesListMeta: {},
  specialtiesListLoading: false,
  specialtiesListError: null,
  specialtiesListSuccess: false,

  updateSpecialtyStatusLoading: false,
  updateSpecialtyStatusError: null,
  updateSpecialtyStatusSuccess: false,
};

const specialtiesListReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET SPECIALTIES LIST
    case specialtiesListConstants.GET_SPECIALTIES_LIST_REQUEST:
      return {
        ...state,
        specialtiesListLoading: true,
      };

    case specialtiesListConstants.GET_SPECIALTIES_LIST_SUCCESS:
      return {
        ...state,
        specialtiesListLoading: false,
        specialtiesListSuccess: true,
        specialtiesListError: null,
        specialtiesList: action.payload.specialties.data,
        specialtiesListMeta: action.payload.specialties.meta,
      };

    case specialtiesListConstants.GET_SPECIALTIES_LIST_REJECTED:
      return {
        ...state,
        specialtiesListLoading: false,
        specialtiesListError: action.payload,
        specialtiesListSuccess: false,
      };

    // UPDATE SPECIALTY STATUS
    case specialtiesListConstants.UPDATE_SPECIALTY_LIST_STATUS_REQUEST:
      return {
        ...state,
        updateSpecialtyStatusLoading: true,
      };

    case specialtiesListConstants.UPDATE_SPECIALTY_LIST_STATUS_SUCCESS:
      return {
        ...state,
        updateSpecialtyStatusLoading: false,
        updateSpecialtyStatusSuccess: true,
        updateSpecialtyStatusError: null,
        specialtiesList: state.specialtiesList.map((specialty) => {
          if (specialty.id === action.payload.id) {
            return {
              ...specialty,
              status: !specialty.status,
            };
          }
          return specialty;
        }),
      };

    case specialtiesListConstants.UPDATE_SPECIALTY_LIST_STATUS_REJECTED:
      return {
        ...state,
        updateSpecialtyStatusLoading: false,
        updateSpecialtyStatusError: action.payload,
        updateSpecialtyStatusSuccess: false,
      };

    default:
      return state;
  }
};

export default specialtiesListReducer;

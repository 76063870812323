import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TrainingCourseProfileContext } from "../../../../../Services/api/toutorials/TrainingCourseProfileContext";
import TrainingCourseSyllabusContentRow from "../TrainingCourseSyllabusContentRow/TrainingCourseSyllabusContentRow";

function TrainingCourseSyllabusContent(props) {
  require("./TrainingCourseSyllabusContent.css");
  const { t } = useTranslation();
  const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);
  useEffect(() => {
    for (let i = 0; i < trainingCourseProfileContext.syllabus.length; i++) {
      trainingCourseProfileContext.syllabus[i].number = i + 1;
    }
  }, [trainingCourseProfileContext.syllabus]);
  return (
    <>
      <div className="training-course-syllabus-section-container">
        <div
          className="training-course-syllabus-section-container-border"
          style={{
            height: "unset",
            opacity: trainingCourseProfileContext.syllabus.length > 0 ? 1 : 0,
          }}
        >
          {trainingCourseProfileContext.syllabus.map((item, index) => {
            return (
              <TrainingCourseSyllabusContentRow key={index} chapter={item} courseId={props.courseId} userType={localStorage.getItem('type')} />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default TrainingCourseSyllabusContent;

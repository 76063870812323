import React from "react";
import classes from "./radioButton.module.css";
const RadioButton = ({ name, label, error, required, readonly, buttons }) => {
  return (
    <div className={classes["input-field"]}>
      <label htmlFor={name}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <div className={classes["radio-list"]}>
        {buttons.map((button) => (
          <div className={classes["radio-button"]} key={button.name}>
            <input
              type="radio"
              id={button.name}
              name={button.name}
              onChange={button.onChange}
              checked={button.checked}
              value={button.value}
              readOnly={readonly}
              disabled={readonly}
            />
            <label htmlFor={button.name}>{button.label}</label>
          </div>
        ))}
      </div>
      {<p className={`form-input-error-space ${classes["input-field-error"]}`}>{error && error}</p>}
    </div>
  );
};

export default RadioButton;

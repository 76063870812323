import moment from "moment";
import { useTranslation } from "react-i18next";
import { utcToLocal } from "../../../utils/utcToLocal";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import classes from "./detailedReport.module.css";

const DetialedReportTable = ({ isAll }) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "name",
      name: t("name_student"),
      key: "name",
      cell: ({ rowData }) => <div className={classes["coupon-list__table-name"]}>{rowData?.name || "-"}</div>,
    },
    {
      id: "type",
      name: t("type"),
      key: "type",
      cell: ({ rowData }) => <>{rowData?.type}</>,
    },
    ...(isAll
      ? [
          {
            id: "main_chapter",
            name: t("main_chapter"),
            key: "main_chapter",
            cell: ({ rowData }) => <>{rowData?.main_chapter}</>,
          },
          {
            id: "subChapter",
            name: t("subChapter"),
            key: "subChapter",
            cell: ({ rowData }) => <>{rowData?.subChapter}</>,
          },
          {
            id: "lesson",
            name: t("lesson"),
            key: "lesson",
            cell: ({ rowData }) => <>{rowData?.lesson}</>,
          },
        ]
      : []),

    {
      id: "views",
      name: t("views"),
      key: "views",
      cell: ({ rowData }) => <>{rowData?.views}</>,
    },

    {
      id: "include_view_per",
      name: t("include_view_per"),
      key: "include_view_per",
      cell: ({ rowData }) => <>{rowData?.include_view_per}</>,
    },
    {
      id: "include_achivement_per",
      name: t("include_achivement_per"),
      key: "include_achivement_per",
      cell: ({ rowData }) => <>{rowData?.include_achivement_per}</>,
    },

    {
      id: "created_at",
      name: t("general.added_date"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
    },
  ];

  return (
    <>
      <MainTable
        columns={columns}
        data={[]} //taxsList ||
        isLoading={false} //taxsListLoading
      />
    </>
  );
};

export default DetialedReportTable;

import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getInvoices } from "../../../Services/api/sponsor/SponsorProvider";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";

export default function Invoice() {
    require('./invoice.css');
    const { t } = useTranslation();
    const history = useHistory();
    const [ loading, setLoading ] = useState(false);
    const [ invoice, setInvoices ] = useState([]);

    useEffect(() => {
        setLoading(true)
        getInvoices()
          .then((res) => {
            if (res.status && res.status == 200 && res.data.status) {
                setInvoices(res.data.data.invoices)
                setLoading(false);
            }
          })
          .catch((err) => {
            
          });
      }, []);



      const downloadFile = (url)=> {
        saveAs(
            url,
            "invoice.pdf"
          );
      }
    return (
        <div>
            <div className="container-fluid profile_wrapper">
                {loading ?
                <div
                    style={{
                        height: "65vh",
                    }}
                >
                    <SkeletonCard />
                </div> :
                <div className="table_bank_tran">

                    <h3 className="table_header"> {t('sponsor.invoice.invoices_list')}</h3>
                    <div className="table-responsive" style={{
                        width: "90%"
                    }}>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">{t("sponsor.Course_Program")}</th>
                                    <th scope="col">{t("sponsor.invoice.Payment_Date")}</th>
                                    <th scope="col">{t("sponsor.invoice.amount")}</th>
                                    <th scope="col">{t("sponsor.invoice.sponsor")}</th>
                                    <th scope="col">{t("sponsor.invoice.invoice_tb")}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {invoice.length == 0 ? (
                                <tr>
                                     <td
                                      colSpan={6}
                                       style={{ fontSize: "20px", textAlign: "center" }}
                                       >
                                          {t("no_record")}
                                    </td>
                                 </tr>
                               ) : (
                                invoice.map((invoice, index) => (
                                    <tr key={invoice.id}>
                                        <td>{invoice.item}</td>
                                        <td>{invoice.date}</td>
                                        <td>{invoice.to_be_paid}</td>
                                        <td>{invoice.sponsor.length == 0 ? "" : invoice.sponsor }</td>
                                        <td style={{color: "#047dff"}} onClick={()=> downloadFile(invoice.url)}>
                                            {invoice.sponsor.length == 0 ? invoice.id : ""}
                                        </td>
                                    </tr>
                                )))}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </div>
        </div>

    )
}

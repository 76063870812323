import { Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { guideLines } from "../../Services/api/toutorials/termsAndConditionsProvider";
import i18n from "../../i18n/i18n";
import { Lang } from "../../utils";
import { BoxCard, ContainerBox } from "../Shared/Components";

function Guidelines(props) {
  const { t } = useTranslation();

  // require("./termsAndConditions.css");
  const [guidelines, setGuidelines] = useState("");

  useEffect(() => {
    guideLines()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setGuidelines(res.data.data);
        }
      })
      .catch((err) => {});
  }, []);
  return (
    <Stack component={"section"}>
      <ContainerBox>
        <BoxCard sx={{ mt: "52px" }}>
          <Typography
            component={"h1"}
            variant="h2"
            color={"primary.main"}
            fontWeight={500}
            mb={"32px"}
            fontFamily="dinNextRegular"
          >
            {t("sidebar.label.Guidelines")}
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html:
                i18n.language == Lang.AR
                  ? guidelines.content_ar
                  : guidelines.content_en,
            }}
          ></div>
        </BoxCard>
      </ContainerBox>
    </Stack>
  );
}

export default Guidelines;
